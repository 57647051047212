<template>
    <div>
        <b-form-input
            v-if="filter.type === 'search'"
            :placeholder="filter.placeholder"
            v-model="filter.value"
            @change="onFilterChange(filter)"
            type="search"
        />

        <st-select
            v-if="filter.type === 'select'"
            v-model="filter.value"
            clearable
            customWidth="170px"
            :textField="filter.textField"
            :valueField="filter.valueField"
            :field="filter"
            :model="storedFilters"
            :placeholder="filter.placeholder"
            @change="onFilterChange(filter)"
            @clearFilter="clearChildrenFilter"
            as-filter
        />

        <st-select
            v-if="filter.type === 'contains'"
            v-model="filter.value"
            :textField="filter.textField"
            :valueField="filter.valueField"
            :field="filter"
            :model="storedFilters"
            clearable
            customWidth="170px"
            :placeholder="filter.placeholder"
            @change="onFilterChange(filter)"
            :disabled="!filter.options.length"
            @clearFilter="clearChildrenFilter"
            as-filter
        />

        <st-date-range
            v-if="filter.type === 'daterange'"
            v-model="filter.value"
            startField="start"
            endField="end"
            @change="onFilterChange(filter)"
        />

        <st-autocomplete
            v-if="filter.type === 'autocomplete'"
            v-model="filter.value"
            :options="filter.options"
            :name="filter.name"
            :placeholder="filter.label"
            clearable
            @change="onFilterChange(filter)"
            customClass="st-autocomplete-filter"
        >
        </st-autocomplete>
    </div>
</template>

<script>
export default {
    name: 'StFilterInput',
    props: {
        filter: {
            type: Object,
            required: true,
        },
        storedFilters: {
            type: Object,
        },
    },
    methods: {
        onFilterChange(filter) {
            this.$emit('change', filter);
        },
        clearChildrenFilter(filter) {
            this.$emit('clearFilter', filter);
        }
    },
    watch: {
        'filter.value': { 
            handler(value) {
                if (value === '') {
                    this.onFilterChange(this.filter);
                }
            }
        }
    }
}
</script>
