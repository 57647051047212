import ApiService from '@/shared/services/api.service';
const LS_ATLAS_LAYERS_KEY = 'st_atlas_layers';
const LS_ATLAS_SIDEBAR = 'st_atlas_sidebar';
export class AtlasService {

    static getLayers(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/layers/me', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static getLayersByUats(params = {}) {
        return new Promise((resolve, reject) => {
            ApiService.query( '/layers-simple/me', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static createLayer(data) {
        return new Promise((resolve, reject) => {
            ApiService.post( '/layer-creation-requests/', { ...data } )
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static publishLayer(data) {
        return new Promise((resolve, reject) => {
            ApiService.post( '/layer-creation-requests/publish-layer', { ...data } )
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static compareLayers(data) {
        return new Promise((resolve, reject) => {
            ApiService.post( '/layer-creation-requests/publish-layer/compare-sublayers', { ...data } )
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static updateLayer(id, data) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/layer-creation-requests/${id}`, { ...data })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

     static addLayerIntoContainer(data) {
        return new Promise((resolve, reject) => {
            ApiService.post( '/layer-creation-requests/component', { ...data } )
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static updateLayerIntoContainer(id, payload) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/layer-creation-requests/component/${id}`, { ...payload })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static updateLayerFromContainer(id, payload) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/layers-simple/${id}`, { ...payload })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static updateLayerContainer(id, payload) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/layers/${id}`, { ...payload })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static deleteLayer(id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/layers/${id}`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static deleteSimpleLayer(id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/layers-simple/${id}`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static upload(token, files) {
        return new Promise((resolve, reject) => {
           ApiService.post('/files?echo=true', files, {
               'content-type': 'multipart/form-data',
               'x-file-access-token': token,
           })
               .then((response) => resolve(response.data))
               .catch((error) => reject(error));
        });
    }

    static generateToken() {
        return new Promise((resolve, reject) => {
            ApiService.post( '/generate-token', { ...data } )
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static getLayerCreationStatus(id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/layer-creation-requests/${id}`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static getGisToken() {
        return new Promise((resolve, reject) => {
            ApiService.get('/gis-tokens/get-token')
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static getPersistedLayers() {
        const layersString = window.localStorage.getItem(LS_ATLAS_LAYERS_KEY);
        return layersString ? JSON.parse(layersString) : {};
    }

    static persistLayers(layers) {
        const layersString = JSON.stringify(layers);
        window.localStorage.setItem(LS_ATLAS_LAYERS_KEY, layersString);
    }

    static getPersistedSidebarState() {
        const state =  window.localStorage.getItem(LS_ATLAS_SIDEBAR);
        return state === 'true';
    }

    static persistSidebarState(state) {
        window.localStorage.setItem(LS_ATLAS_SIDEBAR, state);
    }
}
