import { DocumentSeriesAllocationService } from '@/modules/document-series-allocation/document-series-allocation-service';
import Errors from '@/shared/error/errors';

// action types
export const DO_FETCH = 'doFetch';

// mutation types
export const FETCH_SUCCESS = 'fetchSuccess';
export const SET_CURRENT_PAGE = 'setCurrentPage';
export const SET_FILTERS = 'setFilters';
export const UPDATE_RECORD = 'updateRecord';
export const SET_TOKENS = 'setTokens';

const state = {
    rows: [],
    total: 0,
    storeFilters: {},
    currentPage: 1,
    uploadAccessToken: '',
    downloadAccessToken: '',
};

const getters = {
    rows: (state) => state.rows || [],
    total: (state) => state.total,
    filters: (state) => state.storeFilters,
    currentPage: (state) => state.currentPage,
    uploadAccessToken: (state) => state.uploadAccessToken,
    downloadAccessToken: (state) => state.downloadAccessToken,
};

const actions = {
    [DO_FETCH](context, payload) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'documentSeriesAllocation/get', { root: true });
            const filters = { params: { ...payload, pagination: true } };
            DocumentSeriesAllocationService.list(filters)
                .then(({ headers, data }) => {
                    const uploadAccessToken = headers['x-file-upload-access-token'];
                    const downloadAccessToken = headers['x-file-download-access-token'];
                    context.commit(SET_TOKENS, {
                        uploadAccessToken,
                        downloadAccessToken,
                    });
                    context.commit(FETCH_SUCCESS, {
                        rows: data.data,
                        total: data?.pagination?.total,
                    });
                    resolve();
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'documentSeriesAllocation/get', { root: true });
                });
        });
    }
};

const mutations = {
    [FETCH_SUCCESS](state, payload) {
        state.rows = payload.rows;
        state.total = payload.total;
    },
    [SET_CURRENT_PAGE](state, page) {
        state.currentPage = page;
    },
    [SET_FILTERS](state, { key, value }) {
        state.storeFilters[key] = value;
    },
    [UPDATE_RECORD](state, record) {
        const recordIndex = state.rows.findIndex((el) => el.id === record.id);
        Object.assign(state.rows[recordIndex], { ...record });
    },
    [SET_TOKENS](state, { uploadAccessToken, downloadAccessToken}) {
        state.uploadAccessToken = uploadAccessToken;
        state.downloadAccessToken = downloadAccessToken;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
