import ApiService from '@/shared/services/api.service';
import JwtService from '@/shared/services/jwt.service';
import OrgService from '@/shared/services/org.service';
import MenuService from '@/shared/services/menu.service';
import Errors from '@/shared/error/errors';
import { routerAsync } from '@/app-module';

// action types
export const DO_INIT = 'doInit';
export const DO_WAIT_UNTIL_INIT = 'doWaitUntilInit';
export const LOGIN = 'login';
export const LOGOUT = 'logout';
export const REGISTER = 'register';
export const RESET_PASSWORD = 'resetPassword';
export const CHANGE_PASSWORD = 'changePassword';
export const SEND_PASSWORD_RESET_EMAIL = 'sendPasswordResetEmail';
export const SEND_CONFIRMATION_EMAIL = 'sendConfirmationEmail';
export const VERIFY_PHONE = 'verifyPhone';
export const VALIDATE_PHONE = 'validatePhone';
export const AUTH_FACEBOOK = 'authFacebook';
export const AUTH_GOOGLE = 'authGoogle';
export const GET_GOOGLE_LOGIN_URL = 'getGoogleLoginUrl';
export const GET_PROFILE = 'getProfile';
export const UPDATE_PROFILE = 'updateProfile';
export const UPDATE_ACCOUNT_DETAILS = 'updateAccountDetails';
export const AUTH_VERIFICATION_TOKEN = 'authVerificationToken';
export const CHECK_VALIDATION_TOKEN = 'checkValidationToken';
export const ENROLL_STAFF = 'enrollStaff';
export const ENROLL_STAFF_REDIRECT = 'enrollStaffRedirect';
export const ENROLL_STAFF_STATUS = 'enrollStaffStatus';
export const UPDATE_STAFF_ENROLLED = 'updateStaffEnrolled';
export const LOGIN_DELEGATED_ORG = 'loginDelegatedOrg';

// mutation types
export const PURGE_AUTH = 'logOut';
export const SET_AUTH = 'setUser';
export const SET_PROFILE = 'setProfile';
export const SET_ERROR = 'setError';
export const REGISTRATION_SUCCESS = 'registrationSuccess';
export const AUTH_SUCCESS = 'authSuccess';
export const SET_VERIFICATION_TOKEN = 'setVerificationToken';
export const SET_ACCOUNT_DETAILS = 'setAccountDetails';
export const SET_EXPIRED_TOKEN = 'setExpiredToken';
export const SET_VIDEO_ENROLLMENT_TOKEN = 'setVideoEnrollmentToken';
export const SET_PROFILE_ENROLLED = 'setProfileEnrolled';
export const SET_INITIAL_STATE = 'setInitialState';

import Roles from '@/security/roles';
import { storeAsync } from '@/app-module';
import { isObjectEmpty } from '@/core/helpers/globalMethods';

const state = {
    loadingInit: true,
    verificationToken: null,
    authToken: null,
    errors: null,
    user: {},
    isAuthenticated: !!JwtService.getToken(),
    isEmailConfirmed: false,
    isPhoneConfirmed: false,
    isAuthSucceeded: false,
    accountDetails: {},
    isTokenExpired: false,
    videoEnrollmentToken: null,
    initialState: {}
};

const getters = {
    loadingInit: (state) => !!state.loadingInit,
    currentUser: (state) => state.user,
    isAuthenticated: (state) => state.isAuthenticated,
    isEmailConfirmed: (state) => state.isEmailConfirmed,
    isPhoneConfirmed: (state) => state.isPhoneConfirmed,
    isAuthSucceeded: (state) => state.isAuthSucceeded,
    accountDetails: (state) => state.accountDetails,
    isTokenExpired: (state) => state.isTokenExpired,
    currentUserType: (state) => state.user?.user_type_name,
    verificationToken: (state) => state.verificationToken,
    videoEnrollmentToken: (state) => state.videoEnrollmentToken,
    isStaff: (state, getters) => Roles.staffRoles.includes(getters.currentUserType),
    isLegalEntity: (state, getters) => Roles.values.legal_entity === getters.currentUserType,
    registrationUserType: (state) => state.user?.registration_user_type,
};

const actions = {
    async [DO_INIT](context) {
        try {
            const token = JwtService.getToken();
            if (token) {
                const store = storeAsync();
                const state = store.state;
                // Get initial state on refresh page
                context.commit(SET_INITIAL_STATE, {...JSON.parse(JSON.stringify(state))});

                const orgId = OrgService.geOrgId();
                const response = await ApiService.get('profiles/me');
                const userDetails = await ApiService.get('users/me');
                context.commit(SET_PROFILE, { ...response.data, permissions: userDetails.data.permissions });
                context.commit(SET_ACCOUNT_DETAILS , userDetails.data);
                context.commit(SET_AUTH, {
                    data: {
                        authToken: token,
                    },
                    isEmailConfirmed: true,
                    isPhoneConfirmed: true,
                });
                // Keep selected (if present) the current selected organisation after page refresh
                if (orgId) {
                    await ApiService.get('profiles/me');
                    context.dispatch('administrator/getInstitution', orgId, { root: true });
                }
            } else {
                context.commit(PURGE_AUTH);
            }
        } catch (error) {
            context.commit(PURGE_AUTH);
            context.commit('administrator/clearInstitution', null, { root: true });
        }
    },
    async [DO_WAIT_UNTIL_INIT](context) {
        if (!context.getters.loadingInit) {
            return Promise.resolve();
        }

        return new Promise((resolve) => {
            const waitUntilInitInterval = setInterval(() => {
                if (!context.getters.loadingInit) {
                    clearInterval(waitUntilInitInterval);
                    resolve();
                }
            }, 500);
        });
    },
    [LOGIN](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.post('auth/login', credentials)
                .then(({ data }) => {
                    const { isEmailConfirmed, isPhoneConfirmed } = data;
                    if (!isEmailConfirmed || !isPhoneConfirmed) {
                        context.commit(AUTH_SUCCESS, {
                            email: credentials.email,
                            ...data,
                        });
                        if (data.data && data.data.verificationToken) {
                            context.commit(SET_VERIFICATION_TOKEN, data.data.verificationToken);
                        }
                        resolve(data);
                    } else {
                        context.commit(SET_AUTH, data);
                        context.dispatch('setExpiredToken', false)
                        const store = storeAsync();
                        // Get initial state on login
                        context.commit(SET_INITIAL_STATE, {...JSON.parse(JSON.stringify(store.state))});
                        resolve(data);
                    }
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },
    [AUTH_FACEBOOK](context, accessToken) {
        return new Promise((resolve, reject) => {
            ApiService.post('auth/facebook', { accessToken })
                .then(({ data }) => {
                    const { isEmailConfirmed, isPhoneConfirmed } = data;
                    if (!isEmailConfirmed || !isPhoneConfirmed) {
                        context.commit(AUTH_SUCCESS, data);
                        if (data.data && data.data.verificationToken) {
                            context.commit(SET_VERIFICATION_TOKEN, data.data.verificationToken);
                        }
                        resolve(data);
                    } else {
                        context.commit(SET_AUTH, data);
                        const store = storeAsync();
                        // Get initial state on login with FB
                        context.commit(SET_INITIAL_STATE, {...JSON.parse(JSON.stringify(store.state))});
                        resolve(data);
                    }
                })
                .catch((error) => {
                    Errors.handle(error);
                });
        });
    },
    [GET_GOOGLE_LOGIN_URL](context) {
      return new Promise((resolve, reject) => {
          ApiService.get('auth/google/url')
              .then(({ data }) => {
                const { url } = data.data;
                    resolve(url);
              })
              .catch((error) => {
                  Errors.handle(error);
              });
      });
    },
    [AUTH_GOOGLE](context, token) {
        return new Promise((resolve, reject) => {
            ApiService.post('auth/google/', { token })
                .then(({ data }) => {
                    const { isEmailConfirmed, isPhoneConfirmed } = data;
                    if (!isEmailConfirmed || !isPhoneConfirmed) {
                        context.commit(AUTH_SUCCESS, data);
                        if (data.data && data.data.verificationToken) {
                            context.commit(SET_VERIFICATION_TOKEN, data.data.verificationToken);
                        }
                        resolve(data);
                    } else {
                        context.commit(SET_AUTH, data);
                        const store = storeAsync();
                        // Get initial state on login with Google
                        context.commit(SET_INITIAL_STATE, {...JSON.parse(JSON.stringify(store.state))});
                        resolve(data);
                    }
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [LOGOUT](context) {
        const payload = {
            token: JwtService.getToken()
        };

        if (payload.token) {
            return new Promise((resolve) => {
                ApiService.post('token-invalidate-requests', payload).then(() => {
                    context.dispatch('setMenu', 'default', { root: true });
                    context.commit(PURGE_AUTH);
                    context.commit('administrator/clearInstitution', null, { root: true });

                    const initialState = context.state.initialState;
                    if (!isObjectEmpty(initialState)) {
                        const { auth , ...values} = context.state.initialState;
                        const store = storeAsync();
                        // Replace store with initial one, beside auth. Auth state is changed on logout so it must be the same
                        store.replaceState({...store.state,...values});
                    }
                    resolve();
                }) .catch((error) => {
                    context.commit(PURGE_AUTH);
                    const router = routerAsync();
                    router.push({ name: 'signin' });
                });
            });
        } else {
            context.commit(PURGE_AUTH);
        }
    },
    [REGISTER](context, credentials) {
        return new Promise((resolve) => {
            ApiService.post('auth/register', credentials)
                .then(({ data }) => {
                    context.commit(REGISTRATION_SUCCESS, {
                        email: credentials.email,
                        ...data,
                    });
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                });
        });
    },
    [RESET_PASSWORD](context, payload) {
        context.commit('shared/activateLoading', 'auth/resetPassword', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.patch('auth/reset-password', payload)
                .then(({ data }) => {
                    context.commit(PURGE_AUTH);
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'auth/resetPassword', { root: true });
                });
        });
    },
    [CHANGE_PASSWORD](context, payload) {
        context.commit('shared/activateLoading', 'auth/changePassword', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.post('auth/change-password', payload)
                .then(({ data }) => {
                    context.commit(PURGE_AUTH);
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'auth/changePassword', { root: true });
                });
        });
    },
    [SEND_PASSWORD_RESET_EMAIL](context, payload) {
        return new Promise((resolve) => {
            const email = payload;
            ApiService.post('recover-password', email)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                });
        });
    },
    [SEND_CONFIRMATION_EMAIL](context, payload) {
        return new Promise((resolve) => {
            const email = payload;
            ApiService.query('auth/resend', { params: { email } })
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    Errors.handle(error);
                });
        });
    },
    [VERIFY_PHONE](context, payload) {
        return new Promise((resolve, reject) => {
            const { phone, token } = payload;
            const body = {
                phone,
                token
            };
            ApiService.post('auth/phone/update', body)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    [VALIDATE_PHONE](context, payload) {
        return new Promise((resolve, reject) => {
            const { phone, smsCode, token } = payload;
            const body = {
                phone,
                smsCode,
                token
            };
            ApiService.post('auth/phone/validate', body)
                .then(({ data }) => {
                    context.commit(SET_AUTH, data);
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject(error);
                });
        });
    },
    [UPDATE_PROFILE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.put('profiles/me', payload)
                .then(({ data }) => {
                    context.commit(SET_PROFILE, data);
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                });
        });
    },
    [GET_PROFILE](context) {
        return new Promise((resolve, reject) => {
            ApiService.get('profiles/me')
                .then(async ({ data }) => {
                    const userDetails = await ApiService.get('users/me');
                    context.commit(SET_PROFILE, { ...data, permissions: userDetails?.data?.permissions });
                    context.commit(SET_ACCOUNT_DETAILS , userDetails.data);
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    [AUTH_VERIFICATION_TOKEN](context, token) {
        context.commit(SET_VERIFICATION_TOKEN, token);
    },
    [UPDATE_ACCOUNT_DETAILS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.patch('users', payload)
                .then(({ data }) => {
                    context.commit(SET_ACCOUNT_DETAILS, data);
                    resolve(data);
                })
                .catch(({ error }) => {
                    context.commit(SET_ERROR, error.data.errors);
                    reject(error);
                });
        });
    },
    [SET_EXPIRED_TOKEN](context, tokenState) {
        context.commit(SET_EXPIRED_TOKEN, tokenState);
    },
    [CHECK_VALIDATION_TOKEN](context, token) {
        return new Promise((resolve, reject) => {
            ApiService.query('auth/check-validation-token', { params: { token } })
                .then(() => {
                    resolve();
                })
                .catch(({ error }) => {
                    reject(error);
                });
        });
    },
    [ENROLL_STAFF](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('video-enrollment/', payload)
                .then(({ data }) => {
                    context.commit(SET_VIDEO_ENROLLMENT_TOKEN, data)
                    resolve(data);
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                });
        });
    },
    [ENROLL_STAFF_REDIRECT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get(`video-enrollment/redirect/${payload.id}`)
                .then((response) => {
                    resolve(response);
                })
                .catch(({ error }) => {
                    reject(error);
                });
        });
    },
    [ENROLL_STAFF_STATUS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get(`video-enrollment/status/${payload.id}`)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ error }) => {
                    reject(error);
                });
        });
    },
    [UPDATE_STAFF_ENROLLED](context) {
        context.commit(SET_PROFILE_ENROLLED, true);
    },
    [LOGIN_DELEGATED_ORG](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('auth/login-delegated-org', payload)
                .then(({ data }) => {
                    context.commit(SET_AUTH, {
                        data: {
                            authToken: data.data.token,
                        },
                        isEmailConfirmed: true,
                        isPhoneConfirmed: true,
                    });
                    OrgService.saveOrgId(payload.organisation_id);
                    resolve(payload.organisation_id);
                })
                .catch(({ error }) => {
                    reject(error);
                });
        });
    }
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
        state.loadingInit = false;
    },
    [SET_AUTH](state, payload) {
        const { isEmailConfirmed, isPhoneConfirmed } = payload;
        const { authToken, verificationToken } = payload.data;
        if (verificationToken) {
            state.verificationToken = verificationToken;
        }
        state.isAuthSucceeded = true;
        state.isEmailConfirmed = isEmailConfirmed;
        state.isPhoneConfirmed = isPhoneConfirmed;
        state.errors = {};
        if (authToken) {
            state.authToken = authToken;
            state.isAuthenticated = true;
            JwtService.saveToken(authToken);
        }
        state.loadingInit = false;
    },
    [PURGE_AUTH](state) {
        state.isAuthenticated = false;
        state.isEmailConfirmed = false;
        state.isPhoneConfirmed = false;
        state.authToken = null;
        state.verificationToken = null;
        state.user = {};
        state.accountDetails = {};
        state.errors = {};
        JwtService.destroyToken();
        OrgService.destroyOrgId();
        MenuService.destroyMenuStatus();
        state.loadingInit = false;
        state.isTokenExpired = false;
    },
    [SET_PROFILE](state, profile) {
        state.user = {
            ...state.user,
            ...profile
        };
    },
    [REGISTRATION_SUCCESS](state, payload) {
        state.user.email = payload.email;
        state.isEmailConfirmed = payload.isEmailConfirmed;
        state.isAuthSucceeded = true;
    },
    [AUTH_SUCCESS](state, payload) {
        state.user.email = payload.email;
        state.isEmailConfirmed = payload.isEmailConfirmed;
        state.isPhoneConfirmed = payload.isPhoneConfirmed;
        state.isAuthSucceeded = true;
    },
    [SET_VERIFICATION_TOKEN](state, token) {
        state.verificationToken = token;
    },
    [SET_ACCOUNT_DETAILS](state, details) {
        state.accountDetails = {
            ...state.accountDetails,
            ...details
        };
    },
    [SET_EXPIRED_TOKEN](state, tokenState) {
        state.isTokenExpired = tokenState;
    },
    [SET_VIDEO_ENROLLMENT_TOKEN](state, payload) {
        state.videoEnrollmentToken = payload.context;
    },
    [SET_PROFILE_ENROLLED](state, enrolled) {
        state.user.enrolled = enrolled;
    },
    [SET_INITIAL_STATE](state, obj) {
        state.initialState = obj;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
