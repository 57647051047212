// action types
export const UPDATE_BULDING_DETAILS_ERROR = 'updateBuildingDetailsError';

// mutation types
export const SET_BUILDING_DETAILS_ERROR = 'setBuildingDetailsError';

const state = {
    buildingDetails: {
        validationError: false,
    },
};

const getters = {
    buildingDetails(state) {
        return state.buildingDetails;
    },
};

const actions = {
    [UPDATE_BULDING_DETAILS_ERROR](context, payload) {
        context.commit(SET_BUILDING_DETAILS_ERROR, payload);
    },
};

const mutations = {
    [SET_BUILDING_DETAILS_ERROR](state, error) {
        state.buildingDetails.validationError = error;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};