<template>
    <ApplicationTableCustomField
        fieldType="beneficiary"
        :items="dataObj.item.form.beneficiary_users"
    />
</template>

<script>
import ApplicationTableCustomField from '@/modules/applications/components/ApplicationTableCustomField';
export default {
    name: 'BeneficiaryCustomField',
    components: { ApplicationTableCustomField },
    props: {
        dataObj: {
            type: Object,
        }
    },
};
</script>
