import Vue from 'vue';
import Vuex from 'vuex';
import ProgressBar from '@/shared/progress-bar/progress-bar';
import Router from 'vue-router';
import auth from '@/modules/auth/auth-module';
import layout from '@/modules/layout/layout-module';
import home from '@/modules/home/home-module';
import dashboard from '@/modules/dashboard/dashboard-module';
import profile from '@/modules/profile/profile-module';
import requests from '@/modules/requests/requests-module';
import shared from '@/shared/shared-module';
import notifications from '@/modules/notifications/notifications-module';
import beneficiary from '@/modules/beneficiary/beneficiary-module';
import administrator from '@/modules/administrator/administrator-module';
import accounts from '@/modules/accounts/accounts-module';
import taxCategory from '@/modules/tax-category/tax-category-module';
import complainType from '@/modules/complain-type/complain-type-module';
import responseTemplates from '@/modules/response-templates/response-templates-module';
import applications from '@/modules/applications/applications-module';
import complain from '@/modules/complain/complain-module';
import responsibilities from '@/modules/responsibilities/responsibilities-module';
import archive from '@/modules/archive/archive-module';
import documentTemplate from '@/modules/document-template/document-template-module';
import forms from '@/modules/forms/forms-module';
import applicationType from '@/modules/application-type/application-type-module';
import processEditor from '@/modules/process-editor/process-editor-module';
import permissions from '@/modules/permissions/permissions-module';
import metadataMapping from '@/modules/metadata-mapping/metadata-mapping-module';
import atlas from '@/modules/atlas/atlas-module';
import decisionTarget from '@/modules/decision-target/decision-target-module';
import decisionPurpose from '@/modules/decision-purpose/decision-purpose-module';
import documentSeriesType from '@/modules/document-series-type/document-series-type-module';
import paymentRegistry from '@/modules/payment-registry/payment-registry-module';
import issuedDocument from '@/modules/issued-document/issued-document-module';
import control from '@/modules/control/control-module';
import documentSeriesAllocation from '@/modules/document-series-allocation/document-series-allocation-module';

const modules = {
    shared,
    home,
    auth,
    layout,
    dashboard,
    profile,
    notifications,
    beneficiary,
    requests,
    administrator,
    accounts,
    taxCategory,
    responsibilities,
    complainType,
    responseTemplates,
    applications,
    complain,
    archive,
    documentTemplate,
    forms,
    applicationType,
    processEditor,
    permissions,
    metadataMapping,
    atlas,
    decisionTarget,
    decisionPurpose,
    documentSeriesType,
    paymentRegistry,
    issuedDocument,
    control,
    documentSeriesAllocation,
};

const exists = (el) => !!el;

function setupComponentsFiltersDirectivesAndMixins() {
    Object.keys(modules)
        .map((key) => modules[key].components)
        .filter(exists)
        .forEach((components) => {
            components.forEach((component) => {
                Vue.component(component.name, component);
            });
        });

    Object.keys(modules)
        .map((key) => modules[key].filters)
        .filter(exists)
        .forEach((components) => {
            components.forEach((filter) => {
                Vue.filter(filter.name, filter.implementation);
            });
        });

    Object.keys(modules)
        .map((key) => modules[key].directives)
        .filter(exists)
        .forEach((directives) => {
            directives.forEach((directive) => {
                Vue.directive(directive.name, directive.implementation);
            });
        });

    Object.keys(modules)
        .map((key) => modules[key].mixins)
        .filter(exists)
        .forEach((mixins) => {
            mixins.forEach((mixin) => {
                Vue.mixin(mixin);
            });
        });
}

const routes = [
    ...Object.keys(modules)
        .filter((key) => !!modules[key].routes)
        .map((key) => modules[key].routes)
        .reduce((a, b) => a.concat(b), []),
    {
        path: '*',
        redirect: '/404',
    },
];

const buildStores = () => {
    const output = {};

    Object.keys(modules)
        .filter((key) => !!modules[key].store)
        .forEach((key) => {
            output[key] = modules[key].store;
        });
    return output;
};

let store = null;

const storeAsync = () => {
    if (!store) {
        store = new Vuex.Store({ modules: buildStores() });
    }

    return store;
};

let router = null;

const routerAsync = () => {
    if (!router) {
        router = new Router({
            mode: 'history',
            routes,
            scrollBehavior() {
                return { x: 0, y: 0 };
            },
        });

        router.beforeEach((to, from, next) => {
            if (to.name) {
                ProgressBar.start();
            }

            next();
        });

        router.afterEach(() => {
            ProgressBar.done();
        });
    }

    return router;
};

export { setupComponentsFiltersDirectivesAndMixins, routerAsync, storeAsync };
