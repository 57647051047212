import routes from '@/modules/atlas/atlas-routes';
import store from '@/modules/atlas/atlas-store';
 
export default {
    components: [],
    filters: [],
    mixins: [],
    routes,
    store,
};
