import ApiService from '@/shared/services/api.service';
import { ProfilePermissions } from './profile-permissions';

// action types
export const GET_LOCALITY = 'getLocalities';
export const GET_COUNTIES = 'getCounties';
export const GET_VILLAGES = 'getVillages';

// mutation types
export const SET_COUNTIES = 'setCounties';
export const SET_SETTLEMENTS = 'setSettlements';
export const SET_VILLAGES = 'setVillages';

const state = {
    counties: null,
    settlements: [],
    villages: [],
};

const getters = {
    counties(state) {
        return state.counties;
    },
    settlements (state) {
        return state.settlements;
    },
    villages (state) {
        return state.villages;
    },
    permissions: (state, getters, rootState, rootGetters) => {
        const currentUser = rootGetters['auth/currentUser'];
        return {
            hasPermissionToEditCompanyProfile: new ProfilePermissions(currentUser).editCompany,
            hasPermissionTOEnrollStaff: new ProfilePermissions(currentUser).enrollStaff,
        }
    },
};

const actions = {
    [GET_COUNTIES](context) {
        context.commit('shared/activateLoading', 'profile/getCounties', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.get('counties')
                .then(({ data }) => {
                    context.commit(SET_COUNTIES, data);
                    resolve(data);
                })
                .catch(( error ) => {
                    console.log('error', error);
                    reject(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'profile/getCounties', { root: true });
                })
        });
    },
    [GET_LOCALITY](context, payload) {
        context.commit('shared/activateLoading', 'profile/getLocality', { root: true });
        return new Promise(resolve => {
            ApiService.query('localities', {params: payload})
                .then(({ data }) => {
                    context.commit(SET_SETTLEMENTS, data);
                    resolve(data);
                })
                .catch(({ error }) => {
                    console.log('error', error);
                    reject(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'profile/getLocality', { root: true });
                })
        });
    },
    [GET_VILLAGES](context, payload) {
        context.commit('shared/activateLoading', 'profile/getVillages', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.query('villages', {params: payload})
                .then(({ data }) => {
                    context.commit(SET_VILLAGES, data);
                    resolve(data);
                })
                .catch(({ error }) => {
                    console.log('error', error);
                    reject(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'profile/getVillages', { root: true });
                })
        });
    },
};

const mutations = {
    [SET_COUNTIES](state, counties) {
        state.counties = counties;
    },
    [SET_SETTLEMENTS](state, settlements) {
        state.settlements = settlements;
    },
    [SET_VILLAGES](state, villages) {
        state.villages = villages;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
