import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';

const ApplicationsList = () => import('@/modules/applications/pages/ApplicationsList.vue');
const ApplicationsForm = () => import('@/modules/applications/pages/ApplicationsForm.vue');
const ApplicationView = () => import('@/modules/applications/pages/ApplicationView.vue');
const ApplicationEdit = () => import('@/modules/applications/pages/ApplicationEdit.vue');
const ApplicationsConfirmation = () => import('@/modules/applications/pages/ApplicationConfirmation.vue');
const ApplicationPaymentNotify = () => import('@/modules/applications/pages/ApplicationPaymentNotify.vue');
const ApplicationsPayment = () => import('@/modules/applications/pages/ApplicationsPayment.vue');
const ApplicationDocumentsList = () => import('@/modules/applications/components/view-application/documents/ApplicationDocumentsList.vue');
const ApplicationDocumentsEdit = () => import('@/modules/applications/components/view-application/documents/ApplicationDocumentsEdit.vue');
const ApplicationsAssignedList = () => import('@/modules/applications/pages/ApplicationsAssignedList.vue');

export default [
    {
        path: '',
        component: Layout,
        meta: { auth: true },
        children: [
            {
                name: 'applications',
                path: '/applications',
                component: ApplicationsList,
                meta: {
                    auth: true,
                    permission: Permissions.values.applicationsView 
                }
            },
            {
                name: 'applicationNew',
                path: '/application/new',
                component: ApplicationsForm,
                meta: {
                    auth: true,
                    permission: Permissions.values.applicationCreate
                }
            },
            {
                name: 'applicationEdit',
                path: '/application/:id/edit',
                component: ApplicationEdit,
                meta: {
                    auth: true,
                    permission: Permissions.values.applicationsView 
                },
                props: true,
            },
            {
                name: 'applicationView',
                path: '/application/:id',
                component: ApplicationView,
                children: [
                    {
                        path: 'list',
                        name: 'listDocuments',
                        component: ApplicationDocumentsList,
                        meta: {
                            auth: true,
                        }
                    },
                    {
                        path: 'edit-document',
                        name: 'editDocument',
                        component: ApplicationDocumentsEdit,
                        meta: {
                            auth: true,
                        }
                    }
                ],
                meta: {
                    auth: true,
                    permission: Permissions.values.applicationsView 
                },
                props: true,
            },
            {
                name: 'applicationPayment',
                path: '/application-payment/:id',
                component: ApplicationsPayment,
                meta: {
                    auth: true,
                },
                props: true,
            },
            {
                name: 'applicationConfirmation',
                path: '/application-confirmation/:id',
                component: ApplicationsConfirmation,
                meta: {
                    auth: true,
                },
                props: true,
            },
            {
                name: 'applicationPaymentNotify',
                path: '/application-notify/:id',
                component: ApplicationPaymentNotify,
                meta: {
                    auth: true,
                },
                props: true,
            },
            {
                name: 'applicationsAssigned',
                path: '/applications-assigned',
                component: ApplicationsAssignedList,
                exact: true,
                meta: {
                    auth: true,
                    permission: Permissions.values.applicationsStaffOwnRead 
                }
            },
        ],
    },
];
