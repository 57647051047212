<template>
    <b-dropdown
        class="lang-dropdown"
        size="sm"
        variant="link"
        toggle-class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 text-decoration-none"
        no-caret
        no-flip
    >
        <template v-slot:button-content>
            <img
                class="h-20px w-20px rounded-sm"
                :src="language.flag"
                alt=""
            />
            <span class="ml-2 mr-1">{{ language.name }}</span>
            <b-icon icon="chevron-down" class="pt-1"></b-icon>
        </template>
        <b-dropdown-text tag="div" text-class="px-0">
            <ul class="navi navi-hover py-4">
                <template v-for="(item, i) in languages">
                    <li
                        class="navi-item"
                        :class="{ 'navi-item-active': isActiveLanguage(item.lang) }"
                        :key="i"
                    >
                        <a
                            href=""
                            class="navi-link"
                            @click="selectedLanguage(item.lang)"
                        >
                    <span class="symbol symbol-20 mr-3">
                        <img :src="item.flag" alt="" />
                    </span>
                            <span class="navi-text">{{ item.name }}</span>
                        </a>
                    </li>
                </template>
            </ul>
        </b-dropdown-text>
    </b-dropdown>
</template>

<script>
    import i18nService from '@/shared/services/i18n.service.js';

    export default {
        name: 'LanguageSelect',
        data() {
            return {
                language: '',
                languages: i18nService.languages,
                activeLanguage: i18nService.getActiveLanguage(),
            };
        },
        methods: {
            selectedLanguage(lang) {
                this.language = this.languages.find((val) => {
                    return val.lang === lang;
                });

                i18nService.setActiveLanguage(lang);
                window.location.reload();
            },
            isActiveLanguage(current) {
                return this.activeLanguage === current;
            },
        },
        created() {
            this.language = this.languages.find((val) => {
                return val.lang === i18nService.getActiveLanguage();
            });
        },
    };
</script>
