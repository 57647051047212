import Layout from '@/modules/layout/components/layout';

const Atlas = () => import('@/modules/atlas/pages/Atlas.vue');
const LayerManager = () => import('@/modules/atlas/components/LayerManager.vue');
const ApplicationsManager = () => import('@/modules/atlas/components/ApplicationsManager.vue');
const InterestAreasManager = () => import('@/modules/atlas/components/InterestAreasManager.vue');
const IntersectionManager = () => import('@/modules/atlas/components/IntersectionManager.vue');
const AtlasPublicView = () => import('@/modules/atlas/pages/AtlasPublicView.vue');

export default [
    {
        path: '/atlas-public-view',
        component:  AtlasPublicView,
        meta: { unauth: true },
    },
    {
        path: '/atlas',
        component: Layout,
        meta: { auth: true },
        children: [
            {
                name: 'atlas',
                path: '/',
                redirect: '/atlas/layers',
                component: Atlas,
                meta: {
                    auth: true,
                    isAtlas: true,
                },
                children: [
                    {
                        name: 'layerManager',
                        path: 'layers',
                        component: LayerManager,
                        meta: {
                            auth: true,
                            isAtlas: true,
                        }
                    },
                    {
                        name: 'applicationsManager',
                        path: 'applications',
                        component: ApplicationsManager,
                        meta: {
                            auth: true,
                            isAtlas: true,
                        }
                    },
                    {
                        name: 'interestAreas',
                        path: 'interest-areas',
                        component: InterestAreasManager,
                        meta: {
                            auth: true,
                            isAtlas: true,
                        }
                    },
                    // {
                    //     name: 'layerIntersection',
                    //     path: 'intersection',
                    //     component: IntersectionManager,
                    //     meta: {
                    //         auth: true,
                    //         isAtlas: true,
                    //     }
                    // },
                ]
            },
        ],
    },
];
