import { AtlasService } from '@/modules/atlas/atlas-service';
import Errors from '@/shared/error/errors';
import Message from '@/shared/message/message';
import i18n from '@/shared/plugins/vue-i18n';
import Vue from 'vue';

// action types
export const DO_INIT_STATE = 'doInit';
export const GET_LAYERS = 'getLayers';
export const SHOW_LAYER = 'showLayer';
export const HIDE_LAYER = 'hideLayer';
export const SHOW_SUBLAYER = 'showSublayer';
export const HIDE_SUBLAYER = 'hideSublayer';
export const SHOW_ALL_LAYERS = 'showAllLayers';
export const HIDE_ALL_LAYERS = 'hideAllLayers';
export const CREATE_LAYER = 'createLayer';
export const UPDATE_LAYER = 'updateLayer';
export const DO_UPLOAD = 'upload';
export const GENERATE_TOKEN = 'generateToken';
export const GET_LAYER_CREATION_STATUS = 'getLayerCreationStatus';
export const ADD_LAYER = 'addLayer';
export const SELECT_LAYER = 'selectLayer';
export const HIDE_SUBLAYER_SIDEBAR = 'hideSublayerSidebar';
export const SHOW_PUBLISH_MODAL = 'showPublishModal';
export const HIDE_PUBLISH_MODAL = 'hidePublishModal';
export const SHOW_EDIT_MODAL = 'showEditModal';
export const HIDE_EDIT_MODAL = 'hideEditModal';
export const PUBLISH_LAYER = 'publish';
export const ADD_LAYER_TO_CONTAINER = 'addPublishedLayerToList';
export const DELETE_LAYER = 'deleteLayer';
export const ADD_LAYER_INTO_CONTAINER = 'addLayerToContainer';
export const UPDATE_LAYER_INTO_CONTAINER = 'updateLayerToContainer';
export const DELETE_SIMPLE_LAYER = 'deleteSimpleLayer';
export const UPDATE_LAYER_FROM_CONTAINER = 'updateLayerFromContainer';
export const UPDATE_LAYER_CONTAINER = 'updateLayerContainer';
export const COMPARE_LAYERS = 'compareLayers';
export const SHOW_COMPARE_LAYERS_MODAL = 'showCompareLayersModal';
export const HIDE_COMPARE_LAYERS_MODAL = 'hideCompareLayersModal';

// mutation types
export const INIT_STATE = 'init';
export const SET_SELECTED_LAYER = 'setSelectedLayer';
export const SET_VISIBLE_LAYER = 'setVisibleLayer';
export const REMOVE_VISIBLE_LAYER = 'removeVisibleLayer';
export const SET_VISIBLE_SUBLAYER = 'setVisibleSublayer';
export const REMOVE_VISIBLE_SUBLAYER = 'removeVisibleSublayer';
export const SET_UPLOAD_TOKEN = 'setUploadToken';
export const SET_LAYERS = 'setLayers';
export const SET_LAYER = 'setLayer';
export const REMOVE_SELECTED_LAYER = 'removeSelectedLayer';
export const SET_VISIBLE_LAYERS = 'setVisibleLayers'; // Select layers from container
export const REMOVE_VISIBLE_LAYERS = 'removeVisibleLayers'; // Deselect layers from container
export const SET_PUBLISH_MODAL_VISIBILITY = 'setPublishModalVisibility';
export const SET_PUBLISHING_LAYER = 'setPublishingLayer';
export const REMOVE_PUBLISHING_LAYER = 'removePublishingLayer';
export const SET_EDIT_MODAL_VISIBILITY = 'setEditModalVisibility';
export const SET_EDITING_LAYER = 'setEditingLayer';
export const REMOVE_EDITING_LAYER = 'removeEditingLayer';
export const SET_LAYER_TO_CONTAINER = 'setLayerToContainer';
export const REMOVE_LAYER = 'removeLayer';
export const SET_COMPARE_MODAL_VISIBILITY = 'setCompareLayerModalVisibility';
export const SET_COMPARE_LAYER = 'setCompareLayer';
export const REMOVE_COMPARE_LAYER = 'removeCompareLayer';
export const SET_COMPARED_LAYERS = 'setComparedLayer';
export const SET_LAYER_CREATION_STATUS = 'setLayerCreationStatus';
export const SET_PUBLISH_LAYER_IN_QUEUE = 'setPublishLayerQueue';
export const SET_SIMPLE_LAYERS = 'setSimpleLayers';

const getDefaultState = (state) => ({
    layer: null,
    layers: [],
    selected: state?.selected || [],
    isSublayerSidebarVisible: false,
    isLayerPublishModalVisible: false,
    isLayerEditModalVisible: false,
    publishingLayer: null,
    editingLayer: null,
    isCompareLayersModalVisible: false,
    compareLayersData: null,
    comparedLayersResult: null,
    layerCreationStatus: null,
    publishedLayerQueue: [], 
    simpleLayers: []
});

const state = getDefaultState();

const getters = {
    layer: (state) => state.layer,
    layers: (state) => state.layers,
    simpleLayers: (state) => state.simpleLayers,
    getLayerVisibilityById: (state) => (layerId) => {
        return state.selected?.[layerId]?.visible ?? false;
    },
    getSublayerVisibilityById: (state) => (layerId, sublayerId) => {
        if (!state.selected?.[layerId]?.visible) return false;
        return state.selected?.[layerId]?.sublayers?.[sublayerId]?.visible ?? true;
    },
    visibleLayers: (state) => {
        const layers = [];
        state.layers.forEach((item) => {
            if (item.layer_type === 'container') {
                layers.push(...item.components);
            } else {
                layers.push(item);
            }
        });
        return layers;
    },
    atlasLayers: (state) => state.layers.filter((el) => el.access_level === 'public'),
    privateLayers: (state) => state.layers.filter((el) => el.access_level === 'private'),
    sharedLayers: (state) => state.layers.filter((el) => el.access_level === 'protected'),
    selectedLayers: (state) => state.selected,
    isSublayerSidebarVisible: (state) => !!state.layer,
    isLayerPublishModalVisible: (state) => state.isLayerPublishModalVisible,
    isLayerEditModalVisible: (state) => state.isLayerEditModalVisible,
    containerLayers: (state) => state.layers.filter((el) => el.layer_type === 'container'),
    publishingLayer: (state) => state.publishingLayer,
    editingLayer: (state) => state.editingLayer,
    isCompareLayersModalVisible: (state) => state.isCompareLayersModalVisible,
    compareLayersData: (state) => state.compareLayersData,
    comparedLayersResult: (state) => state.comparedLayersResult,
    layerCreationStatus: (state) => state.layerCreationStatus,
    publishedLayerQueue: (state) => state.publishedLayerQueue,
};

const actions = {
    async [DO_INIT_STATE](context) {
        const persistedLayers = await AtlasService.getPersistedLayers();
        context.commit(INIT_STATE, { selected: persistedLayers });
    },
    async [SHOW_LAYER](context, layer) {
        context.commit(SET_VISIBLE_LAYER, layer);
        const selectedLayers = await context.getters.selectedLayers;
        AtlasService.persistLayers(selectedLayers);
    },
    async [HIDE_LAYER](context, layer) {
        context.commit(REMOVE_VISIBLE_LAYER, layer);
        const selectedLayers = await context.getters.selectedLayers;
        AtlasService.persistLayers(selectedLayers);
    },
    async [SHOW_SUBLAYER](context, payload) {
        context.commit(SET_VISIBLE_SUBLAYER, payload);
        const selectedLayers = await context.getters.selectedLayers;
        AtlasService.persistLayers(selectedLayers);
    },
    async [HIDE_SUBLAYER](context, payload) {
        context.commit(REMOVE_VISIBLE_SUBLAYER, payload);
        const selectedLayers = await context.getters.selectedLayers;
        AtlasService.persistLayers(selectedLayers);
    },
    async [SHOW_ALL_LAYERS](context, layers) {
        await context.commit(SET_VISIBLE_LAYERS, layers);
        const selectedLayers = await context.getters.selectedLayers;
        AtlasService.persistLayers(selectedLayers);
    },
    async [HIDE_ALL_LAYERS](context, layers) {
        await context.commit(REMOVE_VISIBLE_LAYERS, layers);
        const selectedLayers = await context.getters.selectedLayers;
        AtlasService.persistLayers(selectedLayers);
    },
    async [CREATE_LAYER](context, values) {
        context.commit('shared/activateLoading', 'atlas-layer-manager/create', { root: true });
        try {
            const response = await AtlasService.createLayer(values);
            const uploadAccessToken = response.headers['x-file-upload-access-token'];
            const data = response.data;
            return { data, uploadAccessToken };
        } catch(error) {
            Errors.handle(error);
            return data;
        } finally {
            context.commit('shared/deactivateLoading', 'atlas-layer-manager/create', { root: true });
        }
    },
    async [GET_LAYER_CREATION_STATUS](context, id) {
        context.commit('shared/activateLoading', 'atlas-layer-manager/getCreateLayer', { root: true });
        try {
            const response = await AtlasService.getLayerCreationStatus(id);
            context.commit(SET_LAYER_CREATION_STATUS, response);
            return response

        } catch(error) {
            Errors.handle(error);
            return data;
        } finally {
            context.commit('shared/deactivateLoading', 'atlas-layer-manager/getCreateLayer', { root: true });
        }
    },
    async [UPDATE_LAYER](context, { id, values }) {
        context.commit('shared/activateLoading', 'atlas-layer-manager/update', { root: true });
        try {
            return await AtlasService.updateLayer(id, values);
        } catch(error) {
            Errors.handle(error);
            return data;
        } finally {
            context.commit('shared/deactivateLoading', 'atlas-layer-manager/update', { root: true });
        }
    },
    async [DO_UPLOAD](context, payload) {
        context.commit('shared/activateLoading', 'atlas-layer-manager/upload', { root: true });
        try {
            const { uploadAccessToken, files } = payload;
            return await AtlasService.upload(uploadAccessToken, files);
        } catch(error) {
            Errors.handle(error);
            return data;
        } finally {
            context.commit('shared/deactivateLoading', 'atlas-layer-manager/upload', { root: true });
        }
    },
    [GET_LAYERS](context) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'atlas-layer-manager/get', { root: true });
            const params =  {
                limit: 100,
                sort: 'created_date'
            }
            AtlasService.getLayers({params})
                .then((data) => {
                    context.commit(SET_LAYERS, data);
                    context.commit(SET_SIMPLE_LAYERS, data);
                    resolve();
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'atlas-layer-manager/get', { root: true });
                });
        });
    },
    [ADD_LAYER](context, layer) {
        context.commit(SET_LAYER, layer);
    },
    [ADD_LAYER_TO_CONTAINER](context, layer) {
        context.commit(SET_LAYER_TO_CONTAINER, layer);
    },
    [GENERATE_TOKEN](context) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'atlas-layer-manager/generateToken', { root: true });
            AtlasService.generateToken()
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'atlas-layer-manager/generateToken', { root: true });
                });
        });
    },
    [SELECT_LAYER](context, layer) {
        context.commit(SET_SELECTED_LAYER, layer);
    },
    [HIDE_SUBLAYER_SIDEBAR](context) {
        context.commit(REMOVE_SELECTED_LAYER);
    },
    [SHOW_PUBLISH_MODAL](context, layer) {
        context.commit(SET_PUBLISHING_LAYER, layer);
        context.commit(SET_PUBLISH_MODAL_VISIBILITY, true);
    },
    [HIDE_PUBLISH_MODAL](context) {
        context.commit(REMOVE_PUBLISHING_LAYER);
        context.commit(SET_PUBLISH_MODAL_VISIBILITY, false);
    },
    [SHOW_COMPARE_LAYERS_MODAL](context, layer) {
        context.commit(SET_COMPARE_LAYER, layer);
        context.commit(SET_COMPARE_MODAL_VISIBILITY, true);
    },
    [HIDE_COMPARE_LAYERS_MODAL](context) {
        context.commit(REMOVE_COMPARE_LAYER);
        context.commit(SET_COMPARE_MODAL_VISIBILITY, false);
    },
    async [PUBLISH_LAYER](context, payload) {
        context.commit('shared/activateLoading', 'atlas-layer-manager/publish', { root: true });
        const simpleLayers = context.getters.simpleLayers;
        try {
            const { data } = await AtlasService.publishLayer(payload);
            context.commit(SET_PUBLISH_LAYER_IN_QUEUE, {data, simpleLayers});
            return data;
        } catch(error) {
            return error;
        } finally {
            context.commit('shared/deactivateLoading', 'atlas-layer-manager/publish', { root: true });
        }
    },
    async [COMPARE_LAYERS](context, payload) {
        context.commit('shared/activateLoading', 'atlas-layer-manager/compareLayers', { root: true });
        try {
            const data = await AtlasService.compareLayers(payload);
            context.commit(SET_COMPARED_LAYERS, data.data);
            return data.data;
        }
        finally {
            context.commit('shared/deactivateLoading', 'atlas-layer-manager/compareLayers', { root: true });
        }
    },
    [SHOW_EDIT_MODAL](context, layer) {
        context.commit(SET_EDITING_LAYER, layer);
        context.commit(SET_EDIT_MODAL_VISIBILITY, true);
    },
    [SHOW_EDIT_MODAL](context, layer) {
        context.commit(SET_EDITING_LAYER, layer);
        context.commit(SET_EDIT_MODAL_VISIBILITY, true);
    },
    [HIDE_EDIT_MODAL](context) {
        context.commit(SET_EDIT_MODAL_VISIBILITY, false);
        context.commit(REMOVE_EDITING_LAYER);
    },
    [DELETE_LAYER](context, layer) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'atlas-layer-manager/deleteLayer', { root: true });
            AtlasService.deleteLayer(layer.id)
                .then(() => {
                    context.commit(REMOVE_LAYER, layer);
                    const selectedLayers = context.getters.selectedLayers;
                    AtlasService.persistLayers(selectedLayers);
                    Message.success(i18n.t('GENERAL.REMOVE_SUCCESS'));
                    resolve();
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'atlas-layer-manager/deleteLayer', { root: true });
                });
        });
    },
    [DELETE_SIMPLE_LAYER](context, layer) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'atlas-layer-manager/deleteSimpleLayer', { root: true });
            AtlasService.deleteSimpleLayer(layer.id)
                .then(() => {
                    Message.success(i18n.t('GENERAL.REMOVE_SUCCESS'));
                    resolve();
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'atlas-layer-manager/deleteSimpleLayer', { root: true });
                });
        });
    },
    async [ADD_LAYER_INTO_CONTAINER](context, values) {
        context.commit('shared/activateLoading', 'atlas-layer-manager/addLayerIntoContainer', { root: true });
        try {
            const response = await AtlasService.addLayerIntoContainer(values);
            const uploadAccessToken = response.headers['x-file-upload-access-token'];
            const data = response.data;
            return { data, uploadAccessToken };
        } catch(error) {
            Errors.handle(error);
            return data;
        } finally {
            context.commit('shared/deactivateLoading', 'atlas-layer-manager/addLayerIntoContainer', { root: true });
        }
    },
    async [UPDATE_LAYER_INTO_CONTAINER](context, { id, payload }) {
        context.commit('shared/activateLoading', 'atlas-layer-manager/updateLayerIntoContainer', { root: true });
        try {
            return await AtlasService.updateLayerIntoContainer(id, payload);
        } catch(error) {
            Errors.handle(error);
            return data;
        } finally {
            context.commit('shared/deactivateLoading', 'atlas-layer-manager/updateLayerIntoContainer', { root: true });
        }
    },
    async [UPDATE_LAYER_FROM_CONTAINER](context, { id, layer }) {
        context.commit('shared/activateLoading', 'atlas-layer-manager/updateLayerFromContainer', { root: true });
        try {
            return await AtlasService.updateLayerFromContainer(id, layer);
        } catch(error) {
            Errors.handle(error);
            return data;
        } finally {
            context.commit('shared/deactivateLoading', 'atlas-layer-manager/updateLayerFromContainer', { root: true });
        }
    },
    async [UPDATE_LAYER_CONTAINER](context, { layer, payload }) {
        context.commit('shared/activateLoading', 'atlas-layer-manager/updateLayerContainer', { root: true });
        try {

            const response = await AtlasService.updateLayerContainer(layer.id, payload);
            const {components, ...values} = response;
            context.commit(SET_EDITING_LAYER, {...layer , ...values});
            return response;
        } catch(error) {
            Errors.handle(error);
            return data;
        } finally {
            context.commit('shared/deactivateLoading', 'atlas-layer-manager/updateLayerContainer', { root: true });
        }
    },
};

const mutations = {
    [INIT_STATE](state, payload) {
        Object.assign(state, getDefaultState(payload));
    },
    [SET_SELECTED_LAYER](state, layer) {
        state.layer = layer;
        if (!state.selected[layer.gis_layer_id]) {
            const sublayers = {};
            layer.sublayers.forEach((sublayer) => {
                sublayers[sublayer.gis_id] = {
                    visible: false,
                };
            });
            Vue.set(state.selected, layer.gis_layer_id, {visible: false, sublayers});
        }
    },
    [REMOVE_SELECTED_LAYER](state) {
        state.layer = null;
    },
    [SET_VISIBLE_LAYER](state, layer) {
        const layerId = layer.gis_layer_id;
        if (state.selected[layerId]) {
            state.selected[layerId].visible = true;
            const sublayers = state.selected[layerId].sublayers;
            // check if any sublayer is selected
            let showAll = true
            Object.keys(sublayers).forEach((key) => {
                // layer visible exist
                if (sublayers[key].visible) {
                    state.selected[layerId].sublayers[key].visible = sublayers[key].visible;
                    showAll = false
                }
            })
            if (showAll) {
                Object.keys(sublayers).forEach((key) => {
                    state.selected[layerId].sublayers[key].visible = true;
                })
            }
        } else {
            const sublayers = {};
            layer.sublayers.forEach((sublayer) => {
                sublayers[sublayer.gis_id] = {
                    visible: true,
                };
            });
            state.selected[layerId] = {
                visible: true,
                sublayers,
            };
        }
    },
    [REMOVE_VISIBLE_LAYER](state, layer) {
        const layerId = layer.gis_layer_id;
        if (state.selected[layerId]) {
            Vue.set(state.selected[layerId], 'visible', false);
            const sublayers = state.selected[layerId].sublayers;
            // check if any sublayer is selected and maintain state
            let hideAll = true;
            Object.keys(sublayers).forEach((key) => {
                const visibility = sublayers[key].visible;
                if (!sublayers[key].visible) {
                    hideAll = false;
                    state.selected[layerId].sublayers[key].visible = visibility;
                }
            })
            if(hideAll) {
                Object.keys(sublayers).forEach((key) => {
                    state.selected[layerId].sublayers[key].visible = false;
                })
            }
        } else {
            state.selected[layerId] = {
                visible: false,
            };
        }
    },
    [SET_VISIBLE_SUBLAYER](state, {layerId, sublayerId}) {
        Vue.set(state.selected[layerId].sublayers[sublayerId], 'visible', true);
        const sublayers = state.selected[layerId].sublayers
        // Check layer switch in case at least one sublayer is active
        Object.values(sublayers).forEach((sublayer) => {
            if(sublayer.visible) {
                Vue.set(state.selected[layerId], 'visible', true);
            }
        })
    },
    [REMOVE_VISIBLE_SUBLAYER](state, {layerId, sublayerId}) {
        Vue.set(state.selected[layerId].sublayers[sublayerId], 'visible', false);
         // Uncheck layer switch in case all sublayers are inactive
        let layerVisible = false;
        const sublayers = state.selected[layerId].sublayers;
        Object.values(sublayers).forEach((sublayer) => {
            if(sublayer.visible) {
                layerVisible = true
            }
        })
        Vue.set(state.selected[layerId], 'visible', layerVisible);
    },
    [SET_VISIBLE_LAYERS](state, layers) {
        layers.forEach((layer) => {
            const layerId = layer.gis_layer_id;
            if (state.selected[layerId]) {
                Vue.set(state.selected[layerId], 'visible', true);
            } else {
                const sublayers = {};
                layer.sublayers.forEach((sublayer) => {
                    sublayers[sublayer.gis_id] = {
                        visible: true,
                    };
                });
                Vue.set(state.selected, layerId, {visible: true, sublayers});
            }
        });
    },
    [REMOVE_VISIBLE_LAYERS](state, layers) {
        layers.forEach((layer) => {
            const layerId = layer.gis_layer_id;
            if (state.selected[layerId]) {
                Vue.set(state.selected[layerId], 'visible', false);
            } else {
                state.selected[layerId] = {
                    visible: false,
                };
            }
        });
    },
    [SET_LAYERS](state, layers) {
        state.layers = layers;
    },
    [SET_SIMPLE_LAYERS](state, layers) {
        const STATUS = {
            new: 'info',
            pending: 'warning',
            completed: 'success',
            error: 'danger',
            published: 'success',
            not_published:'dark'
        }                  

        const mappedLayers = state.layers.filter((el) => el.layer_type === 'simple').map(layer => {
                let publish_status = layer?.is_published ? 'published' : 'not_published';
                if (layer?.published_simple_layer_status) {
                    publish_status = layer?.published_simple_layer_status
                }
                return { 
                    ...layer,
                    publish_status,
                    type: STATUS[publish_status]
                }
            })
        state.simpleLayers = mappedLayers;
    },
    [SET_LAYER](state, layer) {
        const layerId = layer.layer_id;
        // Switch the layer creation request id with the layer_id
        const newLayer = {
            ...layer,
            id: layerId,
        };
        state.layers.push(newLayer);
    },
    [SET_PUBLISH_MODAL_VISIBILITY](state, isVisible) {
        state.isLayerPublishModalVisible = isVisible;
    },
    [SET_PUBLISHING_LAYER](state, layer) {
        state.publishingLayer = layer;
    },
    [REMOVE_PUBLISHING_LAYER](state) {
        state.publishingLayer = null;
    },
    [SET_EDIT_MODAL_VISIBILITY](state, isVisible) {
        state.isLayerEditModalVisible = isVisible;
    },
    [SET_COMPARE_MODAL_VISIBILITY](state, isVisible) {
        state.isCompareLayersModalVisible = isVisible;
    },
    [SET_COMPARE_LAYER](state, layer) {
        state.compareLayersData = layer;
    },
    [REMOVE_COMPARE_LAYER](state) {
        state.compareLayersData = null;
    },
    [SET_EDITING_LAYER](state, layer) {
        state.editingLayer = layer;
    },
    [REMOVE_EDITING_LAYER](state) {
        state.editingLayer = null;
    },
    [SET_COMPARED_LAYERS](state, data) {
        state.comparedLayersResult = data;
    },
    [SET_LAYER_CREATION_STATUS](state, status) {
        state.layerCreationStatus = status;
    },
    [SET_PUBLISH_LAYER_IN_QUEUE](state, {data, simpleLayers}) {
        const index = simpleLayers.findIndex((item) => item.id === data.published_from_layer_id);
        state.publishedLayerQueue.push({listIndex: index, layer: data})
    },
    [SET_LAYER_TO_CONTAINER](state, layer) {
        const { container_id } = layer;
        const matchedLayer = state.layers.find((el) => el.id === container_id);
        const unmatchedLayers = state.layers.filter((el) => el.id !== container_id);
        state.layers = [
            ...unmatchedLayers,
            {
                ...matchedLayer,
                components: [
                    ...matchedLayer.components,
                    {
                        ...layer,
                        id: layer.layer_id,
                    },
                ],
            },
        ];
    },
    [REMOVE_LAYER](state, layer) {
        // Remove layer from the list
        const index = state.layers.findIndex((item) => item.id === layer.id);
        state.layers.splice(index, 1);
        // Remove selected layer
        if (state.selected?.[layer.gis_layer_id]) {
            delete state.selected[layer.gis_layer_id];
        }
        // Remove selected layer and hide sublayer sidebar
        if (state.layer?.id === layer.id) {
            state.layer = null;
            state.isSublayerSidebarVisible = false;
        }
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
