import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';

const Dashboard = () => import('@/modules/dashboard/pages/Dashboard.vue');

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: {auth: true },
    children: [
      {
        name: 'dashboard',
        path: '/dashboard',
        component: Dashboard,
        exact: true,
        meta: {
          auth: true,
          permission: Permissions.values.dashboardView,
        }
      }
    ]
  }
];
