<template>
    <div class="rounded p-5 gutter-b gutter-t border border-light bg-light-primary">
        <b-icon icon="info-circle-fill" variant="primary" class=" mr-3"></b-icon>
        <span class="text-primary">
            {{ title }}     
        </span>
        <p class="text-muted pt-4 pl-8 font-size-h6">
            {{ text }}
        </p>
    </div>
</template>

<script>
export default {
    name: 'StInfoAlert',
    props: {
        title: {
            type: String,
            default: '',
        },
        text: {
            type: String,
            default: '',
        },
    }
}
</script>