import { mapActions } from 'vuex';
// TODO: make the mixin globally
export default {
    watch: {
        'legalFields.county': {
            deep: true,
            immediate: true,
            handler(countyValue, oldCountyValue) {
                if (countyValue === oldCountyValue) return false;
                this.legalFields.locality = '';

                if (countyValue) {
                    const countyParams = this.counties.find( county => county.county === countyValue);

                    this.getLocalities({parent_id: countyParams.id})
                    .then(()=> {
                        this.cityList = this.localities;

                        // reset field after another county is selected, after the default selection is made
                        this.fv.resetField('locality', true);
                    })
                    .catch((error)=> {
                        console.log('error', error);
                        this.$notify({
                            type: 'error',
                            message: this.$t('SET_PROFILE_DATA.ERRORS.LOCALITIES'),
                        });
                    });
                } else {
                    // reset cityList on reload or when the profile type is changed and then came back
                    this.cityList = [];
                }
            },
        },
    },
    methods: {
        ...mapActions({
            getLocalities: 'profile/getLocalities',
            getVillages: 'profile/getVillages',
        }),
    }

};

