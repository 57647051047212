import { storeAsync } from '@/app-module';
import { routerAsync } from '../../../app-module';

export default {
    async beforeRouteEnter(to, from, next) {
        if (!to.meta || !to.meta.notRenderInApp) {
            next();
            return;
        }

        const store = storeAsync();
        const router = routerAsync();
        const renderedInApp = router.app.renderInApp;
        if (renderedInApp) {
            next('/');
            return;
        }

        await store.dispatch('auth/doWaitUntilInit');
        if (store.getters['auth/isAuthenticated']) {
            next('/');
        } else {
            next();
        }
    },
};
