import applicationsFormStore from '@/modules/applications/store/applications-form-store';
import applicationsListStore from '@/modules/applications/store/applications-list-store';
import applicationsActivitiesStore from '@/modules/applications/store/applications-activities-store';

export default {
    namespaced: true,

    modules: {
        form: applicationsFormStore,
        list: applicationsListStore,
        activity: applicationsActivitiesStore,
    },
};
