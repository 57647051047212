import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';

const FormsList = () => import('@/modules/forms/pages/FormsList.vue');
const FormBuilderForm = () => import('@/modules/forms/pages/FormBuilderForm.vue');

export default [
    {
        path: '',
        component: Layout,
        meta: { auth: true },
        children: [
            {
                name: 'forms',
                path: '/forms',
                component: FormsList,
                meta: {
                    auth: true,
                    permission: Permissions.values.formsRead,
                }
            },
            {
                name: 'formNew',
                path: '/form/new',
                component: FormBuilderForm,
                meta: {
                    auth: true,
                    permission: Permissions.values.formsCreate,
                }
            },
            {
                name: 'formView',
                path: '/form/:id',
                component: FormBuilderForm,
                meta: {
                    auth: true,
                    permission: Permissions.values.formsRead,
                },
                props: true,
            },
        ],
    },
];
