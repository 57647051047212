<template>
    <div>
        <b-modal
            v-model="modalShow"
            :hide-footer=hideFooter
            :centered="isCentered"
            :hide-header-close="hideHeaderClose"
            :modal-class="customClass"
            :header-class="[{'hide-header-delimiter': hideHeaderDelimiter}, customHeaderClass]"
            :footer-class="{'hide-footer-delimiter': hideFooterDelimiter}"
            :body-class="customBodyClass"
            :size="size"
            :no-close-on-backdrop="noCloseOnBackdrop"
            :no-close-on-esc="noCloseOnEsc"
            :id="id"
            :title="title"
            @close="emitCloseEvent"
            @hidden="emitHiddenEvent"
        >
            <template #modal-header >
                <slot name="header"></slot>
            </template>

            <template #default>
                <slot name="body"></slot>
            </template>
            <template #modal-footer>
                <slot name="footer"></slot>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: 'StModal',
    props: {
        id: {
            type: String,
            required: false,
        },
        size: {
            type: String,
            default: 'lg',
        },
        hideFooter: {
           type: Boolean,
           default: false
        },
        hideHeaderDelimiter: {
            type: Boolean,
            default: false
        },
        hideFooterDelimiter: {
            type: Boolean,
            default: false
        },
        noCloseOnBackdrop: {
            type: Boolean,
            default: false
        },
        noCloseOnEsc: {
            type: Boolean,
            default: false
        },
        customClass: String,
        customHeaderClass: String,
        customBodyClass: String,
        isCentered: {
            type: Boolean,
            default: false,
        },
        hideHeaderClose: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
        },
        hideable: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            modalShow: true,
        };
    },
    computed: {},
    methods: {
        show() {
            if (this.hideable) {
                this.modalShow = true;
            }
        },
        hide() {
            if (this.hideable) {
                this.modalShow = false;
            }
        },
        emitCloseEvent() {
            this.$emit('modalClosed', this.id);
        },
        emitHiddenEvent() {
            this.$emit('modalHidden', this.id);
        }
    },
    created() {
        if (this.hideable) {
            this.modalShow = false;
        }
    },
};
</script>
