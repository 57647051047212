var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoading)?_c('Loader'):_vm._e(),_c('ul',{ref:"atlasMenu",staticClass:"menu-nav custom-menu-nav"},[_c('router-link',{attrs:{"to":"/atlas/layers"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"},on:{"click":function($event){return _vm.navigateTo('layers')}}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-layer-group"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('ATLAS.MENU.LAYER_MANAGER')))])])])]}}])}),_c('router-link',{attrs:{"to":"/atlas/applications"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"},on:{"click":function($event){return _vm.navigateTo('applications')}}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-info-circle"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('ATLAS.MENU.MY_APPLICATIONS')))])])])]}}])}),_c('router-link',{attrs:{"to":"/atlas/interest-areas"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"},on:{"click":function($event){return _vm.navigateTo('interest-areas')}}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-draw-polygon"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('ATLAS.MENU.INTEREST_ZONES')))])])])]}}])}),_c('router-link',{attrs:{"to":"/atlas/search"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-search"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('ATLAS.MENU.SEARCH')))])])])]}}])}),_vm._m(0),_c('router-link',{attrs:{"to":"/atlas/help"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-question-circle"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('ATLAS.MENU.HELP')))])])])]}}])}),_vm._m(1),_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MENU.EXTERNAL_APPS')))]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]),_c('router-link',{attrs:{"to":"/archive-dashboard"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{directives:[{name:"can",rawName:"v-can:read",value:('archive'),expression:"'archive'",arg:"read"}],staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon far fa-map"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MENU.ARCHIVE')))])])])]}}])}),_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":function($event){$event.preventDefault();return _vm.goToHome($event)}}},[_c('i',{staticClass:"menu-icon fas fa-archive"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MENU.GU')))])])])]}}])}),(false)?_c('li',[_c('hr')]):_vm._e(),(false)?_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MENU.HELP')))]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]):_vm._e(),(false)?_c('router-link',{attrs:{"to":"/manual"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-open-text-book"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MENU.MANUAL')))])])])]}}],null,false,1657572283)}):_vm._e(),(false)?_c('router-link',{attrs:{"to":"/faq"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon far fa-question-circle"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MENU.FAQ')))])])])]}}],null,false,2654370514)}):_vm._e(),(false)?_c('router-link',{attrs:{"to":"/gdpr"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-book"}),_c('span',{staticClass:"menu-text"},[_vm._v("GDPR")])])])]}}],null,false,4191074141)}):_vm._e()],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_c('hr')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_c('hr')])}]

export { render, staticRenderFns }