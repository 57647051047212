import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';

const Accounts = () => import('@/modules/accounts/pages/Accounts.vue')

export default [
    {
        path: '',
        exact: true,
        component: Layout,
        children: [
            {
                name: 'accounts',
                path: '/accounts',
                component: Accounts,
                exact: true,
                meta: {
                    auth: true,
                    permission: Permissions.values.accountsRead,
                },
            },
        ],
    }
]
