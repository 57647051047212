import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';

const IssuedDocumentList = () => import('@/modules/issued-document/pages/IssuedDocumentList.vue');

export default [
    {
        path: '',
        component: Layout,
        meta: { auth: true },
        children: [
            {
                name: 'issuedDocuments',
                path: '/issued-documents',
                component: IssuedDocumentList,
                meta: {
                    auth: true,
                    permission: Permissions.values.issuedDocumentRead,
                }
            },
        ],
    },
];
