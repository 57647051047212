const AuthLayout = () => import('@/modules/auth/components/AuthLayout.vue');
const Login = () => import('@/modules/auth/pages/Login.vue');
const Register = () => import('@/modules/auth/pages/Register.vue');
const ForgotPassword = () => import('@/modules/auth/pages/ForgotPassword.vue');
const ResetPassword = () => import('@/modules/auth/pages/ResetPassword.vue');
const SetPassword = () => import('@/modules/auth/pages/SetPassword.vue');
const VerifySMS = () => import('@/modules/auth/pages/VerifySMS.vue');
const VerifyEmail = () => import('@/modules/auth/pages/VerifyEmail.vue');
const AuthGoogle = () => import('@/modules/auth/pages/AuthGoogle.vue');
const ChangePassword = () => import('@/modules/auth/pages/ChangePassword.vue');
const InvalidLink = () => import('@/modules/auth/pages/InvalidLink.vue');
const RegisterPersonal = () => import('@/modules/auth/pages/RegisterPersonal.vue');
const RegisterProfessional = () => import('@/modules/auth/pages/RegisterProfessional.vue');

export default [
    {
        path: '/auth',
        name: 'Auth',
        redirect: '/',
        component: AuthLayout,
        meta: { unauth: true },
        children: [
            {
                name: 'signin',
                path: '/auth/signin',
                component: Login,
                meta: { unauth: true }
            },
            {
                name: 'signup',
                path: '/auth/signup',
                component: Register,
                meta: {
                    unauth: true,
                    notRenderInApp: true,
                }
            },
            {
                name: 'signup-personal',
                path: '/auth/signup/personal',
                component: RegisterPersonal,
                meta: {
                    unauth: true,
                    notRenderInApp: true,
                },
            },
            {
                name: 'signup-professional',
                path: '/auth/signup/professional',
                component: RegisterProfessional,
                meta: {
                    unauth: true,
                    notRenderInApp: true,
                },
            },
            {
                name: 'forgotPassword',
                path: '/auth/forgot-password',
                component: ForgotPassword,
                meta: { unauth: true }
            },
            {
                name: 'resetPassword',
                path: '/auth/reset-password',
                component: ResetPassword,
                meta: { requiresConfirmationKey: true }
            },
            {
                name: 'setPassword',
                path: '/auth/set-password',
                component: SetPassword,
                meta: { requiresConfirmationKey: true }
            },
            {
                name: 'changePassword',
                path: '/auth/change-password',
                component: ChangePassword,
                props: true,
            },
        ]
    },
    {
        name: 'authGoogle',
        path: '/auth/google',
        component: AuthGoogle
    },
    {
        name: 'verifyEmail',
        path: '/auth/confirm-email',
        component: VerifyEmail,
        meta: { requiresEmailVerification: true }
    },
    {
        name: 'verifySMS',
        path: '/auth/verify-sms',
        component: VerifySMS,
        meta: {
            requiresPhoneVerification: true,
            requiresTokenVerification: true,
        },
    },
    {
        name: 'invalidLink',
        path: '/auth/invalid-link',
        component: InvalidLink
    },
];
