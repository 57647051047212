import { storeAsync } from '@/app-module';
import { routerAsync } from '../../../app-module';

export default {
    async beforeRouteEnter(to, from, next) {
        if (!to.meta || !to.meta.auth) {
            next();
            return;
        }

        const store = storeAsync();
        await store.dispatch('auth/doWaitUntilInit');

        if (!store.getters['auth/isAuthenticated'] &&
            !store.getters['auth/isAuthSucceeded']
        ) {
            next({ path: '/auth/signin' });
            return;
        }

        const router = routerAsync();
        const renderedInApp = router.app.renderInApp;
        // TODO: Move to config
        const INTERNAL_USERS = ['admin', 'super_admin', 'staff'];
        const currentUserType = store.getters['auth/currentUserType'];
        if ( renderedInApp && !INTERNAL_USERS.includes(currentUserType)) {
            await store.dispatch('auth/logout');
            window.location.replace(window.VUE_APP_EXTERNAL_APP_URL);
            return;
        }
        if (!renderedInApp && INTERNAL_USERS.includes((currentUserType))) {
            await store.dispatch('auth/logout');
            window.location.replace(window.VUE_APP_INTERNAL_APP_URL);
            return;
        }

        if (
            to.path !== '/auth/confirm-email' &&
            !store.getters['auth/isEmailConfirmed']
        ) {
            next({ path: '/auth/confirm-email' });
            return;
        }

        if (to.path !== '/auth/verify-sms' &&
            store.getters['auth/isEmailConfirmed'] &&
            !store.getters['auth/isPhoneConfirmed']
        ) {
            next({ path: '/auth/verify-sms' });
            return;
        }

        next();
    }
};
