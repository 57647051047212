<template>
    <ul class="menu-nav">
        <li class="mr-2 menu-search-item">
            <div class="custom-search">
                <StSearchDefault></StSearchDefault>
            </div>
        </li>
        <li>
            <st-button
              id="btn_advanced_search"
              :outline="true"
              :callback="advancedSearch">
                {{ $t('MENU.SEARCH_BUTTON') }}
            </st-button>
        </li>
    </ul>
</template>

<script>
import StSearchDefault from '@/modules/layout/components/extras/dropdown/SearchDefault.vue';
export default {
    name: 'HeaderMenu',
    data() {
        return {
            search: '',
        };
    },
    components: {
        StSearchDefault,
    },
    methods: {
        hasActiveChildren(match) {
            return this.$route['path'].indexOf(match) !== -1;
        },
        advancedSearch() {
            console.log('Advanced Search');
        },
    },
};
</script>
