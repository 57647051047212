<template>
    <form class="form"
        novalidate="novalidate"
        :id="`st_foreigner_form_${id}`"
    >
        <div class="row mb-6">
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label>
                        {{ $t('SET_PROFILE_DATA.FOREIGNER.PNC') }} *
                    </label>
                    <div label="">
                        <st-input-text
                            v-model="foreignerFields.passport_number"
                            ref="passport_number"
                            name="passport_number"/>
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ $t('SET_PROFILE_DATA.FOREIGNER.LAST_NAME') }} *
                    </label>
                    <div label="">
                        <st-input-text
                            v-model="foreignerFields.last_name"
                            ref="last_name"
                            name="last_name"/>
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ $t('SET_PROFILE_DATA.FOREIGNER.FIRST_NAME') }} *
                    </label>
                    <div label="">
                        <st-input-text
                            v-model="foreignerFields.first_name"
                            ref="first_name"
                            name="first_name"/>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label>
                        {{ $t('SET_PROFILE_DATA.FOREIGNER.COUNTRY') }} *
                    </label>
                    <div label="">
                        <b-form-select
                            v-model="foreignerFields.country"
                            :options="countries"
                            ref="country"
                            name="country"
                            value-field="name"
                            text-field="name"
                        ></b-form-select>
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ $t('SET_PROFILE_DATA.FOREIGNER.CITY') }} *
                    </label>
                    <div label="">
                        <st-input-text
                            v-model="foreignerFields.city"
                            ref="city"
                            name="city"/>
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ $t('SET_PROFILE_DATA.FOREIGNER.ADDRESS') }} *
                    </label>
                    <div label="">
                        <st-input-text
                            v-model="foreignerFields.address"
                            ref="address"
                            name="address"/>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label>
                        {{ $t('SET_PROFILE_DATA.FOREIGNER.EMAIL') }} *
                    </label>
                    <div label="">
                        <st-input-text
                            v-model="foreignerFields.email"
                            ref="email"
                            name="email"/>
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ $t('SET_PROFILE_DATA.FOREIGNER.PHONE') }} *
                    </label>
                    <div label="">
                        <st-input-text
                            v-model="foreignerFields.phone_number"
                            ref="phone_number"
                            name="phone_number"
                            set-validation/>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import Countries from '@/shared/utils/countries';

import formValidation from '@/assets/plugins/formvalidation/dist/es6/core/Core';

// FormValidation plugins
import Trigger from '@/assets/plugins/formvalidation/dist/es6/plugins/Trigger';
import Bootstrap from '@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap';
import SubmitButton from '@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton';

export default {
    name: 'ForeignerForm',
    components: {},
    props: {
        id: {
            type: String,
            required: true
        },
        isContact: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            foreignerFields: {
                user_type_name: 'foreigner',
                passport_number: '',
                last_name: '',
                first_name: '',
                country: '',
                city: '',
                address: '',
                email: '',
                phone_number: '',
                isContactPerson: false
            },
            countries: Countries
        }
    },
    computed: {
        ...mapGetters({
            localities: 'profile/settlements',
            villages: 'profile/villages',
        }),
        ...mapState({
            counties: state => state.shared.counties,
        }),
    },
    mounted() {
        const foreigner_form = KTUtil.getById(`st_foreigner_form_${this.id}`);
        const PHONE_REGEX = '^[\\+\\d]?(?:[\\d]*)$';
        this.fv = formValidation(foreigner_form, {
            fields: {
                passport_number: {
                    validators: {
                        notEmpty: {
                            message: this.$t('AUTH.VALIDATION.REQUIRED_FIELD'),
                        },
                    },
                },
                last_name: {
                    validators: {
                        notEmpty: {
                            message: this.$t('AUTH.VALIDATION.REQUIRED_FIELD'),
                        },
                    },
                },
                first_name: {
                    validators: {
                        notEmpty: {
                            message: this.$t('AUTH.VALIDATION.REQUIRED_FIELD'),
                        },
                    },
                },
                country: {
                    validators: {
                        notEmpty: {
                            message: this.$t('AUTH.VALIDATION.REQUIRED_FIELD'),
                        },
                    },
                },
                city: {
                    validators: {
                        notEmpty: {
                            message: this.$t('AUTH.VALIDATION.REQUIRED_FIELD'),
                        },
                    },
                },
                address: {
                    validators: {
                        notEmpty: {
                            message: this.$t('AUTH.VALIDATION.REQUIRED_FIELD'),
                        },
                    },
                },
                email: {
                    validators: {
                        notEmpty: {
                            message: this.$t('AUTH.VALIDATION.REQUIRED_FIELD'),
                        },
                        emailAddress: {
                            message: this.$t('AUTH.VALIDATION.INVALID_EMAIL'),
                        },
                    },
                },
                phone_number: {
                    validators: {
                        notEmpty: {
                            message: this.$t('AUTH.VALIDATION.REQUIRED_FIELD'),
                        },
                        regexp: {
                            regexp: PHONE_REGEX,
                            message: this.$t('AUTH.VALIDATION.INVALID_PHONE')
                        }
                    },
                }

            },
            plugins: {
                trigger: new Trigger(),
                submitButton: new SubmitButton(),
                bootstrap: new Bootstrap(),
            },
        });
    },
    methods: {
        onSubmit() {
            console.log('foreigner on submit');
            return this.fv.validate()
            .then((status)=>{

                // status can be one of the following value
                // 'NotValidated': The form is not yet validated
                // 'Valid': The form is valid
                // 'Invalid': The form is invalid
                this.foreignerFields.isContactPerson = this.isContact;

                if (status === 'Valid') {
                    return {
                        items: this.foreignerFields
                    }
                } else return false
            });
        }
    }
}
</script>
