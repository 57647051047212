import moment from "moment";

const calcPaginationParams = (currentPage, perPage) => {

    return {
        skip: (currentPage - 1) * perPage,
        limit: perPage,
        pagination: true,
    }
}

const FILTERS = {
    search: (key, value) => `${key} like '%${value}%'`,
    select: (key, value) => `${key} eq '${value}'`,
    autocomplete: (key, value) => `${key} eq '${value}'`,
    contains: (key, value) => `${key} contains '${value}'`,
    daterange: (key, value) => {
        const startQuery = `${key} ge '${value.start}'`;

        const end = moment(value.end).add(1, 'day').format('YYYY-MM-DD');
        const endQuery = `${key} lt '${end}'`;

        if (value?.start && value?.end) {
            return `${startQuery} and ${endQuery}`;
        }
        if (value?.start) {
            return startQuery;
        }
        if (value?.end) {
            return endQuery;
        }
    }
};

const calcFiltersParams = (filters) => {
    const queries = filters
        .filter(filter => {
            if (filter.type === 'daterange') {
                return filter.value.start || filter.value.end;
            }
            return filter.value && !filter.sortByKey;
        })
        .map(filter => {
            if (filter.searchByText && filter.type ==='contains') {
                const filterValue = filter.options.find((filterItem) => filterItem.value === filter.value)?.text;
                return FILTERS[filter.type](filter.key, filterValue)
            }

            return FILTERS[filter.type](filter.key, filter.value)
        });

    return {
        query: queries.join(' and '),
    };
}

const calcSortParams =  (filters) => {
    const sort = {}
    filters.forEach((filter) => {
        if (filter.sortByKey) {
            const selectedSortKey = filter.options.find(option => option.value === filter.value)?.sortkey;
            if (selectedSortKey) sort.sortKey = selectedSortKey;
        }
    })

    return sort;
}

const calcParams = (params) => {
    const { currentPage, perPage, filters } = params;

    const paginationParams = calcPaginationParams(currentPage, perPage);
    const filtersParams = calcFiltersParams(filters);
    const sortingParams = calcSortParams(filters);

    return {
        ...paginationParams,
        ...filtersParams,
        ...sortingParams,
    };
}

export default calcParams;
