var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoading)?_c('Loader'):_vm._e(),_c('ul',{staticClass:"menu-nav custom-menu-nav"},[(false)?_c('li',{staticClass:"row justify-content-center mx-5 py-5"},[_c('st-button',{directives:[{name:"can",rawName:"v-can:create",value:('archive'),expression:"'archive'",arg:"create"}],attrs:{"variant":"warning","block":true,"callback":_vm.manualArchive,"id":"btn_create_request","customClass":"text-dark font-size-h5"}},[_c('i',{staticClass:"fas fa-plus text-dark"}),_vm._v(" "+_vm._s(_vm.$t('ARCHIVE.BUTTONS.MANUAL_ARCHIVE'))+" ")])],1):_vm._e(),_vm._m(0),_c('router-link',{attrs:{"to":"/archive-dashboard"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-th-large"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('ARCHIVE.MENU.DASHBOARD')))])])])]}}])}),_c('li',{staticClass:"pl-8",class:{ 'disable-tree' : _vm.isLoading }},[_c('ul',{staticClass:"mx-0 px-0",attrs:{"id":"tree-view-section"}},[_c('tree-item',{staticClass:"item",attrs:{"item":_vm.treeData},on:{"make-folder":_vm.makeFolder,"add-item":_vm.addItem,"expand":_vm.expand}})],1)]),_c('li',{staticClass:"menu-item",attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":"href"},on:{"click":function($event){$event.preventDefault();return _vm.goToArchiveApp($event)}}},[_c('i',{staticClass:"menu-icon fas fa-cog"}),_c('span',{staticClass:"menu-text"},[_vm._v("Administrare")])])]),_vm._m(1),_vm._m(2),_c('router-link',{attrs:{"to":"/atlas"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon far fa-map"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MENU.ATLAS')))])])])]}}])}),_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":function($event){$event.preventDefault();return _vm.goToHome($event)}}},[_c('i',{staticClass:"menu-icon fas fa-archive"}),_c('span',{staticClass:"menu-text"},[_vm._v("Ghiseul Unic")])])])]}}])}),(false)?_c('li',[_c('hr')]):_vm._e(),(false)?_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MENU.HELP')))]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]):_vm._e(),(false)?_c('router-link',{attrs:{"to":"/manual"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-open-text-book"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MENU.MANUAL')))])])])]}}],null,false,1657572283)}):_vm._e(),(false)?_c('router-link',{attrs:{"to":"/faq"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon far fa-question-circle"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MENU.FAQ')))])])])]}}],null,false,2654370514)}):_vm._e(),(false)?_c('router-link',{attrs:{"to":"/gdpr"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-book"}),_c('span',{staticClass:"menu-text"},[_vm._v("GDPR")])])])]}}],null,false,4191074141)}):_vm._e()],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v("Arhiva")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_c('hr')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v("Aplicatii")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})])}]

export { render, staticRenderFns }