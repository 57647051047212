<template>
    <div class="card card-custom mb-4 py-6 beneficiary-card">
        <div class="card-body-1 px-4">
            <div class="d-flex justify-content-between">
                <p class="font-size-h5 mt-6 font-weight-bold">
                    {{ $t('BENEFICIARY.BENEFICIARY_DETAILS.HEADER') }}
                </p>
                <st-button
                    variant="link"
                    class="font-size-h6 px-5 py-3 my-3 mr-3"
                    :callbackParams=callbackParams
                    :callback="addBeneficiary"
                >
                    <span v-if="callbackParams.id > 0" class="remove-beneficiary">
                        <i class="fas fa-minus text-danger"></i>
                        {{ $t('BENEFICIARY.BENEFICIARY_DETAILS.REMOVE_BTN') }}
                    </span>
                    <span v-else>
                        <i class="fas fa-plus"></i>
                        {{ $t('BENEFICIARY.BENEFICIARY_DETAILS.ADD_BTN') }}
                    </span>
                </st-button>
            </div>
            <div class="row justify-content-start">
                <div class="col-6">
                    <p class="font-size-h6">{{ $t('BENEFICIARY.BENEFICIARY_DETAILS.PERSON.HEADER') }}</p>
                    <b-form-radio-group
                        size="lg"
                        v-model="selectedPerson"
                        :options="person"
                        :name="`radio-options-person-${callbackParams.id}`"
                    ></b-form-radio-group>
                </div>
            </div>
            <hr/>
            <div>
                <div v-if="selectedPerson === 'individual'">
                    <div class="mb-4">
                        <p class="font-size-h6">{{ $t('BENEFICIARY.BENEFICIARY_DETAILS.CITIZENSHIP.HEADER') }}</p>
                        <b-form-radio-group
                            size="lg"
                            v-model="selectedCitizenship"
                            :options="citizenship"
                            :name="`radio-options-citizenship-${callbackParams.id}`"
                        ></b-form-radio-group>
                    </div>
                    <div v-if="selectedCitizenship ==='romanian'">
                        <CitizenForm :id="`${callbackParams.id}`" :ref="`citizen`" :isContact="isBeneficiaryContact"/>
                    </div>
                    <div v-if="selectedCitizenship ==='foreigner'">
                        <p>foreigner</p>
                        <ForeignerForm :id="`${callbackParams.id}`" :ref="`foreigner`" :isContact="isBeneficiaryContact"/>
                    </div>
                </div>
                <div v-if="selectedPerson === 'legal'">
                    <LegalForm :id="`${callbackParams.id}`" :ref="`legal`" :isContact="isBeneficiaryContact"/>
                </div>
            </div>
            <div class="mt-4">
                <b-form-checkbox
                    size="lg"
                    v-model="isBeneficiaryContact"
                    :name="`beneficiary-contact-person-${callbackParams.id}`"
                    :value="true"
                    :unchecked-value="false"
                >
                {{ $t('BENEFICIARY.BENEFICIARY_DETAILS.FOOTER_CHECKBOX') }}
                </b-form-checkbox>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import CitizenForm from '@/modules/beneficiary/components/CitizenForm.vue';
import ForeignerForm from '@/modules/beneficiary/components/ForeignerForm.vue';
import LegalForm from '@/modules/beneficiary/components/LegalForm.vue';

export default {
    name: 'BeneficaryForm',
    components: {
        CitizenForm,
        ForeignerForm,
        LegalForm
    },
    props: {
        id: {
            type: Number,
            required: false,
        }
    },
    data() {
        return {
            citizenship: [
                { text: this.$t('BENEFICIARY.BENEFICIARY_DETAILS.CITIZENSHIP.ROMANIAN'), value: 'romanian' },
                { text: this.$t('BENEFICIARY.BENEFICIARY_DETAILS.CITIZENSHIP.FOREIGNER'), value: 'foreigner' },
            ],
            selectedCitizenship: 'romanian',
            person: [
                { text: this.$t('BENEFICIARY.BENEFICIARY_DETAILS.PERSON.INDIVIDUAL'), value: 'individual' },
                { text: this.$t('BENEFICIARY.BENEFICIARY_DETAILS.PERSON.LEGAL'), value: 'legal' },
            ],
            selectedPerson: 'individual',
            isBeneficiaryContact: false,
            callbackParams: { id: this.id}
        };
    },
    computed: {
        ...mapGetters({
            personFields: 'auth/currentUser',
        }),
        ...mapState({
            counties: state => state.shared.counties,
        }),
    },
    methods: {
        addBeneficiary(id) {
            this.$emit('sendId', id);
        }
    }
}
</script>
