import { AtlasService } from '@/modules/atlas/atlas-service';

// action types
export const DO_INIT_STATE = 'doInit';
export const SHOW = 'show';
export const HIDE = 'hide';
export const SAVE_SELECTED_MENU = 'saveSelectedMenu';
export const SHOW_INTERSECTION_MANAGER = 'showIntersectionManager';
export const HIDE_INTERSECTION_MANAGER = 'hideIntersectionManager';

// mutation types
export const SET_SIDEBAR_STATE = 'setSidebarState';
export const INIT_STATE = 'init';
export const SET_SELECTED_MENU = 'setSelectedMenu';
export const SET_INTERSECTION_MANAGER_STATE = 'setIntersectionManagerState';
import { storeAsync } from '@/app-module';
const getDefaultState = (isSidebarVisible) => ({
    visible: isSidebarVisible,
    selected: '',
    isIntersectionManagerVisible: false,
});

const state = getDefaultState();

const getters = {
    isVisible: (state) => state.visible,
    selected: (state) => state.selected,
};

const actions = {
    async [DO_INIT_STATE](context) {
        const isSidebarVisible = await AtlasService.getPersistedSidebarState();
        context.commit(INIT_STATE, isSidebarVisible);
    },
    [SHOW](context) {
        context.commit(SET_SIDEBAR_STATE, true);
        AtlasService.persistSidebarState(true);
    },
    [HIDE](context) {
        context.commit(SET_SIDEBAR_STATE, false);
        AtlasService.persistSidebarState(false);
    },
    [SAVE_SELECTED_MENU](context, menu) {
        context.commit(SET_SELECTED_MENU, menu);
    },
    [SHOW_INTERSECTION_MANAGER](context) {
        context.commit(SET_INTERSECTION_MANAGER_STATE, true);
    },
    [HIDE_INTERSECTION_MANAGER](context) {
        context.commit(SET_INTERSECTION_MANAGER_STATE, false);
    },
};

const mutations = {
    [INIT_STATE](state, isSidebarVisible) {
        const store = storeAsync();
        const atlasStore = store.state.atlas;
        store.replaceState({...store.state, atlas: {...atlasStore, sidebar: getDefaultState(isSidebarVisible)} });
    },
    [SET_SIDEBAR_STATE](state, isVisible) {
        state.visible = isVisible;
    },
    [SET_SELECTED_MENU](state, menu) {
        state.selected = menu;
    },
    [SET_INTERSECTION_MANAGER_STATE](state, isVisible) {
        state.isIntersectionManagerVisible = isVisible;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
