import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';

const Institutions = () => import('@/modules/administrator/pages/OrganisationList.vue');
const InstitutionDetails = () => import('@/modules/administrator/components/institutions/InstitutionDetails.vue');
export default [
  {
    path: '',
    exact: true,
    component: Layout,
    children: [
      {
        name: 'institutions',
        path: '/institutions',
        component: Institutions,
        exact: true,
        meta: {
            auth: true,
            permission: Permissions.values.organisationsRead,
        },
      },
      {
        name: 'institution',
        path: '/institution',
        component: InstitutionDetails,
        exact: true,
        meta: {
            auth: true,
            permission: Permissions.values.organisationsView,
        },
      },
    ],
  },
];
