<template>
    <div class="topbar-item">
        <div
            class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
            id="kt_quick_user_toggle"
        >
            <span class="symbol symbol-35 symbol-light-success">
                <span v-if="currentUser.photo">
                    <b-avatar :src="currentUser.photo" size="2rem"></b-avatar>
                </span>
                <span v-else class="font-size-h5 font-weight-bold">
                    <b-avatar :src="defaultAvatar" size="2rem"></b-avatar>
                </span>
            </span>
            <span class="font-size-base d-none d-md-inline ml-3">
                <div class="text-dark-50 font-weight-bolder nav-user-name" :title="getFullName">
                {{ getFullName }}
                </div>
                <div class="text-dark-50 font-size-xs">
                    {{ userFunction }}
                </div>
                <p v-if="isSuperAdmin && organisation.name" class="text-primary font-weight-bold">{{organisation.name}}</p>
            </span>
        </div>

        <div id="kt_quick_user" ref="kt_quick_user" class="offcanvas offcanvas-right p-10">
            <!--begin::Header-->
            <div class="offcanvas-header d-flex align-items-center justify-content-between pb-5">
                <h3 class="font-weight-bold m-0">
                    {{ $t('PROFILE.HEADER') }}
                </h3>
                <a
                    href="#"
                    class="btn btn-xs btn-icon btn-light btn-hover-primary"
                    id="kt_quick_user_close"
                >
                    <i class="ki ki-close icon-xs text-muted"></i>
                </a>
            </div>
            <!--end::Header-->

            <!--begin::Content-->
            <perfect-scrollbar
                class="offcanvas-content pr-1 mr-n5 scroll"
                style="max-height: 90vh; position: relative"
            >
                <!--begin::Header-->
                <div class="d-flex align-items-center mt-5">
                    <div class="symbol symbol-100 mr-5">
                        <span v-if="currentUser.photo">
                            <img class="symbol-label" :src="currentUserPersonalInfo.photo" alt="" />
                        </span>
                        <span v-else>
                            <img class="symbol-label" :src="defaultAvatar" alt="" />
                        </span>
                        <i class="symbol-badge bg-success"></i>
                    </div>
                    <div class="d-flex flex-column">
                        <router-link
                            to="/profile"
                            class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
                        >
                            {{ getFullName }}
                        </router-link>
                        <div class="mt-1">
                            {{ userFunction }}
                        </div>
                        <div class="navi mt-2">
                            <a href="#" class="navi-item">
                                <span class="navi-link p-0 pb-2">
                                    <span class="navi-icon mr-1">
                                        <i class="fas fa-envelope-open-text"></i>
                                    </span>
                                    <span class="navi-text text-hover-primary">
                                        {{ currentUser.email }}
                                    </span>
                                </span>
                            </a>
                        </div>
                        <st-button
                          id="btn_logout"
                          :callback="onLogout">
                            {{ $t('PROFILE.LOGOUT_BUTTON_TEXT') }}
                        </st-button>
                    </div>
                </div>
                <!--end::Header-->
                <div class="separator separator-dashed mt-8 mb-5"></div>
                <!--begin::Nav-->
                <div class="navi navi-spacer-x-0 p-0">
                    <router-link
                        to="/profile"
                        class="navi-item"
                        @click.native="closeOffcanvas"
                    >
                        <div class="navi-link">
                            <div class="symbol symbol-40 bg-light mr-3">
                                <div class="symbol-label">
                                    <i class="fas fa-user"></i>
                                </div>
                            </div>
                            <div class="navi-text">
                                <div class="st-dark-body font-weight-bolder">
                                    {{ $t(`PROFILE.OPTIONS.MY_PROFILE.NAME`) }}
                                </div>
                                <div>
                                    <span class="st-dark-caption">
                                        {{ $t(`PROFILE.OPTIONS.MY_PROFILE.DESCRIPTION`) }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </router-link>
                    <div
                        class="navi-item"
                        @click="onResetPassword"
                    >
                        <div class="navi-link">
                            <div class="symbol symbol-40 bg-light mr-3">
                                <div class="symbol-label">
                                    <i class="fas fa-shield-alt"></i>
                                </div>
                            </div>
                            <div class="navi-text">
                                <div>
                                    <div class="st-dark-body font-weight-bolder">
                                        {{ $t(`PROFILE.OPTIONS.RESET_PASSWORD.NAME`) }}
                                    </div>
                                </div>
                                <div>
                                    <span class="st-dark-caption">
                                        {{ $t(`PROFILE.OPTIONS.RESET_PASSWORD.DESCRIPTION`) }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- TODO: (re) add v-can:read="'notificationsPreferences'" once notifications permissions is done - BE side-->
                    <router-link
                        to="/notifications"
                        class="navi-item"
                        @click.native="closeOffcanvas"
                    >
                        <div class="navi-link">
                            <div class="symbol symbol-40 bg-light mr-3">
                                <div class="symbol-label">
                                    <i class="fas fa-bell"></i>
                                </div>
                            </div>
                            <div class="navi-text">
                                <div class="st-dark-body font-weight-bolder">
                                    {{ $t(`PROFILE.OPTIONS.NOTIFICATIONS_MANAGEMENT.NAME`) }}
                                </div>
                                <div>
                                    <span class="st-dark-caption">
                                        {{ $t(`PROFILE.OPTIONS.NOTIFICATIONS_MANAGEMENT.DESCRIPTION`) }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <!--end::Nav-->
            </perfect-scrollbar>
            <!--end::Content-->
        </div>
    </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
    overflow: hidden;
}
</style>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import KTLayoutQuickUser from '@/assets/js/layout/extended/quick-user.js';
import StOffcanvas from '@/assets/js/components/offcanvas.js';
import { LOGOUT, SEND_PASSWORD_RESET_EMAIL } from '../../../../auth/auth-store';
import Roles from '@/security/roles';

export default {
    name: 'QuickUser',
    data() {
        return {};
    },
    methods: {
        ...mapActions({
            logout: `auth/${LOGOUT}`,
            sendPasswordResetEmail: `auth/${SEND_PASSWORD_RESET_EMAIL}`
        }),
        onLogout() {
            this.$alert({
                type: 'warning',
                text: this.$t('LOGOUT.CONFIRMATION_MESSAGE'),
                confirmButtonText: this.$t('GENERAL.YES'),
                cancelButtonText: this.$t('GENERAL.NO'),
                hasConfirmation: true,
                confirmCallback: () => {
                    this.logout().then(() => this.$router.push({ name: 'signin' }));
                }
            });
        },
        onResetPassword() {
            this.$alert({
                type: 'warning',
                text: this.$t('RESET_PASSWORD.CONFIRMATION_MESSAGE'),
                confirmButtonText: this.$t('GENERAL.YES'),
                cancelButtonText: this.$t('GENERAL.NO'),
                hasConfirmation: true,
                confirmCallback: () => {
                    const { email } = this.currentUser;
                    this.sendPasswordResetEmail({ email }).then(() => {
                        this.$alert({
                            type: 'success',
                            text: this.$t('RESET_PASSWORD.SUCCESSFULLY_SENT_MESSAGE'),
                            cancelButtonText: this.$t('GENERAL.CLOSE'),
                            showCancelButton: true,
                            showConfirmButton: false,
                        });
                    });
                }
            });
        },
        closeOffcanvas() {
            new StOffcanvas(KTLayoutQuickUser.getElement()).hide();
        },
    },
    computed: {
        ...mapGetters({
            currentUser: 'auth/currentUser',
            accountDetails: 'auth/accountDetails',
            organisation: 'administrator/institution',
        }),
        ...mapState({
            defaultAvatar: state => state.shared.defaultAvatar,
        }),
        getFullName() {
            if (!this.currentUser?.first_name
                || !this.currentUser?.last_name) return '';
            return (
                this.currentUser.first_name +
                ' ' +
                this.currentUser.last_name
            );
        },
        userFunction() {
            // TODO: Temporary solution
            if (this.currentUser.user_type_name !== 'staff') return '';
            return this.accountDetails?.functions?.length ? this.accountDetails.functions[0] : '';
        },
        isSuperAdmin() {
            return this.currentUser.user_type_name === Roles.values['super_admin'];
        }
    },
    mounted() {
        // Init Quick User Panel
        KTLayoutQuickUser.init(this.$refs['kt_quick_user']);
    },
};
</script>

<style scoped lang="scss" src="@/assets/sass/pages/profile/quick-user.scss"></style>
