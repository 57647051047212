import Layout from '@/modules/layout/components/layout';

const ProcessEditorList = () => import('@/modules/process-editor/pages/ProcessEditorList.vue');
const ProcessForm = () => import('@/modules/process-editor/pages/ProcessForm.vue');
import Permissions from '@/security/permissions';

export default [
    {
        path: '',
        component: Layout,
        meta: { auth: true },
        children: [
            {
                name: 'process-editor',
                path: '/process-editor',
                component: ProcessEditorList,
                meta: {
                    auth: true,
                    permission: Permissions.values.processEditorRead,
                }
            },
            {
                name: 'processNew',
                path: '/process/new',
                component: ProcessForm,
                meta: {
                    auth: true,
                    permission: Permissions.values.processEditorCreate,
                }
            },
            {
                name: 'processView',
                path: '/process/:id',
                component: ProcessForm,
                meta: {
                    auth: true,
                    permission: Permissions.values.processEditorEdit,
                },
                props: true,
            },
        ],
    },
];
