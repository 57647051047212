<template>
  <div class="d-flex flex-column flex-root" v-if="isAuthenticated">
    <!-- login modal used when token expires -->
    <login-modal ref="login-modal"></login-modal>

    <!-- begin:: Header Mobile -->
    <HeaderMobile></HeaderMobile>
    <!-- end:: Header Mobile -->
    <!-- <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader> -->
    <div class="d-flex flex-row flex-column-fluid page">
        <StAside v-if="asideEnabled"></StAside>
        <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper" :class="{ 'fill-content': isAtlas }">
          <Header v-if="!isAtlas"></Header>
          <FloatingHeader v-else></FloatingHeader>
          <div id="kt_content" class="content d-flex flex-column flex-column-fluid">
            <div class="d-flex flex-column-fluid">
              <div
                :class="{
                    'container-fluid': contentFluid,
                    container: !contentFluid,
                }"
              >
                <transition name="fade" mode="out-in">
                    <router-view />
                </transition>
              </div>
            </div>
          </div>
          <Footer v-if="!isAtlas"></Footer>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import StAside from '@/modules/layout/components/aside/Aside.vue';
import Header from '@/modules/layout/components/header/Header.vue';
import HeaderMobile from '@/modules/layout/components/header/HeaderMobile.vue';
import Footer from '@/modules/layout/components/footer/Footer.vue';
import LoginModal from '@/shared/modals/LoginModal.vue'
import FloatingHeader from '@/modules/layout/components/header/FloatingHeader.vue';


// import KTStickyToolbar from '@/view/layout/extras/StickyToolbar.vue';
// import KTScrollTop from '@/view/layout/extras/ScrollTop';

import HtmlClass from '@/shared/services/htmlclass.service';
//import { ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME } from '@/modules/layout/layout-store.js';

export default {
  name: 'app-layout',
  components: {
    StAside,
    HeaderMobile,
    Header,
    Footer,
    LoginModal,
    FloatingHeader
  },
  data() {
    return {
        // asideEnabled: true
    };
  },
  beforeMount() {
    // show page loading
    // this.$store.dispatch(ADD_BODY_CLASSNAME, 'page-loading');

    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  mounted() {
    // check if current user is authenticated
    if (!this.isAuthenticated) {
        this.$router.push({ name: 'signin' });
    }

    if (this.isTokenExpired) {
      this.$bvModal.show('login-modal');
    }

    // this.$store.dispatch(REMOVE_BODY_CLASSNAME, 'page-loading');
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      isTokenExpired: 'auth/isTokenExpired',
      selectedMenu: 'selectedMenu',
    }),
    ...mapGetters([
      // 'breadcrumbs',
      // 'pageTitle',
      'layoutConfig',
    ]),

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig('content.width') === 'fluid';
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo');
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig('aside.self.display');
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      // return !!this.layoutConfig("toolbar.display");
      return true;
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig('subheader.display');
    },
    isAtlas() {
      return this.selectedMenu === 'atlas';
    },
  },
};
</script>

<style>
</style>
