<template>
    
    <div class="control-section">
        <div class="control-wrapper">
            <div class="sample-container">
                <div class="default-section">
                    <ejs-richtexteditor 
                        v-model="inputVal"
                        :change="validate"
                        :create="onCreate"
                        ref="rteInstance" 
                        name="rteInstance"
                        :enabled="enabled" 
                        :enableHtmlEncode="enableHtmlEncode" 
                        :readonly="readOnly" 
                        :showCharCount="showCharCount" 
                        :maxLength="maxLength">
                    </ejs-richtexteditor>
                    <div class="error-element"></div>
                </div>
            </div>
        </div>
    </div>

</template>

<style lang="scss">
// @import "../../../node_modules/@syncfusion/ej2-vue-buttons/styles/material.scss";
// TO DO: import syncfusion styles

</style>

<script>
import Vue from "vue";
import { RichTextEditorPlugin, Toolbar, Link, Image, Count, HtmlEditor, QuickToolbar } from "@syncfusion/ej2-vue-richtexteditor";
import { CheckBoxPlugin, ButtonPlugin } from "@syncfusion/ej2-vue-buttons";
import { NumericTextBoxPlugin } from "@syncfusion/ej2-vue-inputs";
import { FormValidator } from '@syncfusion/ej2-inputs';
import i18n from '@/shared/plugins/vue-i18n';

Vue.use(RichTextEditorPlugin);
Vue.use(CheckBoxPlugin);
Vue.use(ButtonPlugin);
Vue.use(NumericTextBoxPlugin);

export default {
    name: 'StTextEditor',
    props: {
        showCharCount: {
            type: Boolean,
            default: true,
        },
        maxLength: {
            type: Number,
            default: 1000,
        },
        enabled: {
            type: Boolean,
            default: true,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        enableHtmlEncode: {
            type: Boolean,
            default: false,
        },
        value: {
            type: String,
            required: false,
        }
    },
    data() {
        return {
            formObject: null
        }
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
    },
    provide:{
        richtexteditor:[Toolbar, Link, Image, Count, HtmlEditor, QuickToolbar]
    },
    methods: {
        onCreate() {
            var option = {
                rules: {
                    rteInstance: {
                        required: [true, i18n.t('AUTH.VALIDATION.REQUIRED')]
                    }
                },
                customPlacement: (element, errorElement) => {
                    document.querySelector(".error-element").appendChild(errorElement);
                },
            };
            
            this.formObject = new FormValidator('#st_modal_response_template_form', option);
        },
        validate() {
            document.querySelector('.e-rte-content').classList.remove('has-error');
            if (!this.formObject.validate()) {
                document.querySelector('.e-rte-content').classList.add('has-error');
            }
        }
    },
    mounted() {
        this.onCreate();
    }
}
</script>