import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';

const ComplainTypeList = () => import('@/modules/complain-type/pages/ComplainTypeList.vue');

export default [
    {
        path: '',
        component: Layout,
        meta: { auth: true },
        children: [
            {
                name: 'complain-type',
                path: '/complain-type',
                component: ComplainTypeList,
                meta: {
                    auth: true,
                    permission: Permissions.values.complainTypeRead,
                }
            },
        ],
    },
];
