<template>
    <div>
        <b-table
            :items="items"
            :fields="fields"
            :sort-by.sync="stSortBy"
            :sort-desc.sync="stSortDesc"
            :busy="loading"
            :responsive="responsive"
            :hover="hover"
            :per-page="rowsPerPage"
            :current-page="currentPage"
            :filter="filter"
            fixed
            :head-variant="headVariant"
            :table-variant="tableVariant"
            :thead-class="headClass"
            thead-tr-class="st-data-table-head-row"
            tbody-class="st-data-table-body"
            :tbody-tr-class="rowClass"
            class="st-data-table"
            show-empty
            :empty-text="emptyText"
            :selectable="selectable"
            :select-mode="selectMode"
            :actionsClass="actionsClass"
            @row-selected="onRowSelected"
            :no-select-on-click="noSelectOnClick"
            @row-clicked="onRowClicked"
            @sort-changed="sortingChanged"
            :no-local-sorting="serverSideSorting"
            sort-icon-left
        >
            <template #table-busy>
                <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-3">Loading...</strong>
                </div>
            </template>
            <template #table-colgroup="scope" v-if="hasIconDefined()">
                <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{ width: field.key === 'Icons' ? '80px' : '' }"
                >
            </template>
            <template #head()="data">
                <span class="st-header-text">{{ data.label.toUpperCase() }}</span>
            </template>

            <template #cell(Icons) v-if="hasIconDefined()">
                <span class="st-data-table-icon svg-icon svg-icon-md">
                    <span v-if="firstColumnIcons.customIcon" v-html="firstColumnIcons.name"></span>
                    <i v-else :class="`text-${firstColumnIcons.type} fas fa-${firstColumnIcons.name}`"></i>
                </span>
            </template>
            <template #cell(selected)="row" v-if="hasSelectedColumnDefined">
                <template>
                    <input type="checkbox" :disabled="row.item.locked" v-model="row.item.selected" name="selected" :checked="row.rowSelected" @change="checkRow(row)"/>
                </template>
            </template>
            <template #cell()="data">
                <span v-if="!!data.field.booleanType"
                    class="label custom-label"
                    :class="{'label-success': data.value ,
                            'label-text-muted': !data.value }"
                >
                    <i v-if="data.value" class="fas fa-check text-light"></i>
                    <i v-else class="fas fa-check text-light"></i>
                </span>
                <span v-else-if="data.field.labelType && data.value" class="text-truncate">
                    <span v-for="(item, i) in data.field.options" :key="i">
                        <span v-if="item.name === data.value">
                            <span v-if="!!item.text"
                                :class="`label label-xl label-inline label-light-${item.type}`">
                                {{item.text}}
                            </span>
                            <span v-else
                                :class="`label label-xl label-inline label-light-${item.type}`">
                                {{data.value}}
                            </span>
                        </span>
                    </span>
                </span>
                <span v-else-if="data.field.labelType && !data.value" class="text-truncate">
                    <span class="label label-xl label-inline label-light-dark"> - </span>
                </span>
                <span v-else-if="data.field.linkable">
                    <b-link @click.self.prevent="goToLink(data)">{{data.value}}</b-link>
                </span>
                <span v-else-if="data.field.customField">
                    <component
                        :is="data.field.portalTarget"
                        :presenter="presenter(data)"
                        :dataObj="data"
                    />
                </span>
                <div v-else>
                    <span :class="['d-block text-truncate', {'font-weight-bold': !!data.field.bold}]">
                        {{ presenter(data) }}
                    </span>
                </div>
            </template>
            <template #cell(Actions)="data" v-if="actionsList && actionsList.length > 0">
                <span v-for="(action,i) in actionsList" v-bind:key="i">
                    <span v-if="action.displayed && !(action.hideOnRow && data.item.hideAction)">
                        <b-button
                            v-if="!!action.buttonType"
                            size="sm"
                            @click="doAction(action.name, data)"
                            variant="primary"
                            >{{action.buttonText}}
                        </b-button>
                        <b-button
                            v-else
                            :class="`btn btn-icon btn-light btn-hover-${action.type} st-btn-data-table`"
                            @click="doAction(action, data)"
                            v-b-tooltip.hover="{ variant: 'info' }"
                            :title="action.tooltipText"
                            :disabled="!!action.disabled"
                        >
                            <span :class="`st-data-table-icon svg-icon svg-icon-md  svg-icon-${action.type}`">
                                <span v-if="action.customIcon" v-html="action.icon"></span>
                                <i v-else :class="`fas fa-${action.icon}`"></i>
                            </span>
                        </b-button>
                    </span>
                </span>
            </template>
        </b-table>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import i18n from '@/shared/plugins/vue-i18n';
import { isObjectEmpty } from '@/core/helpers/globalMethods';
import EnableTruncate from '../components/data-table/DataTableTruncateField';
import BeneficiaryCustomField from '../components/data-table/BeneficiaryCustomField';
import LocalityCustomField from '../components/data-table/LocalityCustomField';

export default {
    name: 'StDataTable',
    components: { EnableTruncate, BeneficiaryCustomField , LocalityCustomField},
    props: {
        items: {
            type: Array,
            required: true
        },
        fields: {
            type: Array,
            required: true,
        },
        sortBy: {
            type: String,
            default: '',
        },
        sortDesc: {
            type: Boolean,
            default: false,
        },
        responsive: {
            type: String,
            default: 'md',
        },
        hover: {
            type: Boolean,
            default: false,
        },
        perPage: {
            type: Number,
        },
        currentPage: {
            type: Number,
            default: 1,
        },
        filter: {
            type: String
        },
        loading: {
            type: Boolean,
            default:false
        },
        actions: {
            type: Array,
            default: () => []
        },
        headVariant: {
            type: String,
            default: 'light'
        },
        tableVariant: {
            type: String,
            default: 'light'
        },
        actionsLabel: {
            type: String,
            default: i18n.t('GENERAL.TABLE.ACTIONS')
        },
        modelPresenter: {
            type: Function,
        },
        selectMode: {
            type: String,
            default: 'single' // other options: multi, range
        },
        selectable: {
            type: Boolean,
            default: false
        },
        firstColumnIcons: {
            type: Object,
            default: () => ({ }),
        },
        headClass: {
            type: String,
            default: 'st-data-table-head'
        },
        actionsClass: {
            type: String
        },
        tbodyTrClass: {
            type: String,
            default: 'st-data-table-body-row'
        },
        noSelectOnClick: {
            type: Boolean,
            default: false
        },
        treeView: {
            type: Boolean,
            default: false,
        },
        emptyText: {
            type: String,
            default: i18n.t('GENERAL.TABLE_EMPTY_TEXT')
        },
        firstColumnSelectable: {
            type: Boolean,
            default: false
        },
        stateModule: String,
        serverSideSorting: {
            type: Boolean,
            default: false
        }
    },
   data() {
        return {
            stFields: [],
            actionsList: this.actions.map(action => {
                return {
                    ...action,
                    displayed: typeof action.permission === 'boolean' ? action.permission : true
                }
            }),
            selected: []
        }
    },
    computed: {
        ...mapGetters({
            appConfig: "shared/appConfig",
        }),
        rowsPerPage () {
            return this.perPage ?? this.appConfig.RECORDS_PER_PAGE;
        },
        hasSelectedColumnDefined() {
            return this.firstColumnSelectable;
        },
        storedSortData() {
            return this.stateModule ? this.$store.getters[`${this.stateModule}/sorting`] : {};
        },
        stSortBy: {
            get() {
                return this.storedSortData?.sortBy ?? this.sortBy;
            },
            set(val) {
                this.$emit('sortBy', val);
            },
        },
        stSortDesc: {
            get() {
                return this.storedSortData?.sortDesc ?? this.sortDesc;
            },
            set(val) {
                this.$emit('sortDesc', val);
            },
        }
    },
    created() {
        this.manageActionsColumn();
    },
    methods: {
        ...mapActions({
            setSortingParams: 'shared/fetchSortingParams',
        }),
        doAction(action, data) {
            if (action.name !== 'delete') {
                this.$emit(`${action.name}`, data);
            } else {
                // customDeleteMsg can be defined inside action->delete object
                this.$alert({
                type: 'warning',
                    text: !!action.customDeleteMsg ? action.customDeleteMsg: this.$t('GENERAL.ACTIONS.DELETE_MESSAGE'),
                    confirmButtonText: this.$t('GENERAL.YES'),
                    cancelButtonText: this.$t('GENERAL.NO'),
                    hasConfirmation: true,
                    confirmCallback: () => {
                        this.$emit(`${action.name}`, data);
                    }
                });
            }
        },
        manageActionsColumn() {
            const isActionsUsed = this.fields.some(field => field.key === 'Actions');
            this.stFields = this.fields;
            if (this.actionsList.length && !isActionsUsed) {

                const actionsRow = { key:'Actions', label: this.actionsLabel };
                if (this.actionsClass) {
                    actionsRow.tdClass = this.actionsClass;
                }
                this.stFields.push(actionsRow);
            }
            if (this.hasIconDefined()) {
                this.stFields.unshift({key:'Icons', label: ''})
            }
        },
        goToLink(data) {
            this.$emit('goToLink', data);
        },
        presenter(data) {
            if (!this?.modelPresenter) return data.value;
            return this.modelPresenter(data.item, data.field.name);
        },
        onRowSelected(items) {
            this.$emit('onRowSelected', items);
        },
        hasIconDefined() {
            return !isObjectEmpty(this.firstColumnIcons);
        },
        onRowClicked(item, index) {
            this.$emit('onRowClicked', { item, index });
        },
        rowClass(item, type) {
            let classes = this.tbodyTrClass;
            // TODO: Abstract
            if (item?.is_extension) {
                classes += ' is-child-row';
            }

            if (this.hasSelectedColumnDefined) {
                classes += ' selectable';
            }

            return classes;
        },
        checkRow(data) {
            this.$emit('itemChecked', data);
        },
        sortingChanged(ctx) {
            if (this.serverSideSorting) {
                const sortingParams = {sortBy: ctx.sortBy, sortDesc: ctx.sortDesc}
                this.setSortingParams({stateModule: this.stateModule, sortingParams});
            }
        }
    },
};
</script>


