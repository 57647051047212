import Layout from '@/modules/layout/components/layout';

const Requests = () => import('@/modules/requests/components/requests.vue');
const BuildingDetailsTab = () => import('@/modules/requests/components/BuildingDetailsTab.vue');

export default [
    {
        path: '',
        exact: true,
        component: Layout,
        children: [
            {
                name: 'requests',
                path: '/requests',
                component: Requests,
                exact: true,
            },
        ],
    },
    {
        path: '',
        exact: true,
        component: Layout,
        children: [
            {
                name: 'building-details',
                path: '/building-details',
                component: BuildingDetailsTab,
                exact: true,
            },
        ],
    },
];