import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';

const DecisionPurposeList = () => import('@/modules/decision-purpose/pages/DecisionPurposeList.vue');

export default [
    {
        path: '',
        component: Layout,
        meta: { auth: true },
        children: [
            {
                name: 'decisionPurpose',
                path: '/decision-purpose',
                component: DecisionPurposeList,
                meta: {
                    auth: true,
                    permission: Permissions.values.decisionPurposeRead,
                }
            },
        ],
    },
];
