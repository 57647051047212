<template>
    <div>
        <MenuArchive v-if="archiveMenu" @homePage="homePage"/>
        <MenuAtlas v-else-if="atlasMenu"  @homePage="homePage"/>
        <MenuGU v-else @navigate="navigateTo"/>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';

    import MenuArchive from './MenuArchive.vue';
    import MenuAtlas from './MenuAtlas.vue';
    import MenuGU from './MenuGU.vue';

    import ApiService from '@/shared/services/api.service';

    export default {
        name: 'SideMenu',
        components: { MenuArchive, MenuAtlas, MenuGU },
        data() {
            return {
               archiveMenu: false,
               atlasMenu: false,
            }
        },
        watch: {
            selectedMenu(newMenu, oldMenu) {
                if (newMenu === oldMenu) return false;
                this.atlasMenu = newMenu === 'atlas';
                this.archiveMenu = newMenu === 'archive';
            },
        },
        computed: {
            ...mapGetters({
                selectedMenu: 'selectedMenu',
            }),
        },

        methods: {
            ...mapActions({
                setSelectedMenu: 'setMenu',
            }),
            navigateTo(menu) {
                if (menu === 'atlas') {
                    this.goToAtlas();
                }
                if (menu === 'archive') {
                    this.goToArchive();
                }
            },
            goToArchive() {
                this.setSelectedMenu('archive');
                this.$router.push({
                    name: 'archive-dashboard',
                });
                ApiService.setBaseUrl({isArchive: true})
            },
            goToAtlas() {
                this.setSelectedMenu('atlas');
                // Scroll aside-menu to top
                if (document.querySelector('.aside-menu .scroll')) {
                    document.querySelector('.aside-menu .scroll').scrollTop = 0;
                }
                this.$router.push({
                    name: 'atlas',
                });
            },
            homePage() {
                ApiService.setBaseUrl({isArchive: false});
                this.setSelectedMenu('default');
                this.$router.push({
                    name: 'home',
                });
            }
        },
        created() {
            this.archiveMenu = this.selectedMenu === 'archive';
            this.atlasMenu = this.selectedMenu === 'atlas';
        },
    };
</script>
