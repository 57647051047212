import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';
const ResponseTemplatesList = () => import('@/modules/response-templates/pages/ResponseTemplatesList.vue');

export default [
    {
        path: '',
        component: Layout,
        meta: { auth: true },
        children: [
            {
                name: 'response-templates',
                path: '/response-templates',
                component: ResponseTemplatesList,
                meta: {
                    auth: true,
                    permission: Permissions.values.responseTemplatesRead,
                }
            },
        ],
    },
];
