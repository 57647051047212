<template>
    <form class="form"
        novalidate="novalidate"
        id="st_applicant_form"
        @submit.stop.prevent="onSubmit()"
    >
        <div class="row mb-3">
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label>
                        {{ $t('BENEFICIARY.BENEFICIARY_DETAILS.FORM.AUTHORIZATION_CODE') }}
                    </label>
                    <div label="">
                        <st-input-text
                            v-model="applicantDetails.license"
                            ref="license"
                            name="license"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ $t('BENEFICIARY.BENEFICIARY_DETAILS.FORM.SUBSIDIARY') }}
                    </label>
                    <div label="">
                        <st-input-text v-model="applicantDetails.subsidiary" ref="subsidiary" name="subsidiary"/>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label>
                        {{ $t('SET_PROFILE_DATA.LEGAL.LAST_NAME') }}
                    </label>
                    <div label="">
                        <st-input-text v-model="applicantDetails.last_name" ref="last_name" name="last_name"/>
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ $t('SET_PROFILE_DATA.LEGAL.FIRST_NAME') }}
                    </label>
                    <div label="">
                        <st-input-text v-model="applicantDetails.first_name" ref="first_name" name="first_name"/>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label>
                        {{ $t('SET_PROFILE_DATA.LEGAL.EMAIL') }}
                    </label>
                    <div label="">
                        <st-input-text v-model="applicantDetails.email " ref="email" name="email" disabled/>
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ $t('SET_PROFILE_DATA.LEGAL.PHONE') }}
                    </label>
                    <div label="">
                        <st-input-text
                            v-model="applicantDetails.phone_number"
                            ref="phone_number"
                            name="phone_number"
                            set-validation
                        />
                    </div>
                </div>
            </div>
        </div>
        <div>
            <b-form-checkbox
                size="lg"
                id="applicant_person"
                v-model="isApplicantPerson"
                name="contact_applicant-1"
                :value="true"
                :unchecked-value="false"
            >
            {{ $t('BENEFICIARY.APPLICANT_DETAILS.FOOTER_CHECKBOX') }}
            </b-form-checkbox>
        </div>
    </form>
</template>

<script>
// FormValidation plugins
import formValidation from '@/assets/plugins/formvalidation/dist/es6/core/Core';
import Trigger from '@/assets/plugins/formvalidation/dist/es6/plugins/Trigger';
import Bootstrap from '@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap';
import SubmitButton from '@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton';
export default {
    name: 'CitizenForm',
    props: {
        currentUser: {
            type: Object,
        },
    },
    data() {
        return {
            // removed after the auth is finished
            applicantDetails: {
                license: this.currentUser.license ? this.currentUser.license : '123',
                last_name: this.currentUser.last_name ? this.currentUser.last_name : 'Poparca',
                first_name: this.currentUser.first_name ? this.currentUser.first_name : 'Ion',
                email: this.currentUser.email ? this.currentUser.email : 'email@test.io',
                subsidiary: this.currentUser.locality ? this.currentUser.locality : 'Cluj',
                phone_number: this.currentUser.phone_number ? this.currentUser.phone_number : '456456',
            },
            isApplicantPerson: true,
        };
    },
    mounted() {
        const applicant_form = KTUtil.getById(st_applicant_form);
        const PHONE_REGEX = '^[\\+\\d]?(?:[\\d]*)$';
        this.fv = formValidation(applicant_form, {
            fields: {
                email: {
                    validators: {
                        notEmpty: {
                            message: this.$t('AUTH.VALIDATION.REQUIRED_FIELD'),
                        },
                        emailAddress: {
                            message: 'Introduceti o adresa de email valida.',
                        },
                    },
                },
                phone_number: {
                    validators: {
                        notEmpty: {
                            message: this.$t('AUTH.VALIDATION.REQUIRED_FIELD'),
                        },
                        regexp: {
                            regexp: PHONE_REGEX,
                            message: 'Numar de telefon Invalid!'
                        }
                    },
                }
            },
            plugins: {
                trigger: new Trigger(),
                submitButton: new SubmitButton(),
                bootstrap: new Bootstrap(),
            },
        })
    },

    methods: {
        onSubmit() {
            return this.fv.validate()
            .then((status) => {
                this.applicantDetails.isApplicantPerson = this.isApplicantPerson;
                if (status === 'Valid') {
                    return { items: this.applicantDetails };
                } else return false;
            });
        }
    }
}
</script>
