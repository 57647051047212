// import ApiService from '@/shared/services/api.service';

const state = {
};

const getters = {
};

const actions = {
};

const mutations = {
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
