import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';

const DecisionTargetList = () => import('@/modules/decision-target/pages/DecisionTargetList.vue');

export default [
    {
        path: '',
        component: Layout,
        meta: { auth: true },
        children: [
            {
                name: 'decisionTarget',
                path: '/decision-target',
                component: DecisionTargetList,
                meta: {
                    auth: true,
                    permission: Permissions.values.decisionTargetRead,
                }
            },
        ],
    },
];
