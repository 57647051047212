import ApiService from '@/shared/services/api.service';

export class ApplicationsService {
    static list(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/applications', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static listStaff(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/staff-applications', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static find(id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/applications/${id}`)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static findStaff(id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/staff-applications/${id}`)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => reject(error));
        });
    }

    static create(data) {
        return new Promise((resolve, reject) => {
            ApiService.post( '/applications?echo=true', { ...data } )
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => reject(error));
        });
    }

    static update(id, data) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/applications/${id}`, { ...data })
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static updateStaff(id, data) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/staff-applications/${id}`, { ...data })
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static findFiles(id, token, params) {
        return new Promise((resolve, reject) => {
            if (token) {
                ApiService.query(`/files/${id}`, { params, headers: {
                    Accept: 'application/json',
                    'x-file-access-token': token,
                }})
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
            } else {
                resolve([]);
            }
        });
    }

    static assignStaff(params) {
        const { applicationId, payload } = params;
        return new Promise((resolve, reject) => {
            ApiService.patch(`/staff-applications/${applicationId}/assign`, payload)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static approveDocument(id) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/staff-applications/${id}/approve`, {})
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static rejectDocument(id) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/staff-applications/${id}/reject`, {})
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static signDocument(id, action) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/staff-applications/${id}/${action}/sign`, {})
                .then((response) => {
                    // TODO: Remove after BE imp
                    if (response.data.success) {
                        resolve(response.data);
                    } else {
                        reject(response.data);
                    }
                })
                .catch((error) => reject(error));
        });
    }

    static signDocumentCitizen(id) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/applications/${id}/sign`, {})
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static validateDocumentSign(payload) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/staff-applications/${payload.applicationId}/confirm-signing`, {
                pin: payload.pin,
            })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static validateDocumentSignCitizen(payload) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/applications/${payload.applicationId}/confirm-signing`, {
                pin: payload.pin,
            })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static checkSigningStatus(id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/applications/${id}/check-signing-status`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static checkStaffSigningStatus(id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/staff-applications/${id}/check-signing-status`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static downloadDocument(token, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/files/${id}`, {
                'x-file-access-token': token,
            })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static calculateTaxes(id, data) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/application-types/${id}/all-taxes`, data)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static calculateTaxesForApplicationType(data) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/application-types/${data.id}/taxes`, data)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static updatePaymentStatus(payload) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/applications/${payload.id}`, {is_paid: payload.is_paid})
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static finalizeApplication(id) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/applications/${id}/send`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static findUserActions(params){
        return new Promise((resolve, reject) => {
            ApiService.query('/user-actions', { params })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static previewDocument(id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/staff-applications/${id}/preview-document`)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static generateFinalDocument(id, action) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/staff-applications/${id}/${action}/generate-document`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static upload(token, files) {
        return new Promise((resolve, reject) => {
           ApiService.post('/files?echo=true', files, {
               'content-type': 'multipart/form-data',
               'x-file-access-token': token,
           })
               .then((response) => resolve(response.data))
               .catch((error) => reject(error));
        });
    }

    static pay({ id, data }) {
        return new Promise((resolve, reject) => {
            ApiService.post( `/applications/${id}/pay`, data )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => reject(error));
        });
    }

    static checkPaymentStatus(id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/applications/${id}/check-payment-status`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static initializeOfflineSigning(id, filesLocation) {
        return new Promise((resolve, reject) => {
            ApiService.post( `/applications/${id}/initialize-offline-sign?filesLocations=${filesLocation}` )
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => reject(error));
        });
    }

    static checkOfflineSigning(id, new_pdf_id) {
        return new Promise((resolve, reject) => {
            ApiService.post( `/applications/${id}/check-offline-sign`, { new_pdf_id })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => reject(error));
        });
    }

    static finalizeOfflineSigning(id, newPdfId) {
        return new Promise((resolve, reject) => {
            ApiService.post( `/applications/${id}/finish-offline-sign/${newPdfId}` )
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => reject(error));
        });
    }

    static enrollment(data) {
        return new Promise((resolve, reject) => {
            ApiService.post( '/enrollment/person', { ...data })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => reject(error));
        });
    }

    static paymentCallback(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/payments/callback', params)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => reject(error));
        });
    }

    static getSystemMetadata() {
        return new Promise((resolve, reject) => {
            ApiService.get('/applications/system-metadata')
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => reject(error));
        });
    }

    static getActivities(id) {
        return new Promise((resolve, reject) => {
            ApiService.query(`user-messages/identification/${id}`, {
                params: {
                    sort: '-created_date',
                    limit: 100
                }
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => reject(error));
        });
    }

    static sendStaffComment(params) {
        return new Promise((resolve, reject) => {
            ApiService.post( `staff-applications/${params.id}/comment`, { ...params.payload })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => reject(error));
        });
    }
    static sendComment(params) {
        return new Promise((resolve, reject) => {
            ApiService.post( `applications/${params.id}/comment`, { ...params.payload })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => reject(error));
        });
    }

    static addComments(params) {
        const { applicationId, payload } = params;
        return new Promise((resolve, reject) => {
            ApiService.post(`/staff-applications/${applicationId}/comment`, payload)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => reject(error));
        });
    }

    static returnDocument(params) {
        const { applicationId, payload } = params;
        return new Promise((resolve, reject) => {
            ApiService.patch(`/staff-applications/${applicationId}/return-document`, payload)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static findRelatedFiles(id){
        return new Promise((resolve, reject) => {
            ApiService.query(`/staff-applications/${id}/related-files`)
            .then(({data}) => {
                resolve(data);
            })
            .catch((error) => reject(error));
        })
    }

    static sendClarifications(id, comment) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/staff-applications/${id}/review`, { comment })
                .then(({data}) => {
                    resolve(data);
                })
                .catch((error) => reject(error));
        })
    }

    static signDocumentBulk(payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/staff-applications/bulk-sign`, { application_ids: payload })
                .then(() => resolve())
                .catch((error) => reject(error));
        });
    }

    static checkStaffBulkSigningStatus(payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/staff-applications/check-bulk-signing-status`, { application_ids: payload})
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static validateBulkDocumentSign(payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/staff-applications/confirm-bulk-signing`, {
                pin: payload.pin,
                request_id: payload.applicationId
            })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static findUrbanismRegulations(uat_ids){
        return new Promise((resolve, reject) => {
            ApiService.query(`/urbanism-regulation-zones/groups?uat_ids=${uat_ids.join(',')}`)
            .then(({ data }) => {
                resolve(data);
            })
            .catch((error) => reject(error));
        })
    }

    static findUrbanismRegulationEntriesByZone(zone_id){
        return new Promise((resolve, reject) => {
            ApiService.query(`/urbanism-regulations`, {
                params: {
                    query: `zone_id eq ${zone_id}`
                }
            })
            .then(({ data }) => {
                resolve(data);
            })
            .catch((error) => reject(error));
        })
    }

    static manualCompletion(id, data, forceUpdate){
        return new Promise((resolve, reject) => {
            const queryParam = forceUpdate ? `?force=true` : '';
            ApiService.patch(`/staff-applications/${id}/manual-completion${queryParam}`, { ...data })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        })
    }
}
