import { storeAsync } from '@/app-module';

export default {
    async beforeRouteEnter(to, from, next) {
        if (!to.meta || !to.meta.auth) {
            next();
            return;
        }
        const store = storeAsync();
        const profileCompleted = store.getters['auth/currentUser'].profile_completed;
        if (to.name !== 'home' && !profileCompleted) {
            next({ path: '/' });
            return
        }
        next();
    }
};
