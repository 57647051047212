<template>
    <div>
        <template v-for="(item, id) in benficiaryFromList" >
            <div :key=id>
                <BeneficiaryForm :id="id" @sendId="sendId"  ref="beneficiary"/>
            </div>
        </template>

        <div class="card card-custom mt-6 py-6 beneficiary-card">
            <div class="card-body-1 px-4">
                <div class="d-flex justify-content-start my-2">
                    <p class="font-size-h5 font-weight-bold">
                        {{ $t('BENEFICIARY.APPLICANT_DETAILS.HEADER') }}
                    </p>
                </div>
                <ApplicantDetailsForm :currentUser="currentUser" ref="applicant"/>
            </div>
        </div>
        <st-button
            variant="primary"
            class="font-size-h6 px-5 py-3 my-3 mr-3"
            :callback="submit"
        > submit
        </st-button>
    </div>
</template>

<script>
import { mapState, mapGetters  } from 'vuex';
import BeneficiaryForm from '@/modules/beneficiary/components/BeneficiaryForm.vue';
import ApplicantDetailsForm from '@/modules/beneficiary/components/ApplicantDetailsForm.vue';
import Swal from 'sweetalert2';

export default {
    name: 'Beneficiary',
    components: {
        BeneficiaryForm,
        ApplicantDetailsForm
    },
    data() {
        return {
            benficiaryFromList: [{ form: 'form_0'}]
        };
    },
    computed: {
        ...mapState({
            loading: state => state.shared.loading,
        }),
        ...mapGetters({
            currentUser: 'auth/currentUser',
        }),

    },
    methods: {
        sendId (data) {
            if (data.id > 0) {
                this.benficiaryFromList.splice(data.id, 1)
            } else {
                this.benficiaryFromList.push({form: `form_${data.id+1}`})
            }
        },
        submit() {
           const promises = []
           const beneficiaryList = []
           this.$refs.beneficiary.forEach((field) => {
                let selectedEntity = {};
                const fieldRefs = field['$refs'];

                // check submit only the entities that are not undefined
                Object.keys(fieldRefs).forEach(key => {
                    if (!!fieldRefs[key]) {
                        selectedEntity = fieldRefs[key]
                    }
                })

                const prms = selectedEntity.onSubmit()
                .then((data) => {
                    beneficiaryList.push(data === false ? false : data.items)
                })
                promises.push(prms)
            });

            // After all the validations for all the beneficiaries are done
            // check the applicant form the make the request to BE
            Promise.all(promises).then(() => {
                this.$refs.applicant.onSubmit()
                .then((data) => {
                    // Check if any modul is not valid
                    if (beneficiaryList.includes(false)) {
                        return false
                    } else {
                        // Endpoint's payload (in Wizard)
                        const payload = {
                            beneficiaryList,
                            applicant: data.items
                        }
                        console.log('payload', payload)

                        //TODO: remove after API is implemented
                        Swal.fire({
                            title: 'Test next step',
                            text: 'Pas complet',
                            icon: 'success',
                            heightAuto: false,
                            confirmButtonText: 'Next'
                        }).then(() => {
                            // this.$router.push({ name: 'dashboard' })
                        });
                    }

                })
            })
        }
    }
};
</script>

<style scoped lang="scss" src="@/assets/sass/pages/requests/beneficiary.scss"></style>
