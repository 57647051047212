import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';

const PermissionsList = () => import('@/modules/permissions/pages/PermissionsList.vue');

export default [
    {
        path: '',
        component: Layout,
        meta: { auth: true },
        children: [
            {
                name: 'permissions',
                path: '/permissions',
                component: PermissionsList,
                meta: {
                    auth: true,
                    permission: Permissions.values.permissionsRead,
                }
            },
        ],
    },
];

