import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';

const PaymentRegistryList = () => import('@/modules/payment-registry/pages/PaymentRegistryList.vue');
const TaxCalculator = () => import('@/modules/payment-registry/pages/TaxCalculator.vue');
const PaymentOrder = () => import('@/modules/payment-registry/pages/PaymentOrder.vue');

export default [
    {
        path: '',
        component: Layout,
        meta: { auth: true },
        children: [
            {
                name: 'paymentRegistry',
                path: '/payments',
                component: PaymentRegistryList,
                meta: {
                    auth: true,
                    permission: Permissions.values.paymentRegistryRead,
                }
            },
            {
                name: 'taxCalculator',
                path: '/tax-calculator',
                component: TaxCalculator,
                meta: {
                    auth: true,
                    permission: Permissions.values.taxCalculatorView,
                }
            },
            {
                name: 'paymentOrder',
                path: '/payment-order',
                component: PaymentOrder,
                meta: {
                    auth: true,
                    permission: Permissions.values.paymentOrderCreate,
                }
            },
        ],
    },
];
