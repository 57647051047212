import Layout from '@/modules/layout/components/layout';

const Profile = () => import('@/modules/profile/pages/Profile.vue');
const StaffEnrollmentRedirect = () => import('@/modules/profile/pages/StaffEnrollmentRedirect.vue');

export default [
    {
        path: '',
        exact: true,
        component: Layout,
        children: [
            {
                name: 'profile',
                path: '/profile',
                component: Profile,
                exact: true,
                meta: {auth: true},
            },
            {
                name: 'staff-enrollment-redirect',
                path: '/staff-enrollment/redirect/:id',
                component: StaffEnrollmentRedirect,
                exact: true,
                meta: {auth: true},
            }
        ],
    },
];
