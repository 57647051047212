import Roles from '@/security/roles';
const roles = Roles.values;

export const PERMISSIONS_LIST = {
    organisationsRead: {
        id: 'organisationsRead',
        allowedRoles: [
            roles.super_admin,
            roles.admin,
        ],
        allowedPermissions: [
            'CAN_VIEW_ALL',
        ],
    },
    organisationsCreate: {
        id: 'organisationsCreate',
        allowedRoles: [
            roles.super_admin,
            roles.admin,
        ],
        allowedPermissions: [
            'CAN_CREATE_ALL',
            'CAN_CREATE_ORGANISATIONS'
        ],
    },
    organisationsEdit: {
        id: 'organisationsEdit',
        allowedRoles: [
            roles.super_admin,
            roles.admin,
        ],
        allowedPermissions: [
            'CAN_EDIT_ALL',
            'CAN_EDIT_ORGANISATIONS'
        ],
    },
    organisationsDestroy: {
        id: 'organisationsDestroy',
        allowedRoles: [
            roles.super_admin,
            roles.admin,
        ],
        allowedPermissions: [
            'CAN_DELETE_ALL',
            'CAN_DELETE_ORGANISATIONS'
        ],
    },
    organisationsView: {
        id: 'organisationsView',
        allowedRoles: [
            roles.super_admin,
            roles.admin,
        ],
        allowedPermissions: [
            'CAN_VIEW_ALL',
            'CAN_VIEW_ORGANISATIONS',
            'CAN_VIEW_OWN_ORGANISATIONS',
        ],
    },
    organisationsViewOwn: {
        id: 'organisationsViewOwn',
        allowedRoles: [
            roles.admin,
        ],
        allowedPermissions: [
            'CAN_VIEW_OWN_ORGANISATIONS'
        ],
    },
    organisationsBankAccountsView: {
        id: 'organisationsBankAccountsView',
        allowedRoles: [
            roles.super_admin,
            roles.admin,
        ],
        allowedPermissions: [
            'CAN_VIEW_ALL',
            'CAN_VIEW_ALL_ORGANISATIONS_BANK_ACCOUNTS'
        ],
    },
    organisationsBankAccountsRead: {
        id: 'organisationsBankAccountsRead',
        allowedRoles: [
            roles.super_admin,
            roles.admin,
        ],
        allowedPermissions: [
            'CAN_VIEW_ALL',
            'CAN_VIEW_ORGANISATIONS_BANK_ACCOUNTS'
        ],
    },
    organisationsBankAccountsEdit: {
        id: 'organisationsBankAccountsEdit',
        allowedRoles: [
            roles.super_admin,
            roles.admin,
        ],
        allowedPermissions: [
            'CAN_EDIT_ALL',
            'CAN_EDIT_ORGANISATIONS_BANK_ACCOUNTS'
        ],
    },
    organisationsBankAccountsCreate: {
        id: 'organisationsBankAccountsCreate',
        allowedRoles: [
            roles.super_admin,
            roles.admin,
        ],
        allowedPermissions: [
            'CAN_CREATE_ALL',
            'CAN_CREATE_ORGANISATIONS_BANK_ACCOUNTS'
        ],
    },
    organisationsBankAccountsDestroy: {
        id: 'organisationsBankAccountsDestroy',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_DELETE_ALL',
            'CAN_DELETE_ORGANISATIONS_BANK_ACCOUNTS'
        ],
    },
    organisationsDepartmentsView: {
        id: 'organisationsDepartmentsView',
        allowedRoles: [
            roles.super_admin,
            roles.admin,
        ],
        allowedPermissions: [
            'CAN_CREATE_ALL',
            'CAN_VIEW_ORGANISATIONS_DEPARTMENTS'
        ],
    },
    organisationsDepartmentsEdit: {
        id: 'organisationsDepartmentsEdit',
        allowedRoles: [
            roles.super_admin,
            roles.admin,
        ],
        allowedPermissions: [
            'CAN_EDIT_ALL',
            'CAN_EDIT_ORGANISATIONS_DEPARTMENTS'
        ],
    },
    organisationsDepartmentsDelete: {
        id: 'organisationsDepartmentsDelete',
        allowedRoles: [
            roles.super_admin,
            roles.admin,
        ],
        allowedPermissions: [
            'CAN_DELETE_ALL',
            'CAN_DELETE_ORGANISATIONS_DEPARTMENTS'
        ],
    },
    organisationsDepartmentsCreate: {
        id: 'organisationsDepartmentsCreate',
        allowedRoles: [
            roles.super_admin,
            roles.admin,
        ],
        allowedPermissions: [
            'CAN_CREATE_ALL',
            'CAN_CREATE_ORGANISATIONS_DEPARTMENTS'
        ],
    },
    organisationsDepartmentsFunctionsView: {
        id: 'organisationsDepartmentsFunctionsView',
        allowedRoles: [
            roles.super_admin,
            roles.admin,
        ],
        allowedPermissions: [
            'CAN_VIEW_ALL',
            'CAN_VIEW_ORGANISATION_DEPARTMENTS_FUNCTIONS_ASSIGNMENT'
        ],
    },
    organisationsUsersFunctionsView: {
        id: 'organisationsUsersFunctionsView',
        allowedRoles: [
            roles.super_admin,
            roles.admin,
        ],
        allowedPermissions: [
            'CAN_VIEW_ALL',
            'CAN_VIEW_ORGANISATIONS_FUNCTIONS_USERS'
        ],
    },
    organisationsFunctionsView: {
        id: 'organisationsFunctionsView',
        allowedRoles: [
            roles.super_admin,
            roles.admin,
        ],
        allowedPermissions: [
            'CAN_VIEW_ALL',
            'CAN_VIEW_ORGANISATIONS_FUNCTIONS'
        ],
    },
    organisationsFunctionsCreate: {
        id: 'organisationsFunctionsCreate',
        allowedRoles: [
            roles.super_admin,
            roles.admin,
        ],
        allowedPermissions: [
            'CAN_CREATE_ALL',
            'CAN_CREATE_ORGANISATIONS_FUNCTIONS'
        ],
    },
    organisationsFunctionsEdit: {
        id: 'organisationsFunctionsEdit',
        allowedRoles: [
            roles.super_admin,
            roles.admin,
        ],
        allowedPermissions: [
            'CAN_EDIT_ALL',
            'CAN_EDIT_ORGANISATIONS_FUNCTIONS'
        ],
    },
    organisationsFunctionsDestroy: {
        id: 'organisationsFunctionsDestroy',
        allowedRoles: [
            roles.super_admin,
            roles.admin,
        ],
        allowedPermissions: [
            'CAN_DELETE_ALL',
            'CAN_DELETE_ORGANISATIONS_FUNCTIONS'
        ],
    },
    organisationsResponsabilityFunctionsCreate: {
        id: 'organisationsResponsabilityFunctionsCreate',
        allowedRoles: [
            roles.super_admin,
            roles.admin,
        ],
        allowedPermissions: [
            'CAN_CREATE_ALL',
            'CAN_CREATE_NEW_ORGANISATIONS_RESPONSABILITY_TO_FUNCTIONS'
        ],
    },
    organisationsResponsabilityFunctionsAssigmentCreate: {
        id: 'organisationsResponsabilityFunctionsAssigmentCreate',
        allowedRoles: [
            roles.super_admin,
            roles.admin,
        ],
        allowedPermissions: [
            'CAN_CREATE_ALL',
            'CAN_CREATE_ORGANISATIONS_RESPONSIBILITIES_FUNCTIONS_ASSIGNMENT'
        ],
    },
    organisationsResponsibilitiesView: {
        id: 'organisationsResponsibilitiesView',
        allowedRoles: [
            roles.super_admin,
            roles.admin,
        ],
        allowedPermissions: [
            'CAN_VIEW_ALL',
            'CAN_VIEW_ORGANISATIONS_RESPONSIBILITIES'
        ],
    },
    organisationsResponsibilitiesCreate: {
        id: 'organisationsResponsibilitiesCreate',
        allowedRoles: [
            roles.super_admin,
            roles.admin,
        ],
        allowedPermissions: [
            'CAN_CREATE_ALL',
            'CAN_CREATE_ORGANISATIONS_RESPONSIBILITIES'
        ],
    },
    organisationsResponsibilitiesEdit: {
        id: 'organisationsResponsibilitiesEdit',
        allowedRoles: [
            roles.super_admin,
            roles.admin,
        ],
        allowedPermissions: [
            'CAN_EDIT_ALL',
            'CAN_EDIT_ORGANISATIONS_RESPONSIBILITIES'
        ],
    },
    organisationsResponsibilitiesDestroy: {
        id: 'organisationsResponsibilitiesDestroy',
        allowedRoles: [
            roles.super_admin,
            roles.admin,
        ],
        allowedPermissions: [
            'CAN_DELETE_ALL',
            'CAN_DELETE_ORGANISATIONS_RESPONSIBILITIES'
        ],
    },
    organisationsUsersView: {
        id: 'organisationsUsersView',
        allowedRoles: [
            roles.super_admin,
            roles.admin,
        ],
        allowedPermissions: [
            'CAN_VIEW_ALL',
            'CAN_VIEW_ORGANISATIONS_USERS'
        ],
    },
    organisationsStaffUsersCreate: {
        id: 'organisationsStaffUsersCreate',
        allowedRoles: [
            roles.super_admin,
            roles.admin,
        ],
        allowedPermissions: [
            'CAN_CREATE_ALL',
            'CAN_CREATE_STAFF_USERS'
        ],
    },
    organisationsStaffUsersEdit: {
        id: 'organisationsStaffUsersEdit',
        allowedRoles: [
            roles.super_admin,
            roles.admin,
        ],
        allowedPermissions: [
            'CAN_EDIT_ALL',
            'CAN_EDIT_STAFF_USERS'
        ],
    },
    organisationsStaffUsersAccountStatus: {
        id: 'organisationsStaffUsersAccountStatus',
        allowedRoles: [
            roles.super_admin,
            roles.admin,
        ],
        allowedPermissions: [
            'CAN_EDIT_ALL',
            'CAN_EDIT_STAFF_USERS'
        ],
    },
    organisationsStaffUsersResetPassword: {
        id: 'organisationsStaffUsersResetPassword',
        allowedRoles: [
            roles.super_admin,
            roles.admin,
        ],
        allowedPermissions: [
            'CAN_EDIT_ALL',
            'CAN_CREATE_USER_INVALIDATE_PASSWORD'
        ],
    },
    organisationsStaffUsersDestroy: {
        id: 'organisationsStaffUsersDestroy',
        allowedRoles: [
            roles.super_admin,
            roles.admin,
        ],
        allowedPermissions: [
            'CAN_DELETE_ALL',
            'CAN_DELETE_ORGANISATIONS_USERS'
        ],
    },
    organisationsTaxesView: {
        id: 'organisationsTaxesView',
        allowedRoles: [
            roles.super_admin,
            roles.admin,
        ],
        allowedPermissions: [
            'CAN_VIEW_ALL',
            'CAN_VIEW_ORGANISATION_TAXES'
        ],
    },
    organisationsTaxesCreate: {
        id: 'organisationsTaxesView',
        allowedRoles: [
            roles.super_admin,
            roles.admin,
        ],
        allowedPermissions: [
            'CAN_EDIT_ALL',
            'CAN_CREATE_ORGANISATION_TAXES'
        ],
    },
    organisationsTaxesEdit: {
        id: 'organisationsTaxesEdit',
        allowedRoles: [
            roles.super_admin,
            roles.admin,
        ],
        allowedPermissions: [
            'CAN_EDIT_ALL',
            'CAN_EDIT_ORGANISATION_TAXES'
        ],
    },
    organisationsDocumentSeriesView: {
        id: 'organisationsDocumentSeriesView',
        allowedRoles: [
            roles.super_admin,
            roles.admin,
        ],
        allowedPermissions: [
            'CAN_VIEW_ALL',
            'CAN_VIEW_DOCUMENT_SERIES'
        ],
    },
    organisationsDocumentSeriesCreate: {
        id: 'organisationsDocumentSeriesCreate',
        allowedRoles: [
            roles.super_admin,
            roles.admin,
        ],
        allowedPermissions: [
            'CAN_CREATE_ALL',
            'CAN_CREATE_DOCUMENT_SERIES'
        ],
    },
    organisationsDocumentSeriesEdit: {
        id: 'organisationsDocumentSeriesEdit',
        allowedRoles: [
            roles.super_admin,
            roles.admin,
        ],
        allowedPermissions: [
            'CAN_EDIT_ALL',
            'CAN_EDIT_DOCUMENT_SERIES'
        ],
    },
    organisationsDocumentSeriesTypeView: {
        id: 'organisationsDocumentSeriesTypeView',
        allowedRoles: [
            roles.super_admin,
            roles.admin,
        ],
        allowedPermissions: [
            'CAN_VIEW_ALL',
            'CAN_VIEW_DOCUMENT_SERIES_TYPES'
        ],
    },
    organisationsDecisionView: {
        id: 'organisationsDecisionView',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_VIEW_DECISIONS',
            'CAN_VIEW_ALL',
        ],
    },
    organisationsDecisionCreate: {
        id: 'organisationsDecisionCreate',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_CREATE_DECISIONS',
            'CAN_CREATE_ALL',
        ],
    },
    organisationsDecisionEdit: {
        id: 'organisationsDecisionEdit',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_EDIT_DECISIONS',
            'CAN_EDIT_ALL',
        ],
    },
    organisationsDecisionDestroy: {
        id: 'organisationsDecisionDestroy',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_DELETE_DECISIONS',
            'CAN_DELETE_ALL',
        ],
    },
    organisationsApplicationsCreate: {
        id: 'organisationsApplicationsCreate',
        allowedRoles: [
            roles.staff,
        ],
        allowedPermissions: [
            'CAN_CREATE_ORGANISATIONS_APPLICATIONS'
        ],
    },
    organisationsTerritorialUnitAllocationView: {
        id: 'organisationsTerritorialUnitAllocationView',
        allowedRoles: [
            roles.super_admin,
            roles.admin,
        ],
        allowedPermissions: [
            'CAN_VIEW_UNIT_ALLOCATIONS',
            'CAN_VIEW_ALL',
        ],
    },
    organisationsTerritorialUnitAllocationCreate: {
        id: 'organisationsTerritorialUnitAllocationCreate',
        allowedRoles: [
            roles.super_admin,
            roles.admin,
        ],
        allowedPermissions: [
            'CAN_CREATE_UNIT_ALLOCATIONS',
            'CAN_CREATE_ALL',
        ],
    },
    organisationsTerritorialUnitAllocationEdit: {
        id: 'organisationsTerritorialUnitAllocationEdit',
        allowedRoles: [
            roles.super_admin,
            roles.admin,
        ],
        allowedPermissions: [
            'CAN_EDIT_UNIT_ALLOCATIONS',
            'CAN_EDIT_ALL',
        ],
    },
    organisationsTerritorialUnitAllocationDestroy: {
        id: 'organisationsTerritorialUnitAllocationDestroy',
        allowedRoles: [
            roles.super_admin,
            roles.admin,
        ],
        allowedPermissions: [
            'CAN_DELETE_UNIT_ALLOCATIONS',
            'CAN_DELETE_ALL',
        ],
    },
    permissionsView: {
        id: 'permissionsView',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_VIEW_ALL',
        ],
    },
    responsibilitiesView: {
        id: 'responsibilitiesView',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_VIEW_ALL',
        ],
    },
    notificationsTemplateRead: {
        id: 'notificationsTemplateRead',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_VIEW_ALL',
        ],
    },
    notificationsTemplateEdit: {
        id: 'notificationsTemplateEdit',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_EDIT_ALL',
        ],
    },
    notificationsPreferencesRead: {
        id: 'notificationsPreferencesRead',
        allowedRoles: [
            roles.super_admin,
            roles.admin,
            roles.staff,
            roles.citizen,
            roles.foreigner,
            roles.legal_entity,
        ],
        allowedPermissions: [
            'CAN_VIEW_ALL',
            'CAN_VIEW_NOTIFICATION_PREFERENCES'
        ],
    },
    notificationsPreferencesEdit: {
        id: 'notificationsPreferencesEdit',
        allowedRoles: [
            roles.super_admin,
            roles.admin,
            roles.staff,
            roles.citizen,
            roles.foreigner,
            roles.legal_entity,
        ],
        allowedPermissions: [
            'CAN_EDIT_ALL',
            'CAN_EDIT_NOTIFICATION_PREFERENCES'
        ],
    },
    applicationCreate: {
        id: 'applicationCreate',
        allowedRoles: [
            roles.citizen,
            roles.foreigner,
            roles.legal_entity,
        ],
        allowedPermissions: [
            'CAN_CREATE_APPLICATIONS',
        ],
    },
    applicationEdit: {
        id: 'applicationEdit',
        allowedRoles: [
            roles.citizen,
            roles.foreigner,
            roles.legal_entity,
        ],
        allowedPermissions: [
            'CAN_EDIT_OWN_APPLICATIONS',
        ],
    },
    applicationsView: {
        id: 'applicationsView',
        allowedRoles: [
            roles.citizen,
            roles.foreigner,
            roles.legal_entity,
            roles.staff
        ],
        allowedPermissions: [
            'CAN_VIEW_OWN_APPLICATIONS',
            'CAN_VIEW_ORGANISATIONS_APPLICATIONS',
            'CAN_VIEW_ALL',
            'CAN_VIEW_ASSIGNED_ORG_APPLICATIONS',
        ],
    },
    applicationsRead: {
        id: 'applicationsRead',
        allowedRoles: [
            roles.citizen,
            roles.foreigner,
            roles.legal_entity,
        ],
        allowedPermissions: [
            'CAN_VIEW_OWN_APPLICATIONS',
        ],
    },
    applicationsStaffRead: {
        id: 'applicationsStaffRead',
        allowedRoles: [
            roles.staff,
        ],
        allowedPermissions: [
            'CAN_VIEW_ALL',
            'CAN_VIEW_ORGANISATIONS_APPLICATIONS',
        ],
    },
    applicationsStaffOwnRead: {
        id: 'applicationsStaffOwnRead',
        allowedRoles: [
            roles.staff,
        ],
        allowedPermissions: [
            'CAN_VIEW_ASSIGNED_ORG_APPLICATIONS',
        ],
    },
    applicationsStaffAssignEdit: {
        id: 'applicationsStaffAssignEdit',
        allowedRoles: [
            roles.staff
        ],
        allowedPermissions: [
            'CAN_ASSIGN_APPLICATION'
        ]
    },
    createDoc: {
        id: 'createDoc',
        allowedRoles: [
            roles.staff,
            roles.admin,
            roles.super_admin
        ],
        allowedPermissions: [
            'CAN_CREATE_ALL',
        ],
    },
    complainCreate: {
        id: 'complainCreate',
        allowedRoles: [
            roles.citizen,
            roles.foreigner,
            roles.legal_entity,
        ],
        allowedPermissions: [
            // 'CAN_CREATE_COMPLAINS',
        ],
    },
    complainEdit: {
        id: 'complainEdit',
        allowedRoles: [
            roles.staff,
            roles.admin,
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_EDIT_ORGANISATIONS_COMPLAINS',
            'CAN_EDIT_ALL',
        ]
    },
    complainResolve: {
        id: 'complainResolve',
        allowedRoles: [
            roles.staff,
            roles.admin,
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_EDIT_ORGANISATIONS_COMPLAINS',
            'CAN_EDIT_ALL',
        ]
    },
    complainView: {
        id: 'complainView',
        allowedRoles: [
            roles.staff,
            roles.citizen,
            roles.foreigner,
            roles.legal_entity,
            roles.admin,
            roles.super_admin
        ],
        allowedPermissions: [
            'CAN_VIEW_OTHER_ORGANISATIONS_COMPLAINS',
            // 'CAN_VIEW_OWN_COMPLAINS',
            'CAN_VIEW_OWN_ORGANISATIONS_COMPLAINS',
            'CAN_VIEW_ALL'
        ],
    },
    complainRead: {
        id: 'myComplainRead',
        allowedRoles: [
            roles.citizen,
            roles.foreigner,
            roles.legal_entity,
        ],
        allowedPermissions: [
            // 'CAN_VIEW_OWN_COMPLAINS',
        ],
    },
    complainStaffRead: {
        id: 'complainStaffRead',
        allowedRoles: [
            roles.staff,
            roles.admin,
            roles.super_admin
        ],
        allowedPermissions: [
            'CAN_VIEW_OTHER_ORGANISATIONS_COMPLAINS',
            'CAN_VIEW_OWN_ORGANISATIONS_COMPLAINS',
            'CAN_VIEW_ALL',
        ],
    },
    responseTemplatesRead: {
        id: 'responseTemplatesRead',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_VIEW_ALL',
        ],
    },
    responseTemplatesCreate: {
        id: 'responseTemplatesCreate',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_CREATE_ALL',
        ],
    },
    responseTemplatesEdit: {
        id: 'responseTemplatesEdit',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_EDIT_ALL',
        ],
    },
    responseTemplatesDestroy: {
        id: 'responseTemplatesDestroy',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_DELETE_ALL',
        ],
    },
    taxCategoryRead: {
        id: 'taxCategoryRead',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_VIEW_ALL',
        ],
    },
    taxCategoryCreate: {
        id: 'taxCategoryCreate',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_CREATE_ALL',
        ],
    },
    taxCategoryEdit: {
        id: 'taxCategoryEdit',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_EDIT_ALL',
        ],
    },
    taxCategoryDestroy: {
        id: 'taxCategoryDestroy',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_DELETE_ALL',
        ],
    },
    accountsRead: {
        id: 'accountsRead',
        allowedRoles: [
            roles.legal_entity,
        ],
        allowedPermissions: [
            'CAN_VIEW_LEGAL_ENTITY_USERS',
        ],
    },
    accountsCreate: {
        id: 'accountsCreate',
        allowedRoles: [
            roles.legal_entity,
        ],
        allowedPermissions: [
            'CAN_CREATE_LEGAL_ENTITY_USERS',
        ],
    },
    accountsEdit: {
        id: 'accountsEdit',
        allowedRoles: [
            roles.legal_entity,
        ],
        allowedPermissions: [
            'CAN_EDIT_LEGAL_ENTITY_USERS',
        ],
    },
    accountsDestroy: {
        id: 'accountsDestroy',
        allowedRoles: [
            roles.legal_entity,
        ],
        allowedPermissions: [
            'CAN_DELETE_ACCOUNTS',
        ],
    },
    complainTypeRead: {
        id: 'complainTypeRead',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_VIEW_ALL',
        ],
    },
    complainTypeCreate: {
        id: 'complainTypeCreate',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_CREATE_ALL',
        ],
    },
    complainTypeEdit: {
        id: 'complainTypeEdit',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_EDIT_ALL',
        ],
    },
    complainTypeDestroy: {
        id: 'complainTypeDestroy',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_DELETE_ALL',
        ],
    },
    profileComplete: {
        id: 'profileComplete',
        allowedRoles: [
            roles.citizen,
            roles.foreigner,
            roles.legal_entity
        ],
        allowedPermissions: [
            'CAN_CREATE_ALL',
            'CAN_VIEW_OWN_PROFILE',
        ],
    },
    companyProfileEdit: {
        id: 'companyProfileEdit',
        allowedRoles: [
            roles.legal_entity,
        ],
        allowedPermissions: [
            'CAN_EDIT_COMPANY_PROFILE',
        ],
    },
    responsibilityRead: {
        id: 'responsibilityRead',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_VIEW_ALL',
        ],
    },
    responsibilityCreate: {
        id: 'responsibilityCreate',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_CREATE_ALL',
        ],
    },
    responsibilityEdit: {
        id: 'responsibilityEdit',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_EDIT_ALL',
        ],
    },
    responsibilityDestroy: {
        id: 'responsibilityDestroy',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_DELETE_ALL',
        ],
    },
    archiveRead: {
        id: 'archiveRead',
        allowedRoles: [
            roles.admin,
            roles.archiver,
            roles.staff,
        ],
        allowedPermissions: [
            'CAN_VIEW_ARCHIVE',
            'CAN_MANUAL_ARCHIVE',
            'CAN_ADMIN_ARCHIVE',
        ],
    },
    archiveCreate: {
        id: 'archiveCreate',
        allowedRoles: [
            roles.admin,
            roles.archiver,
        ],
        allowedPermissions: [
            'CAN_MANUAL_ARCHIVE',
            'CAN_ADMIN_ARCHIVE'
        ],
    },
    archiveAdmin: {
        id: 'archiveAdmin',
        allowedRoles: [
            roles.admin,
        ],
        allowedPermissions: [
            'CAN_ADMIN_ARCHIVE',
        ],
    },
    formsRead: {
        id: 'formsRead',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_VIEW_ALL',
        ],
    },
    formsCreate: {
        id: 'formsCreate',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_CREATE_ALL',
        ],
    },
    formsEdit: {
        id: 'formsEdit',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_EDIT_ALL',
        ],
    },
    formsDestroy: {
        id: 'formsDestroy',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_DELETE_ALL',
        ],
    },
    applicationTypeRead: {
        id: 'applicationTypeRead',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_VIEW_ALL',
        ],
    },
    applicationTypeCreate: {
        id: 'applicationTypeCreate',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_CREATE_ALL',
        ],
    },
    applicationTypeEdit: {
        id: 'applicationTypeEdit',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_EDIT_ALL',
        ],
    },
    applicationTypeDestroy: {
        id: 'applicationTypeDestroy',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_DELETE_ALL',
        ],
    },
    documentSeriesTypeRead: {
        id: 'applicationTypeRead',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_VIEW_ALL',
        ],
    },
    documentSeriesTypeCreate: {
        id: 'documentSeriesTypeCreate',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_CREATE_ALL',
        ],
    },
    documentSeriesTypeEdit: {
        id: 'documentSeriesTypeEdit',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_EDIT_ALL',
        ],
    },
    documentSeriesTypeDestroy: {
        id: 'documentSeriesTypeDestroy',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_DELETE_ALL',
        ],
    },
    documentTemplateRead: {
        id: 'documentTemplateRead',
        allowedRoles: [
            roles.super_admin,
            roles.admin,
        ],
        allowedPermissions: [
            'CAN_VIEW_ALL',
            'CAN_VIEW_ORGANISATIONS_DOCUMENT_TEMPLATES'
        ],
    },
    documentTemplateCreate: {
        id: 'documentTemplateCreate',
        allowedRoles: [
            roles.super_admin,
            roles.admin,
        ],
        allowedPermissions: [
            'CAN_CREATE_ALL',
            'CAN_CREATE_ORGANISATIONS_DOCUMENT_TEMPLATES'
        ],
    },
    documentTemplateEdit: {
        id: 'documentTemplateEdit',
        allowedRoles: [
            roles.super_admin,
            roles.admin,
        ],
        allowedPermissions: [
            'CAN_EDIT_ALL',
            'CAN_EDIT_ORGANISATIONS_DOCUMENT_TEMPLATES'
        ],
    },
    documentTemplateDestroy: {
        id: 'documentTemplateDestroy',
        allowedRoles: [
            roles.super_admin,
            roles.admin,
        ],
        allowedPermissions: [
            'CAN_DELETE_ALL',
            'CAN_DELETE_ORGANISATIONS_DOCUMENT_TEMPLATES'
        ],
    },
    processEditorRead: {
        id: 'processEditorRead',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_VIEW_ALL',
        ],
    },
    processEditorCreate: {
        id: 'processEditorCreate',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_CREATE_ALL',
        ],
    },
    processEditorEdit: {
        id: 'processEditorEdit',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_EDIT_ALL',
        ],
    },
    processEditorDestroy: {
        id: 'processEditorDestroy',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_DELETE_ALL',
        ],
    },
    processEditorStepsRead: {
        id: 'processEditorStepsRead',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_VIEW_ALL',
        ],
    },
    processEditorStepsCreate: {
        id: 'processEditorstepsCreate',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_CREATE_ALL',
        ],
    },
    processEditorStepsEdit: {
        id: 'processEditorStepsEdit',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_EDIT_ALL',
        ],
    },
    processEditorStepsDestroy: {
        id: 'processEditorStepsDestroy',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_DELETE_ALL',
        ],
    },
    processEditorStepsPublish: {
        id: 'processEditorStepsPublish',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_CREATE_ALL',
        ],
    },
    permissionsRead: {
        id: 'permissionsRead',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_VIEW_ALL',
        ],
    },
    permissionsCreate: {
        id: 'permissionsCreate',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_VIEW_ALL',
        ],
    },
    permissionsEdit: {
        id: 'permissionsEdit',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_EDIT_ALL',
        ],
    },
    metadataMappingRead: {
        id: 'MetadataMappingRead',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_VIEW_ALL',
        ],
    },
    decisionTargetRead: {
        id: 'decisionTargetRead',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_VIEW_ALL',
            'CAN_VIEW_DECISION_TARGETS'
        ],
    },
    decisionTargetCreate: {
        id: 'decisionTargetCreate',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_CREATE_ALL',
            'CAN_CREATE_DECISION_TARGETS'
        ],
    },
    decisionTargetEdit: {
        id: 'decisionTargetEdit',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_EDIT_ALL',
            'CAN_EDIT_DECISION_TARGETS'
        ],
    },
    decisionTargetDestroy: {
        id: 'decisionTargetDestroy',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_DELETE_ALL',
            'CAN_DELETE_DECISION_TARGETS'
        ],
    },
    decisionPurposeRead: {
        id: 'decisionPurposeRead',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_VIEW_ALL',
            'CAN_VIEW_DECISION_PURPOSES'
        ],
    },
    decisionPurposeCreate: {
        id: 'decisionPurposeCreate',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_CREATE_ALL',
            'CAN_CREATE_DECISION_PURPOSES'
        ],
    },
    decisionPurposeEdit: {
        id: 'decisionPurposeEdit',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_EDIT_ALL',
            'CAN_EDIT_DECISION_PURPOSES'
        ],
    },
    decisionPurposeDestroy: {
        id: 'decisionPurposeDestroy',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_DELETE_ALL',
            'CAN_DELETE_DECISION_PURPOSES'
        ],
    },
    paymentRegistryRead: {
        id: 'paymentRegistryRead',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_VIEW_ALL',
            'CAN_VIEW_ORGANISATIONS_APPLICATIONS',
            'CAN_VIEW_OWN_ORGANISATIONS_APPLICATIONS'
        ],
    },
    taxCalculatorView: {
        id: 'taxCalculatorView',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_VIEW_ALL',
            'CAN_VIEW_ORGANISATIONS_APPLICATIONS',
            'CAN_VIEW_OWN_ORGANISATIONS_APPLICATIONS'
        ],
    },
    paymentOrderCreate: {
        id: 'paymentOrderCreate',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_CREATE_ALL',
            'CAN_VIEW_ORGANISATIONS_APPLICATIONS',
            'CAN_VIEW_OWN_ORGANISATIONS_APPLICATIONS'
        ],
    },
    issuedDocumentRead: {
        id: 'issuedDocumentRead',
        allowedRoles: [
            roles.super_admin,
        ],
        allowedPermissions: [
            'CAN_VIEW_ALL',
        ],
    },
    // TODO: Update Permissions
    controlRead: {
        id: 'controlRead',
        allowedRoles: [
            roles.staff,
        ],
        allowedPermissions: [
            'CAN_VIEW_OTHER_ORGANISATION_COMPLAINS',
            'CAN_VIEW_OWN_ORGANISATIONS_COMPLAINS',
        ],
    },
    controlCreate: {
        id: 'controlCreate',
        allowedRoles: [
            roles.staff,
        ],
        allowedPermissions: [
            'CAN_CREATE_ALL',
        ],
    },
    controlEdit: {
        id: 'controlEdit',
        allowedRoles: [
            roles.staff,
        ],
        allowedPermissions: [
            'CAN_EDIT_ALL',
        ],
    },
    documentSeriesAllocationRead: {
        id: 'documentSeriesAllocationRead',
        allowedRoles: [
            roles.super_admin,
            roles.staff,
        ],
        allowedPermissions: [
            'CAN_VIEW_ALL',
            'CAN_VIEW_ALLOCATIONS',
        ],
    },
    documentSeriesAllocationCreate: {
        id: 'documentSeriesAllocationCreate',
        allowedRoles: [
            roles.staff,
        ],
        allowedPermissions: [
            'CAN_CREATE_ALLOCATIONS',
        ],
    },
    dashboardViewOwn: {
        id: 'dashboardViewOwn',
        allowedRoles: [
            roles.staff,
        ],
        allowedPermissions: [
            'CAN_VIEW_OWN_DASHBOARD',
        ],
    },
    dashboardViewOrg: {
        id: 'dashboardViewOrg',
        allowedRoles: [
            roles.admin,
            roles.staff,
        ],
        allowedPermissions: [
            'CAN_VIEW_ORGANISATION_DASHBOARD',
        ],
    },
    // For dashboard menu and route
    dashboardView: {
        id: 'dashboardView',
        allowedRoles: [
            roles.admin,
            roles.staff,
        ],
        allowedPermissions: [
            'CAN_VIEW_ORGANISATION_DASHBOARD',
            'CAN_VIEW_OWN_DASHBOARD',
        ],
    }
};
