<template>
    <!-- begin:: Aside -->
    <div id="kt_brand" ref="kt_brand" class="brand">
        <div class="brand-logo" @click="goToHome" role="button">
            <img :src="siteLogo()" alt="Logo" class="logo-img"/>
            <div v-if="isAtlas" class="brand-name brand-atlas">
                <div class="brand-text">ATLAS</div>
                <div class="brand-text-sub">Teritorial</div>
            </div>
            <div v-else class="brand-name brand-gu">
                <div class="brand-text">{{ brandName }}</div>
                <div class="brand-text-sub">{{ sectionName }}</div>
            </div>
        </div>
        <div v-if="allowMinimize" class="brand-tools">
            <button
                id="kt_aside_toggle"
                ref="kt_aside_toggle"
                class="brand-toggle btn btn-sm px-0"
            >
                <i class="fas fa-angle-double-left"></i>
            </button>
        </div>
    </div>
    <!-- end:: Aside -->
</template>

<style lang="scss" scoped>
.aside-toggle {
    outline: none;
}
</style>

<script>
import { mapGetters } from 'vuex';
import objectPath from 'object-path';
import StLayoutBrand from '@/assets/js/layout/base/brand.js';
import StLayoutAsideToggle from '@/assets/js/layout/base/aside-toggle.js';

export default {
    name: 'Brand',
    mounted() {
        // Init Brand Panel For Logo
        StLayoutBrand.init(this.$refs.kt_brand);

        // Init Aside Menu Toggle
        StLayoutAsideToggle.init(this.$refs.kt_aside_toggle);
    },
    methods: {
        siteLogo() {
            const menuAsideLeftSkin = this.layoutConfig('brand.self.theme');
            // set brand logo
            const logoObject = this.layoutConfig('self.logo');

            let logo;
            if (typeof logoObject === 'string') {
                logo = logoObject;
            }
            if (typeof logoObject === 'object') {
                logo = objectPath.get(logoObject, `${menuAsideLeftSkin}`);
            }
            if (typeof logo === 'undefined') {
                const logos = this.layoutConfig('self.logo');
                logo = logos[Object.keys(logos)[0]];
            }

            return process.env.BASE_URL + logo;
        },
        goToHome() {
            // TODO: redirect to specific user type dashboard for default menu
            const HOME_PAGES = {
                default: 'home',
                archive: 'archive-dashboard',
                atlas: 'atlas'
            }
            this.$router.push({
                name: HOME_PAGES[this.selectedMenu],
            });
        }
    },
    computed: {
        ...mapGetters(['layoutConfig', 'selectedMenu']),

        allowMinimize() {
            return !!this.layoutConfig('aside.self.minimize.toggle');
        },
        isAtlas() {
            return this.selectedMenu === 'atlas';
        },
        brandName() {
            return this.layoutConfig('self.brand');
        },
        sectionName() {
            return this.layoutConfig('self.section');
        },
    },
};
</script>
<style scoped lang="scss">

</style>
