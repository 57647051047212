import Layout from '@/modules/layout/components/layout';

const Beneficiary = () => import('@/modules/beneficiary/pages/Beneficiary.vue');

export default [
  {
    path: '',
    exact: true,
    component: Layout,
    children: [
      {
        name: 'beneficiary',
        path: '/beneficiary',
        component: Beneficiary,
        exact: true,
        meta: { auth: true },
      },
    ],
  },
];
