<template>
    <form class="form"
        novalidate="novalidate"
        :id="`st_legal_form_${id}`"
    >
        <div class="row mb-6">
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label>
                        {{ $t('SET_PROFILE_DATA.LEGAL.URC') }} *
                    </label>
                    <div label="">
                        <st-input-text
                            v-model="legalFields.unique_registration_code"
                            ref="unique_registration_code"
                            name="unique_registration_code"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ $t('SET_PROFILE_DATA.LEGAL.NOMENCLATURE') }} *
                    </label>
                    <div label="">
                        <st-input-text
                            v-model="legalFields.nomenclature"
                            ref="nomenclature"
                            name="nomenclature"/>
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        Reprezentant *
                    </label>
                    <div label="">
                        <st-input-text
                            v-model="legalFields.representative"
                            ref="representative"
                            name="representative"/>
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ $t('SET_PROFILE_DATA.LEGAL.COUNTY') }} *
                    </label>
                    <div label="">
                        <b-form-select
                            v-model="legalFields.county"
                            :options="counties"
                            ref="county"
                            name="county"
                            value-field="county"
                            text-field="county"
                            >
                        </b-form-select>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label>
                        {{ $t('SET_PROFILE_DATA.LEGAL.LOCALITY') }} *
                    </label>
                    <div label="">
                        <b-form-select
                            v-model="legalFields.locality"
                            :options="cityList"
                            value-field="name"
                            text-field="name"
                            ref="locality"
                            name="locality"
                            :disabled= "!cityList.length > 0"
                        ></b-form-select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm">
                        <label>
                            {{ $t('SET_PROFILE_DATA.PERSON.VILLAGE') }}
                        </label>
                        <div label="">
                            <b-form-select
                                v-model="legalFields.village"
                                :options="villageList"
                                value-field="name"
                                text-field="name"
                                ref="villages"
                                name="villages"
                                :disabled= "!villageList.length > 0"
                            ></b-form-select>
                        </div>
                    </div>
                    <div class="col-sm">
                        <div class="form-group">
                            <label>
                                {{ $t('SET_PROFILE_DATA.PERSON.SECTOR') }}
                            </label>
                            <div label="">
                                <st-input-text v-model="legalFields.sector" ref="sector" name="sector"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ $t('SET_PROFILE_DATA.LEGAL.STREET') }} *
                    </label>
                    <div label="">
                        <st-input-text v-model="legalFields.street" ref="street" name="street"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm">
                        <div class="form-group">
                            <label>
                                {{ $t('SET_PROFILE_DATA.LEGAL.POSTAL_CODE') }} *
                            </label>
                            <div label="">
                                <st-input-text
                                    v-model="legalFields.postal_code"
                                    ref="postal_code"
                                    name="postal_code"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm">
                        <div class="form-group">
                            <label>
                                {{ $t('SET_PROFILE_DATA.LEGAL.NUMBER') }} *
                            </label>
                            <div label="">
                                <st-input-text v-model="legalFields.street_number" ref="number" name="number"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <div class="row mb-3">
                        <div class="col-sm">
                            <label>
                                {{ $t('SET_PROFILE_DATA.LEGAL.STAIRCASE') }}
                            </label>
                            <div label="">
                                <st-input-text v-model="legalFields.staircase" ref="staircase"
                                                name="staircase"/>
                            </div>
                        </div>
                        <div class="col-sm">
                            <label>
                                {{ $t('SET_PROFILE_DATA.LEGAL.BLOCK') }}
                            </label>
                            <div label="">
                                <st-input-text v-model="legalFields.block" ref="block" name="block"/>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-sm">
                            <label>
                                {{ $t('SET_PROFILE_DATA.LEGAL.FLOOR') }}
                            </label>
                            <div label="">
                                <st-input-text v-model="legalFields.floor" ref="floor" name="floor"/>
                            </div>
                        </div>
                        <div class="col-sm">
                            <label>
                                {{ $t('SET_PROFILE_DATA.LEGAL.APARTMENT') }}
                            </label>
                            <div label="">
                                <st-input-text
                                    v-model="legalFields.apartment"
                                    ref="apartment"
                                    name="apartment"/>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>
                            {{ $t('SET_PROFILE_DATA.LEGAL.EMAIL') }} *
                        </label>
                        <div label="">
                            <st-input-text v-model="legalFields.email" ref="email" name="email"/>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>
                            {{ $t('SET_PROFILE_DATA.LEGAL.PHONE') }} *
                        </label>
                        <div label="">
                            <st-input-text
                                v-model="legalFields.phone_number"
                                ref="phone_number"
                                name="phone_number"
                                set-validation
                            />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </form>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import localitiesAndVillagesMixin from '@/modules/beneficiary/mixins/legal-person-mixin';

// FormValidation plugins
import formValidation from '@/assets/plugins/formvalidation/dist/es6/core/Core';
import Trigger from '@/assets/plugins/formvalidation/dist/es6/plugins/Trigger';
import Bootstrap from '@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap';
import SubmitButton from '@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton';

export default {
    name: 'LegalForm',
    props: {
       id: {
            type: String,
        },
        isContact: {
            type: Boolean,
        }
    },
    mixins: [localitiesAndVillagesMixin],
    data() {
        return {
            legalFields: {
                user_type_name: 'legal_entity',
                unique_registration_code: '',
                nomenclature: '',
                representative: '',
                county: '',
                locality: '',
                village: '',
                sector: '',
                street: '',
                postal_code: '',
                street_number: '',
                staircase: '',
                block: '',
                floor: '',
                apartment: '',
                email: '',
                phone_number: '',
                license: '',
            },
            cityList: [],
            villageList: []
        }
    },
    computed: {
        ...mapState({
            counties: state => state.shared.counties,
        }),
        ...mapGetters({
            localities: 'profile/settlements',
            villages: 'profile/villages',
        }),
    },
    mounted() {
        const legal_form = KTUtil.getById(`st_legal_form_${this.id}`);
        const PHONE_REGEX = '^[\\+\\d]?(?:[\\d]*)$';
        this.fv = formValidation(legal_form, {
            fields: {
                unique_registration_code: {
                    validators: {
                        notEmpty: {
                            message: this.$t('AUTH.VALIDATION.REQUIRED_FIELD')
                        },
                        urc: {
                            message: this.$t('AUTH.VALIDATION.INVALID_URC')
                        },
                    }
                },
                nomenclature: {
                    validators: {
                        notEmpty: {
                            message: this.$t('AUTH.VALIDATION.REQUIRED_FIELD')
                        }
                    }
                },
                representative: {
                    validators: {
                        notEmpty: {
                            message: this.$t('AUTH.VALIDATION.REQUIRED_FIELD')
                        }
                    }
                },
                county: {
                    validators: {
                        notEmpty: {
                            message: this.$t('AUTH.VALIDATION.REQUIRED_FIELD')
                        }
                    }
                },
                locality: {
                    validators: {
                        notEmpty: {
                            message: this.$t('AUTH.VALIDATION.REQUIRED_FIELD')
                        }
                    }
                },
                street: {
                    validators: {
                        notEmpty: {
                            message: this.$t('AUTH.VALIDATION.REQUIRED_FIELD')
                        }
                    }
                },
                number: {
                    validators: {
                        notEmpty: {
                            message: this.$t('AUTH.VALIDATION.REQUIRED_FIELD'),
                        },
                    },
                },
                postal_code: {
                    validators: {
                        notEmpty: {
                            message: this.$t('AUTH.VALIDATION.REQUIRED_FIELD'),
                        },
                        numeric: {
                            message: this.$t('AUTH.VALIDATION.NUMERIC_FIELD'),
                        },
                        stringLength: {
                            max: 6,
                            message: this.$t('AUTH.VALIDATION.POSTAL_CODE_MAX_LENGTH_FIELD'),
                        }
                    },
                },
                email: {
                    validators: {
                        notEmpty: {
                            message: this.$t('AUTH.VALIDATION.REQUIRED_FIELD')
                        },
                        emailAddress: {
                           message: this.$t('AUTH.VALIDATION.INVALID_EMAIL')
                        }
                    }
                },
                phone_number: {
                    validators: {
                        notEmpty: {
                            message: this.$t('AUTH.VALIDATION.REQUIRED_FIELD')
                        },
                        regexp: {
                            regexp: PHONE_REGEX,
                            message: this.$t('AUTH.VALIDATION.INVALID_PHONE')
                        }
                    }
                },

            },
            plugins: {
                trigger: new Trigger(),
                submitButton: new SubmitButton(),
                bootstrap: new Bootstrap(),
            },
        })
    },
    methods: {
        onSubmit() {
            console.log('legal on submit')

            return this.fv.validate()
            .then((status) => {
                this.legalFields.isContactPerson = this.isContact;
                if (status === 'Valid') {
                    return {
                        items: this.legalFields
                    }
                } else return false

            });
        }
    }
}
</script>
