<template>
    <li
        aria-haspopup="true"
        data-menu-toggle="hover"
    >
        <div
            class="tree-folder"
            @click="toggle(item)"
            ref="toggler"
        >
            <span v-if="item.isFirst">
                <b-icon  icon="briefcase-fill" aria-hidden="true" class="mr-3"></b-icon>
            </span>
            <span v-else>
                <span v-if="isFolder">
                    <span v-if="isOpen" >
                        <b-icon icon="caret-down" aria-hidden="true" class="mr-2"></b-icon>
                        <b-icon icon="folder2-open" aria-hidden="true"></b-icon>
                    </span>
                    <span v-else >
                        <b-icon icon="caret-right-fill" aria-hidden="true" class="mr-2"></b-icon>
                        <b-icon icon="folder" aria-hidden="true"></b-icon>
                    </span>
                </span>
            </span>
            <span
                @click="selectNode"
                tabindex="1"
                :class="{folderTitleSpan: isFolder}"
            >
                {{ item.name}}
            </span>
        </div>
        <ul v-show="isOpen" v-if="isFolder" class="tree-parent-list">
            <tree-item
                class="tree-item"
                v-for="(child, index) in item.children"
                :key="index"
                :item="child"
                @click="select(item)"
                @make-folder="$emit('make-folder', $event)"
                @add-item="$emit('add-item', $event)"
                @expand="$emit('expand', $event)"
            ></tree-item>
        </ul>
    </li>
</template>

<script>
export default {
    name: 'TreeItem',
    props: {
        item: Object
    },
    data() {
        return {
            isOpen: false,
            selectedNode: [],
        };
    },
    computed: {
        isFolder() {
            return this.item.children ;
        },
        setSelected () {
           return this.selectedNode.length > 0 && this.selectedNode[0].title == this.item.name
        }
    },
    methods: {
        toggle(item) {
            this.previous = item;
            if (this.isFolder) {
                this.isOpen = !this.isOpen;
                this.$refs.toggler.focus();
                if (this.isOpen) {
                    this.$emit('expand', item);
                }
            }
        },
        selectNode: function() {
            this.selectedNode = [];
            this.selectedNode.push({
                'title': this.item.name,
                'isSelected': true
            });
        },
        makeFolder () {
            this.$emit("make-folder", this.item);
            this.isOpen = true;
        },
    }
};
</script>

<style scoped lang="scss" src="@/assets/sass/pages/archive/tree-item.scss"></style>
