import complainFormStore from '@/modules/complain/store/complain-form-store';
import complainListStore from '@/modules/complain/store/complain-list-store';

export default {
    namespaced: true,

    modules: {
        form: complainFormStore,
        list: complainListStore,
    },
};
