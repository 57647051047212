import documentSeriesAllocationFromStore from '@/modules/document-series-allocation/store/document-series-allocation-form-store';
import documentSeriesAllocationListStore from '@/modules/document-series-allocation/store/document-series-allocation-list-store';

export default {
    namespaced: true,
    modules: {
        form: documentSeriesAllocationFromStore,
        list: documentSeriesAllocationListStore,
    },
};

