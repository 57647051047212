var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{directives:[{name:"can",rawName:"v-can:create",value:('application'),expression:"'application'",arg:"create"}],staticClass:"row justify-content-center mx-5 py-5"},[_c('st-button',{attrs:{"variant":"secondary","block":true,"callback":_vm.createRequest,"id":"btn_create_request","customClass":"text-dark application-add-button"}},[_c('i',{staticClass:"fas fa-plus text-dark application-btn-icon"}),_c('span',{staticClass:"application-create-btn-text"},[_vm._v(_vm._s(_vm.$t('MENU.CREATE_REQUEST_BUTTON')))])])],1),_c('ul',{staticClass:"menu-nav custom-menu-nav"},[(_vm.hasPermissionsToViewDashboard)?_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-th-large"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MENU.DASHBOARD')))])])])]}}],null,false,2926962172)}):_vm._e(),_c('router-link',{directives:[{name:"can",rawName:"v-can:read",value:('applications'),expression:"'applications'",arg:"read"}],attrs:{"to":"/applications"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon far fa-file"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MENU.MY_APPLICATIONS')))])])])]}}])}),(_vm.hasPermissionToViewApplications)?_c('router-link',{attrs:{"to":"/applications"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon far fa-file"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MENU.STAFF_APPLICATIONS')))])]),_c('ul',[_c('li',{staticClass:"menu-item menu-item-submenu menu-item-open"},[_c('div',{staticClass:"menu-submenu menu-submenu-classic menu-submenu-right "},[_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{directives:[{name:"can",rawName:"v-can:read",value:('applicationsStaffOwn'),expression:"'applicationsStaffOwn'",arg:"read"}],attrs:{"to":"/applications-assigned"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                                            isExactActive && 'menu-item-active'
                                        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-arrow-right"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t('MENU.STAFF_OWN_APPLICATIONS'))+" ("+_vm._s(_vm.countApplicationsAssigned)+")")])])])]}}],null,true)})],1)])])])])]}}],null,false,2596191822)}):_vm._e(),_c('router-link',{directives:[{name:"can",rawName:"v-can:read",value:('issuedDocument'),expression:"'issuedDocument'",arg:"read"}],attrs:{"to":"/issued-documents"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var href = ref.href;
                                        var navigate = ref.navigate;
                                        var isActive = ref.isActive;
                                        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon far fa-folder"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MENU.ISSUED_DOCUMENT')))])])])]}}])}),(false)?_c('router-link',{directives:[{name:"can",rawName:"v-can:read",value:('complain'),expression:"'complain'",arg:"read"}],attrs:{"to":"/complain"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var href = ref.href;
                                        var navigate = ref.navigate;
                                        var isActive = ref.isActive;
                                        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-exclamation-circle"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MENU.MY_COMPLAINS')))])])])]}}],null,false,347862539)}):_vm._e(),_c('router-link',{directives:[{name:"can",rawName:"v-can:read",value:('complainStaff'),expression:"'complainStaff'",arg:"read"}],attrs:{"to":"/complain"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var href = ref.href;
                                        var navigate = ref.navigate;
                                        var isActive = ref.isActive;
                                        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-exclamation-circle"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MENU.STAFF_COMPLAINS')))])])])]}}])}),_c('router-link',{directives:[{name:"can",rawName:"v-can:read",value:('control'),expression:"'control'",arg:"read"}],attrs:{"to":"/controls"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var href = ref.href;
                                        var navigate = ref.navigate;
                                        var isActive = ref.isActive;
                                        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-file-signature"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MENU.CONTROLS')))])])])]}}])}),_c('router-link',{directives:[{name:"can",rawName:"v-can:read",value:('paymentRegistry'),expression:"'paymentRegistry'",arg:"read"}],attrs:{"to":"/payments"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var href = ref.href;
                                        var navigate = ref.navigate;
                                        var isActive = ref.isActive;
                                        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon far fa-credit-card"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MENU.PAYMENT_REGISTRY')))])])])]}}])}),_c('router-link',{directives:[{name:"can",rawName:"v-can:read",value:('documentSeriesAllocation'),expression:"'documentSeriesAllocation'",arg:"read"}],attrs:{"to":"document-series-allocation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var href = ref.href;
                                        var navigate = ref.navigate;
                                        var isActive = ref.isActive;
                                        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-file"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MENU.DOCUMENT_SERIES_ALLOCATION')))])])])]}}])}),_c('li',{directives:[{name:"can",rawName:"v-can:view",value:('organisations'),expression:"'organisations'",arg:"view"}]},[_c('hr')]),_c('li',{directives:[{name:"can",rawName:"v-can:view",value:('organisations'),expression:"'organisations'",arg:"view"}],staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MENU.ORGANISATION_CONFIG')))]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]),_c('router-link',{directives:[{name:"can",rawName:"v-can:read",value:('organisations'),expression:"'organisations'",arg:"read"}],attrs:{"to":"/institutions"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var href = ref.href;
                                        var navigate = ref.navigate;
                                        var isActive = ref.isActive;
                                        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fas fa-sitemap"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MENU.ORGANIZATION')))])])])]}}])}),_c('router-link',{directives:[{name:"can",rawName:"v-can:viewOwn",value:('organisations'),expression:"'organisations'",arg:"viewOwn"}],attrs:{"to":_vm.myInstitutionUrl},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var href = ref.href;
                                        var navigate = ref.navigate;
                                        var isActive = ref.isActive;
                                        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fas fa-sitemap"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MENU.MY_ORGANIZATION')))])])])]}}])}),_c('li',{directives:[{name:"can",rawName:"v-can:view",value:('organisations'),expression:"'organisations'",arg:"view"}]},[_c('hr')]),_c('li',{directives:[{name:"can",rawName:"v-can:view",value:('organisations'),expression:"'organisations'",arg:"view"}],staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MENU.SYSTEM_CONFIG')))]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]),_c('router-link',{directives:[{name:"can",rawName:"v-can:read",value:('processEditor'),expression:"'processEditor'",arg:"read"}],attrs:{"to":"/process-editor"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var href = ref.href;
                                        var navigate = ref.navigate;
                                        var isActive = ref.isActive;
                                        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-cogs"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MENU.PROCESS_MANAGEMENT')))])])])]}}])}),_c('router-link',{directives:[{name:"can",rawName:"v-can:read",value:('accounts'),expression:"'accounts'",arg:"read"}],attrs:{"to":"/accounts"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var href = ref.href;
                                        var navigate = ref.navigate;
                                        var isActive = ref.isActive;
                                        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fas fa-users"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MENU.ACCOUNTS')))])])])]}}])}),_c('router-link',{directives:[{name:"can",rawName:"v-can:read",value:('taxCategory'),expression:"'taxCategory'",arg:"read"}],attrs:{"to":"/tax-category"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var href = ref.href;
                                        var navigate = ref.navigate;
                                        var isActive = ref.isActive;
                                        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fas fa-hand-holding-usd"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MENU.TAX_CATEGORY')))])])])]}}])}),_c('router-link',{directives:[{name:"can",rawName:"v-can:read",value:('complainType'),expression:"'complainType'",arg:"read"}],attrs:{"to":"/complain-type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var href = ref.href;
                                        var navigate = ref.navigate;
                                        var isActive = ref.isActive;
                                        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fas fa-exclamation-circle"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MENU.COMPLAIN_TYPE')))])])])]}}])}),_c('router-link',{directives:[{name:"can",rawName:"v-can:read",value:('applicationType'),expression:"'applicationType'",arg:"read"}],attrs:{"to":"/application-type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var href = ref.href;
                                        var navigate = ref.navigate;
                                        var isActive = ref.isActive;
                                        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fas fa-exclamation-circle"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MENU.APPLICATION_TYPE')))])])])]}}])}),_c('router-link',{directives:[{name:"can",rawName:"v-can:read",value:('documentSeriesType'),expression:"'documentSeriesType'",arg:"read"}],attrs:{"to":"document-series-type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var href = ref.href;
                                        var navigate = ref.navigate;
                                        var isActive = ref.isActive;
                                        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fas fa-exclamation-circle"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MENU.DOCUMENT_SERIES_TYPE')))])])])]}}])}),_c('router-link',{directives:[{name:"can",rawName:"v-can:read",value:('forms'),expression:"'forms'",arg:"read"}],attrs:{"to":"/forms"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var href = ref.href;
                                        var navigate = ref.navigate;
                                        var isActive = ref.isActive;
                                        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fab fa-wpforms"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MENU.FORMS')))])])])]}}])}),_c('router-link',{directives:[{name:"can",rawName:"v-can:read",value:('documentTemplate'),expression:"'documentTemplate'",arg:"read"}],attrs:{"to":"/document-template"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var href = ref.href;
                                        var navigate = ref.navigate;
                                        var isActive = ref.isActive;
                                        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon far fa-copy"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MENU.DOCUMENT_TEMPLATE')))])])])]}}])}),_c('router-link',{directives:[{name:"can",rawName:"v-can:read",value:('responseTemplates'),expression:"'responseTemplates'",arg:"read"}],attrs:{"to":"/response-templates"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var href = ref.href;
                                        var navigate = ref.navigate;
                                        var isActive = ref.isActive;
                                        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fas fa-exclamation-circle"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MENU.RESPONSE_TEMPLATES')))])])])]}}])}),_c('router-link',{directives:[{name:"can",rawName:"v-can:read",value:('notificationsTemplate'),expression:"'notificationsTemplate'",arg:"read"}],attrs:{"to":"/notifications-templates"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var href = ref.href;
                                        var navigate = ref.navigate;
                                        var isActive = ref.isActive;
                                        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon far fa-bell"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MENU.NOTIFICATIONS')))])])])]}}])}),_c('router-link',{directives:[{name:"can",rawName:"v-can:read",value:('responsibility'),expression:"'responsibility'",arg:"read"}],attrs:{"to":"/responsibilities"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var href = ref.href;
                                        var navigate = ref.navigate;
                                        var isActive = ref.isActive;
                                        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon  fas fa-people-carry"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MENU.RESPONSIBILITIES')))])])])]}}])}),_c('router-link',{directives:[{name:"can",rawName:"v-can:read",value:('permissions'),expression:"'permissions'",arg:"read"}],attrs:{"to":"/permissions"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var href = ref.href;
                                        var navigate = ref.navigate;
                                        var isActive = ref.isActive;
                                        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-user-lock"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MENU.PERMISSIONS')))])])])]}}])}),_c('router-link',{directives:[{name:"can",rawName:"v-can:read",value:('metadataMapping'),expression:"'metadataMapping'",arg:"read"}],attrs:{"to":"/metadata-mapping"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var href = ref.href;
                                        var navigate = ref.navigate;
                                        var isActive = ref.isActive;
                                        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-map-signs"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MENU.METADATA_MAPPING')))])])])]}}])}),(false)?_c('router-link',{attrs:{"to":"/payments"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var href = ref.href;
                                        var navigate = ref.navigate;
                                        var isActive = ref.isActive;
                                        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon far fa-credit-card"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MENU.PAYMENTS')))])])])]}}],null,false,1768744200)}):_vm._e(),(false)?_c('router-link',{attrs:{"to":"/documents"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var href = ref.href;
                                        var navigate = ref.navigate;
                                        var isActive = ref.isActive;
                                        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon far fa-folder"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MENU.DOCUMENTS')))])])])]}}],null,false,3349102175)}):_vm._e(),_vm._m(0),(_vm.isStaff)?_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MENU.EXTERNAL_APPS')))]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]):_vm._e(),(_vm.isStaff)?_c('router-link',{attrs:{"to":"/atlas"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var href = ref.href;
                                        var isActive = ref.isActive;
                                        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":function($event){$event.preventDefault();return _vm.navigateTo('atlas')}}},[_c('i',{staticClass:"menu-icon far fa-map"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MENU.ATLAS')))])])])]}}],null,false,264116198)}):_vm._e(),(_vm.isStaff)?_c('router-link',{directives:[{name:"can",rawName:"v-can:read",value:('archive'),expression:"'archive'",arg:"read"}],attrs:{"to":"/archive-dashboard"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var href = ref.href;
                                        var isActive = ref.isActive;
                                        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":function($event){$event.preventDefault();return _vm.navigateTo('archive')}}},[_c('i',{staticClass:"menu-icon fas fa-archive"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MENU.ARCHIVE')))])])])]}}],null,false,3004073465)}):_vm._e(),(false)?_c('li',[_c('hr')]):_vm._e(),(false)?_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MENU.HELP')))]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]):_vm._e(),(false)?_c('router-link',{attrs:{"to":"/manual"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var href = ref.href;
                                        var navigate = ref.navigate;
                                        var isActive = ref.isActive;
                                        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-open-text-book"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MENU.MANUAL')))])])])]}}],null,false,1657572283)}):_vm._e(),(false)?_c('router-link',{attrs:{"to":"/faq"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var href = ref.href;
                                        var navigate = ref.navigate;
                                        var isActive = ref.isActive;
                                        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon far fa-question-circle"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MENU.FAQ')))])])])]}}],null,false,2654370514)}):_vm._e(),(false)?_c('router-link',{attrs:{"to":"/gdpr"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var href = ref.href;
                                        var navigate = ref.navigate;
                                        var isActive = ref.isActive;
                                        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-book"}),_c('span',{staticClass:"menu-text"},[_vm._v("GDPR")])])])]}}],null,false,4191074141)}):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_c('hr')])}]

export { render, staticRenderFns }