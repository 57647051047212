// RO
const locale = {
    MENU: {
        DASHBOARD: 'Dashboard',
        CREATE_REQUEST_BUTTON: 'Creeaza cerere',
        SEARCH_BUTTON: 'Cautare avansata',
        RESPONSE_TEMPLATES: 'Template-uri de raspuns',
        TAX_CATEGORY: 'Categorii de taxare',
        COMPLAIN_TYPE: 'Tipologii de sesizare',
        ORGANIZATION: 'Administrare organizatii',
        MY_ORGANIZATION: 'Administrare organizație',
        ACCOUNTS: 'Conturi secundare',
        MY_APPLICATIONS: 'Cererile mele',
        STAFF_APPLICATIONS: 'Registru cereri',
        NOTIFICATIONS: 'Mesaje si notificari',
        RESPONSIBILITIES: 'Nomenclator Responsabilități',
        MY_COMPLAINS: 'Sesizarile mele',
        STAFF_COMPLAINS: 'Registru sesizari',
        PAYMENTS: 'Registru plati',
        DOCUMENTS: 'Acte emise',
        EXTERNAL_APPS: 'Aplicații',
        ATLAS: 'Atlas teritorial',
        ARCHIVE: 'Arhiva',
        HELP: 'Ajutor',
        MANUAL: 'Manual',
        FAQ: 'FAQ',
        ADMINISTRATOR: {
            HEADER: 'Administrator',
            MANAGE: 'Gestiune',
            FUNCTIONARY: 'Functionar'
        },
        DOCUMENT_TEMPLATE: 'Formulare tipizate',
        FORMS: 'Interfețe formulare',
        APPLICATION_TYPE: 'Tipologii de cereri',
        PROCESS_MANAGEMENT:'Management procese',
        PERMISSIONS: 'Permisiuni',
        METADATA_MAPPING: 'Mapare Metadate',
        GU: 'Ghișeul Unic',
        STAFF_OWN_APPLICATIONS: 'De semnat',
        DECISION_TARGET: 'Hotărâri locații',
        DECISION_PURPOSE: 'Hotărâri scopuri',
        DOCUMENT_SERIES_TYPE: 'Tipologii de serie',
        PAYMENT_REGISTRY: 'Registru plăți',
        ISSUED_DOCUMENT: 'Acte emise',
        CONTROLS: 'Registru de control',
        ORGANISATION_CONFIG: 'Configurări organizație',
        SYSTEM_CONFIG: 'Configurări sistem',
        DOCUMENT_SERIES_ALLOCATION: 'Rezervare serie document'
    },
    GENERAL: {
        YES: 'Da',
        NO: 'Nu',
        CLOSE: 'Inchide',
        NEXT: 'Inainte',
        PREV: 'Înapoi',
        STATUS: 'Status',
        ACTIVE: 'Activ',
        INACTIVE: 'Inactiv',
        CHECK: 'Verifică',
        BUTTON: {
            ADD: 'Adaugă',
            CANCEL: 'Renunță',
            SAVE: 'Salvează',
            UPDATE: 'Modifică',
            CONFIRM: 'Confirmă',
            ERASE: 'Sterge',
            MORE_FILTERS: 'Mai multe filtre',
            OK: 'Ok',
            SEND_CODE: 'Trimite codul',
            SEND: 'Trimite',
            CONTINUE: 'Continuă',
            PUBLISH: 'Publică',
            UPLOAD_FILE: 'Alege fișier',
            COMPLETE: 'Completează',
        },
        ACTIONS: {
            EDIT: 'Editare',
            DELETE: 'Ștergere',
            DELETE_MESSAGE: 'Ești sigur că vrei să continui acțiunea de ștergere?',
            VIEW: 'Vizualizare',
            DOWNLOAD: 'Descarcă',
            SIGN: 'Semnează'
        },
        DATEPICKER: {
            FROM_DATE: 'De la data',
            TO_DATE: 'Până la data',
            SELECT_DATE: 'Nici o dată selectată',
            HELP: 'Folosiți cursorul pentru a naviga prin datele din calendar'
        },
        PAGINATION: 'Afișare rezultate de la {start} la {end}',
        CREATED_SUCCESS: 'Salvat cu succes.',
        UPDATED_SUCCESS: 'Salvat cu succes.',
        PUBLISHED_SUCCESS: 'Publicat cu succes.',
        PUBLISHED_FAILED: 'A apărut o eroare la publicarea layerului, vă rugăm reîncercați',
        REMOVE_SUCCESS: 'Șters cu succes.',
        SELECT_PLACEHOLDER: 'Alege',
        EXPIRED_SESSION: 'Sesiunea a expirat',
        TABLE: {
            ACTIONS: 'Acțiuni',
        },
        TABLE_ACTIONS_COLUMN: 'Acțiuni',
        TABLE_EMPTY_TEXT: 'Nu există înregistrări de afișat', // There are no records to show
        DAYS: 'zile | zi | zile',
        AUTOCOMPLETE_NO_OPTIONS: 'Nici o opțiune potrivită',
        AUTOCOMPLETE_LOAD_MORE: 'Încarcă mai multe opțiuni...',
        DUAL_LIST_BOX: {
            SEARCH_PLACEHOLDER: 'Caută',
            SEARCH_RESULTS: 'Nu s-au găsit rezultate.'
        },
        CLICK_HERE: 'click aici',
        CITIZEN: 'Cetățean',
        STAFF: 'Funcționar',
        SIGN: 'Semnare',
        SYSTEM: 'Sistem',
        ERROR_PAGE: {
            TITLE: 'Oops...',
            TEXT: 'A apărut o problemă...',
            SUB_TEXT: 'eroare neașteptată'
        },
        SELECT_LOADING: 'Încărcare',
        CURRENCY_LABEL: 'RON',
        RECOMANDED_SCREEN: 'Această aplicație nu este optimizată pentru telefoane mobile sau tablete. Pentru cea mai bună experiență de navigare și funcționare recomandăm folosirea versiunii web a aplicației.',
    },
    ALERT_MODAL: {
        ERROR: 'Eroare',
        WARNING: 'Atenție',
        SUCCESS: 'Succes',
        INFO: 'Info',
    },
    AUTH: {
        GENERAL: {
            OR: 'sau',
            CANCEL_BUTTON: 'Renunță',
            BACK_BUTTON: 'Inapoi',
            PRIVACY: 'Politica de Confidențialitate',
            LEGAL: 'Termeni și Condiții',
        },
        LOGIN: {
            TITLE: 'Bun venit!',
            DESC: 'Introduceți email-ul și parola pentru a accesa platforma',
            NO_ACCOUNT: 'Nu ai cont? Creează acum!',
            BUTTON: 'Intră în cont',
            FORGOT_PASSWORD_LABEL: 'Ai uitat parola ?',
            GOOGLE_BUTTON: 'Intră in cont folosind Google',
            FACEBOOK_BUTTON: 'Intră în cont folosind Facebook',
            HELP_TEXT: '* Aplicația permite crearea a două tipuri de conturi. Unul personal dedicat persoanelor fizice și juridice și unul profesional destinat birourilor de proiectare/planificare.'
        },
        FORGOT: {
            TITLE: 'Resetare parolă?',
            DESC: 'Introduceți adresa de email aferentă contului.',
            BUTTON: 'Trimite email',
            SUCCESS: 'Your account has been successfully reset.'
        },
        RESET: {
            TITLE: 'Resetare parolă?',
            DESC: 'Introduceți parola nouă',
            BUTTON: 'Schimbă parola',
        },
        CHANGE_PASSWORD: {
            TITLE: 'Schimbare parolă',
            DESC: 'Introduceți parola nouă',
            BUTTON: 'Schimbă parola',
        },
        SET: {
            TITLE: 'Setare parolă',
            DESC: 'Introduceți o parola',
            BUTTON: 'Setează parola',
        },
        REGISTER: {
            TITLE: 'Bun venit!',
            DESC: 'Aplicația permite crearea a două tipuri de conturi. Unul personal dedicat persoanelor fizice/juridice, UAT și unul profesional destinat birourilor de proiectare/planificare.',
            HAS_ACCOUNT: 'Ai cont deja? Conectează-te',
            GOOGLE_BUTTON: 'Creează cont folosind Google',
            FACEBOOK_BUTTON: 'Creează cont folosind Facebook',
            BUTTON: 'Creează cont',
            ACCEPT_TERMS: 'Accept {0}, {1}.',
            PRIVACY: 'politica de confidențialitate',
            TOS: 'termeni și condițiile',
            PASSWORD_HELP: 'Minim 8 caractere, o majusculă, o minusculă, o cifră și un caracter special',
            PROFESSIONAL_TITLE: 'Creează cont Profesional nou',
            PROFESSIONAL_DESC: 'Contul de tip profesional se adresează persoanelor juridice - birouri / societăți / p.f.a. / b.i.a care activează în domeniul proiectării în (arhitectură, urbansim, inginerie, s.a.) și este destinat gestionării/depunerii de cereri pentru unul sau mai mulți beneficiari - permite subconturi',
            PERSONAL_TITLE: 'Creează cont Personal nou',
            PERSONAL_DESC: 'Contul de tip personal se adresează persoanelor fizice / juridice, administrațiilor publice și este destinat depunerii de cereri pentru/în numele persoanelor fizice / juridice / primăriilor',
            BUTTON_PERSONAL: 'Creează cont Personal',
            BUTTON_PROFESSIONAL: 'Creează cont Profesional',
        },
        INPUT: {
            EMAIL: 'E-mail',
            PASSWORD: 'Parolă',
            CONFIRM_PASSWORD: 'Confirmă parola',
            NEW_PASSWORD: 'Parolă noua',
            OLD_PASSWORD: 'Parolă veche',
            PHONE: 'Număr telefon',
            VALIDATION_CODE_PLACEHOLDER: 'Cod validare'
        },
        VERIFY_EMAIL: {
            TITLE: 'Confirmare e-mail',
            MESSAGE_SENT: 'S-a trimis un link de activare la adresa de e-mail {0}. Verifică e-mailul iar dupa confirmare continuă procesul de validare a contului.',
            RESEND_CONFIRMATION_MESSAGE: 'Nu ai primit e-mail de confirmare? {0}.',
            RESEND_LINK: 'Retrimite link-ul',
            TOAST_MESSAGE: 'Emailul de confirmare trimis cu succes'
        },
        VERIFY_SMS: {
            TITLE: 'Validarea contului',
            MESSAGE:
                'Din motive de securitate Ghiseul Unic are nevoie sa iti verifice contul prin SMS. Introdu numarul de telefon si urmeaza pasii pentru validare.',
            MESSAGE_SENT:
                'S-a trimis un SMS la numarul de telefon {0}. Adauga codul primit si valideaza contul.',
            BUTTON_SUBMIT: 'Trimite cod',
            BUTTON_VALIDATE: 'Valideaza contul',
            INPUT_PLACEHOLDER: 'Cod validare',
            MESSAGE_NOT_RECEIVED: 'Nu ai primit codul? {0}',
            RESEND_LINK: 'Retrimite mesajul',
            CHANGED_VALIDATION_METHOD_LINK: 'schimba metoda de validare'
        },
        INVALID_LINK: {
            TITLE: 'Link de activare invalid',
            MESSAGE: 'Link-ul de activare este invalid, a expirat sau a fost deja folosit. Te rugăm să îți verifici contul sau să reiei procesul de înregistrare.',
            REGISTER_MESSAGE: 'Mergi la pagina de {0}.',
            REGISTER_LINK: 'înregistrare'
        },
        VALIDATION: {
            INVALID: '{{name}} nu este valid',
            REQUIRED: 'Acest câmp este obligatoriu',
            EMAIL: 'Adresa de e-mail nu este valabilă',
            INVALID_EMAIL: 'Adresa de e-mail nu este validă.',
            MIN_LENGTH: 'Lungimea minimă a {{name}} este {{min}}',
            AGREEMENT_REQUIRED: 'Acceptarea termenilor și condițiilor este obligatoriu',
            NOT_FOUND: 'The requested {{name}} is not found',
            INVALID_LOGIN: 'Date de autentificare incorecte. Te rugăm să încerci din nou.',
            REQUIRED_FIELD: 'Acest câmp este obligatoriu',
            CONFIRM_PASSWORD: 'Parola nu este identică',
            MIN_LENGTH_FIELD: 'Câmpul trebuie să aibă minim {min} caractere',
            MAX_LENGTH_FIELD: 'Câmpul trebuie să aibă maxim {max} caractere',
            INVALID_FIELD: 'Câmpul nu este valid',
            STRONG_PASSWORD: 'Parolă invalidă',
            INVALID_PHONE: 'Numarul de telefon nu este valid',
            NUMERIC_FIELD: 'Acest câmp conține doar valori numerice',
            PHONE_FIELD: 'Acest câmp conține doar valori numerice',
            INVALID_URC: 'CUI invalid',
            INVALID_PNC: 'CNP invalid',
            POSTAL_CODE_MAX_LENGTH_FIELD: 'Codul poștal trebuie să conțină 6 cifre',
            SMS_CODE_LENGTH: 'Codul de verificare trebuie să conțină 6 cifre',
            IBAN: 'Cod IBAN invalid.',
            INTEGER: 'Valoarea câmpului nu este un număr întreg valid',
            INVALID_DATE: 'Dată invalidă',
            ALPHABETICAL: 'Valoarea câmpului trebuie să conțină doar litere și spațiu',
            NO_UPPER: 'Acest câmp conține doar minuscule',
            ALPHABETICAL_SPECIAL_CHARS: 'Valoarea câmpului trebuie să conțină doar litere, spațiu, apostrof și -'
        }
    },
    LAYOUT: {
        HEADER: {
            SEARCH_PLACEHOLDER: 'Cauta cerere, aviz sau alt document'
        }
    },
    LOGOUT: {
        CONFIRMATION_MESSAGE: 'Esti sigur ca doresti terminarea sesiunii de lucru?'
    },
    RESET_PASSWORD: {
        CONFIRMATION_MESSAGE: 'Eşti sigur că doreşti să resetezi parola?',
        SUCCESSFULLY_SENT_MESSAGE: 'S-a trimis un link de resetare a parolei pe adresa ta de e-mail indicată.',
        SUCCESSFULLY_MESSAGE: 'Parola dvs. a fost schimbată cu succes'
    },
    HOME: {
        WELCOME_TEXT: 'Bine ați venit în aplicația Ghișeul Unic.',
    },
    PROFILE: {
        HEADER: 'Contul meu',
        HEADER_CITIZEN: 'Contul meu Personal',
        HEADER_LEGAL: 'Contul meu Profesional',
        LOGOUT_BUTTON_TEXT: 'Delogare',
        UPDATE_BUTTON: 'Actualizeaza datele',
        VIDEO_ENROLL_BUTTON: 'Înrolare video',
        SECTIONS: {
            ACCOUNT_DETAILS: {
                HEADER: 'Detalii cont'
            },
            PERSONAL_DETAILS: {
                HEADER: 'Detalii personale'
            },
            PERSONAL_DATAS: {
                HEADER: 'Date personale'
            },
            COMPANY_DATAS: {
                HEADER: 'Date firmă'
            },
            RESPONSIBILITIES: {
                HEADER: 'Responsabilitati'
            },
            IDENTITY_CARD: {
                HEADER: 'Date carte de identitate'
            }
        },
        OPTIONS: {
            MY_PROFILE: {
                NAME: 'Profilul meu',
                DESCRIPTION: 'Vezi detalii profil'
            },
            RESET_PASSWORD: {
                NAME: 'Resetare Parola',
                DESCRIPTION: 'Aceseaza formularul de resetare'
            },
            NOTIFICATIONS_MANAGEMENT: {
                NAME: 'Management Notificari',
                DESCRIPTION: 'Gestioneaza tipul si frecventa notificarilor'
            }
        },
        FIELDS: {
            PERSONAL_NUMERIC_CODE: 'CNP',
            LAST_NAME: 'Nume',
            FIRST_NAME: 'Prenume',
            COUNTY: 'Județ',
            LOCALITY: 'Municipiu/Oraș/Comună',
            VILLAGE: 'Localitate',
            SECTOR: 'Sector',
            STREET: 'Strada',
            POSTAL_CODE: 'Cod Poștal',
            STREET_NUMBER: 'Număr',
            STAIRCASE: 'Scara',
            BLOCK: 'Bloc',
            FLOOR: 'Etaj',
            APARTMENT: 'Apartament',
            EMAIL: 'E-mail',
            PHONE: 'Telefon',
            PASSPORT_NUMBER: 'Pașaport/Carte de identitate',
            COUNTRY: 'Țara',
            CITY: 'Oraș',
            ADDRESS: 'Adresa',
            UNIQUE_REGISTRATION_CODE: 'CUI',
            COMPANY_NAME: 'Denumire',
            LICENSE: 'Arhitect - număr TNA',
            STATUS: 'Status',
            IDENTIFICATION_NUMBER: 'Număr legitimaţie',
            ORGANISATION: 'Instituție',
            FUNCTIONS: 'Funcție',
            DEPARTMENTS: 'Departament',
            USER_TYPE_NAME: 'Tip utilizator',
            ID_CARD_SERIAL: 'Serie CI',
            ID_CARD_NUMBER: 'Număr CI',
            ID_CARD_EXPIRATION_DATE: 'Data expirării',
            ID_CARD_ISSUER: 'Eliberat de',
        },
        PLACEHOLDERS: {
            COUNTY: 'Județ',
            LOCALITY: 'Localitate',
            VILLAGE: 'Sat',
            COUNTRY: 'Țara',
            STATUS: 'Status',
        },
        ENUMS: {
            STATUS: {
                ACTIVE: 'Activ',
                INACTIVE: 'Inactiv',
            },
        },
        MESSAGES: {
            ID_CARD_EXPIRED: 'Cartea de identitate a expirat!',
            STAFF_ENROLLMENT_PROCESSING_TITLE: 'Înrolare video în procesare...',
            STAFF_ENROLLMENT_SUCCESS_TITLE: 'Funcționarul a fost înrolat cu succes',
            STAFF_ENROLLMENT_REDIRECT_TEXT: 'Vei fi redirecționat în câteva secunde la profilul tău. Dacă nu ești redirecționat automat te rugăm dă {0}',
            STAFF_ENROLLMENT_FAILED_TITLE: 'A apărut o eroare la procesul de înrolare, te rugăm încearcă din nou.',
        },
        ENROLLED_BADGE_LABEL: 'Înrolat',
    },
    SET_PROFILE_DATA: {
        WELCOME_MODAL: {
            BRAND: {
                NAME: 'Ghișeul unic',
                ORGANISATION: 'Consiliul Județean Cluj'
            },
            HEADER: {
                TOP_P: 'Bun venit în platforma Ghișeul Unic!',
                BOTTOM_P: 'Completează datele profilului tău pentru a putea customiza experiența conform nevoilor tale.',
                BOTTOM_P_PERSON: 'Setează cont Personal',
                BOTTOM_P_LEGAL: 'Setează cont Profesional'
            },
            OPTIONS: {
                PERSON: 'Setează profil Personal',
                LEGAL: 'Setează profil Profesional'
            }
        },
        CITIZEN: {
            ROMANIAN: 'Cetățean roman',
            FOREIGNER: 'Cetățean strain'
        },
        FORM_BUTTONS: {
            CHANGE_TYPE: 'Schimbă tipul profilului',
            SAVE: 'Salvează datele'
        },
        LEGAL_FORM: {
            COMPANY: 'Detalii Firma',
            LEGAL_REPRESENTATIVE: 'Detalii reprezentant Legal'
        },
        PERSON: {
            PNC: 'CNP',
            LAST_NAME: 'Nume',
            FIRST_NAME: 'Prenume',
            COUNTY: 'Judet',
            LOCALITY: 'Municipiu/Oraș/Comună',
            VILLAGE: 'Localitate',
            SECTOR: 'Sector',
            STREET: 'Strada',
            POSTAL_CODE: 'Cod Postal',
            NUMBER: 'Numar',
            STAIRCASE: 'Scara',
            BLOCK: 'Bloc',
            FLOOR: 'Etaj',
            APARTMENT: 'Apartament',
            EMAIL: 'E-mail',
            PHONE: 'Telefon'
        },
        FOREIGNER: {
            PNC: 'Pasaport/Carte de identitate',
            LAST_NAME: 'Nume',
            FIRST_NAME: 'Prenume',
            COUNTRY: 'Tara',
            CITY: 'Oras',
            ADDRESS: 'Adresa',
            EMAIL: 'E-mail',
            PHONE: 'Telefon'
        },
        LEGAL: {
            URC: 'CUI',
            COMPANY_NAME: 'Denumire',
            COUNTY: 'Judet',
            LOCALITY: 'Localitate',
            STREET: 'Strada',
            POSTAL_CODE: 'Cod Postal',
            NUMBER: 'Numar',
            STAIRCASE: 'Scara',
            BLOCK: 'Bloc',
            FLOOR: 'Etaj',
            APARTMENT: 'Apartament',
            LAST_NAME: 'Nume',
            FIRST_NAME: 'Prenume',
            PNC: 'CNP',
            LICENSE: 'Arhitect - număr TNA',
            EMAIL: 'E-mail',
            PHONE: 'Telefon'
        },
        ERRORS: {
            LOCALITIES: 'Eroare la generarea listei de localitati!',
            VILLAGES: 'Eroare la generarea listei de sate!',
            UPDATE: 'Eroare la modificarea informațiilor despre profil!',
            CREATE: 'Eroare la crearea profil utilizator!'
        },
        SUCCESS_MODAL: {
            TITLE: 'Bun venit {0}!',
            TEXT: 'Acum ai posibilitatea de a încarca și transmite documentele necesare procesului de autorizare și de a-ți gestiona documentele.',
            CONFIRM_BUTTON: 'Hai să începem!',
        },
        UPDATED_MESSAGE: 'Profilul a fost actualizat cu succes!'
    },
    NOTIFICATIONS: {
        BUTTON_TEXT: 'Aplică modificările',
        HEADER: {
            TOP: 'Management notificări',
            COL_1: 'Tipul notificării',
            COL_2: 'Email',
            COL_3: 'Sms',
            COL_4: 'În aplicație'
        },
        SECTION_TITLE: {
            ACCOUNT: 'Cont',
            APPLICATIONS: 'Cereri',
            DOCUMENTS: 'Acte',
            PAYMENTS: 'Plați',
            ALERTS: 'Alerte',
            OTHERS: 'Altele',
            COMPLAINS: 'Sesizări',
            COMMENTS: 'Mesaje'
        },
        FREQUENCY: {
            DAILY: 'Zilnic',
            WEEKLY: 'Saptamanal',
            MONTHLY: 'Lunar'
        },
        ITEMS: {
            ACCOUNT_DATA_CHANGED: 'Se modifica datele contului',
            ACCOUNT_TYPE_CHANGED: 'Se modifica tipul contului',
            NEW_APPLICATION: 'Se primește o cerere noua',
            APPLICATION_CHANGED: 'Se modifică o cerere',
            APPLICATION_STATUS_CHANGED: 'Se schimbă statusul unei cereri',
            APPLICATION_SENT: 'Se trimite o cerere',
            DOCUMENT_CREATED: 'Se emite un act nou',
            DOCUMENT_CANCELLED: 'Se anuleaza un act',
            NEW_PAYMENT: 'Se face o plata noua',
            PAYMENT_APPROVED: 'Se aproba o plata',
            TAX_DEADLINE_EXPIRES: 'Expira un termen de plata',
            RESPONSE_DEADLINE_EXPIRES: 'Expira un termen de raspuns',
            MESSAGE_SENT: 'Se trimite mesaj.',
            NEW_COMPLAIN: 'Se primește o sesizare nouă',
            COMPLAIN_STATUS_CHANGED: 'Se schimbă statusul unei sesizări',
            NEW_COMMENT: 'Se primește un mesaj nou',
        },
        ERRORS: {
            NOTIFICATIONS: 'Eroare la generarea listei de notificari!',
            UPDATE: 'Eroare la modificarea notificării!'
        },
        SUCCESS: {
            UPDATE: 'Modificarea notificării s-a realizat cu succes.'
        },
        TEMPLATES: {
            TITLE: 'Template-uri notificari',
            TABLE: {
                ID: 'ID',
                TITLE: 'Titlu',
                TYPE: 'Tip',
                LANGUAGE: 'Limba',
                ACTIONS: 'Actiuni',
                EDIT: 'Editeaza',
                SEARCH_TEMPLATE: 'Cauta template',
            },
            EDIT: {
                PAGE_TITLE: 'Editeaza template',
                LANGUAGE: 'Limba',
                RO: 'Ro',
                EN: 'En',
                HU: 'Hu',
                NAME: 'Denumire template mesaj',
                SUBJECT: 'Subiect',
                TITLE: 'Titlu',
                CHANNEL: 'Canal de livrare',
                SMS: 'SMS',
                EMAIL: 'E-mail',
                IN_APP: 'In-App',
                ACCEPTED_PLACEHOLDERS: 'Placeholdere acceptate',
                CANCEL: 'Renunta',
                SAVE: 'Salveaza',
                MAX_CHARS: 'Max: {chars} caractere.',
                REMAINING_CHARS: 'Au mai ramas: {chars}',
            }
        },
    },
    NOTIFICATIONS_IN_APP: {
        TITLE: 'Notificări',
        MESSAGE: {
            INPUT_CODE: 'Cod intrare',
            BENEFICIARY: 'Beneficiar'
        },
        LOCALE: {
            FUTURE: 'în',
            PAST: 'acum',
            SECOND: 'căteva secunde',
            SECONDS: 'secunde',
            MINUTE: 'un minut',
            MINUTES: 'minute',
            HOUR: 'o oră',
            HOURS: 'ore',
        },
        NOW: 'Acum'
    },
    BENEFICIARY: {
        BENEFICIARY_DETAILS: {
            HEADER: 'Detalii beneficiar',
            ADD_BTN: 'Adaugă beneficiar',
            REMOVE_BTN: 'Sterge beneficiar',
            PERSON: {
                HEADER: 'Tip persoană',
                INDIVIDUAL: 'Persoană fizică',
                LEGAL: 'Persoană juridică'
            },
            CITIZENSHIP: {
                HEADER: 'Cetătenie',
                ROMANIAN: 'Cetătean roman',
                FOREIGNER: 'Cetătean strain'
            },
            FOOTER_CHECKBOX: 'Beneficiarul este persoana de contact',
            FORM: {
                AUTHORIZATION_CODE: 'Cod autorizatie',
                SUBSIDIARY: 'Filiala'
            },
            ADDRESS: 'Adresă',
            CONTACT: 'Contact',
        },
        APPLICANT_DETAILS: {
            HEADER: 'Detalii solicitant',
            FOOTER_CHECKBOX: 'Solicitantul este persoana de contact'
        },
        SINGLE: 'Total {total} beneficiar',
        MULTIPLE: 'Total {total} beneficiari',
        LIST_HEADER: 'Lista beneficiarilor',
        MODAL: {
            ADD_HEADER: 'Adaugă un beneficiar',
            EDIT_HEADER: 'Editează un beneficiar',
        },
        CARD: {
            PERSONAL_NUMERIC_CODE: 'CNP',
            ADDRESS: 'Adresă domiciliu',
            CONTACT: 'Contact',
            CITIZENSHIP: 'Cetătenie',
            ADDRESS_COUNTY: 'Judetul',
            ADDRESS_STREET: 'Str.',
            ADDRESS_NUMBER: 'nr.',
            ADDRESS_STAIRCASE: 'scara',
            ADDRESS_APARTMENT: 'ap.'
        }
    },
    ERRORS: {
        DEFAULT_ERROR_MESSAGE: 'A apărut o eroare, vă rugăm reîncercați',
        AUTH_REQUIRED: 'Este nevoie de autorizare.',
        AUTH_INVALID: 'Autentificare eșuată.',
        AUTH_INVALID_ATTEMPTS_REMAINING: 'Autentificare eșuată. {0} încercări rămase.',
        REGISTRATION_EMAIL_ALREADY_EXISTS: 'Adresa de e-mail există deja.',
        REGISTRATION_INVALID_PASSWORD: 'Parolă invalidă',
        REGISTRATION_INVALID: 'Înregistrare invalidă.',
        FACEBOOK_EMAIL_NOT_RECEIVED: 'A apărut o eroare, nu s-a putut obține adresa dvs. de e-mail. Verificați setările Facebook și încercați din nou!',
        PASSWORD_EXPIRED: 'Parola dvs. a expirat și trebuie schimbată.',
        AUTH_FAILED: 'Autentificare eșuată.',
        AUTH_FAILED_ATTEMPTS_REMAINING: 'Autentificare eșuată. {0} încercări rămase.',
        RESET_PASSWORD_ALREADY_USED: 'Parola trebuie să fie diferită de cea curentă.',
        FORBIDDEN: 'Ne pare rău, nu aveți drepturi necesare ca să continuați.',
        TOKEN_INVALID: 'Token invalid',
        DUPLICATE_FUNCTION_NAME: 'Numele funcției adăugate deja există!',
        CATEGORY_NOT_EMPTY: 'Categoria nu poate fi ștearsă!',
        NAME_NOT_UNIQUE: 'Numele nu este unic.',
        ERROR_PAGE_MESSAGE: 'OOPS! Something went wrong here',
        AUTH_ACCOUNT_NOT_FOUND: 'Credențialele sunt introduse greșit!',
        PARENT_MUST_BE_ADMIN_OF_LEGAL_ENTITY: 'Nu aveți permisiunea de a adăuga sau edita un cont!',
        USER_ALREADY_EXISTS: 'Acest utilizator este deja existent!',
        TAX_ALREADY_EXISTS: 'Această taxă este deja existentă!',
        VERIFICATION_SERVICE_UNAVAILABLE: 'Codul introdus nu este valid!',
        CANNOT_DELETE_ORGANISATION: 'Această organizație nu poate fi ștearsă fiindcă există utilizatori asociați',
        ORGANISATION_ALREADY_EXISTS: 'Această organizație există deja',
        DOCUMENT_SERIES_ALREADY_EXISTS: 'Această serie este deja existentă!',
        DOCUMENT_SERIES_NOT_EXIST: 'Nu există serie definită!',
        DOCUMENT_SERIES_NOT_ACTIVE: 'Seria nu este activă!',
        RESPONSIBILITY_ALREADY_EXISTS: 'Această responsabilitate este deja existentă!',
        INDEX_CANNOT_BE_LOWER_THAN_CURRENT_VALUE: 'Valoarea indexului nu poate fi mai mică decât valoarea curentă!',
        CONFLICT: 'Înregistrare duplicat',
        PAYMENT_ALREADY_PROCESSED: 'Plata a fost deja procesată!',
        AUTH_INVALID_CONFIRMATION_VALIDATIONS: 'Solicitarea de resetare parolă a expirat!',
        CERTSIGN_CONFIRMING_SIGNING_FAILED: 'Codul introdus nu este valid!',
        ATLAS_NO_SEARCH_RESULT: 'Adresa nu a fost găsită!',
        DECISION_TARGET_CANNOT_BE_DELETED: 'Această locație nu poate fi ștearsă fiindcă este asociată unei hotărâri',
        DECISION_PURPOSE_CANNOT_BE_DELETED: 'Aceast scop nu poate fi ștears fiindcă este asociat unei hotărâri',
        DECISION_PURPOSE_ALREADY_EXISTS: 'Aceast scop este deja existent!',
        DECISION_TARGET_ALREADY_EXISTS: 'Această locație este deja existentă!',
        DOCUMENT_SERIES_TYPE_CANNOT_BE_DELETED: 'Această tipologie nu poate fi ștearsă fiindcă este asociată unei serii',
        DOCUMENT_SERIES_TYPE_ALREADY_EXISTS: 'Această tipologie este deja existentă!',
        DUPLICATE_COMPLAIN_TYPE_NAME: 'Numele tipului de sesizare introdus deja există!',
        DUPLICATE_RESPONSE_TEMPLATE_NAME: 'Numele templateului introdus deja există!',
        INVALID_EMAIL: 'Adresa de email nu este valabilă',
        INVALID_CERTSIGN_ENROLLMENT: 'A apărut o eroare la procesul de semnare, lipsă înrolare.',
        REQUEST_ALREADY_CONFIRMED: 'Cererea a fost deja semnată',
        INVALID_TOKEN: 'Ne pare rău, a apărut o eroare. Incercați din nou!',
        SOURCE_FILE_ID_MISSING_ERROR: 'Ne pare rău, încărcarea fișierului nu a fost cu succes. Incercați din nou!',
        UAT_ALREADY_ASSIGNED: 'UAT-ul selectat este asignat la alt funcționar!',
        ERROR_VALIDATING_LAYER_CREATION_REQUEST: 'A apărut o eroare, vă rugăm reîncercați',
        NOT_OK: 'A apărut o eroare, verificați dacă documentele sunt semnate și încercați din nou.',
        ARCHIVE_CONTENT_INVALID_FILES_BY_NAME: 'A apărut o eroare, verificați numele pachetului de documente și încercați din nou.',
        INVALID_FILE: 'A apărut o eroare, extensia pachetului de documente trebuie fie .zip',
        AUTH_FAILED_ACCOUNT_LOCKED: 'Autentificarea a eșuat. Contul este blocat. Incercați din nou peste {0} minute',
        AUTH_DISABLED: 'Eroare de autentificare, contul este blocat sau dezactivat. Incercați din nou peste {0} minute',
        URBANISM_REQULATION_ZONE_NOT_UNIQUE: 'Denumirea zonei (per UAT și Scop) nu este unică.',
        AUTH_FAILED_PASSWORD_EXPIRED: 'Parola dvs. a expirat și trebuie schimbată.',
        WORKFLOW_EXISTS: 'Un proces cu acest nume există deja',
        MISSING_SIGN_PLACEHOLDER: 'A apărut o eroare la procesul de semnare, vă rugăm reîncercați.',
        INVALID_TARGET_FOR_SIGN_PLACEHOLDER: 'A apărut o eroare la procesul de semnare, vă rugăm reîncercați.',
        MISMATCH_TARGET_FILES_AND_META: 'A apărut o eroare la procesul de semnare, vă rugăm reîncercați.',
        CERTSIGN_SIGNING_ID_NOT_GENERATED: 'A apărut o eroare la procesul de semnare, vă rugăm reîncercați.',
        PERMISSIONS_ARE_EQUAL_TO_CURRENT_RESPONSIBILITY: 'Permisiunile salvate sunt aceleași cu cele inițiale.',
        TOO_MANY_FILES_OR_FILE_SIZE_EXCEEDED: 'Numărul maxim de fișiere a fost atins sau documentul depășește limita maxim admisă.',
        CERTSIGN_INTERNAL_ERROR: 'A apărut o eroare la procesul de semnare, vă rugăm reîncercați.',
        ERROR_DOCUMENT_GENERATION: 'A apărut o eroare, te rugăm încearcă mai târziu.',
        CANNOT_DELETE_FUNCTION_ASSOCIATED_USERS_EXIST:'A apărut o eroare la ștergere, funcția are utilizatori asociați.',
        CANNOT_DELETE_FUNCTION_ASSOCIATED_DEPARTMENTS_EXIST: 'A apărut o eroare la ștergere, funcția este asignată la un departament.',
        APPLICATION_NOT_SUBMITTED: 'A apărut o eroare la procesul de depunere, vă rugăm reîncercați.',
        ERROR_VALIDATING_RU_SCOPE_AND_UAT_ALREADY_EXISTS_FOR_SIMPLE_LAYER: 'Scop RU cu UAT-ul selectat deja există.',
        ERROR_VALIDATING_CONTAINER_COMPONENT_MISMATCHED_RU_SCOPE: 'Scop RU nu este aceleași cu cel al container-ului.',
        ERROR_VALIDATING_RU_SCOPE_ALREADY_EXISTS_FOR_CONTAIANERS: 'Scop RU există deja în lista de containere.',
        PDF_INVALID: 'Fișierul nu are o semnătura validă!',
        ERROR_VALIDATING_PUBLISHED_LAYER_WAS_ALREADY_PUBLISHED: 'Layerul selectat a fost deja publicat. '
    },
    REQUESTS: {
        BUILDING: {
            DETAILS: 'Detalii imobil',
            LAND: 'Teren',
            LAND_CONSTRUCTIONS: 'Teren + Constructii',
            COUNTY: 'Judet',
            LOCALITY: 'Localitate',
            VILLAGE: 'Sat',
            SECTOR: 'Sector',
            STREET: 'Strada',
            POSTAL_CODE: 'Cod postal',
            NUMBER: 'Numar',
            STAIRCASE: 'Scara',
            BLOCK: 'Bloc',
            FLOOR: 'Etaj',
            APARTMENT: 'Apartament',
            LAND_AREA: 'Suprafata terenului',
            OTHER_IDENTIFICATION: 'Alta identificare',
            CADASTRAL_NUMBER: 'Nr. cadastral',
            ADD_CADASTRAL_NUMBER: 'Adauga nr. cadastral',
            REMOVE_CADASTRAL_NUMBER: 'Sterge nr. cadastral'
        }
    },
    VALIDATION: {
        REQUIRED_FIELD: 'Acest camp este obligatoriu!',
        NUMERIC_FIELD: 'Acest camp contine doar valori numerice!',
        EMAIL_FIELD: 'Adresa de e-mail este invalida',
        PHONE_FIELD: 'Numarul de telefon este invalid',
        UPLOADED_FILE_SIZE: 'Dimensiunea fișierul este prea mare',
        UPLOADED_FILE_TYPE: 'Tipul fișierul nu este suportat',
        MAX_UPLOADED_FILES: 'Maxim {max} fisiere',
        PDF_FIELD: 'Extensia fișierului selectat trebuie să fie .pdf .',
    },
    PLACEHOLDERS: {
        MP: 'mp',
    },
    INSTITUTION: {
        MAIN_HEADER: 'Organizație selectată',
        PAGE_HEADER: 'Organizații',
        LIST: {
            TITLE: 'Lista organizațiilor',
        },
        FIELDS: {
            NAME: 'Denumire',
            STATUS: 'Status',
            COUNTY: 'Județ',
            LOCALITY: 'Localitate',
            TYPE: 'Tip',
            UAT: 'UAT',
            DESCRIPTION: 'Descriere',
            IS_TEST: 'Organizație de test',
            STAFF_USERS: 'Funcționar',
            ORGANISATION: 'Organizație'
        },
        PLACEHOLDERS: {
            NAME: 'Nume',
            STATUS: 'Stare',
            DESCRIPTION: 'Introduceți descrierea organizației',
            IS_TEST: 'Organizație de test'
        },
        ENUMS: {
            STATUS: {
                ACTIVE: 'Activ',
                INACTIVE: 'Inactiv',
            },
            IS_TEST: {
                YES: 'Da',
                NO: 'Nu'
            }
        },
        MAIN_PAGE: {
            SEARCH: {
                PLACEHOLDER: 'Caută organizație',
                ERASE_BUTTON: 'Șterge'
            },
            TABLE_HEADER: {
                NAME: 'Nume',
                DESCRIPTION: 'Descriere',
                STATUS: 'Stare',
                IS_TEST: 'Organizație de test',
                ACTIONS: 'Acțiuni'
            },
            TOOLTIPS: {
                EDIT: 'Editare',
                DELETE: 'Ștergere',
                FUNCTIONS: 'Funcții',
                DEPARTMENTS:'Departamente',
                USERS: 'Utilizatori',
                ACCOUNTS:'Conturi colectoare',
                TAXES: 'Taxe',
                RESPONSIBILITIES: 'Responsabilități',
            },
            STATUS_TYPE: {
                ACTIVE: 'Activ',
                INACTIVE: 'Inactiv'
            }
        },
        TABS: {
            DETAILS: 'Detalii',
            FUNCTIONS: 'Funcții',
            DEPARTMENTS: 'Departamente',
            TAXES: 'Taxe',
            USERS: 'Utilizatori',
            ACCOUNTS:'Conturi',
            DOCUMENT_SERIES: 'Serii documente',
            RESONSIBILITIES: 'Responsabilitați',
            DECISIONS: 'Hotărâri',
            URBANISM_REGULATIONS: 'Reglementări'
        },
        ADD_EDIT_FORM: {
            NAME: 'Nume organizație',
            TYPE: 'Tip',
            DESCRIPTION: 'Descriere',
            COUNTY: 'Județ',
            CITY: 'Localitate',
            STATUS_FIELD: 'Status',
            TEST_INST: 'Organizație de test',
            STATUS: {
                ACTIVE: 'Activ',
                INACTIVE: 'Inactiv'
            },
            TYPES: {
                COUNTY_COUNCIL: 'Consiliul Județean',
                CITY_HALL: 'Primărie',
                SERVICES: 'Servicii'
            },
            PLACEHOLDER: 'Introduceți descrierea organizației'
        },
        ADD_MODAL: {
            HEADER: 'Adaugă organizație'
        },
        BUTTON: {
            ADD: 'Adaugă',
            CANCEL: 'Renunță',
            SAVE: 'Salvează',
            UPDATE: 'Modifică',
        },
        FUNCTIONS: {
            SEARCH: {
                PLACEHOLDER: 'Caută functie',
                ERASE_BUTTON: 'Șterge'
            },
            TABLE_HEADER: {
                NAME: 'Nume Funcție',
                DESCRIPTION: 'Descriere',
                ACTIONS: 'Acțiuni'
            },
            TOOLTIPS: {
                EDIT: 'Editare',
                DELETE: 'Ștergere',
                USERS: 'Utilizatori',
                RESPOSABILITIES:'Asociere responsabilități',
            },
        },
        ERRORS: {
            ORG: 'Eroare la generarea listei de instituții!',
            SINGLE_ORG: 'Eroare la generarea datelor pentru instituție!',
            FUNCTIONS: 'Eroare la generarea listei de funcții!',
            UPDATE_ORG: 'Eroare la modificarea instituției.',
            ADD_ORG: 'Eroare la adăugarea instituției.',
            DELETE_ORG: 'Această instituție nu poate fi ștearsă fiindcă există utilizatori asociați',
        },
        SUCCESS: {
            UPDATE_ORG: 'Instituția a fost modificată cu succes.',
            ADD_ORG: 'Instituția a fost adaugată cu succes.',
            DELETE_ORG: 'Instituția a fost ștearsă cu succes.'
        },
        ORG_SELECTION_FORM: {
            HEADER: 'Selectare organizație activă'
        }
    },
    DEPARTMENTS: {
        PAGE_HEADER: 'Departamente',
        LIST: {
            TITLE: 'Lista departamentelor',
        },
        FIELDS: {
            NAME: 'Denumire',
            STATUS: 'Stare',
            DESCRIPTION: 'Descriere',
        },
        PLACEHOLDERS: {
            NAME: 'Nume',
            STATUS: 'Stare',
            DESCRIPTION: 'Descriere',
        },
        ENUMS: {
            STATUS: {
                ACTIVE: 'Activ',
                INACTIVE: 'Inactiv',
            },
        },
        TABLE_HEADER: {
            NAME: 'Nume Departament/Serviciu',
            DESCRIPTION: 'Descriere',
            STATUS: 'Stare',
            ACTIONS: 'Acțiuni'
        },
        TOOLTIPS: {
            FUNCTIONS: 'Asignare Funcții',
            USERS: 'Utilizatori',
        },
        ADD_EDIT_MODAL: {
            HEADER: {
                ADD: 'Adaugă departament',
                EDIT: 'Modifică departament'
            },
            NAME: 'Nume departament',
            DESCRIPTION: 'Descriere',
            STATUS: 'Stare',
            STATUS_TYPE: {
                ACTIVE: 'Activ',
                INACTIVE: 'Inactiv'
            },
            TYPES: {
                COUNTY_COUNCIL: 'Consiliul Județean',
                CITY_HALL: 'Primărie',
                SERVICES: 'Servicii'
            },
            PLACEHOLDER: 'Introduceți descrierea departamentului'
        },
        ASSOCIATED_FCT_MODAL: {
            HEADER: 'Asignare Funcții',
            ERRORS: {
                LIST: 'Eroare la generarea listei de funcții asociate!',
                ADD_FUNCTION: 'Eroare la adaugarea funcției la departament.'
            }
        },
        ERRORS: {
            DEP_LIST: 'Eroare la generarea listei de departamente!',
            ADD: 'Eroare la adăugarea departamentului.',
            UPDATE: 'Eroare la modificarea departamentului.',
        },
        SUCCESS: {
            ADD: 'Departamentul a fost adaugat cu succes.',
            UPDATE: 'Departamentul a fost modificat cu succes.',
        },
        BUTTON: {
            ADD_DEPARTMENT: 'Adaugă departament',
        },
    },
    FUNCTIONS: {
        PAGE_HEADER: 'Funcții',
        LIST: {
            TITLE: 'Lista funcțiilor',
            SYSTEM_FUNCTIONS: 'Funcțiile - {systemFunctions} - sunt create automat de sistem.'
        },
        FIELDS: {
            NAME: 'Denumire',
            DESCRIPTION: 'Descriere',
        },
        PLACEHOLDERS: {
            NAME: 'Nume',
            DESCRIPTION: 'Descriere',
        },
        ASSOCIATED_RESPOSABILITIES_MODAL: {
            HEADER: 'Asignare Responsabilități',
            BODY: {
                TOP: 'Funcția',
            }
        },
        ADD_EDIT_FORM: {
            HEADER_ADD: 'Adaugă funcție',
            HEADER_EDIT:'Editează funcție',
            NAME: 'Denumire',
            DESCRIPTION: 'Descriere',
            PLACEHOLDER: 'Introduceți descrierea funcției'
        },
        SUCCESS: {
            ADD: 'Funcția a fost adaugată cu succes.',
            UPDATE: 'Funcția a fost modificată cu succes.',
            DELETE: 'Funcția a fost ștearsă cu succes.',
        },
        BUTTON: {
            ADD_FUNCTION: 'Adaugă funcție',
        },
    },
    TERRITORIAL_UNIT_ALLOCATION:{
        HEADER: 'Asignare UAT',
        FIELDS: {
            TU_NAME: 'Denumire',
            TERRITORIAL_ADMINISTRATIVE_UNIT_ID: 'UAT',
        },
        PLACEHOLDERS: {
            TU_NAME: 'Nume',
        },
        MODAL: {
            HEADER: 'Adaugă UAT',
            OBS: 'UAT-urile asignate nu apar în listă'
        },
        BUTTON: {
            ADD_UAT: 'Adaugă UAT',
        },
        TABLE_EMPTY_TEXT: 'Nu sunt UAT-uri asignate pentru acest utilizator.'
    },
    ADMIN_USERS_MODAL: {
        HEADER: 'Listă de conturi utilizatori',
        PLACEHOLDER: 'Caută utilizator',
        TABLE_HEADER: {
            LAST_NAME: 'Nume',
            FIRST_NAME: 'Prenume',
            FUNCTION: 'Funcție',
            STATUS: 'Stare',
            STATUS_ACTIVE: 'Activ',
            STATUS_INACTIVE: 'Inactiv',
        },
        FUNCTIONS: {
            ERRORS: {
                LIST: 'Eroare la generarea listei de utilizatori pentru o funcție!',
            },
        },
        DEPARTMENTS:{
            ERRORS: {
                LIST: 'Eroare la generarea listei de utilizatori pentru un departament!'
            },
        }
    },
    ACCOUNTS: {
        BUTTONS: {
            ADD_ACCOUNT: 'Adaugă cont',
            CANCEL: 'Renunță',
            INVITE: 'Invită prin email',
            RESEND_LINK: 'Retrimite link',
            SAVE: 'Salvează',
        },
        ENUMS: {
            ROLES: {
                ADMINISTRATOR: 'Administrator',
                EDITOR: 'Editor',
            },
            STATUS: {
                ACTIVE: 'Activ',
                INACTIVE: 'Inactiv',
                PENDING: 'Trimis',
            },
        },
        FIELDS: {
            CREATED_BY_NAME: 'Creat de',
            EMAIL: 'E-mail',
            FIRST_NAME: 'Prenume',
            LAST_NAME: 'Nume',
            PHONE: 'Telefon',
            STATUS: 'Status',
            ROLE: 'Rol',
            USER_NAME: 'Nume',
        },
        LABELS: {
            CREATED_BY: 'Creat de',
            USER: 'Utilizator',
        },
        LIST: {
            ACTIONS: 'Acțiuni',
            EDIT: 'Editează',
            HEADER: 'Conturi conectate',
        },
        MODALS: {
            EDIT_ACCOUNT: 'Editează contul',
            ADD_NEW_ACCOUNT: 'Adaugă un cont nou',
        },
        NOTIFIERS: {
            EDITED_SUCCESS: 'Contul a fost actualizat!',
            INVITE_TEXT: 'O invitație a fost trimisă cu succes pe adresa de e-mail <strong>{email}</strong>. După validarea contului aceasta va apărea în cadrul organizației',
            INVITE_TITLE: 'Invitație trimisă',
            RESEND_SUCCESS: 'Linkul a fost retrimis!',
        },
        PLACEHOLDERS: {
            CHOOSE: 'Alege',
            ROL: 'Rol',
            SEARCH_NAME: 'Cauta numele',
            STATUS: 'Status',
        },
        TITLE: 'Administrare conturi',
    },
    COLLECTOR_ACCOUNTS: {
        PAGE_HEADER: 'Conturi colectoare',
        LIST: {
            TITLE: 'Lista conturilor colectoare',
        },
        FIELDS: {
            NAME: 'Nume',
            BANK: 'Banca',
            IBAN: 'Cont IBAN',
            STATUS: 'Stare',
        },
        PLACEHOLDERS: {
            NAME: 'Nume',
            BANK: 'Banca',
            IBAN: 'Cont IBAN',
            STATUS: 'Stare',
        },
        ENUMS: {
            STATUS: {
                ACTIVE: 'Activ',
                INACTIVE: 'Inactiv',
            },
        },
        TABLE_HEADER: {
            NAME: 'Nume',
            BANK: 'Banca',
            IBAN: 'Cont IBAN',
            STATUS: 'Stare',
            ACTIONS: 'Acțiuni'
        },
        STATUS_TYPE: {
            ACTIVE: 'Activ',
            INACTIVE: 'Inactiv'
        },
        TOOLTIPS: {
            EDIT: 'Editare',
            DELETE: 'Ștergere',
        },
        MANAGE_ACCOUNT_MODAL: {
            HEADER: {
                EDIT: 'Editeaza Cont',
                ADD: 'Adauga Cont'
            },
            FORM: {
                NAME: 'Nume',
                BANK: 'Banca',
                IBAN: 'Cont IBAN',
                STATUS: 'Stare',
            }
        },
        CONFIRMATION_MESSAGE: 'Esti sigur ca vrei sa stergi contul colector?',
        SUCCESS: {
            DELETE: 'Contul Colector a fost sters cu succes.',
            ADD: 'Contul colector a fost adaugat cu succes.',
            UPDATE: 'Contul colector a fost modificat cu succes.',
        },
        BUTTON: {
            ADD_COLLECTOR_ACCOUNT: 'Adaugă cont',
        },
    },
    TAX_CATEGORY: {
        LIST: {
            TITLE: 'Categorii de taxare',
        },
        FILTER: {
            TITLE: 'Categorii de taxare',
        },
        ADD_BUTTON: 'Categorie noua',
        FIELDS: {
            NAME: 'Denumire',
            STATUS: 'Status',
        },
        FORM: {
            EDIT_TITLE: 'Editeaza categorie',
            ADD_TITLE: 'Categorie noua',
        },
        ENUMS: {
            STATUS: {
                ACTIVE: 'Activ',
                INACTIVE: 'Inactiv'
            }
        },
        PLACEHOLDERS: {
            NAME: 'Cauta dupa denumire',
            STATUS: 'Status'
        },
    },
    COMPLAIN_TYPE: {
        LIST: {
            TITLE: 'Tipologii de sesizări',
        },
        ADD_BUTTON: 'Tipologie nouă',
        FIELDS: {
            NAME: 'Denumire',
            STATUS: 'Status',
            COMPLAIN_DOCUMENT_SERIES_TYPE: 'Tip serie de document',
            TENANT_ID: 'Organizație',
        },
        FORM: {
            EDIT_TITLE: 'Editează tip sesizare',
            ADD_TITLE: 'Tip sesizare nouă',
        },
        ENUMS: {
            STATUS: {
                ACTIVE: 'Activ',
                INACTIVE: 'Inactiv'
            }
        },
        PLACEHOLDERS: {
            NAME: 'Caută după denumire',
            STATUS: 'Status'
        },
    },
    COMPLAIN: {
        LIST: {
            TITLE: 'Sesizări',
            TABLE_HEADER: 'Istoric sesizări',
            ADD_BUTTON: 'Sesizare nouă',
        },
        STAFF_LIST: {
            TITLE: 'Sesizări',
            TABLE_HEADER: 'Sesizări',
            ADD_BUTTON: 'Control nou',
        },
        FIELDS: {
            COMPLAIN_TYPE: 'Tip sesizare',
            LOCALITY: 'Localitate',
            ADDRESS: 'Adresă',
            ADDRESS_DETAILS: 'Detalii adiționale locație',
            DESCRIPTION: 'Descrierea sesizării',
            FIRST_NAME: 'Prenume',
            LAST_NAME: 'Nume',
            EMAIL: 'E-mail',
            PHONE: 'Telefon',
            STATUS: 'Status',
            REPORTED_BY: 'Creat de',
            REPORTED_FOR: 'Persoana de contact',
            IDENTIFICATION_NUMBER: 'Cod intrare',
            CREATED_DATE: 'Data',
            USE_PROFILE_DATA: 'Folosește datele profilului meu',
            TERRITORIAL_ADMINISTRATIVE_UNIT_ID: 'UAT',
        },
        FORM: {
            TITLE: 'Formular sesizare nouă',
            COMPLAIN_DETAIL_SECTION_TITLE: 'Detalii sesizare',
            USER_DETAIL_SECTION_TITLE: 'Detalii persoană contact',
            ATLAS_PREVIEW_SECTION_TITLE: 'Locație',
            DOCUMENTS_SECTION_TITLE: 'Încarcă documente / fotografii',
            VIEW_DOCUMENTS_SECTION_TITLE: 'Vizualizare documente / fotografii',
            UPLOAD_DOCUMENT_BUTTON: 'Încarcă document',
            SUBMIT_BUTTON: 'Trimite sesizarea',
            VIEW_GOOGLE_MAPS_BUTTON: 'Vizualizare in Google Maps',
            CLASSIFY_BUTTON: 'Clasare',
            EMPTY_DOCUMENT_LABEL: 'Nu există documente atașate',
            RESOLVE_BUTTON: 'Trimite răspuns - Soluţionată',
        },
        TABS: {
            COMPLAIN: 'Sesizare',
            DISCUSSIONS: 'Discuții',
        },
        PLACEHOLDERS: {
            DESCRIPTION: 'Mesajul tău',
            STATUS: 'Status',
            REPORTED_BY: 'Creat de',
            REPORTED_FOR: 'Persoana de contact',
            LOCALITY: 'Localitate'
        },
        ENUMS: {
            STATUS: {
                NEW: 'Inregistrată',
                PENDING: 'In procesare',
                RESOLVED: 'Soluţionată',
                REJECTED: 'Respinsă',
                DISMISSED: 'Clasată',
            }
        },
        NOTIFIERS: {
            CREATED_SUCCESS_TITLE: 'Sesizare trimisă',
            CREATED_SUCCESS_TEXT: 'Sesizarea cu numarul <strong>{number}</strong> a fost trimisă cu succes.</br> Dupa o analiza si verificare vei fi notificat in legatura cu rezolvarea acesteia.',
            CLASSIFICATION_MESSAGE: 'Sunteţi sigur că doriţi să clasaţi sesizarea?',
            CLASSIFICATION_SUCCESS_TEXT: 'Sesizarea a fost clasată.',
            RESOLVE_MESSAGE: 'Sunteţi sigur că doriţi să soluţionaţi sesizarea?',
            RESOLVE_SUCCESS_TEXT: 'Sesizarea a fost soluţionată.',
            LOCATION_TITLE: 'Lipsă locaţie!',
            LOCATION_TEXT: 'Te rog alege locaţia sesizării pe hartă pentru a depune sesizarea.'
        },
    },
    FORM_FIELDS: {
        SAVE_BTN: 'Salvează',
        INVITE_BTN: 'Invită prin email',
        CANCEL_BTN: 'Renunţă',
        FIRST_NAME: 'Prenume',
        LAST_NAME: 'Nume',
        FULL_NAME: 'Nume Prenume',
        EMAIL: 'Email',
        PHONE: 'Telefon',
        DEPARTMENT: 'Departament',
        ORGANISATION: 'Instituţie',
        ROLE: 'Rol',
        TAG_ID: 'Număr legitimaţie',
        TAG_RELEASED_BY: 'Legitimaţie eliberată de',
        FUNCTION: 'Funcţie',
        RESPONSIBILITIES: 'Responsabilităţi',
        PERMISSIONS: 'Permisiuni',
        STATUS: 'Status',
        IS_ORGANISATION_ADMIN: 'Administrator organizație',
        MESSAGES: {
            ACCOUNT_CREATED: 'Contul a fost creat',
            ACCOUNT_UPDATED: 'Contul a fost modificat',
            ACCOUNT_NOT_CREATED: 'A aparut o eroare la crearea contului',
            ACCOUNT_NOT_UPDATED: 'A aparut o eroare la modificarea contului'
        },
        ASSIGNED_TERRITORIAL_ADMINISTRATIVE_UNIT: 'UAT-uri asignate'
    },
    FUNCTIONARY: {
        LIST: {
            TITLE: 'Lista funcționarilor',
        },
        ENUMS: {
            STATUS: {
                ACTIVE: 'Activ',
                INACTIVE: 'Inactiv',
                PENDING: 'În așteptare',
            },
        },
        FIELDS: {
            FIRST_NAME: 'Prenume',
            LAST_NAME: 'Nume',
            EMAIL: 'Email',
            STATUS: 'Status',
            FULL_NAME: 'Nume Prenume',
            DEPARTMENTS: 'Departament',
            FUNCTIONS: 'Funcție',
            ACCOUNT_TYPE: 'Tip cont',
            ADMIN: 'Administrator',
            STAFF: 'Funcționar'
        },
        PLACEHOLDERS: {
            EMAIL: 'Email',
            STATUS: 'Status',
            FULL_NAME: 'Nume Prenume',
            DEPARTMENTS: 'Departament',
            FUNCTIONS: 'Funcție'
        },
        HEADER_LIST: 'Administrare conturi funcționari',
        ADD_BTN: 'Adaugă cont',
        ADD_ACCOUNT_HEADER: 'Adaugă cont nou',
        EDIT_ACCOUNT_HEADER: 'Modificare cont',
        TABLE_HEADER: {
            ACTIONS: 'Acțiuni'
        },
        DELETE_ACCOUNT_MESSAGE: 'Ești sigur că vrei să ștergi contul de funcționar?',
        DELETED_ACCOUNT_SUCCESS: 'Contul a fost șters',
        RESET_PASS_SUCCESS: 'S-a trimis un link de resetare a parolei pe adresa de e-mail <strong>{email}</strong>.',
        CHANGE_STATUS_SUCCESS: 'Statusul contului a fost modificat',
        DENY_DELETE_ACCOUNT: 'Contul nu poate fi șters',
        CHANGE_ACCOUNT_STATUS: '<p>Ești sigur că vrei să schimbi statusul contului?<p><small>Status curent: <b>{status}</b></small>',
        RESET_PASSWORD: 'Resetare parolă',
        RESET_PASSWORD_CONFIRM: 'Ești sigur că vrei să resetezi parola?',
        CHANGE_ACCOUNT_STATUS_ACTION: 'Activare/dezactivare cont',
        DENY_RESET_PASSWORD: 'Parola nu poate fi resetată pentru un cont inactiv',
        PERMISSIONS_TOOLTIP: 'Permisiuni',
        PERMISSIONS: {
            HEADER: 'Listă permisiuni',
            FILTER_PLACEHOLDER: 'Caută după denumire',
            PERMISSION_NAME_LABEL: 'Denumire',
        },
        TOOLTIPS: {
            TERRITORIAL_UNIT_ALLOCATION: 'Adăugare/Ștergere UAT-uri'
        }
    },
    RESPONSE_TEMPLATES: {
        LIST: {
            TITLE: 'Template-uri de raspuns',
            ACTIONS: 'Actiuni'
        },
        ADD_BUTTON: 'Adauga template',
        FIELDS: {
            TITLE: 'Titlu',
            CATEGORY: 'Categorie',
            MESSAGE: 'Mesaj template',
        },
        FORM: {
            EDIT_TITLE: 'Editeaza template',
            ADD_TITLE: 'Adauga template',
        },
        ENUMS: {
            CATEGORY: {
                COMPLAINS: 'Sesizari',
                APPLICATIONS: 'Cereri',
                NOTICE: 'Avize'
            }
        },
        PLACEHOLDERS: {
            TITLE: 'Cauta dupa titlu',
            CATEGORY: 'Categorie',
            MESSAGE: 'Mesaj template',
        },
        CATEGORY: {
            COMPLAINS: 'sesizare',
            APPLICATIONS: 'cerere',
            NOTICE: 'aviz'
        }
    },
    TAXES: {
        BUTTONS: {
            ADD_TAX: 'Adaugă taxă',
        },
        FIELDS: {
            NAME: 'Denumire',
            ENTRY_DATE: 'Data intrării în vigoare',
            JUSTIFICATION: 'HCL/NR/DATA',
            CATEGORY: 'Categorie',
            FIXED_VALUE: 'Valoare fixă',
            PERCENTAGE_VALUE: 'Valoare procentuală',
            PRICE_CALCULATION_MODEL: 'Tipul de calcul',
            START: 'Început',
            END: 'Final',
            VALUE: 'Valoare'
        },
        LABELS: {
        },
        LIST: {
            ACTIONS: 'Acțiuni',
            EDIT: 'Editează',
            HEADER: 'Lista taxelor',
        },
        MODALS: {
            ADD_TITLE: 'Adaugă taxă',
            EDIT_TITLE: 'Editează taxă',
            HCL: 'HCL / Altă justificare',
            ADD: 'Adaugă',
            SAVE: 'Salvează',
            CANCEL: 'Renunță',
        },
        NOTIFIERS: {
            SAVED: 'Taxa a fost salvată',
            EDITED: 'Taxa a fost actualizată',
            INVALID_RANGES: 'Intervalele sau valorile nu sunt valide'
        },
        PLACEHOLDERS: {
            NAME: 'Denumire',
            CATEGORY: 'Categorie',
        },
        ENUMS: {
            PRICE_CALCULATION_MODEL: {
                FIXED: 'Fix',
                RANGE: 'Intervale',
                MIXED: 'Mix',
                PERCENTAGE: 'Procentual'
            }
        }
    },
    DOCUMENT_SERIES: {
        BUTTONS: {
            ADD_SERIES: 'Adaugă serie'
        },
        FIELDS: {
            NAME: 'Denumire',
            SEPARATOR: 'Separator',
            DESCRIPTION: 'Descriere',
            PREFIX: 'Prefix',
            REJECTED_PREFIX: 'Prefix adresă răspuns',
            CURRENT_INDEX: 'Index curent',
            YEARLY_RESET: 'Resetare anuală',
            ACTIVE: 'Activ',
            SERIES_TYPE: 'Tip serie'
        },
        LIST: {
            EDIT: 'Editează',
            HEADER: 'Lista seriilor de documente',
        },
        MODALS: {
            ADD_TITLE: 'Adaugă serie document',
            EDIT_TITLE: 'Editează serie document',
            ADD: 'Adaugă',
            SAVE: 'Salvează',
            CANCEL: 'Renunță',
        },
        NOTIFIERS: {
            SAVED: 'Seria de documente a fost salvată',
            EDITED: 'Seria de documente a fost actualizată'
        },
        PLACEHOLDERS: {
            NAME: 'Denumire',
        },
    },
    ORG_RESPONSIBILITIES:{
        BUTTONS: {
            ADD_RESPONSIBILITY: 'Adaugă responsabilitate'
        },
        LIST: {
            TITLE: 'Responsabilități',
        },
        FILTER: {
            TITLE: 'Listă Responsabilități',
        },
        FIELDS: {
            RESPONSIBILITY_NAME: 'Denumire',
            RESPONSIBILITIES: 'Responsabilități'
        },
        PLACEHOLDERS: {
            RESPONSIBILITY_NAME: 'Caută dupa denumire',
            RESPONSIBILITIES: 'Responsabilități'
        },
        FORM: {
            ADD_TITLE: 'Adaugă responsabilitate',
            EDIT_TITLE: 'Modifică responsabilitate',
            SELECTED_RESPONSIBILITY: 'Responsabilitate selectată',
            PERMISSIONS: 'Permisiuni'
        },
        CLARIFICATION: {
            ADD: 'Responsabilitatea se poate adăuga dacă există responsabilitate și permisiuni selectate.',
            EDIT: 'Responsabilitatea se poate modifica doar dacă există permisiuni selectate.'
        }
    },
    RESPONSIBILITY: {
        FILTER: {
            TITLE: 'Responsabilități',
        },
        FIELDS: {
            NAME: 'Denumire',
            DESCRIPTION: 'Descriere',
            CREATED_DATE: 'Data inserării',
            UPDATED_DATE: 'Ultima actualizare',
            ACTIONS: 'Acțiuni',
        },
        PLACEHOLDERS: {
            NAME: 'Caută dupa denumire',
            PERMISSIONS: 'Caută Permisiuni'
        },
        FORM: {
            ASSOCIATED_PERMISSIONS: 'Permisiuni asociate',
            EDIT_TITLE: 'Editează responsabilitate',
            ADD_TITLE: 'Adaugă responsabilitate'
        }
    },
    APPLICATION: {
        LIST: {
            TITLE_STAFF: 'Registru cereri',
            TITLE: 'Cereri',
            TABLE_HEADER: 'Listă cereri',
            ADD_BUTTON: 'Cerere nouă',
            REFRESH_BUTTON: 'Reîncarcă',
            ADD_DOCUMENT_BUTTON: 'Document',
            DOCUMENT_CREATED_HEADER: 'Documente create',
            DOCUMENT_RECEIVED_HEADER: 'Documente primite',
            NO_ASSIGNEE: 'Nealocată',
            SIGN_DOCUMENT_BULK: 'Semnează documente',
        },
        FIELDS: {
            APPLICATION_TYPE_NAME: 'Selectează tipul cererii',
            APPLICATION_TYPE_NAME_COLUMN: 'Tip cerere',
            APPLICATION_TYPE_PURPOSE: 'Selectează scopul cererii',
            TERRITORIAL_ADMINISTRATIVE_UNIT: 'UAT',
            TERRITORIAL_ADMINISTRATIVE_UNIT_TYPE: 'Tip UAT',
            PAYMENT: 'Plata',
            STATUS: 'Status',
            LOCALITY: 'Municipiu / Oraș / Comună',
            EMPLACEMENT_LOCALITY: 'Localitate',
            FIRST_NAME: 'Prenume',
            LAST_NAME: 'Nume',
            EMAIL: 'E-mail',
            PHONE: 'Telefon',
            IDENTIFICATION_NUMBER: 'Cod intrare',
            CREATED_DATE: 'Dată creare',
            APPLICATION_DATE: 'Dată intrare',
            RESPONSE_DUE_IN: 'Timp rămas',
            CADASTRAL_NUMBER: 'Număr cadastru',
            USER_TYPE_NAME: 'Tip persoană',
            PERSONAL_NUMERIC_CODE: 'CNP',
            COUNTY: 'Județ',
            VILLAGE: 'Localitate',
            SECTOR: 'Sector',
            STREET: 'Strada',
            STREET_NUMBER: 'Număr stradă',
            POSTAL_CODE: 'Cod poștal',
            STAIRCASE: 'Scara',
            BLOCK: 'Bloc',
            FLOOR: 'Etaj',
            APARTMENT: 'Apartament',
            REAL_ESTATE_TYPE: 'Tip amplasament',
            AUTHORISATION_CODE: 'Cod autorizație',
            SUBSIDIARY: 'Filiala',
            LAND_AREA: 'Suprafața terenului',
            OTHER_IDENTIFICATION: 'Altă identificare',
            CREATED_BY: 'Creat de',
            FILE_TYPE: 'Tip document',
            RESOLVED_DATE: 'Data soluționării',
            PIN: 'Cod confirmare',
            USE_PROFILE_DATA: 'Folosește date profil',
            PASSPORT_NUMBER: 'Nr. pașaport',
            CITY: 'Oraș',
            ADDRESS: 'Adresă',
            LICENSE: 'Licență',
            COMPANY_NAME: 'Companie',
            UNIQUE_REGISTRATION_CODE: 'CUI',
            FILENAME: 'Nume document',
            IS_CONTACT_PERSON: 'Solicitantul este persoana de contact',
            TITLE: 'Titlu',
            MESSAGE: 'Mesaj',
            COMMENTS: 'Comentarii',
            ASSIGNED_TO: 'Responsabil',
            CLARIFICATION_COMMENT: 'Mențiuni',
            MESSAGE_COMMENT: 'Mesajul tău',
            CONTACT_PERSON: 'Persoană contact',
            BENEFICIARY: 'Beneficiar',
            IS_PAID: 'Plată',
            IS_SIGNED: 'Semnătură',
            CADASTRAL_NUMBER: 'Nr. cadastral',
            COMMENTS: 'Comentarii',
            TOTAL_TAX: 'Total'
        },
        ENUMS: {
            STATUS: {
                NEW: 'Nouă',
                PENDING: 'În verificare',
                RESOLVED: 'Soluționată',
                REJECTED: 'Respinsă',
                APPROVED: 'În lucru',
                DRAFT: 'Draft',
                GENERATED: 'Generat',
                SIGNED: 'Semnat',
                SIGN_PENDING: 'În semnare',
                CLOSED: 'Finalizată',
                REVIEW: 'În clarificări',
            },
            REAL_ESTATE_TYPE: {
                LAND: 'Teren',
                LAND_CONSTRUCTIONS: 'Teren + Constructii',
            }
        },
        PLACEHOLDERS: {
            BENEFICIARY: 'Beneficiar',
            SERVICE: 'Alege',
            ALLOCATED_STAFF: 'Alege',
            IDENTIFICATION_NUMBER: 'Cod intrare',
            APPLICATION_TYPE_NAME: 'Tip cerere',
            CADASTRAL_NUMBER: 'Nr CF',
            APPLICATION_TYPE_PURPOSE: 'Scopul cererii',
            TERRITORIAL_ADMINISTRATIVE_UNIT: 'UAT',
            STATUS: 'Status',
            COUNTY: 'Județ',
            LOCALITY: 'Localitate',
            RESPONSE_DUE_IN: 'Timp rămas',
            VILLAGE: 'Localitate',
            TERRITORIAL_ADMINISTRATIVE_UNIT_TYPE: 'Tip UAT',
            CREATED_BY: 'Creat de',
            EMPLACEMENT_LOCALITY: 'Localitate',
            COMMENTS: 'Comentarii',
            MESSAGE: 'Mesaj',
            ASSIGNED_TO: 'Responsabil',
        },
        FORM: {
            TITLE: 'Formular cerere nouă',
            SUBMIT_BUTTON: 'Trimite cererea',
            SAVE_AS_DRAFT_BUTTON: 'Salvează ca draft',
            CANCEL_BUTTON: 'Anulează cererea',
            DETAILS_SECTION: 'Detalii cerere',
            TAXES_BUTTON: 'Calculează taxe',
            TAXES_ERROR: 'Vă rugăm selectați tipul cererii și completați suprafața terenului',
            WIZARD_TITLE: 'Depunere cerere',
            GO_BACK: 'Înapoi',
            GENERATE_DOCUMENT: 'Finalizează document',
            NO_TEMPLATE: 'Nu există un template pentru acest tip de cerere',
            PAY_BUTTON: 'Plătește',
            SIGN_BUTTON: 'Semnează și depune',
            APPLY_BUTTON: 'Depune cerere',
            SEND_MESSAGE_BUTTON: 'Trimite mesajul',
            PREVIEW_DOC: 'Previzualizare',
            SEND_REVIEW: 'Trimite clarificări',
            PENDING_RELATED_APPLICATIONS: 'Avizul de primar în lucru',
        },
        NOTIFIERS: {
            CREATED_SUCCESS_TITLE: 'Cerere trimisă',
            CREATED_SUCCESS_TEXT: 'Cererea cu numărul {number} a fost trimisă cu succes. După o analiză și verificare vei fi notificat în legătură cu rezolvarea acesteia.',
            DRAFT_SAVED_SUCCESS: 'Cererea a fost salvată ca draft',
            CHOOSE_SIGN_PACKAGE: 'Vă rugăm alegeți pachetul de semnături',
            OFFLINE_SIGN_FAILED: 'Documentele nu sunt semnate',
            PAYMENT_FAILED: 'Plată eșuată',
            PAYMENT_SUCCESS: 'Plata a fost finalizată cu succes',
            INVALID_PROFILE_FOR_SIGNING_TITLE: 'Datele din profil nu sunt valide',
            INVALID_PROFILE_FOR_SIGNING_TEXT: 'Te rugăm să verifici CNP-ul și datele cărții de identitate din secțiunea <b>Profilul meu</b> necesare pentru semnarea online!',
            INVALID_PROFILE_FOR_SIGNING_BUTTON_TEXT: 'Mergi la Profilul meu',
            INVALID_STAFF_ENROLLMENT_TITLE: 'A apărut o eroare la procesul de semnare',
            INVALID_STAFF_ENROLLMENT_TEXT: 'Te rugăm să te înrolezi pentru a putea semna',
            INVALID_STAFF_ENROLLMENT_BUTTON_TEXT: 'Mergi la Profilul meu',
            DUPLICATE_BENEFICIARY_PNC: 'Beneficiarul cu același CNP ({pnc}) este deja adăugat.',
            GENERATE_OFFLINE_PACKAGE: 'Pachetul de cereri și anexe se generează. Va rugăm așteptați!',
            REVIEW_SENT: 'Clarificările au fost trimise',
            REVIEW_NOT_SENT: 'A apărut o eroare la trimiterea clarificărilor. Te rugăm sa încerci din nou',
        },
        VIEW: {
            TITLE_STAFF: 'Registru cereri',
            TITLE: 'Cereri',
            SAVE_AS_DRAFT_BUTTON: 'Salvează ca draft',
            REASSIGN_STAFF: 'Alocare cerere',
            ASSIGN_STAFF: 'Asignare',
            SEND_RESPONSE: 'Trimite răspuns',
            APPLICATION_DETAILS_SECTION_TITLE: 'Detalii cerere',
            BENEFICIARY_DETAILS_SECTION_TITLE: 'Detalii beneficiari',
            BENEFICIARY_DETAILS_TITLE: 'Beneficiar',
            REAL_ESTATE_DETAILS_SECTION_TITLE: 'Detalii imobil',
            REAL_ESTATE_EMPLACEMENT_DETAILS_SECTION_TITLE: 'Detalii amplasament',
            APPLICANT_DETAILS_SECTION_TITLE: 'Detalii solicitant',
            PAYMENT_DETAILS_SECTION_TITLE: 'Plăti',
            ATLAS_DETAILS_SECTION_TITLE: 'Previzualizare din atlas teritorial',
            ATTACHMENTS_DETAILS_SECTION_TITLE: 'Documente',
            NEXT_STEP: 'Pasul următor',
            SIGN_DOCUMENT: 'Semnează document',
            ASSIGN_STAFF_MESSAGE: 'Ești sigur că vrei să preiei această cerere?',
            APPROVE_DOCUMENT: 'Aprobă',
            REJECT_DOCUMENT: 'Respinge',
            APPROVE_DOCUMENT_MESSAGE: 'Ești sigur că vrei să aprobi această cerere?',
            REJECT_DOCUMENT_MESSAGE: 'Ești sigur că vrei să respingi această cerere?',
            GENERATE_DOCUMENT_MESSAGE: 'Documentul a fost generat',
            GENERATE_DOCUMENT_ERROR: 'Documentul nu poate fi generat',
            SIGN_DOCUMENT_ERROR: 'Documentul nu poate fi semnat',
            SIGN_SUCCESS: 'Documentul a fost semnat',
            AUTOSAVE_SUCCESS: 'Salvat cu succes',
            AUTOSAVE_FAILED: 'Erroare la salvare',
            SEND_CLARIFICATIONS: 'Necesită clarificări',
            RETURN_DOCUMENT: 'Regenerare document',
            RETURN_DOCUMENT_MESSAGE: 'Sunteți sigur că vreți să returnați acest document? Această acțiune este ireversibilă și presupune regenerarea actului.',
        },
        CONFIRM_SIGN_MODAL: {
            TITLE: 'Validare semnatură digitală',
            MESSAGE_SENT: 'S-a trimis un SMS la numărul de telefon <strong>{0}</strong>. Adaugă codul primit, pentru a semna cererea folosind semnătura electronică și a trimite cererea.'
        },
        TABS: {
            APPLICATION: 'Detalii cerere',
            DOCUMENTS: 'Documente',
            ACTIVITY: 'Activități',
            DOCUMENT_CREATED: 'Cereri',
            DOCUMENT_RECEIVED: 'Acte emise',
            REAL_ESTATE: 'Imobil',
            TAXES: 'Taxe',
            BENEFICIARY: 'Beneficiar',
            NEW_APPLICATION: 'Detalii',
            SIGN: 'Semnătură',
            SUMMARY: 'Rezumat',
            CERTIFICAT_URBANISM: 'Certificat urbanism',
        },
        TERRITORIAL_ATLAS: {
            HEADER: 'Previzualizare din atlas teritorial',
            SUBTITLE: 'Harta se populează pe măsură ce se adaugă amplasamente.',
            GENERATE_BUTTON: 'Vizualizează',
            REAL_ESTATES_LABEL: 'Vizualizează amplasamentul',
            PLACEHOLDER: 'Introdu datele imobilului apoi apasa butonul “Genereaza” pentru previzualizarea hartii.',
            NOT_FOUND: 'Ne pare rău numărul cadastral nu a fost găsit. Incercați din nou!',
            ERROR: 'Ne pare rău sincronizarea nu a fost cu succes. Incercați din nou!'
        },
        PAYMENT: {
            SECTION_HEADER: 'Taxe',
            TAX_HEADER: 'Taxă eliberare act',
            DETAILS_HEADER: 'Detalii plată',
            TABS: {
                SIGN_DETAILS: 'Pregătire',
                TAX_DETAILS: 'Plata',
                PAYMENT_DETAILS: 'Card',
                SIGN_DOCUMENTS: 'Semnare',
                SEND_APPLICATION: 'Finalizare'
            },
            SUCCESS_MESSAGE: 'Plata s-a efectuat cu succes',
            STATUS_PAID: 'achitat',
            STATUS_UNPAID: 'neachitat'
        },
        MODALS: {
            ADD: 'Adaugă',
            CANCEL: 'Renunță',
            SAVE: 'Salvează',
        },
        SIGNATURE: {
            TITLE: 'Alege modalitatea de semnare',
            SECTION_TITLE: 'Semnează cererile și anexele',
            TABS: {
                ONLINE: 'Semnare online',
                OFFLINE: 'Semnare offline',
            },
            ONLINE: {
                HELP_TEXT: 'Pentru depunerea cererii online aceasta va fi semnată digital prin semnătură digitală. Procesul de semnare se poate face de la distanță, iar pentru aceasta aveți nevoie de o semnătură digitală.',
                INFO_TEXT: 'Pentru a intermedia achiziționarea certificatului digital pentru semnături de la certSIGN, vom folosi datele de identificare din contul tău. Le vei furniza o singură dată, iar pe baza lor va fi creat un cod unic de identificare care va garanta autenticitatea solicitărilor tale.',
                PACKAGE_LABEL: 'Costul acestora va fi inclus în total.',
                SUMMARY_DIGITAL_SIGNATURE_LABEL: 'Semnături digitale',
                SUMMARY_REQUIRED_SIGNATURES: 'Necesare',
                SUMMARY_AVAILABLE_SIGNATURES: 'Disponibile',
                SUMMARY_LEFTOVER_SIGNATURES: 'Vor rămane',
                SUMMARY_TEXT: 'Semnătură electronică la distanță'
            },
            OFFLINE: {
                HELP_TEXT: 'Alege "SEMNARE ONLINE" dacă dorești să achiziționezi un certificat pentru semnăturile digitale necesare, sau "SEMNARE OFFLINE" dacă deții deja unul de la un furnizor autorizat.',
                INFO_TEXT: 'Descarcă pachetul de documente pe dispozitivul tău, semnează-le și încarcă documentele pentru a continua. Nota bene: este nevoie de semnătură digitală validată cu certificat calificat, nu electronică (sau olografă).',
                ACTION_DOWNLOAD_TITLE: 'Descarcă pachetul de cereri și anexe',
                ACTION_DOWNLOAD_SUBTITLE: 'care trebuie semnate digital',
                ACTION_UPLOAD_TITLE: 'Încarcă pachetul de documente',
                ACTION_UPLOAD_SUBTITLE: 'care trebuie semnate digital',
                STEP_DOWNLOAD: 'Descarcă',
                STEP_SIGN: 'Semnează',
                STEP_VERIFY: 'Verificare',
                STEP_CHECKED: 'Documente ok',
                SUMMARY_TEXT: 'Semnătură electronică personală',
                UPLOAD_NOTIFICATION: 'O dată cu încărcarea documentelor semnate, cererea nu va mai putea fi modificată. Ești sigur ca vrei să continui?'
            },
        },
        CLARIFICATION_MODAL: {
            TITLE: 'Trimitere răspuns',
        },
        SUMMARY: {
            SIGN_TYPE_TITLE: 'Modalitate de semnare',
            APPLICATION_DETAIL_TITLE: 'Detalii cerere',
            PAYMENT_TYPE_TITLE: 'Modalitate de plată',
            SIGN_TYPE_FOOTER: 'Asigurat prin CertSign',
            APPLICATION_OBJECTIVE: 'Obiectivul cererii',
            APPLICANT: 'Solicitant',
            PAYMENT_TYPE: 'Card online',
            PAYMENT_TYPE_HELP_TEXT: 'După inițierea plății, vei fi redirecționat către procesatorul de plăți pentru a efectua plata.',
            PAYMENT_TYPE_HELP_TEXT_FAILED: 'Plata nu a putut fi efectuată.',
            PAYMENT_TYPE_HELP_TEXT_SUCCESS: 'Plata a fost efectuată cu succes.',
            DETAILS: 'Desfășurător',
            CERTIFICATE_TOTAL_TAXES: 'Total costuri certificate digitale',
            TOTAL_TAXES: 'Total taxe și avize',
            SECTION_TITLE: 'Revizuiește și trimite cererea',
            TOTAL: 'Total',
            SELECTED_REAL_ESTATES_TEXT: 'nici un imobil ales | + 1 imobil ales | + {count} imobile alese',
            PAY_AS_YOU_GO_SUMMARY_TEXT: 'Semnatură digitală',
            TAX_TEXT: 'Taxă',
        },
        ENROLLMENT_DETAILS: {
            PERSON: {
                HEADER: 'Tip persoană',
                INDIVIDUAL: 'Persoană fizică',
                LEGAL: 'Persoană juridică'
            },
            CITIZENSHIP: {
                HEADER: 'Cetătenie',
                ROMANIAN: 'Cetătean roman',
                FOREIGNER: 'Cetătean strain'
            },
            PERSONAL_NUMERIC_CODE: 'CNP',
            ADDRESS: 'Adresă domiciliu',
            CONTACT: 'Contact',
            ADDRESS_COUNTY: 'Județul',
            ADDRESS_STREET: 'Str.',
            ADDRESS_NUMBER: 'nr.',
            ADDRESS_STAIRCASE: 'scara',
            ADDRESS_APARTMENT: 'ap.'
        },
        SEND_SUCCESS_TITLE: 'Felicitări, solicitarea ta a fost trimisă fără a genera risipă de hârtie!',
        SEND_SUCCESS_TEXT: 'Cererea cu numarul <strong>{number}</strong> a fost înregistrată cu succes.</br> După ce o analizăm și verificăm, vei fi notificat (aici și prin email) în legatură cu rezolvarea acesteia.',
        PAYMENT_SUCCESS_TITLE: 'Plata a fost înregistrată cu succes.',
        PAYMENT_REDIRECT_TEXT: 'Vei fi redirecționat în cateva secunde la pagina cererii. Dacă nu ești redirecționat automat te rugăm dă {0}',
        PAYMENT_FAILED_TITLE: 'A apărut o eroare la procesarea plății, te rugăm încearcă din nou.',
        MISSING_PAYMENT_CALLBACK_PAYLOAD: 'A apărut o eroare la procesarea plății, te rugăm încearcă din nou.',
        RESPONSE_MODAL: {
            APPROVE_APPLICATION_TITLE: 'Notificare aprobare',
            REJECT_APPLICATION_TITLE: 'Notificare respingere',
        },
        ASSIGN_MODAL_TITLE: 'Asignare Cerere',
        ASSIGN_SUCCESS: 'Cererea a fost asignată la <strong>{name}</strong> cu succes!',
        SIGN_CONFIRMATION: {
            INVALID_PIN: 'Codul nu este valid, vă rugăm reîncercați',
            PIN_EXPIRED: 'Codul a expirat, vă rugăm reîncercați',
            SESSION_EXPIRED: 'Sesiunea a expirat, vă rugăm reîncercați',
            ERROR: 'A apărut o eroare, vă rugăm reîncercați',
        },
        SIGN_AND_APPLY_LOADER: {
            TITLE: 'Depunerea durează câteva minute',
            TEXT: 'Te rugăm să aștepți, depunerea cererii este în progres și poate dura câteva minute, fără a genera risipă de hârtie și timp.'
        }
    },
    ARCHIVE: {
        BUTTONS: {
            MANUAL_ARCHIVE: 'Arhivare manuală',
            ADD_WORKSPACE: 'Adaugă workspace',
            DOWNLOAD: 'Descarcă'
        },
        MENU: {
            DASHBOARD: 'Dashboard',
            DOCUMENTS: 'Documente'
        },
        WORKSPACE: {
            LIST: {
                TITLE: 'Workspaces',
            },
            FIELDS: {
                NAME: 'Denumire',
                ENTITY_TYPE: 'Tip',
                CREATED_DATE: 'Arhivare',
                DIMENSION: 'Dimensiune',
                FOLDERS_NUMBER: 'Nr foldere',
                DESCRIPTION: 'Descriere'
            },
            PLACEHOLDERS: {
                NAME: 'Denumire',
                ENTITY_TYPE: 'Tip',
                CREATED_DATE: 'Arhivare',
            },
            FORM: {
                EDIT_TITLE: 'Editează workspace',
                ADD_TITLE: 'Adaugă workspace'
            }
        },
        SUBFOLDER: {
            LIST: {
                TITLE: 'Documente',
            },
            FIELDS: {
                NAME: 'Denumire',
                TYPE: 'Tip',
                CREATED_DATE: 'Arhivare',
                DIMENSION: 'Dimensiune',
                DOCUMENT_NUMBER: 'Nr Documente',
                WORKSPACE: 'Workspace',
                DESCRIPTION: 'Descriere',
                DOCUMENT_TYPE_CODE: 'Tip document'
            },
            PLACEHOLDERS: {
                NAME: 'Denumire',
                ACHIVE_DATE: 'Arhivare',
            },
            ACTIONS: {
                EDIT: 'Modifică Folder',
                ADD: 'Adaugă Folder'
            },
            FORM: {
                WORKSPACE: 'Workspace',
                TYPE: 'Tip Document corelat',
                NAME: 'Denumire Folder'
            },
        },
        DOCUMENTS: {
            LIST: {
                TITLE: 'Documente',
            },
            FIELDS: {
                DOCUMENT_NAME: 'Denumire',
                DOCUMENT_TYPE_NAME: 'Tip',
                DOCUMENT_SIZE: 'Dimensiune',
                CREATED_DATE: 'Data creării',
                UPDATED_DATE: 'Data modificării',
            },
            PLACEHOLDERS: {
                NAME: 'Denumire',
                ACHIVE_DATE: 'Arhivare',
            },
            ACTIONS: {
                EDIT: 'Modifică Folder',
                ADD: 'Adaugă Folder'
            },
        },
        DOCUMENT: {
            TITLE_MANUAL_ARCHIVE: 'Arhivare manuală',
            TITLE_VIEW_ARCHIVE: 'Vizualizare arhivă',
            HEADERS: {
                ATTACHMENTS: 'Atașamente',
                DETAILS: 'Detalii',
                METADATA: 'Metadate'
            },
            FIELDS: {
                WORKSPACE: 'Workspace',
                DOCUMENT_NAME: 'Nume Document',
                DOCUMENT_FOLDER: 'Folder',
                DOCUMENT_TYPE: 'Tip document',
            },
        },
        CONFIRMATION_TEXT: 'Pentru consistența datelor va rugam sa verificați ca acestea sunt valide si conforme cu documentele arhivate. Pentru orice modificari ulterioare va rugam sa contactati un administrator al sistemului. Sunteti sigur ca doriti sa salvati documentul?',
        MESSAGES: {
            SUCCESS: 'Arhivare manuală realizată cu succes!',
            ERRORS: 'Eroare la arhivarea manuală!'
        },
        ATTACHMENTS: {
            PLACEHOLDER: 'fișiere selectate',
            EMPTY: 'Nu există documente atașate.',
            EMPTY_WARNING: 'Pentru a salva, arhiva trebuie sa conțină fișiere atașate.',
            LABEL: 'Fișier',
            ADD_BUTTON: 'Adaugă atașament'
        },
        REPORTS: {
            WORKSPACE_LABEL: 'Workspace',
            WORKSPACE_DIMENSION: {
                TITLE: 'Total spațiu arhivat',
                DESCRIPTION: 'Raport workspace'
            },
            WORKSPACE_DOCUMENTS: {
                TITLE: 'Total număr documente arhivate',
                DESCRIPTION: 'Raport workspace'
            },
            WORKSPACE_FOLDERS_DIMENSION: {
                TITLE: 'Total dimensiune documente arhivate per workspace',
                CHART_LABELS_TITLE: 'Listă foldere',
                DESCRIPTION: 'Raport foldere din workspace'
            },
            WORKSPACE_FOLDERS_DOCUMENTS: {
                TITLE: 'Total număr documente arhivate per workspace',
                CHART_LABELS_TITLE: 'Listă foldere',
                DESCRIPTION: 'Raport foldere din workspace'
            },
            WORKSPACE_PROGRESS_ANNUAL: {
                TITLE_DOC: 'Progres de arhivare {period} - documente',
                TITLE_DIMENSION: 'Progres de arhivare {period} - dimensiune',
                TITLE_DESCRIPTION_DOC: 'Fișiere arhivate',
                TITLE_DESCRIPTION_DIMENSION: 'Dimensiune fișiere arhivate',
                TOTAL_DESCRIPTION_DOC: 'Documente în perioada selectată',
                TOTAL_DESCRIPTION_DIMENSION: 'Dimensiune în perioada selectată',
                CHART_TITLE: 'Analiză',
                Y_AXIS_DOC: 'Documente',
                Y_AXIS_DIMENSION: 'Dimensiune',
            },
        },
        PERIOD: {
            ANNUALY: 'anual',
            MONTHLY: 'lunar',
            WEEKLY: 'săptămânal'
        }
    },
    FORMS: {
        LIST: {
            TITLE: 'Interfețe formulare',
            ADD_BUTTON: 'Adaugă formular',
            TABLE_HEADER: 'Modele interfețe formulare',
        },
        FIELDS: {
            NAME: 'Denumire',
            STATUS: 'Stare',
            DESCRIPTION: 'Descriere',
        },
        PLACEHOLDERS: {
            NAME: 'Nume',
        },
        ENUMS: {
            STATUS: {
                DRAFT: 'Draft',
                PUBLISHED: 'Public',
            },
        },
        CREATE_MODAL: {
            TITLE: 'Salvare interfață formular'
        },
        COMPONENTS: {
            FIELDS: {
                input: 'Input',
                textarea: 'Textarea',
                number: 'Number',
                radio: 'Radio',
                checkbox: 'Checkbox',
                time: 'Time',
                date: 'Date',
                rate: 'Rate',
                color: 'Color',
                select: 'Select',
                switch: 'Switch',
                slider: 'Slider',
                text: 'Text',
                blank: 'Custom',
                fileupload: 'File',
                imgupload: 'Image',
                editor: 'Editor',
                cascader: 'Cascader',
                table: 'Sub-table',
                grid: 'Grid',
                section: 'Section',
                inline: 'Inline',
                tabs: 'Tabs',
                divider: 'Divider',
                file: 'File',
                html: 'HTML',
                'beneficiary-list': 'Beneficiar',
                'applicant-form': 'Solicitant',
                'application-detail-form': 'Detalii cerere',
                'real-estate-list': 'Imobil',
                'decision-list': 'Hotărâri',
                'attachments': 'Atașamente',
                'urbanism-regulation-picker': 'Reglementări'
            },
            BASIC: {
                TITLE: 'Basic Component',
            },
            ADVANCE: {
                TITLE: 'Advance Component',
            },
            LAYOUT: {
                TITLE: 'Layout',
            },
            CUSTOM: {
                TITLE: 'Custom Component',
            },
        },
        DESCRIPTION: {
            CONTAINER_EMPTY: 'You can drag and drop the item from the left to add components',
            CONFIG_EMPTY: 'Please add a component',
            TABLE_EMPTY: 'You can drag and drop the item from the left to add components',
            UPLOAD_JSON_INFO: 'There is the format of JSON below，you can overwrite it with you own JSON code'
        },
        MESSAGE: {
            COPY_SUCCESS: 'Copy Successed',
            VALID_ERROR: 'Vă rugăm să completați toate câmpurile obligatorii.'
        },
        ACTIONS: {
            IMPORT: 'Import JSON',
            CLEAR: 'Clear',
            PREVIEW: 'Preview',
            JSON: 'Generate JSON',
            CODE: 'Generate Code',
            GET_DATA: 'Get Data',
            RESET: 'Reset',
            COPY_DATA: 'Copy Data',
            CANCEL: 'Cancel',
            CONFIRM: 'Confirm',
            ADD_OPTION: 'Add Option',
            ADD_COLUMN: 'Add Column',
            ADD_TAB: 'Add Tab',
            UPLOAD: 'Upload',
            ADD: 'Add'
        },
        CONFIG: {
            FORM: {
                TITLE: 'Form Attribute',
            },
            WIDGET: {
                TITLE: 'Component Attribute',
                MODEL: 'ID',
                NAME: 'Name',
                META: 'Meta',
                WIDTH: 'Width',
                HEIGHT: 'Height',
                SIZE: 'Size',
                LABEL_WIDTH: 'Label Width',
                CUSTOM: 'Custom',
                PLACEHOLDER: 'Placeholder',
                LAYOUT: 'Layout',
                BLOCK: 'Block',
                INLINE: 'Inline',
                CONTENT_POSITION: 'Content Position',
                LEFT: 'Left',
                RIGHT: 'Right',
                CENTER: 'Center',
                SHOW_INPUT: 'Display Input Box',
                MIN: 'Minimum',
                MAX: 'Maximum',
                STEP: 'Step',
                MULTIPLE: 'Multiple',
                FILTERABLE: 'Searchable',
                ALLOW_HALF: 'Allow Half',
                SHOW_ALPHA: 'Support transparency options',
                SHOW_LABEL: 'Show lable',
                OPTION: 'Option',
                STATIC_DATA: 'Static Data',
                REMOTE_DATA: 'Remote Date',
                REMOTE_FUNC: 'Remote Function',
                VALUE: 'Value',
                LABEL: 'Label',
                CHILDREN_OPTION: 'Sub-Option',
                DEFAULT_VALUE: 'Default Value',
                LIMIT: 'Maximum Upload Count',
                IMAGE_ACTION: 'Picture upload address',
                TIP: 'Text Prompt',
                ACTION: 'Upload Address',
                DEFAULT_TYPE: 'Data Type',
                STRING: 'String',
                OBJECT: 'Object',
                ARRAY: 'Array',
                NUMBER: 'Number',
                BOOLEAN: 'Boolean',
                INTEGER: 'Integer',
                FLOAT: 'Float',
                URL: 'URL',
                EMAIL: 'E-mail',
                HEX: 'Hexadecimal',
                GUTTER: 'Grid Spacing',
                COLUMN_OPTION: 'Column Configuration',
                SPAN: 'Grid spans',
                JUSTIFY: 'Horizontal Arrangement',
                JUSTIFY_START: 'Start',
                JUSTIFY_END: 'End',
                JUSTIFY_CENTER: 'Center',
                JUSTIFY_SPACE_AROUND: 'Space Around',
                JUSTIFY_SPACE_BETWEEN: 'Space Between',
                ALIGN: 'Vertical Arrangement',
                ALIGN_TOP: 'Top',
                ALIGN_MIDDLE: 'Middle',
                ALIGN_BOTTOM: 'Bottom',
                TYPE: 'Type',
                DEFAULT: 'Default',
                CARD: 'Tabs',
                BORDER_CARD: 'Border-Card',
                TAB_POSITION: 'Tab Position',
                TOP: 'Top',
                BOTTOM: 'Bottom',
                TAB_OPTION: 'Label Configuration',
                TAB_NAME: 'Tab Name',
                CUSTOM_CLASS: 'Custom Class',
                ATTRIBUTE: 'Attribute Action',
                DATA_BIND: 'Data Binding',
                HIDDEN: 'Hidden',
                READONLY: 'Read Only',
                DISABLE: 'Disable',
                EDITABLE: 'Text box is editable',
                CLEARABLE: 'Display Clear Button',
                ALLOW_CONTROL: 'Use the arrow for time selection',
                IS_DELETE: 'Deletable',
                IS_EDIT: 'Editable',
                SHOW_PASSWORD: 'Display Password',
                VALIDATE: 'Validation',
                REQUIRED: 'Required',
                PATTERN_PLACEHOLDER: 'Fill in the regular expressions',
                NEW_OPTION: 'New Option',
                TAB: 'Tab',
                VALIDATOR_REQUIRED: 'Required',
                VALIDATOR_TYPE: 'Invaild format',
                VALIDATOR_PATTERN: 'Unmatched pattern',
                COLLAPSIBLE: 'Collapsible',
                SECTION_TITLE: 'Title',
                IS_CONDITIONED: 'Is conditioned',
                CONDITION: 'Condition',
                FONT_SIZE: 'Font size',
                IS_BOLD: 'Bold',
                HIDE_LABEL: 'Hide label',
                SHOW_TOOLTIP: 'Show tooltip',
                TOOLTIP_TEXT: 'Text tooltip',
                ROWS: 'Rows',
                MAX_ROWS: 'Max rows',
                PLAINTEXT: 'Read only'
            }
        },
        UPLOAD: {
            PREVIEW: 'preview',
            EDIT: 'replace',
            DELETE: 'delete'
        }
    },
    APPLICATION_TYPE: {
        LIST: {
            TITLE: 'Tipologii de cereri',
        },
        ADD_BUTTON: 'Tipologie nouă',
        FIELDS: {
            NAME: 'Denumire',
            STATUS: 'Status',
            DESCRIPTION: 'Descriere',
            FORM_CITIZEN: 'Interfață formular cetățean',
            FORM_STAFF: 'Interfață formular funcționar',
            REJECT_FORM_STAFF: 'Interfață formular respingere funcționar',
            DOCUMENT_TEMPLATE: 'Formular tipizat funcționar',
            REJECT_DOCUMENT_TEMPLATE: 'Formular tipizat respingere funcționar',
            APPLICATION_DOCUMENT_TEMPLATE: 'Formular tipizat cetățean',
            DOCUMENT_SERIES_TYPE: 'Tip serie document funcționar',
            APPLICATION_DOCUMENT_SERIES_TYPE: 'Tip serie document cetățean',
            TAX_CATEGORY: 'Categorie de taxare',
            APPLICATION_TYPE_REF: 'Documente referință',
            ARCHIVE_DOCUMENT_TYPE_CODE: 'Tip document arhivă',
            WORKFLOW: 'Proces',
            TENANT_ID: 'Organizație',
            IS_AUTO: 'Tipologie automată',
            REMAINING_PERIOD: 'Timp rămas'
        },
        FORM: {
            EDIT_TITLE: 'Editează tip cerere',
            ADD_TITLE: 'Tip cerere nouă',
        },
        ENUMS: {
            STATUS: {
                ACTIVE: 'Activ',
                INACTIVE: 'Inactiv'
            }
        },
        PLACEHOLDERS: {
            NAME: 'Caută după denumire',
            STATUS: 'Status',
            NO_REF_DOC: 'Nici un document de referință',
            APPLY_DISCOUNT: 'Aplică discount pentru UAT rural'
        },
        MESSAGES: {
            IS_AUTO: 'O tipologie automată nu are documente de referință'
        }
    },
    DOCUMENT_TEMPLATE: {
        LIST: {
            TITLE: 'Formulare tipizate',
            ADD_BUTTON: 'Formular nou',
            TABLE_HEADER: 'Modele formulare',
        },
        FIELDS: {
            NAME: 'Denumire',
            DESCRIPTION: 'Descriere',
            STATUS: 'Stare',
            CREATED_DATE: 'Data inserării',
        },
        PLACEHOLDERS: {
            STATUS: 'Stare',
            NAME: 'Nume',
        },
        ENUMS: {
            STATUS: {
                ACTIVE: 'Activ',
                INACTIVE: 'Inactiv',
            },
        },
        CREATE_MODAL: {
            TITLE: 'Salvare formular tipizat'
        },
        EDIT_MODAL: {
            TITLE: 'Editare formular tipizat'
        },
        FORM: {
            TITLE: 'Template formular',
            DOWNLOAD_BUTTON: 'Descarcă',
            FIELDS_LIST_TITLE: 'Inserează câmpuri',
            APPLICATION_TYPES_LABEL: 'Selectează tipul de cerere',
            RELATED_APPLICATION_TYPES_LABEL: 'Selectează tipul de cerere asociat',
            FORM_TYPE_LABEL: 'Selectează tipul de formular',
            CITIZEN_LABEL: 'Cetățean',
            STAFF_LABEL: 'Funcționar'
        }
    },
    PROCESS_EDITOR:{
        HEADER: 'Management procese',
        NEW_PROCESS_BTN: 'Proces nou',
        GENERAL: {
            HEADER: 'Editor de proces',
            HEADER_LIST: 'Listă Procese',
            TITLE: 'Proces nou',
            SAVE_BTN: 'Salvează',
            EDIT_BTN: 'Modifică',
            FIELDS: {
                NAME: 'Denumire',
                DESCRIPTION: 'Descriere',
                STATUS: 'Status'
            },
            PLACEHOLDERS: {
                NAME: 'Caută după denumire',
                DESCRIPTION: 'Descriere'
            },
        },
        TABS: {
            GENERAL: 'General',
            STEPS: 'Etape'
        },
        STEPS: {
            HEADER: 'Editor de proces',
            HEADER_LIST: 'Etape create',
            EDIT_MODAL_HEADER: 'Modifică etapă',
            ADD_MODAL_HEADER: 'Adaugă etapă',
            ADD_BTN: 'Adaugă etapă',
            SAVE_LIST_BTN: 'Salvează etape',
            PUBLISH_BTN: 'Publică',
            PUBLISH_MSG: 'Etapa de start nu este prima in listă! Lista nu se poate publica.',
            PUBLISH_CONFIRM_MSG: 'Sunteți sigur ca vreți să publicați acest proces? El va fi disponibil pentru a putea fi folosit',
            FIELDS: {
                NAME: 'Nume Etapă',
                ACTION_NAME: 'Acțiune',
                ACTION_TYPE: 'Tip acțiune',
                NEXT_STEP: 'Etapa următoare',
                CONDITIONAL_ROUTING: 'Rutare condiționată',
                FILE_STATUS: 'Status fisier',
                FILE_TYPE: 'Tip fisier',
                FUNCTION_TYPE: 'Funcție',
                SIGN_BY_ASSIGNED_USER: 'Semnare de către userul asignat',
                APPLICATION_STATUS: 'Status cerere',
                RETURN_ACTION: 'Revenire la',
            },
            PLACEHOLDERS: {
                NAME: 'Nume',
                ACTION_NAME: 'Actiune',
                ACTION_TYPE: 'Tip acțiune',
                NEXT_STEP: 'Etapa următoare',
                CONDITIONAL_ROUTING: 'Rutare condiționată'
            },
            ACTIONS: {
                START: 'Start',
                END: 'Sfârșit'
            }
        },
        ENUMS: {
            STATUS: {
                YES: 'DA',
                NO: 'NU',
                DRAFT: 'Draft',
                ERROR: 'Nepublicat',
                PUBLISHED: 'Publicat',
                PENDING: 'Procesare',
            },
        },
        TABLE_HEADER: {
            TRANSITION: 'Tranziție',
            ACTIONS: 'Acțiuni'
        },
        MESSAGES: {
            ALREADY_EXISTS: 'Un proces cu această denumire există deja'
        }
    },
    PERMISSIONS: {
        HEADER: 'Permisiuni',
        FILTER: {
            TITLE: 'Listă permisiuni',
        },
        BUTTONS: {
            SET_PERMISSIONS: 'Setări Permisiuni',
        },
        FIELDS: {
            NAME: 'Nume',
            PERMISSION_NAME: 'Denumire',
            DESCRIPTION: 'Descriere',
            CATEGORY: 'Categorie',
            SCOPE: 'Scope',
            ORGANISATIONS: 'Organizație',
            SYSTEM_DEFAULT: 'Asignare Default',
            DEFAULT_ASSIGNED_TO: 'Utilizator Default',
            APPLY_DEFAULT_TO_ORGANISATION: 'Organizație Default',
        },
        PLACEHOLDERS: {
            NAME: 'Nume',
            PERMISSION_NAME: 'Denumire',
            CATEGORY: 'Categorie',
        },
        FORM: {
            TITLE: 'Setează permisiuni',
            PERMISSIONS: 'Permisiuni'
        },
    },
    REAL_ESTATE: {
        FORM: {
            HEADER: 'Adaugă amplasament',
            ADD_BTN: 'Adaugă amplasament nou',
            ADD_FILE_LABEL: 'Adaugă plan cadastral (pdf/zip)',
            ADD_FILE_BTN: 'Alege fișiere',
            DUPLICATE_CADASTRAL_NUMBER: 'Acest număr cadastral este deja adăugat'
        },
        VIEW: {
            ADDRESS: 'Adresă',
            CONTACT: 'Contact',
            CADASTRAL_PLAN: 'Plan cadastral',
            NO_FILES: 'Nu există fișiere',
            FILES_LABEL: 'Fișiere de identificare',
        },
        SINGLE: 'Total {total} amplasament',
        MULTIPLE: 'Total {total} amplasamente',
        LIST_HEADER: 'Lista amplasamentelor',
        MODAL: {
            ADD_HEADER: 'Adaugă un amplasament',
            SUMMARY_HEADER: 'Lista completă a imobilelor',
        },
        CARD: {
            CADASTRAL_NUMBER: 'Nr. cadastral',
            UAT: 'UAT',
            LAND_AREA: 'Suprafață',
            ADDRESS_COUNTY: 'Județul {county}',
            CATEGORY: 'Cod categorie',
            MISSING_COORDINATES_MESSAGE: 'Lipsă coordonate ANCPI'
        }
    },
    METADATA_MAPPING: {
        FIELDS: {
            METADATA: 'Metadata GU',
            ARCHIVE_METADATA_NAME: 'Metadata Arhivă Nume',
            ARCHIVE_METADATA_LABEL: 'Metadata Arhivă Etichetă',
        },
        PAGE_TITLE: 'Mapare Metadate Arhiva cu Metadate GU',
        TABLE_TITLE: 'Lista mapărilor existente',
        FORM: {
            ARCHIVE: {
                DOC_TYPE:'Selectează Document',
                METADATA: 'Selectează Metadata - Arhivă'
            },
            GU: {
                APP_TYPE: 'Selectează tip cerere',
                METADATA: 'Selectează Metadata - GU'
            }
        },
        MANDATORY_NOTE: 'Opțiunile cu prefixul (M) sunt mandatory (obligatorii).',
        CONFIRMATION_MESSAGE : 'Metadata arhiva <strong>{label}</strong> este asociat la metadata GU <strong>{metadata}</strong>. <hr> Doresti suprascrierea cu <strong>{text}</strong>?'
    },
    ATLAS: {
        MENU: {
            LAYER_MANAGER: 'Atlas',
            MY_APPLICATIONS: 'Cererile mele',
            INTEREST_ZONES: 'Zone de interes',
            DISTANCE: 'Distanță',
            ARIA: 'Arie',
            SEARCH: 'Căutare',
            HELP: 'Ajutor'
        },
        LAYER_MANAGER: {
            TITLE: 'Layer manager',
            SEARCH_LAYER: 'Caută un layer',
            MORE_ACTION: {
                SHARE: 'Distribuie',
                EDIT: 'Editează',
                EXPORT: 'Exportă',
                DELETE: 'Șterge',
                PUBLISH: 'Publică',
            },
            ATLAS_LABEL: 'Atlas',
            PRIVATE_LABEL: 'Layere organizație',
            IMPORT_LAYER_LINK: 'importă un layer',
            EMPTY_LAYER_TEXT: 'Nu există layere',
            UPLOADING: 'Uploading...',
            UPLOAD_DONE: 'Done!',
            UPLOAD_ERROR: 'Error!',
            UPLOAD_MORE: 'Upload more ?',
            UPLOAD_TRY: 'Try again!',
            DELETE_MODAL_TITLE: 'Ești sigur că vrei să ștergi acest layer?',
            DELETE_MODAL_SUBTITLE: 'Vei renunța la toate datele de pe sublayere.',
            UPLOAD_LAYER: {
                TITLE: 'Încarcă acum un layer nou (.ZIP)',
            },
            UPDATE_CONTAINER_TITLE: 'Modificare container',
            UPDATE_LAYER_TITLE: 'Modifică Layer',
            ADD_LAYER_TITLE: 'Adăugare Layer',
        },
        APPLICATIONS_MANAGER: {
            TITLE: 'Cererile mele',
        },
        INTEREST_AREAS_MANAGER: {
            TITLE: 'Zone de interes'
        },
        INTERSECTION_MANAGER: {
            TITLE: 'Locație marcată',
            INFO_LAYER_TEXT: 'Informații',
            DETAILS_TEXT: 'Detalii',
            SIRUTA_LABEL: 'SIRUTA',
            COUNTY_NAME_LABEL: 'Denumire județ',
            COUNTY_INDICATIV_LABEL: 'Indicativ județ',
            UAT_LABEL: 'Denumire UAT',
            APPROVAL_YEAR_LABEL: 'An aprobare PUG',
            YEAR_OF_EXTENSION_LABEL: 'An prelungire PUG',
            NO_YEAR_OF_EXTENSION: 'Nu este cazul',
            URBANISM_REGULATION_LABEL: 'Reglementari urbanistice'
        },
        SUBLAYER_MANAGER: {
            SUBLAYER_NAME: 'Denumire sublayer',
            SUBLAYER_ACTIONS: 'Acțiuni',
            EMPTY_SUBLAYERS_TEXT: 'Nu există sublayere',
        },
        CREATE_LAYER_MODAL: {
            HEADER: 'Adaugă layer nou',
            ERROR: 'Container cu scop RU selectat deja există!'
        },
        PUBLISH_LAYER_MODAL: {
            HEADER: 'Publicare layer',
        },
        COMPARE_LAYER_MODAL: {
            HEADER: 'Comparare Layere',
            TEMPLATE_LAYER: 'Destinație layer(template)',
            SOURCE_LAYER: 'Sursa: {layer}',
            FORCE_PUBLISH: 'Forțează publicarea',
            STATUS: {
                READY: 'Layerul este pregătit de publicare.',
                NOT_READY: 'Layerul nu poate fi publicat, structura lui nu respectă structura layer-ului șablon.'
            }
        },
        FIELDS: {
            NAME: 'Denumire layer',
            CONTAINER_NAME: 'Denumire container',
            ACCESS_LEVEL: 'Nivel acces',
            OWNERSHIP_TYPE: 'Tip actualizare',
            ORGANIZATION: 'Organizație',
            LAYER_TYPE: 'Tip layer',
            CONTAINER_LAYER: 'Alege container din Atlas',
            RU_SCOPE: 'Scop RU',
            UAT: 'UAT',
            USE_AS_TEMPLATE: 'Adaugă structură pentru toate UAT-urile',
            DECISION: 'HCL(prin care a fost promulgat)',
            HAS_DECISION: 'Nu se aplică',
            LAYER: 'Alege layer din Atlas',
            OWNER: 'Owner',
            PUBLISH_NAME: 'Nume',
            TEMPLATE_NAME: 'Nume',
            GEOMETRY_TYPE: 'Tip geometrie',
            STATUS: 'Status'
        },
        ENUMS: {
            LAYER_TYPE: {
                CONTAINER: 'Container',
                SIMPLE: 'Simplu',
            },
            ACCESS_LEVEL: {
                PRIVATE: 'Privat',
                PUBLIC: 'Public',
                PROTECTED: 'Protected',
            },
            OWNERSHIP_TYPE: {
                CENTRALIZED: 'Centralizat',
                DISTRIBUTED: 'Distribuit'
            },
            RU_SCOPE: {
                PUG: 'PUG',
                PUZ: 'PUZ',
                PUD: 'PUD',
                OTHER: 'Altul',
            },
            STATUS: {
                OK: 'Ok',
                MISSING: 'Lipsește',
                NEW: 'Nou'
            }
        },
        EDIT_MODAL: {
            LAYER_NAME: 'Denumire',
            SUBLAYER_NAME: 'Zone urbanistice',
            ACCESS_LEVEL: 'Nivel acces',
            OWNER: 'Organizație',
            RU_SCOPE: 'Scop RU',
            UAT: 'UAT',
            TYPE: 'Tip',
            STATUS: 'Status',
            UPLOAD_BROWSE_TXT: 'Alege fișier',
            UPLOAD_BROWSE_PLACEHOLDER: 'Alege un fișier sau lasă-l aici...',
            DUPLICATE_LAYER_NAME: 'Numele layerului deja există!',
        },
        BUTTONS:{
            ADD_SIMPLE_LAYER: 'Adaugă layer simplu',
            CLOSE_SIMPLE_LAYER: 'Închide adăugare layer simplu',
            UPDATE_CONTAINER: 'Modifică container'
        },
        PUBLISH_STATUS: {
            PUBLISHED: 'Publicat',
            NOT_PUBLISHED: 'Nepublicat',
            NEW: 'Nou',
            PENDING: 'În procesare',
            ERROR: 'Eroare',
            COMPLETED: 'Publicat',
            MESSAGE_SUCCESS: 'Layerul a fost publicat cu succes în Atlas',
            MESSAGE_FAILED: 'Layerul nu a putut fi publicat în Atlas. Vă rugăm reîncercați',
            MESSAGE_CONFIRMATION: 'Publicarea durează câteva minute. Te rugăm să aștepti și să nu închizi pagina.'
        }
    },
    DECISION: {
        PAGE_HEADER: 'Hotărâri',
        LIST: {
            TITLE: 'Listă hotărâri',
            EDIT: 'Editează',
            DELETE: 'Șterge',
            ADD_EXTENSION: 'Adaugă prelungire',
        },
        FIELDS: {
            NAME: 'Denumire',
            DESCRIPTION: 'Label',
            NUMBER: 'Număr',
            ENTRY_DATE: 'Data emitere',
            EXPIRATION_DATE: 'Data expirare',
            TARGET_ID: 'Locație',
            PURPOSE_ID: 'Scop',
            COUNTY: 'Județ',
            DECISION_ID: 'Hotărâre',
            KEY: 'Cheie',
        },
        PLACEHOLDERS: {
            NAME: 'Denumire',
            TARGET_ID: 'Caută după locație',
            PURPOSE_ID: 'Caută după scop',
        },
        ADD_EDIT_FORM: {
            HEADER_ADD: 'Adaugă hotărâre',
            HEADER_EDIT:'Editează hotărâre',
        },
        SUCCESS: {
            ADD: 'Hotărârea a fost adaugată cu succes.',
            UPDATE: 'Hotărârea a fost modificată cu succes.',
        },
        BUTTON: {
            ADD_DECISION: 'Adaugă hotărâre',
        },
        TOTAL_TEXT: 'Total 0 hotărâri| Total 1 hotărâre | Total {total} hotărâri',
        CARD: {
            EXTENSION_LABEL: 'Prelungită cu',
            DECISION_LABEL: 'Aprobat prin',
            PURPOSE_LABEL: 'Faza',
        }
    },
    DECISION_TARGET: {
        LIST: {
            TITLE: 'Listă locații',
        },
        ADD_BUTTON: 'Adaugă locație',
        FIELDS: {
            NAME: 'Denumire',
        },
        FORM: {
            EDIT_TITLE: 'Editează locație',
            ADD_TITLE: 'Locație nouă',
        },
        PLACEHOLDERS: {
            NAME: 'Caută după denumire',
        },
    },
    DECISION_PURPOSE: {
        LIST: {
            TITLE: 'Listă scopuri',
        },
        ADD_BUTTON: 'Adaugă scop',
        FIELDS: {
            NAME: 'Denumire',
        },
        FORM: {
            EDIT_TITLE: 'Editează scop',
            ADD_TITLE: 'Scop nou',
        },
        PLACEHOLDERS: {
            NAME: 'Caută după denumire',
        },
    },
    DOCUMENT_SERIES_TYPE: {
        TITLE: 'Tipologii de serie',
        LIST: {
            TITLE: 'Listă tipologii de serie',
        },
        ADD_BUTTON: 'Tipologie nouă',
        FIELDS: {
            NAME: 'Denumire',
        },
        FORM: {
            EDIT_TITLE: 'Editează tipologie de serie',
            ADD_TITLE: 'Tipologie de serie nouă',
        },
        PLACEHOLDERS: {
            NAME: 'Caută după denumire',
        },
    },
    PAYMENT_REGISTRY: {
        TITLE: 'Plăți',
        LIST: {
            TITLE: 'Istoric plăți',
        },
        CALCULATE_TAX: 'Calculează preț',
        ADD_PAYMENT_ORDER: 'Dispoziție plată',
        FIELDS: {
            NAME: 'Denumire',
        },
        PLACEHOLDERS: {
            NAME: 'Caută după denumire',
        },
    },
    TAX_CALCULATOR: {
        TITLE: 'Calculator preț',
        FORM_SECTION_TITLE: 'Taxă eliberare act',
        SUMMARY_SECTION_TITLE: 'Sumar comandă',
        FIELDS: {
            APPLICATION_TYPE: 'Tipul actului',
            EXEMPTION: 'Scutiri taxe conform Cod Fiscal',
            LAND_AREA: 'Suprafața terenului',
            TERRITORIAL_ADMINISTRATIVE_UNIT: 'UAT',
        },
        CALCULATE_BUTTON: 'Calculează',
        SUMMARY: {
            OTHER_TAXES_LABEL: 'Alte taxe',
            EXEMPTION_LABEL: 'Scutiri',
            VAT_LABEL: 'TVA',
        },
        TOTAL: 'Total de plată',
        DEFAULT_TAX_NAME: 'Act',
    },
    ISSUED_DOCUMENT: {
        TITLE: 'Acte emise',
        LIST: {
            TITLE: 'Emiterile mele',
        },
        FIELDS: {
            DOCUMENT_SERIES: 'Nr.ieșire',
            DOCUMENT_NAME: 'Tip act',
            ISSUED_DATE: 'Data emiterii',
            BENEFICIARY_USER: 'Beneficiar',
            ISSUED_YEAR: 'Anul',
            ISSUER_USER_FULL_NAME: 'Emis de',
        },
        PLACEHOLDERS: {
            NAME: 'Caută după denumire',
            DOCUMENT_NAME: 'Tip act',
            ISSUED_YEAR: 'Anul',
            BENEFICIARY_USER: 'Caută după nume',
            DOCUMENT_SERIES: 'Caută după număr',
            ISSUER_USER_FULL_NAME: 'Emis de',
        },
    },
    CONTROL: {
        PAGE_HEADER: 'Registru control',
        LIST: {
            TITLE: 'Controale',
        },
        BUTTON: {
            ADD_CONTROL: 'Control nou',
            ADD_COMPLAIN: 'Adaugă sesizare',
            ADD_LOCATION: 'Adaugă locație',
            NEXT_STEP: 'Pasul următor',
            DELETE_LOCATION: 'Șterge locație'
        },
        FIELDS: {
            CREATED_DATE: 'Data',
            CONTROL_ID: 'Cod Intrare',
            COMPLAIN_REF: 'Ref Sesizare',
            CREATED_BY: 'Creat de',
            STATUS: 'Stare',
            COMPLAIN_NUMBER: 'Nr. sesizare',
            TERRITORIAL_ADMINISTRATIVE_UNIT_ID: 'UAT',
            ADDRESS: 'Adresă'
        },
        PLACEHOLDERS: {
            CONTROL_ID: 'Cod Intrare',
            COMPLAIN_REF: 'Ref Sesizare',
            CREATED_BY: 'Creat de',
            STATUS: 'Stare',
        },
        ENUMS: {
            STATUS: {
                NEW: 'Inregistrată',
                PENDING: 'In procesare',
                RESOLVED: 'Soluţionată',
                DISMISSED: 'Clasată',
            },
        },
        LABEL: {
            CONTROL_ID: 'Numar registru',
        },
        FORM: {
            ADD_TITLE: 'Adaugă control',
            EDIT_TITLE: 'Modifică control',
            NEW_CONTROL: 'Control nou',
        },
        PREPARATION:{
            HEADER: 'Detalii sesizare',
            COMPLAIN_LIST_TAGS: 'Listă cod intrare sesizări',
            WARNING: 'Pentru a adăuga un control adaugă o locaţie sau selectează din lista de sesizări !',
            LIST: 'Listă locaţii',
            PREVIEW_MAP: 'Previzualizare din atlas teritorial',
            FORM: {
                LOCATION: 'Locaţie',
                COMPLAIN_DESCRIPTION: 'Conform sesizare',
                MODAL_HEADER: 'Date locaţie sesizare'
            }
        }

    },
    DASHBOARD: {
        APPLICATIONS_STATUS: {
            HEADER: 'Status Cereri',
            PERIOD: 'Ultimele 30 zile'
        },
        TOP_USERS: {
            HEADER: 'Top utilizatori'
        },
        REGISTRED_APPLICATIONS: {
            HEADER: 'Cereri înregistrate',
            DESCRIPTION: 'Raport cereri depuse vs soluționate',
            PERIOD: 'Ultimele 12 luni'
        },
        SOLVED_APPLICATIONS: {
            HEADER: 'Cereri soluționate',
            DESCRIPTION: 'Procentaj soluționări din totalul depunerilor',
            APPLICATIONS_TYPE_HEADER_LEGAL: 'Top tipuri de cereri',
            APPLICATIONS_TYPE_HEADER_STAFF: 'Cereri pe UAT',
        },
        RECENT_APPLICATIONS: {
            HEADER: 'Cereri recente',
            BUTTON: {
                VIEW_ALL_APPLICATIONS: 'Vezi toate cererile',
            },
        },
        RECENT_ISSUED_DOC: {
            HEADER: 'Acte emise recent',
            BUTTON: {
                VIEW_ALL_ISSUED_DOC: 'Vezi toate emiterile',
            },
        },
        PERFORMANCE: {
            HEADER: 'Performanţă',
            ABOVE_AVG: 'Peste medie',
            NORMAL: 'Normal',
            BELLOW_AVG: 'Sub medie',
            RESOLUTION_AVG: 'Media',
            OBJECTIVE_HEADER: 'Obiectiv termen de soluţionare',
        },
        TOTALS: {
            NEW: 'Total noi',
            SOLVED: 'Total soluţionate',
            IN_OPERATION: 'Total în operare',
            PERCENT: 'Procent total',
            TOP_USERS: 'Top funcţionari repartizaţi'
        },
        TYPES: {
            STATS: 'Statistică',
            NEW: 'Noi',
            SOLVED: 'Soluţionate',
            IN_OPERATION: 'În operare',
            TOTAL_LABEL: 'Total',
            DAYS: 'Zile',
            SOLUTION_TERM: 'Termen soluţionare',
            APPLICATIONS_PER_UATS: 'Cereri pe Unitatea Administrativ Teritorială'
        }
    },
    URBANISM_REGULATION_ZONES: {
        LIST: {
            TITLE: 'Reglementări urbanistice',
        },
        ADD_BUTTON: 'Reglementare nouă',
        FIELDS: {
            NAME: 'Denumire',
            KEY: 'Cheie',
            TYPE: 'Scop RU',
            TENANT: 'UAT',
        },
        FORM: {
            EDIT_TITLE: 'Editează reglementare',
            ADD_TITLE: 'Reglementare nouă',
        },
        ENUMS: {
            TYPE: {
                PUG: 'PUG',
                PUZ: 'PUZ',
                PUD: 'PUD'
            }
        },
        PLACEHOLDERS: {
            NAME: 'Caută după denumire',
            KEY: 'Caută după cheie',
            TENANT: 'UAT',
            TYPE: 'Scop RU',
        },
    },
    URBANISM_REGULATIONS: {
        TITLE: 'Editare categorii RLU pentru {0}',
        FIELDS: {
            CONTENT: 'Conţinut'
        },
        ITEM_DETAILS: 'Detalii',
        EMPTY_ITEMS_LABEL: 'Nu există categorii atașate',
        EDIT_ITEM_FORM: 'Editare {0} {1}'
    },
    URBANISM_REGULATIONS_PICKER: {
        SECTION_TITLE: 'Precompletare manuală a reglementărilor urbanistice',
        TITLE: 'Precompletare manuală',
        COMPLETE_BUTTON: 'Selectează reglementările',
        NO_DATA_FOUND: 'Nu există reglementări urbanistice de afișat',
        PREVIEW_HEADER: 'Previzualizare',
        SELECT_HEADER: 'Selectează zonă',
        SELECT_PLACEHOLDER: 'Caută după denumire',
        ENTRY_NOT_DEFINED: 'Nu este definit',
        PREVIEW_HELP_TEXT: 'Selectează o zonă din lista alăturată pentru a previzualiza conținutul',
        COULD_NOT_SUBMIT_MESSAGE: 'A apărut o eroare, verificați dacă reglementările urbanistice sunt configurate corect.',
        FORCE_UPDATE_LABEL: 'Suprascrie datele',
        HELP_TEXT_COMPLETED: 'Documentul a fost pre-completat pe baza configurărilor din Atlasul Teritorial ',
        HELP_TEXT_NOT_COMPLETED: 'Documentul nu a putut fi pre-completat automat deoarece nu s-au găsit inţormatii în Atlasul Teritorial',
        HELP_TEXT_FORCE_UPDATE: 'Toate câmpurile vor fi suprascrise cu valori noi',
        HELP_TEXT_NOT_FORCE_UPDATE: 'Doar câmpurile goale vor fi completate cu valori noi',
    },
    DOCUMENT_SERIES_ALLOCATION: {
        LIST: {
            TITLE: 'Serii rezervate',
        },
        ADD_BUTTON: 'Rezervă serie',
        FIELDS: {
            CREATED_BY: 'Rezervat de',
            CREATED_DATE: 'Data rezervării',
            ALLOCATED_SERIES: 'Serie',
            TYPE_NAME: 'Tip serie',
            FILE: 'Document (PDF)'
        },
        FORM: {
            ADD_TITLE: 'Rezervă serie',
            VIEW_TITLE: 'Vizualizare serie rezervată'
        },
        PLACEHOLDERS: {
            CREATED_BY: 'Rezervat de',
            CREATED_DATE: 'Data rezervării',
            ALLOCATED_SERIES: 'Caută dupa serie',
            TYPE_NAME: 'Tip serie'
        },
        NOTIFIERS: {
            CREATED_SUCCESS_TITLE: 'Rezervat cu succes',
            CREATED_SUCCESS_TEXT: 'Seria cu numarul <strong>{number}</strong> a fost rezervată cu succes.',
        }
    },
    APPLICATION_ATTACHMENTS: {
        TITLE: 'Anexe',
    }
};

module.exports = { locale };
