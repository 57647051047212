import controlFormStore from '@/modules/control/store/control-form-store';
import controlListStore from '@/modules/control/store/control-list-store';

export default {
    namespaced: true,
    modules: {
        form: controlFormStore,
        list: controlListStore,
    },
};
