import { DocumentSeriesAllocationService } from '@/modules/document-series-allocation/document-series-allocation-service';
import Errors from '@/shared/error/errors';

// action types
export const DO_CREATE = 'create';
export const DO_UPLOAD = 'upload';

const state = {};

const getters = {};

const actions = {
    [DO_CREATE](context, values) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'documentSeriesAllocation/create', { root: true });
            DocumentSeriesAllocationService.create(values)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'documentSeriesAllocation/create', { root: true });
                });
        });
    },
    [DO_UPLOAD](context, payload) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'documentSeriesAllocation/upload', { root: true });
            const { uploadAccessToken, files } = payload;
            DocumentSeriesAllocationService.upload(uploadAccessToken, files)
                .then((data) => {
                    resolve(data);
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'documentSeriesAllocation/upload', { root: true });
                });
        });
    },
};

const mutations = {};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};

