<template>
    <vue-select
        v-model="selected"
        :loading="isAsyncDataLoading"
        :options="options"
        :label="textField"
        :placeholder="placeholder"
        class="st-select form-control"
        :name="fieldName"
        :class="customClass"
        :style="`width: ${customWidth}`"
        :reduce="el => el[valueField]"
        :clearable="clearable"
        :disabled="disabled"
        :searchable="searchable"
        @open="onOpen"
        @close="onClose"
        @search="(query) => (search = query)"
    >
        <template #no-options="{}">
            {{ $t('GENERAL.AUTOCOMPLETE_NO_OPTIONS') }}
        </template>
        <template #selected-option="option" v-if="asyncData">
            <span v-if="isAsyncDataLoading">{{ $t('GENERAL.SELECT_LOADING') }}</span>
            <span v-else-if="asyndDataLoadFailed"></span>
            <span v-else>{{ option[textField] }}</span>
        </template>
        <template #list-footer>
            <li v-show="hasNextPage" ref="load" class="option-loader">
                {{ $t('GENERAL.AUTOCOMPLETE_LOAD_MORE') }}
            </li>
        </template>
    </vue-select>
</template>

<script>
    import calcParams from '@/shared/utils/calc-query-params';
    import i18n from '@/shared/plugins/vue-i18n';

    export default {
        inheritAttrs: false,
        name: 'StSelect',
        props: {
            value: {
                type: String,
                default: ''
            },
            placeholder: {
                type: String,
                default: i18n.t('GENERAL.SELECT_PLACEHOLDER'),
            },
            textField: {
                type: String,
                default: 'text',
            },
            valueField: {
                type: String,
                default: 'value',
            },
            customClass: String,
            disabled: {
                type: Boolean,
                default: false,
            },
            clearable: {
                type: Boolean,
                default: false,
            },
            customWidth: {
                type: String,
                default: '100%',
            },
            field: {
                type: null,
                required: true,
            },
            model: Object,
            formValidation: Object,
            searchable: {
                type: Boolean,
                default: false,
            },
            autoSelectFirstOption: {
                type: Boolean,
                default: false,
            },
            asFilter: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                page: 1,
                limit: 30,
                observer: new IntersectionObserver(this.infiniteScroll),
                search: '',
                hasNextPage: false,
                parentEl: null,
                parentElScollPosition: 0,
                asyncData: !!this.field.asyncData,
                isAsyncDataLoading: false,
                asyndDataLoadFailed: false,
                options: [],
            }
        },
        watch: {
            options(newValue) {
                if (this.field?.autocompleteRequired) {
                    if (!this.field.enabledValidation) {
                        this.formValidation[newValue.length ? 'enableValidator' : 'disableValidator'](this.field.name);
                    }
                    this.formValidation?.resetField(this.field.name, true);
                }
                if (this.field?.hasPagination && this.field?.pagination) {
                    this.hasNextPage = this.options.length < this.field.pagination.total;
                    if (this.parentEl) {
                        this.$nextTick(() => {
                            this.parentEl.scrollTop = this.parentElScollPosition;
                        });
                    }
                }
            },
            selected(newValue, oldValue) {
                if (newValue === oldValue) return false;
                if (newValue && this.field?.autocompleteRequired && this.formValidation) {
                    this.formValidation.revalidateField(this.field.name, true);
                }
                this.$emit('change', newValue);
            },
            async page(newValue, oldValue) {
                if (newValue === oldValue) return false;
                await this.emitParamsChangedEvent();
                await this.field.updateOptions();
            },
            'field.options'(newValue) {
                this.options = newValue;
            }
        },
        computed: {
            selected: {
                get() {
                    return this.value;
                },
                set(val) {
                    this.$emit('input', val);
                    this.$emit('change', val);
                }
            },
            hasParents() {
                return !!this.field?.parentFields?.length;
            },
            fieldName() {
                return this.name || this.field?.name;
            },
            name() {
                return this.field?.name;
            },
        },
        methods: {
            addWatchers() {
                const callbackFn = this.watcherCallbackFn;
                this.field.parentFields.forEach((el) => {
                    this.$watch(`model.${el.name}`, callbackFn, { deep: true });
                }, this);

            },
            watcherCallbackFn(newValue, oldValue) {
                if (newValue === oldValue) return false;
                const { name, fetchParams, parentFields } = this.field;
                this.model[name] = '';
                if (this.asFilter) {
                    this.$emit('clearFilter', { name });
                }
                fetchParams[parentFields[0].key] = newValue;
                this.field.updateOptions();
            },
            async emitParamsChangedEvent() {
                const params = await calcParams({
                    currentPage: this.page,
                    perPage: this.limit,
                    filters: [],
                });

                this.field.fetchParams = {
                    ...this.field.fetchParams,
                    ...params,
                }
            },
            async onOpen() {
                if (this.hasNextPage) {
                    await this.$nextTick();
                    this.observer.observe(this.$refs.load);
                }
            },
            onClose() {
                this.observer.disconnect();
                this.parentEl = null;
                this.parentElScollPosition = 0;
            },
            async infiniteScroll([{ isIntersecting, target }]) {
                if (isIntersecting) {
                    this.parentEl = target.offsetParent;
                    this.parentElScollPosition = target.offsetParent.scrollTop;
                    this.page += 1;
                }
            },
        },
        created() {
            this.options = [];
            if (this.field && !this.field.asyncData) {
                this.options = this.field?.options || [];
            }
        },
        async mounted() {
            if (this.field && this.field.asyncData) {
                this.isAsyncDataLoading = true;
                this.field.options = [];
                await this.emitParamsChangedEvent();

                this.field.getOptions()
                .then((data) => {
                    this.options = data;
                    if (this.autoSelectFirstOption)  this.selected = this.options[0].value;
                })
                .catch(() => {
                    this.asyndDataLoadFailed = true;
                }).finally(() => {
                    this.isAsyncDataLoading = false;
                });
            }
            if (this.hasParents) {
                this.addWatchers();
            }
        },
        beforeDestroy() {
            if (this.field && this.field.fetchParams) {
                this.field.fetchParams = {};
            }
        },
    };
</script>
<style scoped>
    .option-loader {
        text-align: center;
        color: #bbbbbb;
    }
</style>
