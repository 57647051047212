import ApiService from '@/shared/services/api.service';
import { storeAsync } from '@/app-module';

export default {
    async beforeRouteEnter(to, from, next) {
        const store = storeAsync();
        if (to.meta.isArchive) {
            await store.dispatch('setMenu', 'archive');
            ApiService.setBaseUrl({ isArchive: true })
        } else if (to.meta.isAtlas) {
            await store.dispatch('setMenu', 'atlas');
        } else {
            await store.dispatch('setMenu', 'default');
        }
        
        next();
    },
    async beforeRouteLeave(to, from, next) {
        if (!to.meta.isArchive || !to.meta.isAtlas) {
            ApiService.setBaseUrl({ isArchive: false });
        }
        next();
    }
};
