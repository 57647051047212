import { storeAsync } from '@/app-module';

export default {
  name: 'can',
  implementation: {
    async bind(el, binding, vnode) {
      const arg = binding.arg;
      const verb = arg[0].toUpperCase() + arg.slice(1);
      const module = binding.expression.replace(/'/g, '');
      const permission = module + verb;

      const currentUser = await storeAsync().getters['auth/accountDetails'];
      const userPermissions = currentUser?.permissions ? Object.keys(currentUser.permissions) : [];

      const permissionsMap = await storeAsync().getters['shared/permissions'];
      const allowedPermissions = permissionsMap[permission]?.allowedPermissions;

      const hasPermission = allowedPermissions?.some((permission) =>
        userPermissions.includes(permission),
      );
      if (!hasPermission) {
        vnode.elm.parentElement.removeChild(vnode.elm);
      }
    }
  }
}