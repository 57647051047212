import ApiService from '@/shared/services/api.service';

export class DocumentTemplateService {
    static list(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/document-templates', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static create(data) {
        return new Promise((resolve, reject) => {
            ApiService.post( '/document-templates?echo=true', { ...data } )
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
    }

    static update(id, data) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/document-templates/${id}`, { ...data })
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static async remove(id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/document-templates/${id}`)
                .then(() => resolve())
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static async listAutocomplete() {
        const response = await ApiService.get('/document-templates');
        return response?.data?.data || [];
    }

    static async fileUpload(token, formData) {
        return new Promise((resolve, reject) => {
           ApiService.post('/files?echo=true', formData, {
               'content-type': 'multipart/form-data',
               'x-file-access-token': token,
           })
               .then((response) => resolve(response.data))
               .catch((error) => reject(error));
        });
    }

    static find(id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/document-templates/${id}`)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static downloadFile(token, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/files/${id}`, {
                'x-file-access-token': token,
                'Accept': 'text/plain',
            })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }
}
