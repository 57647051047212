import Layout from '@/modules/layout/components/layout';

const ArchiveDashboard = () => import('@/modules/archive/pages/ArchiveDashboard.vue');
const ArchiveWorkspaces = () => import('@/modules/archive/pages/ArchiveWorkspaces.vue');
const ArchiveSubfolders = () => import('@/modules/archive/pages/ArchiveSubfolders.vue');
const ArchiveDocuments = () => import('@/modules/archive/pages/ArchiveDocuments.vue');
const ArchiveDocument = () => import('@/modules/archive/pages/ArchiveDocument.vue');
import Permissions from '@/security/permissions';

export default [
    {
        path: '',
        component: Layout,
        meta: { auth: true },
        children: [
            {
                name: 'archive-dashboard',
                path: '/archive-dashboard',
                component: ArchiveDashboard,
                meta: {
                    auth: true,
                    isArchive: true,
                    permission: Permissions.values.archiveRead,
                }
            },
            {
                name: 'archive-workspaces',
                path: '/archive-workspaces',
                component: ArchiveWorkspaces,
                meta: {
                    auth: true,
                    isArchive: true,
                    permission: Permissions.values.archiveRead,
                }
            },
            {
                name: 'subfolder',
                path: '/subfolder/:id',
                component: ArchiveSubfolders,
                meta: {
                    auth: true,
                    isArchive: true,
                    permission: Permissions.values.archiveRead,
                },
                props: true,
            },
            {
                name: 'archive-documents',
                path: '/archive-documents/:id',
                component: ArchiveDocuments,
                meta: {
                    auth: true,
                    isArchive: true,
                    permission: Permissions.values.archiveRead,
                },
                props: true,
            },
            {
                name: 'archive-document',
                path: '/archive-document/:id',
                component: ArchiveDocument,
                meta: {
                    auth: true,
                    isArchive: true,
                    permission: Permissions.values.archiveRead,
                },
                props: true,
            },
        ],
    },
];
