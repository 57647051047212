export const CONTROLS = {
    data: [
       {
            id: '1',
            control_id: 'XC-004356',
            created_date: '2021-05-18T10:30:06.168Z',
            complain_ref: 'S-1142342;S-3423423',
            created_by: 'Dana Pop',
            status: 'new'
        },
        {
            id: '2',
            control_id: 'XC-004356',
            created_date: '2021-05-18T10:30:06.168Z',
            complain_ref: 'S-1142342;S-3423423',
            created_by: 'Ana Pop',
            status: 'pending'
        },
        {
            id: '3',
            control_id: 'XC-004356',
            created_date: '2021-05-18T10:30:06.168Z',
            complain_ref: 'S-1142342;S-3423423',
            created_by: 'Ion Pop',
            status: 'resolved'
        },
        {
            id: '4',
            control_id: 'XC-004356',
            created_date: '2021-05-18T10:30:06.168Z',
            complain_ref: 'S-1142342;S-3423423',
            created_by: 'John Doe',
            status: 'dismissed'
        },
    ],
    pagination: {
        count: 1,
        first: '/workspaces?skip=0&limit=10&sort=&envelope=true',
        last: '/workspaces?skip=-9&limit=10&sort=&envelope=true',
        limit: 10,
        next: '/workspaces?skip=10&limit=10&sort=&envelope=true',
        prev: '/workspaces?skip=0&limit=10&sort=&envelope=true',
        self: '/workspaces?skip=0&limit=10&sort=&envelope=true',
        skip: 0,
        template: '/workspaces?skip={skip}&limit={limit}&sort={sortString}&envelope={envelope}&pretty={pretty}&fields={fields}',
        total: 3
    }
}
