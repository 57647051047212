import ApiService from '@/shared/services/api.service';

export class MetadataMappingService {
    static getAllDocumentsTypes() {
        ApiService.setBaseUrl({isArchive: true})
        return new Promise((resolve, reject) => {
            ApiService.post('/DocumentTypeGxtService/getAllDocumentTypesForDisplay')
                .then((response) => resolve(response))
                .catch((error) => reject(error))
                .finally(() => {
                    ApiService.setBaseUrl({isArchive: false})
                })
        });
    }
    static getDocumentTypeById(id) {
        ApiService.setBaseUrl({isArchive: true})
        return new Promise((resolve, reject) => {
            ApiService.post(`/DocumentTypeGxtService/getDocumentTypeById/${id}`)
                .then((response) => resolve(response))
                .catch((error) => reject(error))
                .finally(() => {
                    ApiService.setBaseUrl({isArchive: false})
                })

        });
    }

    static getApplicationsTypes() {
        return new Promise((resolve, reject) => {
            ApiService.get('application-types')
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static getApplicationMetadata(params) {
        return new Promise((resolve, reject) => {
            ApiService.query(`/application-to-archive-metadata`, params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }
    static getApplicationMetadataStatic() {
        return new Promise((resolve, reject) => {
            ApiService.get(`/application-to-archive-metadata/static`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static updateMapping(params) {
        return new Promise((resolve, reject) => {
            ApiService.post('/application-to-archive-metadata', params)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static async removeMappedMetadata(id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/application-to-archive-metadata/${id}`)
                .then(() => resolve())
                .catch((error) => reject(error));
        });
    }

    static updateMappedMetadata({id, payload}) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/application-to-archive-metadata/${id}`, payload)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

}
