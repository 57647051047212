import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';

const DocumentSeriesTypeList = () => import('@/modules/document-series-type/pages/DocumentSeriesTypeList.vue');

export default [
    {
        path: '',
        component: Layout,
        meta: { auth: true },
        children: [
            {
                name: 'documentSeriesType',
                path: '/document-series-type',
                component: DocumentSeriesTypeList,
                meta: {
                    auth: true,
                    permission: Permissions.values.documentSeriesTypeRead,
                }
            },
        ],
    },
];
