<template>
    <div class="pb-lg-0 mb-5">
        <st-button
            id="st_login_google"
            :block="true"
            variant="danger"
            :callback="loginWithGoogle"
            customClass="font-size-h4"
        >
            <b-icon icon="google" class="p-1"></b-icon>
            {{ buttonText }}
        </st-button>
    </div>
</template>
<script>
    import { mapActions } from 'vuex';
    import { AUTH_GOOGLE, GET_GOOGLE_LOGIN_URL, GET_PROFILE } from '../auth-store';

    export default {
        name: 'GoogleLoginButton',
        props: {
            formType: {
                type: String,
                required: true
            }
        },
        computed: {
            buttonText() {
                return this.formType === 'register'
                    ? this.$t('AUTH.REGISTER.GOOGLE_BUTTON')
                    : this.$t('AUTH.LOGIN.GOOGLE_BUTTON');
            }
        },
        methods: {
            ...mapActions({
               authGoogle: `auth/${AUTH_GOOGLE}`,
               getGoogleUrl: `auth/${GET_GOOGLE_LOGIN_URL}`,
               getProfile: `auth/${GET_PROFILE}`
            }),
            loginWithGoogle() {
                this.getGoogleUrl().then((url) => {
                    document.location.href = url;
                });
                // TODO: Remove
                // this.$gAuth
                //     .getAuthCode()
                //     .then((authCode) => {
                //         this.authGoogle(authCode).then((data) => {
                //             const { isPhoneConfirmed } = data;
                //             if (!isPhoneConfirmed) {
                //                 this.$router.push({ name: 'verifySMS' });
                //             } else {
                //                 this.getProfile().then(() => {
                //                     this.$router.push({ name: 'home' });
                //                 });
                //             }
                //         });
                //     })
                //     .catch((error) => {
                //         //on fail do something
                //     });
                // auth2.grantOfflineAccess().then(this.signInCallback);
            },
            signInCallback(authResult) {
                console.log(authResult['code']);
                this.authGoogle(authResult['code']).then((data) => {
                    const { isPhoneConfirmed } = data;
                    if (!isPhoneConfirmed) {
                        this.$router.push({ name: 'verifySMS' });
                    } else {
                        this.getProfile().then(() => {
                            this.$router.push({ name: 'home' });
                        });
                    }
                });
            },
        }
    };
</script>
