export default function phoneNumber() {
    return {
        validate: function(input) {
            const { value } = input;

            const re = /^(\+4|)?(07[0-8]{1}[0-9]{1}|02[0-9]{2}|03[0-9]{2}){1}?(\s|\.|\-)?([0-9]{3}(\s|\.|\-|)){2}$/;
            const valid = re.test(value);

            return {
                valid,
            }
        }
    }
}