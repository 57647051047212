import ApiService from '@/shared/services/api.service';

export class AncpiService {
    static searchParcel(data) {
        return new Promise((resolve, reject) => {
            ApiService.post( '/ancpi/parcels-search-requests/', { ...data } )
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => reject(error));
        });
    }
}