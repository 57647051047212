import { ArchiveService } from '@/modules/archive/archive-service';
import Errors from '@/shared/error/errors';

export const GET_DOCUMENTS = 'getDocuments';

// mutation types
export const FETCH_SUCCESS = 'fetchSuccess';
export const SET_CURRENT_PAGE = 'setCurrentPage';
export const SET_FILTERS = 'setFilters';

const state = {
    rows: [],
    total: 0,
    storeFilters: {},
    currentPage: 1,
};

const getters = {
    rows: (state) => state.rows,
    total: (state) => state.total,
    filters: (state) => state.storeFilters,
    currentPage: (state) => state.currentPage,
};
const actions = {
    [GET_DOCUMENTS](context, {payload, workspaceId, folderId}) {
        context.commit('shared/activateLoading', 'archive/getDocuments', { root: true });
        const filters = { params:
            {
                folderId,
                workspaceRealName: workspaceId,
                ...payload
            } };

        return new Promise((resolve, reject) => {
            ArchiveService.listDocuments(filters)
            .then((data) => {
                context.commit(FETCH_SUCCESS, {
                    rows: data.items,
                    total: data.totalItems,
                });
                resolve();
            })
            .catch(( error ) => {
                Errors.handle(error);
                reject();
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'archive/getDocuments', { root: true });
            });
        });
    },
}
const mutations = {
    [FETCH_SUCCESS](state, payload) {
        state.rows = payload.rows;
        state.total = payload.total;
    },
    [SET_CURRENT_PAGE](state, page) {
        state.currentPage = page;
    },
    [SET_FILTERS](state, { key, value }) {
        state.storeFilters[key] = value;
    },
};
export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
