<template>
    <!-- begin::Page loader -->
    <div class="page-loader page-loader-logo">
        <div v-if="text" class="bg-light">
            <p class="text-primary font-size-h5">{{text}}</p>
        </div>
        <img alt="Logo" :src="logoImage" width="100" />
        <div class="spinner" v-bind:class="spinnerClass || 'spinner-primary'"></div>
    </div>
    <!-- end::Page Loader -->
</template>

<script>
import { ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME } from '@/modules/layout/layout-store.js';
export default {
    name: 'Loader',
    props: {
        logo : String,
        spinnerClass: String,
        text: {
            type: String,
            default: ''
        }
    },
    computed: {
        logoImage() {
            if (this.logo) return this.logo
            return `${process.env.BASE_URL}media/logos/logo-GU-CJCJ-stema.png`;
        },
    },
    beforeMount() {
        // console.log('loader created')
        this.$store.dispatch(ADD_BODY_CLASSNAME, 'page-loading');
    },
    destroyed () {
        // console.log('loader destroy')
        this.$store.dispatch(REMOVE_BODY_CLASSNAME, 'page-loading');
    }
};
</script>
