<template>
    <div>
        <Loader  v-if="isLoading" :logo="logoImage"></Loader>
        <div class="d-flex justify-content-between">
            <p class="font-size-h5 mt-6">{{ $t('NOTIFICATIONS.HEADER.TOP') }}</p>
        </div>
        <div class="card card-custom card-stretch gutter-b mt-4" v-if="!error">
            <!--begin::Header-->
            <div class="card-header border-0 mt-6 notifications-card-header">
                <div class="row w-100 mx-0">
                    <div class="col-6">
                        <p class="font-size-lg font-weight-bold mb-0">
                            {{ $t('NOTIFICATIONS.HEADER.COL_1') }}
                        </p>
                    </div>
                    <div class="col-3"></div>
                    <div class="col-1">
                        <div class="d-flex justify-content-center">
                            <p class="font-size-lg font-weight-bold mb-0">
                                {{ $t('NOTIFICATIONS.HEADER.COL_2') }}
                            </p>
                        </div>
                    </div>
                    <div class="col-1 notif-custom-col">
                        <div class="d-flex justify-content-center">
                            <p class="font-size-lg font-weight-bold mb-0">
                                {{ $t('NOTIFICATIONS.HEADER.COL_3') }}
                            </p>
                        </div>
                    </div>
                    <div class="col-1">
                        <div class="d-flex justify-content-center">
                            <p class="font-size-lg font-weight-bold mb-0">
                                {{ $t('NOTIFICATIONS.HEADER.COL_4') }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <!--end::Header-->
            <!--begin::Body-->
            <div class="card-body pt-0">
                <template v-for="(notif, key) in notifications">
                    <div class="mb-6" :key="key">
                        <div class="row w-100 mx-0">
                            <div class="col-8">
                                <p class="font-size-lg font-weight-bold">
                                    {{ $t(`NOTIFICATIONS.SECTION_TITLE.${key}`.toUpperCase()).toUpperCase() }}
                                </p>
                            </div>
                        </div>
                        <template v-for="(item, keyItem) in notif" >
                            <div class="row mt-1 mx-0" :key=keyItem v-if="keyItem !== 'order'">
                                <div class="col-6">
                                    <p class="font-size-lg">
                                        {{ $t(`NOTIFICATIONS.ITEMS.${keyItem}`.toUpperCase()) }}
                                    </p>
                                </div>
                                <div class="col-3"></div>
                                <div class="col-1">
                                    <div class="d-flex justify-content-center">
                                        <span class="switch switch-custom switch-md">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    checked="checked"
                                                    :disabled= "!item.email_selectable"
                                                    v-model="item.status.email"
                                                    @change="updateNotificationsOnChange(item)"
                                                    />
                                                    <span></span>
                                            </label>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-1">
                                    <div class="d-flex justify-content-center">
                                        <span class="switch switch-custom switch-md">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    checked="checked"
                                                    :disabled= "!item.sms_selectable"
                                                    v-model="item.status.sms"
                                                    @change="updateNotificationsOnChange(item)"
                                                    />
                                                    <span></span>
                                            </label>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-1">
                                    <div class="d-flex justify-content-center">
                                        <span class="switch switch-custom switch-md">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    checked="checked"
                                                    :disabled= "!item.in_app_selectable"
                                                    v-model="item.status.in_app"
                                                    @change="updateNotificationsOnChange(item)"
                                                    />
                                                    <span></span>
                                            </label>
                                        </span>
                                    </div>
                                </div>
                                <hr class="w-100 my-1">
                            </div>
                        </template>
                    </div>
                </template>
            </div>
            <!--end: Card Body-->
        </div>
        <div v-if="error" class=" d-flex  justify-content-center align-items-center bg-light pt-6">
            <div class="preview-area">
                <div class="text-center">
                    <i class="fas fa-exclamation-triangle text-danger fa-4x" />
                </div>
                <h5 class="mt-6">
                   An error occurred during the loading!
                </h5>
                <div class="text-center my-4">
                    <st-button
                        variant="warning"
                        class="font-size-h6 px-5 py-3 my-3 mr-3"
                        :outline=true
                        :callback="refresh"
                    >
                        <i class="fas fa-sync-alt"></i>
                        <span>Refresh</span>
                    </st-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { NotificationsPermissions } from '@/modules/notifications/notifications-permissions';
export default {
    name: 'UserNotifications',
    data() {
        return {
            notificationPrefList: {},
            params: {},
            error: false,
            notifications: [],
        };
    },
    computed: {
        ...mapState({
            loading: state => state.shared.loading,
        }),
        ...mapGetters({
            notificationsPreferences: 'notifications/management/notificationsPreferences',
            notificationsTypes: 'notifications/management/notificationsTypes',
            currentUser: 'auth/currentUser',
        }),
        logoImage() {
            return `${process.env.BASE_URL}media/logos/logo-GU-CJCJ-stema.png`;
        },
        isLoading() {
            return this.loading['notifications/management/getNotifications'] || this.loading['notifications/management/updateNotifications']
        },
        // TODO: use hasPermissionToEditNotificationsPreferences after BE fix for display notifications
        hasPermissionToEditNotificationsPreferences() {
            return new NotificationsPermissions(this.currentUser).edit;
        },
    },
    created () {
        this.getNotificationsType()
        .then(() => {
           this.setNotifications();
        })
        .catch(()=> {
            this.error = true;
            this.$notify({
                type: 'error',
                message: this.$t('NOTIFICATIONS.ERRORS.NOTIFICATIONS'),
            });
        })
    },
    methods: {
        ...mapActions({
            getNotificationsPreferences: 'notifications/management/getNotificationsPreferences',
            getNotificationsType: 'notifications/management/getNotificationsType',
            updateNotificationsPreferences: 'notifications/management/updateNotificationsPreferences',
        }),
        setNotifications() {
            const pref = this.notificationsPreferences;

            let result = {};
            let status = {};
            Object.keys(this.notificationsTypes).forEach(keyNotif => {
                let child = {};
                Object.keys(this.notificationsTypes[keyNotif]).forEach(keyChildNotif => {
                    if(keyChildNotif !== 'order') {
                        status =  pref.find(p => p.name === keyChildNotif);

                        // notification is not found in notification types
                        // create default status object
                        if (status === undefined ) {
                            Object.keys(this.notificationsTypes[keyNotif]).forEach(keyType => {
                                if(keyType !== 'order') {
                                    status = {
                                        "name": keyType,
                                        "sms": false,
                                        "email": false,
                                        "in_app": false
                                    }
                                }
                            })
                        }
                        child[keyChildNotif] = {...this.notificationsTypes[keyNotif][keyChildNotif], status};
                    }
                });
                result[keyNotif] = child;
            })

            this.notifications = result;
        },
        updateNotificationsOnChange() {
            let notificationsPref = []

            // get the status from each notification types
            Object.keys(this.notificationsTypes).forEach(keyType => {
                let status = {}

                Object.keys(this.notifications[keyType]).forEach(keyName => {
                    status = this.notifications[keyType][keyName].status
                    notificationsPref.push(status)
                });
            })

            this.updateNotificationsPreferences(notificationsPref)
            .then(() => {
                this.$notify({
                    type: 'success',
                    message: this.$t('NOTIFICATIONS.SUCCESS.UPDATE'),
                });
            })
            .catch(()=> {
                this.$notify({
                    type: 'error',
                    message: this.$t('NOTIFICATIONS.ERRORS.UPDATE'),
                });
            })
        },
        refresh() {
            this.getNotificationsType()
            .then(()=> {
                this.setNotifications;
            })
            .catch(()=> {
                this.error = true;
                this.$notify({
                    type: 'error',
                    message: this.$t('NOTIFICATIONS.ERRORS.NOTIFICATIONS'),
                });
            })
        }
    }
};
</script>

<style scoped lang="scss" src="@/assets/sass/pages/notifications/management-notifications.scss"></style>
