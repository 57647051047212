import { DocumentTemplateService } from '@/modules/document-template/document-template-service';
import { FormsService } from '@/modules/forms/forms-service';
import { ApplicationTypeService } from '@/modules/application-type/application-type-service';
import { ApplicationsService } from '@/modules/applications/applications-service';
import { ProcessService } from '@/modules/process-editor/process-editor-service';
import Errors from '@/shared/error/errors';
import Message from '@/shared/message/message';
import i18n from '@/shared/plugins/vue-i18n';

// action types
export const DO_CREATE = 'create';
export const DO_UPDATE = 'update';
export const DO_REMOVE = 'remove';
export const DO_FETCH_APPLICATION_TYPES = 'fetchApplicationTypes';
export const DO_FETCH_FORMS = 'fetchForms';
export const DO_FIND_FORM = 'findForm';
export const DO_FILE_UPLOAD = 'fileUpload';
export const DO_FIND = 'find';
export const DO_CLEAR = 'clear';
export const DO_DOWNLOAD_FILE ='downloadFile';
export const DO_COLLECT_METADATA = 'collectMetadata';
export const DO_FETCH_SYSTEM_METADATA = 'fetchSystemMetadata';

// mutation types
export const FIND_SUCCESS = 'findSuccess';
export const RESET = 'reset';
export const DOWNLOAD_SUCCESS = 'downloadSuccess';

const state = {
    record: null,
    fileDownloadAccessToken: null,
    file: null,
};

const getters = {
    record: (state) => state.record,
    fileDownloadAccessToken: (state) => state.fileDownloadAccessToken,
    file: (state) => state.file,
};

const actions = {
    [DO_CREATE](context, values) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'documentTemplate/create', { root: true });
            DocumentTemplateService.create(values)
                .then(({ headers, data }) => {
                    const uploadAccessToken = headers['x-file-upload-access-token'];
                    resolve({ data, uploadAccessToken });
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'documentTemplate/create', { root: true });
                });
        });
    },
    [DO_UPDATE](context, { id, values }) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'documentTemplate/update', { root: true });
            DocumentTemplateService.update(id, values)
                .then(({ headers, data }) => {
                    Message.success(i18n.t('GENERAL.UPDATED_SUCCESS'));
                    const uploadAccessToken = headers['x-file-upload-access-token'];
                    resolve({ data, uploadAccessToken });
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'documentTemplate/update', { root: true });
                });
        });
    },
    [DO_REMOVE](context, id) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'documentTemplate/remove', { root: true });
            DocumentTemplateService.remove(id)
                .then(() => {
                    context.commit('documentTemplate/list/removeRecord', id, { root: true });
                    Message.success(i18n.t('GENERAL.REMOVE_SUCCESS'));
                    resolve();
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'documentTemplate/remove', { root: true });
                });
        });
    },
    [DO_FETCH_APPLICATION_TYPES](context) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'documentTemplate/fetchApplicationTypes', { root: true });
            ApplicationTypeService.list({ params: { limit: 100 } })
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'documentTemplate/fetchApplicationTypes', { root: true });
                });
        });
    },
    [DO_FETCH_FORMS](context) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'documenTemplate/fetchForm', { root: true });
            FormsService.list()
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'documenTemplate/fetchForm', { root: true });
                });
        });
    },
    [DO_FIND_FORM](context, id) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'documentTemplate/findForm', { root: true });
            FormsService.find(id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'documentTemplate/findForm', { root: true });
                });
        });
    },
    [DO_FILE_UPLOAD](context, payload) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'documentTemplate/fileUpload', { root: true });
            const { uploadToken, formData } = payload;
            DocumentTemplateService.fileUpload(uploadToken, formData)
                .then((data) => {
                    resolve(data);
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'documentTemplate/fileUpload', { root: true });
                });
        });
    },
    [DO_FIND](context, id) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'documentTemplate/find', { root: true });
            DocumentTemplateService.find(id)
                .then(async ({ headers, data }) => {
                    const fileDownloadAccessToken = headers['x-file-download-access-token'];
                    context.commit(FIND_SUCCESS, { fileDownloadAccessToken, data });
                    await context.dispatch(DO_DOWNLOAD_FILE);
                    resolve(data);
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'documentTemplate/find', { root: true });
                });
        });
    },
    [DO_CLEAR](context) {
        context.commit(RESET);
    },
    [DO_DOWNLOAD_FILE](context) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'documentTemplate/downloadFile', { root: true });
            const { state } = context;
            const id = state.record.files.sfdt;
            const token = state.fileDownloadAccessToken;
            DocumentTemplateService.downloadFile(token, id)
                .then((data) => {
                    context.commit(DOWNLOAD_SUCCESS, data);
                    resolve(data);
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'documentTemplate/downloadFile', { root: true });
                });
        });
    },
    [DO_COLLECT_METADATA](context, id) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'documentTemplate/collectMetadata', { root: true });
            ProcessService.collectMetadata(id)
                .then((data) => {
                    resolve(data.data);
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'documentTemplate/collectMetadata', { root: true });
                });
        });
    },
    [DO_FETCH_SYSTEM_METADATA](context) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'documentTemplate/fetchSystemMetadata', { root: true });
            ApplicationsService.getSystemMetadata()
                .then((data) => {
                    resolve(data);
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'documentTemplate/fetchSystemMetadata', { root: true });
                });
        });
    },
};

const mutations = {
    [FIND_SUCCESS](state, payload) {
        const { fileDownloadAccessToken, data } = payload;
        state.fileDownloadAccessToken = fileDownloadAccessToken;
        state.record = data;
    },
    [RESET](state) {
        state.fileDownloadAccessToken = null;
        state.record = null;
    },
    [DOWNLOAD_SUCCESS](state, payload) {
        state.file = payload;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
