<template>
    <!-- begin:: Header Topbar -->
    <div class="topbar justify-content-end">
        <!--begin: Language bar -->
        <div class="topbar-item pr-2">
            <language-select/>
        </div>
        <!--end: Language bar -->

        <!--begin: Notifications -->
        <b-dropdown
            size="sm"
            variant="link"
            toggle-class="topbar-item text-decoration-none"
            no-caret
            right
            no-flip
            @show="show"
            @hide="hide"
            ref="dropdown"
        >
            <template v-slot:button-content>
                <div class="btn btn-icon">
                    <i class="fas fa-bell"></i>
                </div>
            </template>
            <b-dropdown-text tag="div" class="min-w-md-350px">
                <form>
                    <StDropdownNotification
                        @goToPage="goToPage"
                        :notificationsVisible="notificationsVisible"
                        :notificationMessages="notificationMessages"
                    ></StDropdownNotification>
                </form>
            </b-dropdown-text>
        </b-dropdown>
        <!--end: Notifications -->

        <!--begin: User Bar -->
        <StQuickUser></StQuickUser>
        <!--end: User Bar -->
    </div>
    <!-- end:: Header Topbar -->
</template>

<style lang="scss">
.topbar {
    .dropdown-toggle {
        padding: 0;
        &:hover {
            text-decoration: none;
        }

        &.dropdown-toggle-no-caret {
            &:after {
                content: none;
            }
        }
    }

    .dropdown-menu {
        margin: 0;
        padding: 0;
        outline: none;
        .b-dropdown-text {
            padding: 0;
        }
    }
}
</style>

<script>
import StDropdownNotification from '@/modules/layout/components/extras/dropdown/DropdownNotification.vue';
import StQuickUser from '@/modules/layout/components/extras/offcanvas/QuickUser.vue';
import LanguageSelect from '@/shared/i18n/LanguageSelect.vue';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import moment from 'moment';

export default {
    name: 'Topbar',
    components: {
        StDropdownNotification,
        StQuickUser,
        LanguageSelect,
    },
    data() {
        return {
            notificationsVisible: false,
            notificationMessages: []
        };
    },
    computed: {
        ...mapGetters({
            userMessages: 'notifications/inApp/userMessages',
            listPeriod: 'notifications/inApp/listPeriod'
        }),
    },
    methods: {
        ...mapActions({
            getUserMessages: 'notifications/inApp/getUserMessages',
        }),
        ...mapMutations({
            setListPeriod: 'notifications/inApp/setListPeriod',
        }),
        show() {
            this.notificationsVisible = true;
            this.getUserMessages(this.listPeriod)
            .then(()=> {
                this.notificationMessages = this.userMessages;
            })
        },
        hide() {
            this.notificationsVisible = false;
            const now = new Date();
            const endDate = moment(now).add(1, 'day').format('YYYY-MM-DD');
            const startMonth = moment(now).subtract(1, 'months').format('YYYY-MM-DD');
            const listPeriod = {
                startDate: startMonth,
                endDate: endDate
            }
            this.setListPeriod(listPeriod);
        },
        goToPage(item) {
            this.$refs.dropdown.hide(true);
            // For now only complains and applications will have notification(s) (in-app)
            // Once other sources will be added this need to be updated accordingly
            if (item.message.message_source) {
                const PAGE_LINKS = {
                    application: 'applicationView',
                    complain: 'complainView',
                };
                const name = PAGE_LINKS[item.message.message_source];
                this.$router.push({
                    name,
                    params: {
                        id: item.recipient.recipient_identification_id,
                    },
                });
            }
        }
    }
};
</script>
