import ApiService from '@/shared/services/api.service';

export class ComplainService {
    static list(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/complains', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static find(id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/complains/${id}`)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static findForStaff(id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/staff-complains/${id}`)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static create(data) {
        return new Promise((resolve, reject) => {
            ApiService.post( '/complains?echo=true', { ...data } )
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static update(id, data) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/complains/${id}`, { ...data })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }
    static updateStaff(id, data) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/staff-complains/${id}`, { ...data })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }
    static listStaff(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/staff-complains/all', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }
    static upload(token, files) {
        return new Promise((resolve, reject) => {
           ApiService.post('/files?echo=true', files, {
               'content-type': 'multipart/form-data',
               'x-file-access-token': token,
           })
               .then((response) => resolve(response.data))
               .catch((error) => reject(error));
        });
    }
    static download(token, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/files/${id}`, {
                'x-file-access-token': token,
            })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }
}
