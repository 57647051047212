import layerManagerStore from '@/modules/atlas/store/layer-manager-store';
import sidebarStore from '@/modules/atlas/store/sidebar-store';
import gisTokenStore from '@/modules/atlas/store/gis-token-store';
import interestAreasStore from '@/modules/atlas/store/interest-areas-store';
import layerIntersectionStore from '@/modules/atlas/store/layer-intersection-store';

export default {
    namespaced: true,
    modules: {
        'layer-manager': layerManagerStore,
        sidebar: sidebarStore,
        token: gisTokenStore,
        'interest-areas': interestAreasStore,
        'layer-intersection': layerIntersectionStore,
    },
};
