import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';

const ApplicationTypeList = () => import('@/modules/application-type/pages/ApplicationTypeList.vue');

export default [
    {
        path: '',
        component: Layout,
        meta: { auth: true },
        children: [
            {
                name: 'application-type',
                path: '/application-type',
                component: ApplicationTypeList,
                meta: {
                    auth: true,
                    permission: Permissions.values.applicationTypeRead,
                }
            },
        ],
    },
];
