import {CONTROLS} from '@/core/mock/mock-data/mock-controls';

export class ControlService {
    static listControls() {
        return new Promise((resolve, reject) => {
            resolve(CONTROLS)
        });
    }

}
