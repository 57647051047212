<template>
    <div class="notification-list-container">
        <div class="d-flex flex-column pt-6">
            <h4 class="d-flex flex-center">
                {{ $t('NOTIFICATIONS_IN_APP.TITLE') }}
            </h4>
        </div>
        <div class="separator separator-dashed">
            <b-skeleton-wrapper :loading="isLoading">
                <template #loading>
                    <b-card v-for="index in 2" :key="index">
                        <b-skeleton width="80%"></b-skeleton>
                        <b-skeleton width="65%"></b-skeleton>
                        <b-skeleton width="75%"></b-skeleton>
                        <p class="d-flex justify-content-end pt-3">
                            <b-skeleton width="20%"></b-skeleton>
                        </p>
                    </b-card>
                </template>
            </b-skeleton-wrapper>
            <perfect-scrollbar
                v-if="!isLoading"
                class="navi navi-hover notification-perfect-scrollbar scroll my-4 mx-2"
                @ps-y-reach-end="onScrollEnd"
                ref="scrollbar"
            >
                <template v-for="(item, i) in notificationMessages">
                    <div class="navi-item navi-item-custom my-4"  v-bind:key="i" @click="goToPage(item)">
                        <div class="row">
                            <div class="col-md-auto ml-4 mt-4">
                                <i class="h3 far fa-file-alt"></i>
                            </div>
                            <div class="col mt-3 mr-2">
                            <div class="font-weight-normal">
                                    <p class="my-0">{{item.message.body}}</p>
                                    <p class="my-0">
                                        {{ $t('NOTIFICATIONS_IN_APP.MESSAGE.INPUT_CODE') }}:
                                        {{item.recipient.recipient_identification_number}}
                                    </p>
                                    <p class="my-0">
                                        {{ $t('NOTIFICATIONS_IN_APP.MESSAGE.BENEFICIARY') }}:
                                        {{item.recipient.recipient_name}}
                                    </p>
                                    <p class="text-right">{{formatDate(item.created_date)}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="notification-check-icon"
                            v-if="item.has_been_read"
                        >
                            <i class="text-primary fas fa-check-circle"></i>
                        </div>
                    </div>
                </template>
            </perfect-scrollbar>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import moment from 'moment';
export default {
    name: 'KTDropdownNotification',
    props: {
        notificationMessages: {
            type: Array,
            requred: true,
        },
        notificationsVisible: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        const now = new Date().toISOString();
        return {
            dateNow: now,
            loadOnce: false
        };
    },
    computed: {
        ...mapGetters({
            loading:  'shared/loading',
            userMessages: 'notifications/inApp/userMessages',
            listPeriod: 'notifications/inApp/listPeriod'
        }),

        isLoading() {
            if (!this.loadOnce) {
                return this.loading['notifications/getUserMessages'];
            }
            return false;
        }
    },
    methods: {
        ...mapActions({
            getUserMessages: 'notifications/inApp/getUserMessages',
            updateUserMessage: 'notifications/inApp/updateUserMessage',
        }),
        ...mapMutations({
            setListPeriod: 'notifications/inApp/setListPeriod',
        }),
        formatDate(date) {
            moment.updateLocale('ro',{
                relativeTime : {
                    future: `${this.$t('NOTIFICATIONS_IN_APP.LOCALE.FUTURE')} %s`,
                    past:  `${this.$t('NOTIFICATIONS_IN_APP.LOCALE.PAST')} %s`,
                    s  : this.$t('NOTIFICATIONS_IN_APP.LOCALE.SECOND'),
                    ss : `%d ${this.$t('NOTIFICATIONS_IN_APP.LOCALE.SECONDS')}`,
                    m:  this.$t('NOTIFICATIONS_IN_APP.LOCALE.MINUTE'),
                    mm: `%d ${this.$t('NOTIFICATIONS_IN_APP.LOCALE.MINUTES')}`,
                    h:  this.$t('NOTIFICATIONS_IN_APP.LOCALE.HOUR'),
                    hh: `%d ${this.$t('NOTIFICATIONS_IN_APP.LOCALE.HOURS')}`,
                }
            });
            const dateNow = moment(this.dateNow);
            const messageDate = moment(date);
            if (dateNow.diff(messageDate, 'hours') < 24) {
                const timeAgo = dateNow.from(messageDate, true);
                return `${this.$t('NOTIFICATIONS_IN_APP.NOW')} ${timeAgo}`
            }
            return moment(date).format('YYYY/MM/DD LT');
        },
        goToPage(item) {
            // TODO: Check if is possible to get the total and make another request
            // if the number of 1st batch (1month) > total user messages
            if (item.has_been_read) {
                this.$emit('goToPage', item);
                return;
            }
            this.updateUserMessage(item.id)
            .then(()=> {
                this.$emit('goToPage', item);
            })
        },
        onScrollEnd() {
            if (this.notificationsVisible) {
                this.loadOnce = true;
                const startDate = moment(this.listPeriod.startDate).subtract(1, 'months').format('YYYY-MM-DD');
                const endDate = moment(this.listPeriod.endDate).subtract(1, 'months').format('YYYY-MM-DD');
                const listPeriod = {
                    startDate,
                    endDate
                }
                this.getUserMessages(listPeriod)
                .then(()=> {
                    if (this.userMessages.length) {
                        this.notificationMessages.push(...this.userMessages);
                    }
                    this.setListPeriod(listPeriod);
                })
            }
        }
    },
};
</script>

<style scoped lang="scss" src="@/assets/sass/pages/notifications/in-app-notifications.scss"></style>