// USA
const locale = {
    MENU: {
        DASHBOARD: 'Dashboard',
        CREATE_REQUEST_BUTTON: 'Creeaza cerere',
        SEARCH_BUTTON: 'Cautare avansata',
        RESPONSE_TEMPLATES: 'Template-uri de raspuns',
        TAX_CATEGORY: 'Categorii de taxare',
        COMPLAIN_TYPE: 'Tipologii de sesizare',
        ORGANIZATION: 'Administrare organizatii',
        ACCOUNTS: 'Conturi secundare',
        MY_APPLICATIONS: 'Cererile mele',
        STAFF_APPLICATIONS: 'Registru cereri',
        NOTIFICATIONS: 'Mesaje si notificari',
        RESPONSIBILITIES: 'Nomenclator Responsabilități',
        MY_COMPLAINS: 'Sesizarile mele',
        STAFF_COMPLAINS: 'Registru sesizari',
        PAYMENTS: 'Registru plati',
        DOCUMENTS: 'Acte emise',
        EXTERNAL_APPS: 'Aplicatii externe',
        ATLAS: 'Atlas teritorial',
        ARCHIVE: 'Arhiva',
        HELP: 'Ajutor',
        MANUAL: 'Manual',
        FAQ: 'FAQ',
        ADMINISTRATOR: {
            HEADER: 'Administrator',
            MANAGE: 'Gestiune',
            FUNCTIONARY: 'Functionar'
        },
        FORMS: 'Interfețe formulare',
        APPLICATION_TYPE: 'Tipologii de cereri',
        PROCESS_MANAGEMENT:'Process management',
        PERMISSIONS: 'Permissions',
        METADATA_MAPPING: 'Mapare Metadate',
        STAFF_OWN_APPLICATIONS: 'Cereri asignate',
        CONTROLS: 'Registru de control',
        ORGANISATION_CONFIG: 'Configurări organizație',
        SYSTEM_CONFIG: 'Configurări sistem',
    },
    GENERAL: {
        YES: 'Da',
        NO: 'Nu',
        CLOSE: 'Inchide',
        NEXT: 'Inainte',
        PREV: 'Inapoi',
        STATUS: 'Status',
        ACTIVE: 'Activ',
        INACTIVE: 'Inactiv',
        BUTTON: {
            ADD: 'Adaugă',
            CANCEL: 'Renunță',
            SAVE: 'Salvează',
            UPDATE: 'Modifică',
            CONFIRM: 'Confirmă',
            ERASE: 'Sterge',
            MORE_FILTERS: 'Mai multe filtre',
            CONTINUE: 'Continuă'
        },
        ACTIONS: {
            EDIT: 'Edit',
            DELETE: 'Delete',
            DELETE_MESSAGE: 'Are you sure you want to delete this?',
            VIEW: 'View',
            DOWNLOAD: 'Download',
        },
        TABLE: {
            ACTIONS: 'Actions',
        },
        DATEPICKER: {
            FROM_DATE: 'From',
            TO_DATE: 'To',
            SELECT_DATE: 'No date selected',
        },
        PAGINATION: 'Showing {start} to {end} results',
        CREATED_SUCCESS: 'Successfully created.',
        UPDATED_SUCCESS: 'Successfully updated',
        REMOVE_SUCCESS: 'Successfully deleted',
        SELECT_PLACEHOLDER: 'Choose',
        EXPIRED_SESSION: 'Session expired',
        TABLE_ACTIONS_COLUMN: 'Actions',
        TABLE_EMPTY_TEXT: 'There are no records to show',
        DAYS: 'days',
        AUTOCOMPLETE_NO_OPTIONS: 'Sorry, no matching options.',
        DUAL_LIST_BOX: {
            SEARCH_PLACEHOLDER: 'Search',
            SEARCH_RESULTS: 'No results found.'
        },
        ERROR_PAGE: {
            TITLE: 'Oops...',
            TEXT: 'A apărut o problemă...',
            SUB_TEXT: 'eroare neașteptată'
        },
        CITIZEN: 'Citizen',
        STAFF: 'Staff',
        SIGN: 'Sign',
        SYSTEM: 'System',
        CURRENCY_LABEL: 'RON',
    },
    ALERT_MODAL: {
        ERROR: 'Eroare',
        WARNING: 'Atentie',
        SUCCESS: 'Succes',
        INFO: 'Info',
    },
    AUTH: {
        GENERAL: {
            OR: 'sau',
            CANCEL_BUTTON: 'Renunta',
            BACK_BUTTON: 'Inapoi',
            PRIVACY: 'Politica de Confidentialitate',
            LEGAL: 'Termeni si Conditii',
        },
        LOGIN: {
            TITLE: 'Bun venit!',
            DESC: 'Introduceti email-ul si parola pentru a accesa platforma',
            NO_ACCOUNT: 'Nu ai cont? Creeaza acum!',
            BUTTON: 'Intra in cont',
            FORGOT_PASSWORD_LABEL: 'Ai uitat parola ?',
            GOOGLE_BUTTON: 'Intra in cont folosind Google',
            FACEBOOK_BUTTON: 'Intra in cont folosind Facebook'
        },
        FORGOT: {
            TITLE: 'Resetare parola?',
            DESC: 'Introduceti adresa de email aferenta contului.',
            BUTTON: 'Trimite email',
            SUCCESS: 'Your account has been successfully reset.'
        },
        RESET: {
            TITLE: 'Resetare parola?',
            DESC: 'Introduceti parola noua',
            BUTTON: 'Schimba parola',
        },
        CHANGE_PASSWORD: {
            TITLE: 'Schimbare parola',
            DESC: 'Introduceti parola noua',
            BUTTON: 'Schimba parola',
        },
        SET: {
            TITLE: 'Setare parola',
            DESC: 'Introduceti o parola',
            BUTTON: 'Setează parola',
        },
        REGISTER: {
            TITLE: 'Bun venit!',
            DESC: 'Creati un cont nou pentru a accesa platforma',
            HAS_ACCOUNT: 'Ai cont deja? Conecteaza-te',
            GOOGLE_BUTTON: 'Creeaza cont folosind Google',
            FACEBOOK_BUTTON: 'Creeaza cont folosind Facebook',
            BUTTON: 'Creeaza cont',
            ACCEPT_TERMS: 'Accept {0}, {1}.',
            PRIVACY: 'politica de confidentialitate',
            TOS: 'termeni si conditiile',
            PASSWORD_HELP: 'Minim 8 caractere si un caracter special'
        },
        INPUT: {
            EMAIL: 'E-mail',
            PASSWORD: 'Parola',
            CONFIRM_PASSWORD: 'Confirma parola',
            NEW_PASSWORD: 'Parola noua',
            OLD_PASSWORD: 'Parola veche',
            PHONE: 'Numar telefon',
            VALIDATION_CODE_PLACEHOLDER: 'Cod validare'
        },
        VERIFY_EMAIL: {
            TITLE: 'Confirmare e-mail',
            MESSAGE_SENT: 'S-a trimis un link de activare la adresa de e-mail {0}. Verifica e-mailul iar dupa confirmare continua procesul de validare a contului.',
            RESEND_CONFIRMATION_MESSAGE: 'Nu ai primit e-mail de confirmare? {0}.',
            RESEND_LINK: 'Retrimite link-ul',
            TOAST_MESSAGE: 'Emailul de confirmare trimis cu succes'
        },
        VERIFY_SMS: {
            TITLE: 'Validarea contului',
            MESSAGE:
                'Din motive de securitate Ghiseul Unic are nevoie sa iti verifice contul prin SMS. Introdu numarul de telefon si urmeaza pasii pentru validare.',
            MESSAGE_SENT:
                'S-a trimis un SMS la numarul de telefon {0}. Adauga codul primit si valideaza contul.',
            BUTTON_SUBMIT: 'Trimite cod',
            BUTTON_VALIDATE: 'Valideaza contul',
            INPUT_PLACEHOLDER: 'Cod validare',
            MESSAGE_NOT_RECEIVED: 'Nu ai primit codul? {0}',
            RESEND_LINK: 'Retrimite mesajul',
            CHANGED_VALIDATION_METHOD_LINK: 'schimba metoda de validare'
        },
        INVALID_LINK: {
            TITLE: 'Link de activare invalid',
            MESSAGE: 'Link-ul de activare este invalid, a expirat sau a fost deja folosit. Te rugăm să îți verifici contul sau să reiei procesul de înregistrare.',
            REGISTER_MESSAGE: 'Mergi la pagina de {0}.',
            REGISTER_LINK: 'înregistrare'
        },
        VALIDATION: {
            INVALID: '{{name}} nu este valid',
            REQUIRED: 'Acest camp este obligatoriu',
            EMAIL: 'Adresa de e-mail nu este valabilă',
            INVALID_EMAIL: 'Adresa de e-mail nu este validă.',
            MIN_LENGTH: 'Lungimea minina a {{name}} este {{min}}',
            AGREEMENT_REQUIRED: 'Acceptarea termenilor si conditiilor este obligatoriu',
            NOT_FOUND: 'The requested {{name}} is not found',
            INVALID_LOGIN: 'Date de autentificare incorecte. Te rugam sa incerci din nou.',
            REQUIRED_FIELD: 'Acest camp este obligatoriu',
            CONFIRM_PASSWORD: 'Parola nu este identica',
            MIN_LENGTH_FIELD: 'Campul trebuie sa aiba minim {{min}} caractere',
            MAX_LENGTH_FIELD: 'Campul trebuie sa aiba maxim {max} caractere',
            INVALID_FIELD: 'Campul nu este valid',
            STRONG_PASSWORD: 'Parola nu este validă',
            INVALID_PHONE: 'Numarul de telefon nu este valid',
            NUMERIC_FIELD: 'Acest camp contine doar valori numerice!',
            PHONE_FIELD: 'Acest camp contine doar valori numerice!',
            INVALID_URC: 'CUI invalid.',
            INVALID_PNC: 'CNP Invalid.',
            POSTAL_CODE_MAX_LENGTH_FIELD: 'Codul Postal trebuie sa contina 6 cifre.',
            SMS_CODE_LENGTH: 'Codul de verificare trebuie sa contina 6 cifre',
            IBAN: 'Cod IBAN invalid.',
            INTEGER: 'Valoarea câmpului nu este un număr întreg valid',
            INVALID_DATE: 'Dată invalidă',
        }
    },
    LAYOUT: {
        HEADER: {
            SEARCH_PLACEHOLDER: 'Cauta cerere,aviz sau alt document'
        }
    },
    LOGOUT: {
        CONFIRMATION_MESSAGE: 'Esti sigur ca doresti terminarea sesiunii de lucru?'
    },
    RESET_PASSWORD: {
        CONFIRMATION_MESSAGE: 'Esti sigur ca doresti sa resetezi parola?',
        SUCCESSFULLY_SENT_MESSAGE: ' S-a trimis un link de resetare a parolei pe adresa ta de e-mail asociata acestui cont.'
    },
    HOME: {
        WELCOME_TEXT: 'Bine ați venit în aplicația Ghișeul Unic.',
    },
    PROFILE: {
        HEADER: 'Contul meu',
        LOGOUT_BUTTON_TEXT: 'Delogare',
        UPDATE_BUTTON: 'Actualizeaza datele',
        SECTIONS: {
            ACCOUNT_DETAILS: {
                HEADER: 'Detalii cont'
            },
            PERSONAL_DETAILS: {
                HEADER: 'Detalii personale'
            },
            PERSONAL_DATAS: {
                HEADER: 'Date personale'
            },
            COMPANY_DATAS: {
                HEADER: 'Date firmă'
            },
            RESPONSIBILITIES: {
                HEADER: 'Responsabilitati'
            },
            IDENTITY_CARD: {
                HEADER: 'Date carte de identitate'
            }
        },
        OPTIONS: {
            MY_PROFILE: {
                NAME: 'Profilul meu',
                DESCRIPTION: 'Vezi detalii profil'
            },
            RESET_PASSWORD: {
                NAME: 'Resetare Parola',
                DESCRIPTION: 'Aceseaza formularul de resetare'
            },
            NOTIFICATIONS_MANAGEMENT: {
                NAME: 'Management Notificari',
                DESCRIPTION: 'Gestioneaza tipul si frecventa notificarilor'
            }
        },
        FIELDS: {
            PERSONAL_NUMERIC_CODE: 'CNP',
            LAST_NAME: 'Nume',
            FIRST_NAME: 'Prenume',
            COUNTY: 'Județ',
            LOCALITY: 'Localitate',
            VILLAGE: 'Sat',
            SECTOR: 'Sector',
            STREET: 'Strada',
            POSTAL_CODE: 'Cod Poștal',
            STREET_NUMBER: 'Număr',
            STAIRCASE: 'Scara',
            BLOCK: 'Bloc',
            FLOOR: 'Etaj',
            APARTMENT: 'Apartament',
            EMAIL: 'E-mail',
            PHONE: 'Telefon',
            PASSPORT_NUMBER: 'Pașaport/Carte de identitate',
            COUNTRY: 'Țara',
            CITY: 'Oraș',
            ADDRESS: 'Adresa',
            UNIQUE_REGISTRATION_CODE: 'CUI',
            COMPANY_NAME: 'Denumire',
            LICENSE: 'Autorizație',
            STATUS: 'Status',
            IDENTIFICATION_NUMBER: 'Număr înregistrare',
            ORGANISATION: 'Instituție',
            FUNCTIONS: 'Funcție',
            DEPARTMENTS: 'Departament',
            USER_TYPE_NAME: 'Tip utilizator',
            USER_TYPE_NAME: 'Tip utilizator',
            ID_CARD_SERIAL: 'Serie CI',
            ID_CARD_NUMBER: 'Număr CI',
            ID_CARD_EXPIRATION_DATE: 'Data expirării',
            ID_CARD_ISSUER: 'Eliberat de',
        },
        PLACEHOLDERS: {
            COUNTY: 'Județ',
            LOCALITY: 'Localitate',
            VILLAGE: 'Sat',
            COUNTRY: 'Țara',
            STATUS: 'Status',
        },
        ENUMS: {
            STATUS: {
                ACTIVE: 'Activ',
                INACTIVE: 'Inactiv',
            },
        },
        MESSAGES: {
            ID_CARD_EXPIRED: 'Cartea de identitate a expirat!',
            ENROLLED_SUCCESSFUL: 'Functionarul a fost inrolat cu succes',
        },
    },
    SET_PROFILE_DATA: {
        WELCOME_MODAL: {
            BRAND: {
                NAME: 'Ghișeul unic',
                ORGANISATION: 'Consiliul Judetean Cluj'
            },
            HEADER: {
                TOP_P: 'Bun venit in platforma Ghiseul Unic!',
                BOTTOM_P: 'Completeaza datele profilului tau pentru a putea customiza experienta conform nevoilor tale.',
                BOTTOM_P_PERSON: 'Seteaza cont pentru persoana fizica.',
                BOTTOM_P_LEGAL: 'Seteaza cont pentru persoana juridica.'
            },
            OPTIONS: {
                PERSON: 'Seteaza profil pentru persoana fizica',
                LEGAL: 'Seteaza profil pentru persona juridica (Birou Arhitectura)'
            }
        },
        CITIZEN: {
            ROMANIAN: 'Cetatean roman',
            FOREIGNER: 'Cetatean strain'
        },
        FORM_BUTTONS: {
            CHANGE_TYPE: 'Schimba tipul profilului',
            SAVE: 'Salveaza datele'
        },
        LEGAL_FORM: {
            COMPANY: 'Detalii Firma',
            LEGAL_REPRESENTATIVE: 'Detalii reprezentant Legal'
        },
        PERSON: {
            PNC: 'CNP',
            LAST_NAME: 'Nume',
            FIRST_NAME: 'Prenume',
            COUNTY: 'Judet',
            LOCALITY: 'Localitate',
            VILLAGE: 'Sat',
            SECTOR: 'Sector',
            STREET: 'Strada',
            POSTAL_CODE: 'Cod Postal',
            NUMBER: 'Numar',
            STAIRCASE: 'Scara',
            BLOCK: 'Bloc',
            FLOOR: 'Etaj',
            APARTMENT: 'Apartament',
            EMAIL: 'E-mail',
            PHONE: 'Telefon'
        },
        FOREIGNER: {
            PNC: 'Pasaport/Carte de identitate',
            LAST_NAME: 'Nume',
            FIRST_NAME: 'Prenume',
            COUNTRY: 'Tara',
            CITY: 'Oras',
            ADDRESS: 'Adresa',
            EMAIL: 'E-mail',
            PHONE: 'Telefon'
        },
        LEGAL: {
            URC: 'CUI',
            COMPANY_NAME: 'Denumire',
            COUNTY: 'Judet',
            LOCALITY: 'Localitate',
            STREET: 'Strada',
            POSTAL_CODE: 'Cod Postal',
            NUMBER: 'Numar',
            STAIRCASE: 'Scara',
            BLOCK: 'Bloc',
            FLOOR: 'Etaj',
            APARTMENT: 'Apartament',
            LAST_NAME: 'Nume',
            FIRST_NAME: 'Prenume',
            PNC: 'CNP',
            LICENSE: 'Autorizatie',
            EMAIL: 'E-mail',
            PHONE: 'Telefon'
        },
        ERRORS: {
            LOCALITIES: 'Eroare la generarea listei de localitati!',
            VILLAGES: 'Eroare la generarea listei de sate!',
            UPDATE: 'Eroare la modificarea informațiilor despre profil!',
            CREATE: 'Eroare la crearea profil utilizator!'
        },
        SUCCESS_MODAL: {
            TITLE: 'Bun venit {0}!',
            TEXT: 'Acum ai posibilitatea de a incarca si transmite documentele necesare procesului de autorizare si de a-ti gestiona documentele.',
            CONFIRM_BUTTON: 'Hai sa incepem!',
        },
        UPDATED_MESSAGE: 'Profilul a fost actualizat cu succes!'
    },
    NOTIFICATIONS: {
        BUTTON_TEXT: 'Aplica modificarile',
        HEADER: {
            TOP: 'Management notificari',
            COL_1: 'Tipul notificarii',
            COL_2: 'Email',
            COL_3: 'Sms',
            COL_4: 'În aplicație'
        },
        SECTION_TITLE: {
            ACCOUNT: 'Cont',
            APPLICATIONS: 'Cereri',
            DOCUMENTS: 'Acte',
            PAYMENTS: 'Plați',
            ALERTS: 'Alerte',
            OTHERS: 'Altele',
            COMPLAINS: 'Sesizări',
            COMMENTS: 'Mesaje'
        },
        FREQUENCY: {
            DAILY: 'Zilnic',
            WEEKLY: 'Saptamanal',
            MONTHLY: 'Lunar'
        },
        ITEMS: {
            ACCOUNT_DATA_CHANGED: 'Se modifica datele contului',
            ACCOUNT_TYPE_CHANGED: 'Se modifica tipul contului',
            NEW_APPLICATION: 'Se primeste o cerere noua',
            APPLICATION_CHANGED: 'Se modifica o cerere',
            APPLICATION_STATUS_CHANGED: 'Se schimba statusul unei cereri',
            APPLICATION_SENT: 'Se trimite o cerere',
            DOCUMENT_CREATED: 'Se emite un act nou',
            DOCUMENT_CANCELLED: 'Se anuleaza un act',
            NEW_PAYMENT: 'Se face o plata noua',
            PAYMENT_APPROVED: 'Se aproba o plata',
            TAX_DEADLINE_EXPIRES: 'Expira un termen de plata',
            RESPONSE_DEADLINE_EXPIRES: 'Expira un termen de raspuns',
            MESSAGE_SENT: 'Se trimite mesaj.',
            NEW_COMPLAIN: 'Se primeste o sesizare nouă',
            COMPLAIN_STATUS_CHANGED: 'Se schimba statusul unei sesizari',
            NEW_COMMENT: 'Se primeste un mesaj nou',
        },
        ERRORS: {
            NOTIFICATIONS: 'Eroare la generarea listei de notificari!',
            UPDATE: 'Eroare la modificarea notificării!'
        },
        SUCCESS: {
            UPDATE: 'Modificarea notificării s-a realizat cu succes.'
        },
        TEMPLATES: {
            TITLE: 'Template-uri notificari',
            TABLE: {
                ID: 'ID',
                TITLE: 'Titlu',
                TYPE: 'Tip',
                LANGUAGE: 'Limba',
                ACTIONS: 'Actiuni',
                EDIT: 'Editeaza',
                SEARCH_TEMPLATE: 'Cauta template',
            },
            EDIT: {
                PAGE_TITLE: 'Editeaza template',
                LANGUAGE: 'Limba',
                RO: 'Ro',
                EN: 'En',
                HU: 'Hu',
                NAME: 'Denumire template mesaj',
                SUBJECT: 'Subiect',
                TITLE: 'Titlu',
                CHANNEL: 'Canal de livrare',
                SMS: 'SMS',
                EMAIL: 'E-mail',
                IN_APP: 'In-App',
                ACCEPTED_PLACEHOLDERS: 'Placeholdere acceptate',
                CANCEL: 'Renunta',
                SAVE: 'Salveaza',
                MAX_CHARS: 'Max: {chars} caractere.',
                REMAINING_CHARS: 'Au mai ramas: {chars}',
            }
        },
    },
    NOTIFICATIONS_IN_APP: {
        TITLE: 'Notificări',
        MESSAGE: {
            INPUT_CODE: 'Cod intrare',
            BENEFICIARY: 'Beneficiar'
        },
        LOCALE: {
            FUTURE: 'în',
            PAST: 'acum',
            SECOND: 'căteva secunde',
            SECONDS: 'secunde',
            MINUTE: 'un minut',
            MINUTES: 'minute',
            HOUR: 'o oră',
            HOURS: 'ore',
        },
        NOW: 'Acum'
    },
    BENEFICIARY: {
        BENEFICIARY_DETAILS: {
            HEADER: 'Detalii beneficiar',
            ADD_BTN: 'Adauga beneficiar',
            REMOVE_BTN: 'Sterge beneficiar',
            PERSON: {
                HEADER: 'Tip persoana',
                INDIVIDUAL: 'Persoana fizica',
                LEGAL: 'Persoana juridica'
            },
            CITIZENSHIP: {
                HEADER: 'Cetatenie',
                ROMANIAN: 'Cetatean roman',
                FOREIGNER: 'Cetatean strain'
            },
            FOOTER_CHECKBOX: 'Beneficiarul este persoana de contact',
            FORM: {
                AUTHORIZATION_CODE: 'Cod autorizatie',
                SUBSIDIARY: 'Filiala'
            },
            ADDRESS: 'Adresă',
            CONTACT: 'Contact',
        },
        APPLICANT_DETAILS: {
            HEADER: 'Detalii solicitant',
            FOOTER_CHECKBOX: 'Solicitantul este persoana de contact'
        }

    },
    ERRORS: {
        DEFAULT_ERROR_MESSAGE: 'Ops, an error occurred',
        AUTH_REQUIRED: 'Auth required',
        AUTH_INVALID: 'Auth invalid. Retry!',
        AUTH_INVALID_ATTEMPTS_REMAINING: 'Auth invalid. {0} attemps remaining.',
        REGISTRATIONISTER_EMAIL_ALREADY_EXISTS: 'Email already exists.',
        REGISTRATION_INVALID_PASSWORD: 'Invalid password',
        REGISTRATION_INVALID: 'Reqistration invalid.',
        FACEBOOK_EMAIL_NOT_RECEIVED: 'Something went wrong, could not get your email address. Check your Facebook settings and try again!',
        PASSWORD_EXPIRED: 'Your password has expired and must be changed.',
        AUTH_FAILED: 'Auth failed.',
        AUTH_FAILED_ATTEMPTS_REMAINING: 'Auth faild. {0} attemps remaining.',
        RESET_PASSWORD_ALREADY_USED: 'Password already used.',
        FORBIDDEN: 'We\'re sorry, you are not allowed to proceed',
        DUPLICATE_FUNCTION_NAME: 'Numele functiei adaugate deja exista!',
        CATEGORY_NOT_EMPTY: 'Categoria nu poate fii stearsa!',
        NAME_NOT_UNIQUE: 'Numele nu este unic.',
        ERROR_PAGE_MESSAGE: 'OOPS! Something went wrong here',
        AUTH_ACCOUNT_NOT_FOUND: 'Credențialele sunt introduse greșit!',
        PARENT_MUST_BE_ADMIN_OF_LEGAL_ENTITY: 'Nu aveți permisiunea de a adăuga sau edita un cont!',
        USER_ALREADY_EXISTS: 'Acest utilizator este deja existent!',
        TAX_ALREADY_EXISTS: 'Această taxă este deja existentă!',
        VERIFICATION_SERVICE_UNAVAILABLE: 'Codul introdus nu este valid!',
        CANNOT_DELETE_ORGANISATION: 'This organisation cannot be deleted because there are associated users',
        ORGANISATION_ALREADY_EXISTS: 'This organisation already exists',
        DOCUMENT_SERIES_ALREADY_EXISTS: 'This series already exists!',
        DOCUMENT_SERIES_NOT_EXIST: 'Nu există serie definită!',
        DOCUMENT_SERIES_NOT_ACTIVE: 'Seria nu este activă!',
        INDEX_CANNOT_BE_LOWER_THAN_CURRENT_VALUE: 'The value of the index cannot be less than the current value!',
        CONFLICT: 'Înregistrare duplicat',
        AUTH_INVALID_CONFIRMATION_VALIDATIONS: 'Password reset request expired!',
        DUPLICATE_COMPLAIN_TYPE_NAME: 'Numele tipului de sesizare introdus deja există!',
        DUPLICATE_RESPONSE_TEMPLATE_NAME: 'Numele templateului introdus deja există!',
        UAT_ALREADY_ASSIGNED: 'UAT-ul selectat este asginat la alt funcționar!',
        WORKFLOW_EXISTS: 'Un proces cu acest nume există deja',
        ERROR_DOCUMENT_GENERATION: 'A apărut o eroare, te rugăm încearcă mai târziu.'
    },
    REQUESTS: {
        BUILDING: {
            DETAILS: 'Detalii imobil',
            LAND: 'Teren',
            LAND_CONSTRUCTIONS: 'Teren + Constructii',
            COUNTY: 'Judet',
            LOCALITY: 'Localitate',
            VILLAGE: 'Sat',
            SECTOR: 'Sector',
            STREET: 'Strada',
            POSTAL_CODE: 'Cod postal',
            NUMBER: 'Numar',
            STAIRCASE: 'Scara',
            BLOCK: 'Bloc',
            FLOOR: 'Etaj',
            APARTMENT: 'Apartament',
            LAND_AREA: 'Suprafata terenului',
            OTHER_IDENTIFICATION: 'Alta identificare',
            CADASTRAL_NUMBER: 'Nr. cadastral',
            ADD_CADASTRAL_NUMBER: 'Adauga nr. cadastral',
            REMOVE_CADASTRAL_NUMBER: 'Sterge nr. cadastral'
        }
    },
    VALIDATION: {
        REQUIRED_FIELD: 'Acest camp este obligatoriu!',
        NUMERIC_FIELD: 'Acest camp contine doar valori numerice!',
        EMAIL_FIELD: 'Adresa de e-mail este invalida',
        PHONE_FIELD: 'Numarul de telefon este invalid',
        MAX_UPLOADED_FILES: 'Maxim {max} fisiere',
        PDF_FIELD: 'Extensia fișierului selectat trebuie să fie .pdf .',
    },
    PLACEHOLDERS: {
        MP: 'mp',
    },
    INSTITUTION: {
        MAIN_HEADER: 'Organizație selectată',
        PAGE_HEADER: 'Organizații',
        LIST: {
            TITLE: 'Lista organizațiilor',
        },
        FIELDS: {
            NAME: 'Denumire',
            STATUS: 'Status',
            COUNTY: 'Județ',
            LOCALITY: 'Localitate',
            TYPE: 'Tip',
            UAT: 'UAT',
            DESCRIPTION: 'Descriere',
            IS_TEST: 'Organizație de test',
            STAFF_USERS: 'Funcționar',
            ORGANISATION: 'Organizație'
        },
        PLACEHOLDERS: {
            NAME: 'Nume',
            STATUS: 'Stare',
            DESCRIPTION: 'Introduceți descrierea organizației',
            IS_TEST: 'Organizație de test'
        },
        ENUMS: {
            STATUS: {
                ACTIVE: 'Activ',
                INACTIVE: 'Inactiv',
            },
            IS_TEST: {
                YES: 'Da',
                NO: 'Nu'
            }
        },
        MAIN_PAGE: {
            SEARCH: {
                PLACEHOLDER: 'Caută organizație',
                ERASE_BUTTON: 'Șterge'
            },
            TABLE_HEADER: {
                NAME: 'Nume',
                DESCRIPTION: 'Descriere',
                STATUS: 'Stare',
                IS_TEST: 'Organizație de test',
                ACTIONS: 'Acțiuni'
            },
            TOOLTIPS: {
                EDIT: 'Editare',
                DELETE: 'Ștergere',
                FUNCTIONS: 'Funcții',
                DEPARTMENTS:'Departamente',
                USERS: 'Utilizatori',
                ACCOUNTS:'Conturi colectoare',
                TAXES: 'Taxe',
                RESPONSIBILITIES: 'Responsibilities',
            },
            STATUS_TYPE: {
                ACTIVE: 'Activ',
                INACTIVE: 'Inactiv'
            }
        },
        TABS: {
            DETAILS: 'Detalii organizație',
            FUNCTIONS: 'Funcții',
            DEPARTMENTS: 'Departamente',
            TAXES: 'Taxe',
            USERS: 'Utilizatori',
            ACCOUNTS:'Conturi colectoare',
            DOCUMENT_SERIES: 'Document series',
            RESONSIBILITIES: 'Responsibilities'
        },
        ADD_EDIT_FORM: {
            NAME: 'Nume organizație',
            TYPE: 'Tip',
            DESCRIPTION: 'Descriere',
            COUNTY: 'Județ',
            CITY: 'Localitate',
            STATUS_FIELD: 'Status',
            TEST_INST: 'Organizație de test',
            STATUS: {
                ACTIVE: 'Activ',
                INACTIVE: 'Inactiv'
            },
            TYPES: {
                COUNTY_COUNCIL: 'Consiliul Județean',
                CITY_HALL: 'Primărie',
                SERVICES: 'Servicii'
            },
            PLACEHOLDER: 'Introduceți descrierea organizației'
        },
        ADD_MODAL: {
            HEADER: 'Adaugă organizație'
        },
        BUTTON: {
            ADD: 'Adaugă',
            CANCEL: 'Renunță',
            SAVE: 'Salvează',
            UPDATE: 'Modifică',
        },
        FUNCTIONS: {
            SEARCH: {
                PLACEHOLDER: 'Caută functie',
                ERASE_BUTTON: 'Șterge'
            },
            TABLE_HEADER: {
                NAME: 'Nume Funcție',
                DESCRIPTION: 'Descriere',
                ACTIONS: 'Acțiuni'
            },
            TOOLTIPS: {
                EDIT: 'Editare',
                DELETE: 'Ștergere',
                USERS: 'Utilizatori',
                RESPOSABILITIES:'Asociere responsabilități',
            },
        },
        ERRORS: {
            ORG: 'Eroare la generarea listei de instituții!',
            SINGLE_ORG: 'Eroare la generarea datelor pentru instituție!',
            FUNCTIONS: 'Eroare la generarea listei de funcții!',
            UPDATE_ORG: 'Eroare la modificarea instituției.',
            ADD_ORG: 'Eroare la adăugarea instituției.',
            DELETE_ORG: 'Această instituție nu poate fi stearsă fiindcă există utilizatori asociați',
        },
        SUCCESS: {
            UPDATE_ORG: 'Instituția a fost modificată cu succes.',
            ADD_ORG: 'Instituția a fost adaugată cu succes.',
            DELETE_ORG: 'Instituția a fost stearsă cu succes.'
        },
        ORG_SELECTION_FORM: {
            HEADER: 'Selectare organizație activă'
        }
    },
    DEPARTMENTS: {
        PAGE_HEADER: 'Departamente',
        LIST: {
            TITLE: 'Lista departamentelor',
        },
        FIELDS: {
            NAME: 'Denumire',
            STATUS: 'Stare',
            DESCRIPTION: 'Descriere',
        },
        PLACEHOLDERS: {
            NAME: 'Nume',
            STATUS: 'Stare',
            DESCRIPTION: 'Descriere',
        },
        ENUMS: {
            STATUS: {
                ACTIVE: 'Activ',
                INACTIVE: 'Inactiv',
            },
        },
        TABLE_HEADER: {
            NAME: 'Nume Departament/Serviciu',
            DESCRIPTION: 'Descriere',
            STATUS: 'Stare',
            ACTIONS: 'Acțiuni'
        },
        TOOLTIPS: {
            FUNCTIONS: 'Asignare Funcții',
            USERS: 'Utilizatori',
        },
        ADD_EDIT_MODAL: {
            HEADER: {
                ADD: 'Adaugă departament',
                EDIT: 'Modifică departament'
            },
            NAME: 'Nume departament',
            DESCRIPTION: 'Descriere',
            STATUS: 'Stare',
            STATUS_TYPE: {
                ACTIVE: 'Activ',
                INACTIVE: 'Inactiv'
            },
            TYPES: {
                COUNTY_COUNCIL: 'Consiliul Județean',
                CITY_HALL: 'Primărie',
                SERVICES: 'Servicii'
            },
            PLACEHOLDER: 'Introduceți descrierea departamentului'
        },
        ASSOCIATED_FCT_MODAL: {
            HEADER: 'Asignare Funcții',
            ERRORS: {
                LIST: 'Eroare la generarea listei de funcții asociate!',
                ADD_FUNCTION: 'Eroare la adaugarea funcției la departament.'
            }
        },
        ERRORS: {
            DEP_LIST: 'Eroare la generarea listei de departamente!',
            ADD: 'Eroare la adăugarea departamentului.',
            UPDATE: 'Eroare la modificarea departamentului.',
        },
        SUCCESS: {
            ADD: 'Departamentul a fost adaugat cu succes.',
            UPDATE: 'Departamentul a fost modificat cu succes.',
        }
    },
    FUNCTIONS: {
        PAGE_HEADER: 'Funcții',
        LIST: {
            TITLE: 'Lista funcțiilor',
        },
        FIELDS: {
            NAME: 'Denumire',
            DESCRIPTION: 'Descriere',
        },
        PLACEHOLDERS: {
            NAME: 'Nume',
            DESCRIPTION: 'Descriere',
        },
        ASSOCIATED_RESPOSABILITIES_MODAL: {
            HEADER: 'Asignare Responsabilități',
            BODY: {
                TOP: 'Funcția',
            }
        },
        ADD_EDIT_FORM: {
            HEADER_ADD: 'Adaugă funcție',
            HEADER_EDIT:'Editează funcție',
            NAME: 'Denumire',
            DESCRIPTION: 'Descriere',
            PLACEHOLDER: 'Introduceți descrierea funcției'
        },
        SUCCESS: {
            ADD: 'Funcția a fost adaugată cu succes.',
            UPDATE: 'Funcția a fost modificată cu succes.',
        }
    },
    TERRITORIAL_UNIT_ALLOCATION:{
        HEADER: 'Asignare UAT',
        FIELDS: {
            TU_NAME: 'Denumire',
            TERRITORIAL_ADMINISTRATIVE_UNIT_ID: 'UAT',
        },
        PLACEHOLDERS: {
            TU_NAME: 'Nume',
        },
        MODAL: {
            HEADER: 'Adaugă UAT',
            OBS: 'UAT-urile asignate nu apar in listă'
        },
        BUTTON: {
            ADD_UAT: 'Adaugă UAT',
        },
        TABLE_EMPTY_TEXT: 'Nu sunt UAT-uri asignate pentru acest utilizator.'
    },
    ADMIN_USERS_MODAL: {
        HEADER: 'Listă de conturi utilizatori',
        PLACEHOLDER: 'Caută utilizator',
        TABLE_HEADER: {
            LAST_NAME: 'Nume',
            FIRST_NAME: 'Prenume',
            FUNCTION: 'Funcție',
            STATUS: 'Stare',
            STATUS_ACTIVE: 'Activ',
            STATUS_INACTIVE: 'Inactiv',
        },
        FUNCTIONS: {
            ERRORS: {
                LIST: 'Eroare la generarea listei de utilizatori pentru o funcție!',
            },
        },
        DEPARTMENTS:{
            ERRORS: {
                LIST: 'Eroare la generarea listei de utilizatori pentru un departament!'
            },
        }
    },
    ACCOUNTS: {
        BUTTONS: {
            ADD_ACCOUNT: 'Adauga cont',
            CANCEL: 'Renunță',
            INVITE: 'Invită prin email',
            RESEND_LINK: 'Retrimite link',
            SAVE: 'Salvează',
        },
        ENUMS: {
            ROLES: {
                ADMINISTRATOR: 'Administrator',
                EDITOR: 'Editor',
            },
            STATUS: {
                ACTIVE: 'Activ',
                INACTIVE: 'Inactiv',
                PENDING: 'Trimis',
            },
        },
        FIELDS: {
            CREATED_BY_NAME: 'Creat de',
            EMAIL: 'E-mail',
            FIRST_NAME: 'Prenume',
            LAST_NAME: 'Nume',
            PHONE: 'Telefon',
            STATUS: 'Status',
            ROLE: 'Rol',
            USER_NAME: 'Nume',
        },
        LABELS: {
            CREATED_BY: 'Creat de',
            USER: 'Utilizator',
        },
        LIST: {
            ACTIONS: 'Acțiuni',
            EDIT: 'Editează',
            HEADER: 'Conturi conectate',
        },
        MODALS: {
            EDIT_ACCOUNT: 'Editează contul',
            ADD_NEW_ACCOUNT: 'Adaugă un cont nou',
        },
        NOTIFIERS: {
            EDITED_SUCCESS: 'Contul a fost actualizat!',
            INVITE_TEXT: 'O invitație a fost trimisă cu succes pe adresa de e-mail <strong>{email}</strong>. După validarea contului aceasta va apărea în cadrul organizației',
            INVITE_TITLE: 'Invitație trimisă',
            RESEND_SUCCESS: 'Linkul a fost retrimis!',
        },
        PLACEHOLDERS: {
            CHOOSE: 'Alege',
            ROL: 'Rol',
            SEARCH_NAME: 'Cauta numele',
            STATUS: 'Status',
        },
        TITLE: 'Administrare conturi',
    },
    COLLECTOR_ACCOUNTS: {
        PAGE_HEADER: 'Conturi colectoare',
        LIST: {
            TITLE: 'Lista conturilor colectoare',
        },
        FIELDS: {
            NAME: 'Nume',
            BANK: 'Banca',
            IBAN: 'Cont IBAN',
            STATUS: 'Stare',
        },
        PLACEHOLDERS: {
            NAME: 'Nume',
            BANK: 'Banca',
            IBAN: 'Cont IBAN',
            STATUS: 'Stare',
        },
        ENUMS: {
            STATUS: {
                ACTIVE: 'Activ',
                INACTIVE: 'Inactiv',
            },
        },
        TABLE_HEADER: {
            NAME: 'Nume',
            BANK: 'Banca',
            IBAN: 'Cont IBAN',
            STATUS: 'Stare',
            ACTIONS: 'Acțiuni'
        },
        STATUS_TYPE: {
            ACTIVE: 'Activ',
            INACTIVE: 'Inactiv'
        },
        TOOLTIPS: {
            EDIT: 'Editare',
            DELETE: 'Ștergere',
        },
        MANAGE_ACCOUNT_MODAL: {
            HEADER: {
                EDIT: 'Editeaza Cont',
                ADD: 'Adauga Cont'
            },
            FORM: {
                NAME: 'Nume',
                BANK: 'Banca',
                IBAN: 'Cont IBAN',
                STATUS: 'Stare',
            }
        },
        CONFIRMATION_MESSAGE: 'Esti sigur ca vrei sa stergi contul colector?',
        SUCCESS: {
            DELETE: 'Contul Colector a fost sters cu succes.',
            ADD: 'Contul colector a fost adaugat cu succes.',
            UPDATE: 'Contul colector a fost modificat cu succes.',
        }
    },

    TAX_CATEGORY: {
        LIST: {
            TITLE: 'Categorii de taxare',
        },
        FILTER: {
            TITLE: 'Categorii de taxare',
        },
        ADD_BUTTON: 'Categorie noua',
        FIELDS: {
            NAME: 'Denumire',
            STATUS: 'Status',
        },
        FORM: {
            EDIT_TITLE: 'Editeaza categorie',
            ADD_TITLE: 'Categorie noua',
        },
        ENUMS: {
            STATUS: {
                ACTIVE: 'Activ',
                INACTIVE: 'Inactiv'
            }
        },
        PLACEHOLDERS: {
            NAME: 'Cauta dupa denumire',
            STATUS: 'Status'
        },
    },
    COMPLAIN_TYPE: {
        LIST: {
            TITLE: 'Tipologii de sesizări',
        },
        ADD_BUTTON: 'Tipologie nouă',
        FIELDS: {
            NAME: 'Denumire',
            STATUS: 'Status',
            TENANT_ID: 'Organizație',
        },
        FORM: {
            EDIT_TITLE: 'Editează tip sesizare',
            ADD_TITLE: 'Tip sesizare nouă',
        },
        ENUMS: {
            STATUS: {
                ACTIVE: 'Activ',
                INACTIVE: 'Inactiv'
            }
        },
        PLACEHOLDERS: {
            NAME: 'Caută după denumire',
            STATUS: 'Status'
        },
    },
    COMPLAIN: {
        LIST: {
            TITLE: 'Sesizări',
            TABLE_HEADER: 'Istoric sesizări',
            ADD_BUTTON: 'Sesizare nouă',
        },
        STAFF_LIST: {
            TITLE: 'Sesizari',
            TABLE_HEADER: 'Sesizari si controale',
            ADD_BUTTON: 'Control nou',
        },
        FIELDS: {
            COMPLAIN_TYPE: 'Tip sesizare',
            LOCALITY: 'Localitate',
            ADDRESS: 'Adresă',
            DESCRIPTION: 'Descrierea sesizării',
            FIRST_NAME: 'Nume',
            LAST_NAME: 'Prenume',
            EMAIL: 'E-mail',
            PHONE: 'Telefon',
            STATUS: 'Status',
            REPORTED_BY: 'Creat de',
            REPORTED_FOR: 'Persoana de contact',
            IDENTIFICATION_NUMBER: 'Cod intrare',
            CREATED_DATE: 'Data/ora',
            USE_PROFILE_DATA: 'Folosește datele profilului meu',
            TERRITORIAL_ADMINISTRATIVE_UNIT_ID: 'UAT',
        },
        FORM: {
            TITLE: 'Formular sesizare nouă',
            COMPLAIN_DETAIL_SECTION_TITLE: 'Detalii sesiare',
            USER_DETAIL_SECTION_TITLE: 'Detalii solicitant / persoană de contact',
            ATLAS_PREVIEW_SECTION_TITLE: 'Previzualizare din atlas teritorial',
            DOCUMENTS_SECTION_TITLE: 'Incarcă documente / fotografii',
            VIEW_DOCUMENTS_SECTION_TITLE: 'Vizualizare documente / fotografii',
            UPLOAD_DOCUMENT_BUTTON: 'Incarcă document',
            USE_MY_PROFILE_DATA_LABEL: 'Folosește datele profilului meu',
            SUBMIT_BUTTON: 'Trimite sesizarea',
            GO_TO_ATLAS_BUTTON: 'Mergi la atlas',
            CLASSIFY_BUTTON: 'Clasare',
            EMPTY_DOCUMENT_LABEL: 'Nu există documente atașate',
        },
        TABS: {
            COMPLAIN: 'Sesizare',
            DISCUSSIONS: 'Discuții',
        },
        PLACEHOLDERS: {
            DESCRIPTION: 'Mesajul tău',
            STATUS: 'Status',
            REPORTED_BY: 'Creat de',
            REPORTED_FOR: 'Persoana de contact',
            LOCALITY: 'Localitate'
        },
        ENUMS: {
            STATUS: {
                NEW: 'Inregistrat',
                PENDING: 'Procesare',
                RESOLVED: 'Solutionată',
                REJECTED: 'Respinsă',
                DISMISSED: 'Clasată',
            }
        },
        NOTIFIERS: {
            CREATED_SUCCESS_TITLE: 'Sesizare trimisă',
            CREATED_SUCCESS_TEXT: 'Sesizare cu numarul <strong>{number}</strong> a fost trimisă cu success.</br> Dupa o analiza si verificare vei fi notificat in legatura cu rezolvarea acesteia.',
            CLASSIFICATION_MESSAGE: 'Ești sigur că vrei să clasezi această sesizare?',
            CLASSIFICATION_SUCCESS_TEXT: 'Sesizarea a fost clasată.',
            RESOLVE_MESSAGE: 'Sunteţi sigur că doriţi să soluţionaţi sesizarea?',
            RESOLVE_SUCCESS_TEXT: 'Sesizarea a fost soluţionată.',
            LOCATION_TITLE: 'Lipsă locaţie!',
            LOCATION_TEXT: 'Te rog alege locaţia sesizării pe hartă pentru a depune sesizarea.'
        },
    },
    FORM_FIELDS: {
        SAVE_BTN: 'Salveaza',
        INVITE_BTN: 'Invita prin email',
        CANCEL_BTN: 'Renunta',
        FIRST_NAME: 'Nume',
        LAST_NAME: 'Prenume',
        FULL_NAME: 'Nume Prenume',
        EMAIL: 'Email',
        PHONE: 'Telefon',
        DEPARTMENT: 'Departament',
        ORGANISATION: 'Institutie',
        ROLE: 'Rol',
        TAG_ID: 'Nr legitimatie',
        TAG_RELEASED_BY: 'Legitimatie eliberata de',
        FUNCTION: 'Functie',
        RESPONSIBILITIES: 'Responsabilitati',
        STATUS: 'Status',
        MESSAGES: {
            ACCOUNT_CREATED: 'Contul a fost creat',
            ACCOUNT_UPDATED: 'Contul a fost modificat',
            ACCOUNT_NOT_CREATED: 'A aparut o eroare la crearea contului',
            ACCOUNT_NOT_UPDATED: 'A aparut o eroare la modificarea contului'
        },
        ASSIGNED_TERRITORIAL_ADMINISTRATIVE_UNIT: 'UAT-uri asignate'
    },
    FUNCTIONARY: {
        LIST: {
            TITLE: 'Staff',
        },
        ENUMS: {
            STATUS: {
                ACTIVE: 'Active',
                INACTIVE: 'Inactive',
                PENDING: 'Pending',
            },
        },
        FIELDS: {
            FIRST_NAME: 'First name',
            LAST_NAME: 'Last name',
            EMAIL: 'Email',
            STATUS: 'Status',
            FULL_NAME: 'Fullname',
            DEPARTMENTS: 'Department',
            FUNCTIONS: 'Function',
            ORGANISATION: 'Organisation'
        },
        PLACEHOLDERS: {
            EMAIL: 'Email',
            STATUS: 'Status',
            FULL_NAME: 'Fullname',
            DEPARTMENTS: 'Department',
            FUNCTIONS: 'Function',
            ORGANISATION: 'Organisation'
        },
        HEADER_LIST: 'Manage staff accounts',
        ADD_BTN: 'Add account',
        ADD_ACCOUNT_HEADER: 'Add new staff account',
        EDIT_ACCOUNT_HEADER: 'Edit account',
        TABLE_HEADER: {
            ACTIONS: 'Actions'
        },
        DELETE_ACCOUNT_MESSAGE: 'Are you sure you want to delete the staff account?',
        DELETED_ACCOUNT_SUCCESS: 'Account deleted',
        RESET_PASS_SUCCESS: 'An email with reset instructions was sent',
        CHANGE_STATUS_SUCCESS: 'Account status modified',
        DENY_DELETE_ACCOUNT: 'Tha account cannot be deleted',
        CHANGE_ACCOUNT_STATUS: 'Are you sure you want to change the account status?',
        RESET_PASSWORD: 'Reset password',
        RESET_PASSWORD_CONFIRM: 'Are you sure you want to reset the password?',
        CHANGE_ACCOUNT_STATUS_ACTION: 'Activate/deactivate account',
        DENY_RESET_PASSWORD: 'You cannot reset the password for an inactive account',
        TOOLTIPS: {
            TERRITORIAL_UNIT_ALLOCATION: 'Adăugare/Ștergere UAT-uri'
        }
    },
    RESPONSE_TEMPLATES: {
        LIST: {
            TITLE: 'Template-uri de raspuns',
            ACTIONS: 'Actiuni'
        },
        ADD_BUTTON: 'Adauga template',
        FIELDS: {
            TITLE: 'Titlu',
            CATEGORY: 'Categorie',
            MESSAGE: 'Mesaj template',
        },
        FORM: {
            EDIT_TITLE: 'Editeaza template',
            ADD_TITLE: 'Adauga template',
        },
        ENUMS: {
            CATEGORY: {
                COMPLAINS: 'Sesizari',
                APPLICATIONS: 'Cereri',
                NOTICE: 'Avize'
            }
        },
        PLACEHOLDERS: {
            TITLE: 'Cauta dupa titlu',
            CATEGORY: 'Categorie',
            MESSAGE: 'Mesaj template',
        },
        CATEGORY: {
            COMPLAINS: 'sesizare',
            APPLICATIONS: 'cerere',
            NOTICE: 'aviz'
        }
    },
    TAXES: {
        BUTTONS: {
            ADD_TAX: 'Adaugă taxă',
        },
        FIELDS: {
            NAME: 'Denumire',
            ENTRY_DATE: 'Data intrării în vigoare',
            JUSTIFICATION: 'HCL/NR/DATA',
            CATEGORY: 'Category',
            FIXED_VALUE: 'Value',
            PERCENTAGE_VALUE: 'Percentage value',
            PRICE_CALCULATION_MODEL: 'Calculation type',
            START: 'Start',
            END: 'End',
            VALUE: 'Value'
        },
        LABELS: {
        },
        LIST: {
            ACTIONS: 'Acțiuni',
            EDIT: 'Editează',
            HEADER: 'Taxe',
        },
        MODALS: {
            ADD_TITLE: 'Adaugă taxă',
            EDIT_TITLE: 'Editează taxă',
            HCL: 'HCL / Altă justificare',
            ADD: 'Adaugă',
            SAVE: 'Salvează',
            CANCEL: 'Renunță',
        },
        NOTIFIERS: {
            SAVED: 'Tax has been saved',
            EDITED: 'Tax has been updated'
        },
        PLACEHOLDERS: {
            NAME: 'Denumire',
            CATEGORY: 'Categorie',
        },
    },
    DOCUMENT_SERIES: {
        BUTTONS: {
            ADD_SERIES: 'Add series'
        },
        FIELDS: {
            NAME: 'Name',
            SEPARATOR: 'Separator',
            DESCRIPTION: 'Description',
            PREFIX: 'Prefix',
            REJECTED_PREFIX: 'Prefix adresă răspuns',
            CURRENT_INDEX: 'Current index',
            YEARLY_RESET: 'Yearly reset',
            ACTIVE: 'Active',
            SERIES_TYPE: 'Series type'
        },
        LIST: {
            EDIT: 'Edit',
            HEADER: 'List of document series',
        },
        MODALS: {
            ADD_TITLE: 'Add document series',
            EDIT_TITLE: 'Edit document series',
            ADD: 'Add',
            SAVE: 'Save',
            CANCEL: 'Cancel',
        },
        NOTIFIERS: {
            SAVED: 'The document series has been saved',
            EDITED: 'The document series has been updated'
        },
        PLACEHOLDERS: {
            NAME: 'Name',
        },
    },
    ORG_RESPONSIBILITIES:{
        BUTTONS: {
            ADD_RESPONSIBILITY: 'Adaugă responsabilitate'
        },
        LIST: {
            TITLE: 'Responsabilități',
        },
        FILTER: {
            TITLE: 'Listă Responsabilități',
        },
        FIELDS: {
            RESPONSIBILITY_NAME: 'Denumire',
            RESPONSIBILITIES: 'Responsabilități'
        },
        PLACEHOLDERS: {
            RESPONSIBILITY_NAME: 'Caută dupa denumire',
            RESPONSIBILITIES: 'Responsabilități'
        },
        FORM: {
            ADD_TITLE: 'Adaugă responsabilitate',
            EDIT_TITLE: 'Modifică responsabilitate',
            SELECTED_RESPONSIBILITY: 'Responsabilitate selectată',
            PERMISSIONS: 'Permisiuni'
        },
    },
    RESPONSIBILITY: {
        FILTER: {
            TITLE: 'Responsibilities',
        },
        FIELDS: {
            NAME: 'Name',
            DESCRIPTION: 'Description',
            CREATED_DATE: 'Created date',
            UPDATED_DATE: 'Update date',
            ACTIONS: 'Actions',
        },
        PLACEHOLDERS: {
            NAME: 'Search by name',
            PERMISSIONS: 'Search permisssion'
        },
        FORM: {
            ASSOCIATED_PERMISSIONS: 'Permisiuni asociate',
            EDIT_TITLE: 'Editează responsabilitate',
            ADD_TITLE: 'Adaugă responsabilitate',
        },
    },
    APPLICATION: {
        LIST: {
            TITLE_STAFF: 'Applications',
            TITLE: 'Applications',
            TABLE_HEADER: 'New applications',
            ADD_BUTTON: 'New application',
            DOCUMENT_CREATED_HEADER: 'Created documents',
            DOCUMENT_RECEIVED_HEADER: 'Received documents',
            NO_ASSIGNEE: 'Nealocată',
            SIGN_DOCUMENT_BULK: 'Semnează documente',
        },
        FIELDS: {
            APPLICATION_TYPE_NAME: 'Application type',
            APPLICATION_TYPE_PURPOSE: 'Application purpose',
            TERRITORIAL_ADMINISTRATIVE_UNIT: 'UAT',
            TERRITORIAL_ADMINISTRATIVE_UNIT_TYPE: 'UAT type',
            PAYMENT: 'Payment',
            STATUS: 'Status',
            LOCALITY: 'Locality',
            EMPLACEMENT_LOCALITY: 'Localitate',
            FIRST_NAME: 'First name',
            LAST_NAME: 'Last name',
            EMAIL: 'E-mail',
            PHONE: 'Phone',
            IDENTIFICATION_NUMBER: 'Identification number',
            CREATED_DATE: 'Created date',
            RESPONSE_DUE_IN: 'Remaining time',
            CADASTRAL_NUMBER: 'Cadastral number',
            USER_TYPE_NAME: 'User type',
            PERSONAL_NUMERIC_CODE: 'Personal numeric code',
            COUNTY: 'County',
            VILLAGE: 'Village',
            SECTOR: 'Sector',
            STREET: 'Street',
            STREET_NUMBER: 'Street number',
            POSTAL_CODE: 'Postal code',
            STAIRCASE: 'Staircase',
            BLOCK: 'Block',
            FLOOR: 'Floor',
            APARTMENT: 'Apartment',
            REAL_ESTATE_TYPE: 'Real estate type',
            LAND_AREA: 'Suprafata terenului',
            AUTHORISATION_CODE: 'Authorisation code',
            SUBSIDIARY: 'Subsidiary',
            LAND_AREA: 'Land area',
            OTHER_IDENTIFICATION: 'Other identification',
            CREATED_BY: 'Created by',
            DOCUMENT_TYPE: 'Document type',
            RESOLVED_DATE: 'Resolved date',
            PIN: 'Confirmation code',
            USE_PROFILE_DATA: 'Use profile data',
            MESSAGE_COMMENT: 'Mesajul tău',
            CONTACT_PERSON: 'Persoană contact',
            BENEFICIARY: 'Beneficiar',
            IS_PAID: 'Plată',
            IS_SIGNED: 'Semnatură',
            CADASTRAL_NUMBER: 'Nr cadastral',
            COMMENTS: 'Comments',
        },
        ENUMS: {
            STATUS: {
                NEW: 'New',
                PENDING: 'Pending',
                RESOLVED: 'Resolved',
                REJECTED: 'Rejected',
                APPROVED: 'Approved',
                DRAFT: 'Draft',
                GENERATED: 'Generated',
                SIGNED: 'Signed',
                SIGN_PENDING: 'În semnare',
                REVIEW: 'Necesită clarificări',
            },
            REAL_ESTATE_TYPE: {
                LAND: 'Land',
                LAND_CONSTRUCTIONS: 'Land + Constructions',
            }
        },
        PLACEHOLDERS: {
            BENEFICIARY: 'Search by name',
            SERVICE: 'Service',
            ALLOCATED_STAFF: 'Allocated stuff',
            IDENTIFICATION_NUMBER: 'Search by ID',
            APPLICATION_TYPE_NAME: 'Application type',
            CADASTRAL_NUMBER: 'Cadastral number',
            APPLICATION_TYPE_PURPOSE: 'Application purpose',
            TERRITORIAL_ADMINISTRATIVE_UNIT: 'UAT',
            STATUS: 'Status',
            COUNTY: 'County',
            LOCALITY: 'Locality',
            RESPONSE_DUE_IN: 'Remaining time',
            VILLAGE: 'Village',
            TERRITORIAL_ADMINISTRATIVE_UNIT_TYPE: 'UAT type',
            EMPLACEMENT_LOCALITY: 'Locality',
            MESSAGE: 'Mesaj',
            APPLICATION_TYPE_NAME: 'Selectează tipul cererii',
            ASSIGNED_TO: 'Responsabil',
        },
        FORM: {
            TITLE: 'New application form',
            SUBMIT_BUTTON: 'Send application',
            SAVE_AS_DRAFT_BUTTON: 'Save as draft',
            CANCEL_BUTTON: 'Cancel application',
            DETAILS_SECTION: 'Detalii cerere',
            TAXES_BUTTON: 'Calculează taxe',
            TAXES_ERROR: 'Vă rugăm selectați tipul cererii și completați suprafața terenului',
            WIZARD_TITLE: 'Depunere cerere',
            GO_BACK: 'Go back',
            GENERATE_DOCUMENT: 'Generate document',
            NO_TEMPLATE: 'Nu există un template pentru acest tip de cerere',
            PAY_BUTTON: 'Pay and sign',
            SIGN_BUTTON: 'Sign',
            SEND_MESSAGE_BUTTON: 'Trimite mesajul',
            SEND_REVIEW: 'Trimite clarificări',
            PENDING_RELATED_APPLICATIONS: 'Avizul de primar în lucru',
        },
        NOTIFIERS: {
            CREATED_SUCCESS_TITLE: 'Cerere trimisă',
            CREATED_SUCCESS_TEXT: 'Cererea cu numarul <strong>{number}</strong> a fost trimisă cu success.</br> Dupa o analiza si verificare vei fi notificat in legatura cu rezolvarea acesteia.',
            DUPLICATE_BENEFICIARY_PNC: 'Beneficiarul cu același CNP ({pnc}) este deja adăugat.',
            REVIEW_SENT: 'Clarificările au fost trimise',
            REVIEW_NOT_SENT: 'A apărut o eroare la trimiterea clarificărilor. Te rugăm sa încerci din nou',
        },
        VIEW: {
            TITLE_STAFF: 'Applications registry',
            TITLE: 'Applications',
            SAVE_AS_DRAFT_BUTTON: 'Save as draft',
            REASSIGN_STAFF: 'Reassign staff',
            SEND_RESPONSE: 'Send response',
            APPLICATION_DETAILS_SECTION_TITLE: 'Application details',
            BENEFICIARY_DETAILS_SECTION_TITLE: 'Beneficiary details',
            REAL_ESTATE_DETAILS_SECTION_TITLE: 'Real estate details',
            REAL_ESTATE_EMPLACEMENT_DETAILS_SECTION_TITLE: 'Emplacement details',
            APPLICANT_DETAILS_SECTION_TITLE: 'Applicant details',
            PAYMENT_DETAILS_SECTION_TITLE: 'Payments',
            ATLAS_DETAILS_SECTION_TITLE: 'Atlas preview',
            ATTACHMENTS_DETAILS_SECTION_TITLE: 'Documents',
            NEXT_STEP: 'Next step',
            SIGN_DOCUMENT: 'Sign document',
            ASSIGN_STAFF_MESSAGE: 'Are you sure you want to have this application assigned?',
            APPROVE_DOCUMENT: 'Approve',
            REJECT_DOCUMENT: 'Reject',
            APPROVE_DOCUMENT_MESSAGE: 'Are you sure you want to approve this application?',
            REJECT_DOCUMENT_MESSAGE: 'Are you sure you want to deny this application?',
            GENERATE_DOCUMENT_MESSAGE: 'Document created',
            GENERATE_DOCUMENT_ERROR: 'There was an error while creating the document',
            SIGN_DOCUMENT_ERROR: 'Documentul nu poate fi semnat',
            SIGN_SUCCESS: 'Documentul a fost semnat',
            RETURN_DOCUMENT: 'Regenerare document',
            RETURN_DOCUMENT_MESSAGE: 'Sunteți sigur că vreți să returnați acest document? Această acțiune este ireversibilă și presupune regenerarea actului.',
        },
        TABS: {
            APPLICATION: 'Application details',
            DOCUMENTS: 'Documents',
            ACTIVITY: 'Activities',
            DOCUMENT_CREATED: 'Created',
            DOCUMENT_RECEIVED: 'Received',
        },
        TERRITORIAL_ATLAS: {
            HEADER: 'Territorial atlas preview',
            GENERATE_BUTTON: 'Generate'
        },
        MODALS: {
            ADD: 'Adaugă',
            CANCEL: 'Renunță',
        },
        SIGNATURE: {
            TITLE: 'Alege modalitatea de semnare',
            SECTION_TITLE: 'Semnează cererile și anexele',
            TABS: {
                ONLINE: 'Semnare online',
                OFFLINE: 'Semnare offline',
            },
            ONLINE: {
                HELP_TEXT: 'Pentru depunerea cererii online aceasta va fi semnată digital prin semnătură digitală. Procesul de semnare se poate face de la distanță, iar pentru aceasta aveți nevoie de o semnătură digitală.',
                INFO_TEXT: 'Pentru a intermedia achiziționarea certificatului digital pentru semnături de la certSIGN, vom folosi datele de identificare din contul tău. Le vei furniza o singură dată, iar pe baza lor va fi creat un cod unic de identificare care va garanta autenticitatea solicitărilor tale.',
                PACKAGE_LABEL: 'Alege pachetul de semnături digitale potrivit nevoilor tale. Costul acestora va fi inclus în total.',
                SUMMARY_DIGITAL_SIGNATURE_LABEL: 'Semnături digitale',
                SUMMARY_REQUIRED_SIGNATURES: 'Necesare',
                SUMMARY_AVAILABLE_SIGNATURES: 'Disponibile',
                SUMMARY_LEFTOVER_SIGNATURES: 'Vor rămane',
                SUMMARY_TEXT: 'Semnătură electronică la distanță'
            },
            OFFLINE: {
                HELP_TEXT: 'Alege "SEMNARE ONLINE" dacă dorești să achiziționezi un certificat pentru semnăturile digitale necesare, sau "SEMNARE OFFLINE" dacă deții deja unul de la un furnizor autorizat.',
                INFO_TEXT: 'Descarcă pachetul de documente pe dispozitivul tău, semnează-le și încarcă documentele pentru a continua. Nota bene: este nevoie de semnătură digitală validată cu certificat calificat, nu electronică (sau olografă).',
                ACTION_DOWNLOAD_TITLE: 'Descarcă pachetul de cereri și anexe',
                ACTION_DOWNLOAD_SUBTITLE: 'care trebuie semnate digital',
                ACTION_UPLOAD_TITLE: 'Încarcă pachetul de documente',
                ACTION_UPLOAD_SUBTITLE: 'care trebuie semnate digital',
                STEP_DOWNLOAD: 'Descarcă',
                STEP_SIGN: 'Semnează',
                STEP_VERIFY: 'Verificare',
                STEP_CHECKED: 'Documente ok',
                SUMMARY_TEXT: 'Semnătură electronică personală'
            }
        },
        SUMMARY: {
            SIGN_TYPE_TITLE: 'Modalitate de semnare',
            APPLICATION_DETAIL_TITLE: 'Detalii cerere',
            PAYMENT_TYPE_TITLE: 'Modalitate de plată',
            SIGN_TYPE_FOOTER: 'Asigurat prin CertSign',
            APPLICATION_OBJECTIVE: 'Obiectivul cererii',
            ATTACHED_REAL_ESTATE: 'Imobilele atașate',
            PAYMENT_TYPE: 'Card online',
            PAYMENT_TYPE_HELP_TEXT: 'După inițierea plății, vei fi redirecționat către procesatorul de plăți pentru a efectua plata.',
            DETAILS: 'Desfasurator',
            CERTIFICATE_TOTAL_TAXES: 'Total costuri certificate digitale',
            TOTAL_TAXES: 'Total taxe si avize',
            SECTION_TITLE: 'Revizuieste si trimite cererea',
            TOTAL: 'Total',
        },
        ENROLLMENT_DETAILS: {
            PERSON: {
                HEADER: 'Tip persoană',
                INDIVIDUAL: 'Persoană fizică',
                LEGAL: 'Persoană juridică'
            },
            CITIZENSHIP: {
                HEADER: 'Cetătenie',
                ROMANIAN: 'Cetătean roman',
                FOREIGNER: 'Cetătean strain'
            },
            PERSONAL_NUMERIC_CODE: 'CNP',
            ADDRESS: 'Adresă domiciliu',
            CONTACT: 'Contact',
        },
        ASSIGN_MODAL_TITLE: 'Asignare Cerere',
        ASSIGN_SUCCESS: 'Cererea a fost asignata la <strong>{name}</strong> cu success!'
    },
    ARCHIVE: {
        BUTTONS: {
            MANUAL_ARCHIVE: 'Arhivare manuală',
            ADD_WORKSPACE: 'Adaugă workspace',
            DOWNLOAD: 'Descarcă'
        },
        MENU: {
            DASHBOARD: 'Dashboard',
            DOCUMENTS: 'Documente'
        },
        WORKSPACE: {
            LIST: {
                TITLE: 'Workspaces',
            },
            FIELDS: {
                NAME: 'Denumire',
                ENTITY_TYPE: 'Tip',
                CREATED_DATE: 'Arhivare',
                DIMENSION: 'Dimensiune',
                FOLDERS_NUMBER: 'Nr foldere',
                DESCRIPTION: 'Descriere'
            },
            PLACEHOLDERS: {
                NAME: 'Denumire',
                ENTITY_TYPE: 'Tip',
                CREATED_DATE: 'Arhivare',
            },
            FORM: {
                EDIT_TITLE: 'Edit workspace',
                ADD_TITLE: 'Add workspace'
            }
        },
        SUBFOLDER: {
            LIST: {
                TITLE: 'Documente',
            },
            FIELDS: {
                NAME: 'Denumire',
                TYPE: 'Tip',
                CREATED_DATE: 'Arhivare',
                DIMENSION: 'Dimensiune',
                DOCUMENT_NUMBER: 'Nr Documente',
                WORKSPACE: 'Workspace',
                DESCRIPTION: 'Descriere',
                DOCUMENT_TYPE_CODE: 'Tip document',
            },
            PLACEHOLDERS: {
                NAME: 'Denumire',
                ACHIVE_DATE: 'Arhivare',
            },
            ACTIONS: {
                EDIT: 'Modifica Folder',
                ADD: 'Adauga Folder'
            },
            FORM: {
                WORKSPACE: 'Workspace',
                TYPE: 'Tip Document corelat',
                NAME: 'Denumire Folder'
            },
        },
        DOCUMENTS: {
            LIST: {
                TITLE: 'Documente',
            },
            FIELDS: {
                DOCUMENT_NAME: 'Denumire',
                DOCUMENT_TYPE_NAME: 'Tip',
                DOCUMENT_SIZE: 'Dimensiune',
                CREATED_DATE: 'Data crearii',
                UPDATED_DATE: 'Data modificarii',
            },
            PLACEHOLDERS: {
                NAME: 'Denumire',
                ACHIVE_DATE: 'Arhivare',
            },
            ACTIONS: {
                EDIT: 'Modifica Folder',
                ADD: 'Adauga Folder'
            },
        },
        DOCUMENT: {
            TITLE_MANUAL_ARCHIVE: 'Arhivare manuală',
            TITLE_VIEW_ARCHIVE: 'Vizualizare arhivă',
            HEADERS: {
                ATTACHMENTS: 'Atașamente',
                DETAILS: 'Detalii',
                METADATA: 'Metadate'
            },
            FIELDS: {
                WORKSPACE: 'Workspace',
                DOCUMENT_NAME: 'Nume Document',
                DOCUMENT_FOLDER: 'Folder',
                DOCUMENT_TYPE: 'Tip document',
            },
        },
        CONFIRMATION_TEXT: 'Pentru consistența datelor va rugam sa verificați ca acestea sunt valide si conforme cu documentele arhivate. Pentru orice modificari ulterioare va rugam sa contactati un administrator al sistemului. Sunteti sigur ca doriti sa salvati documentul?',
        MESSAGES: {
            SUCCESS: 'Arhivare manuală realizată cu succes!',
            ERRORS: 'Eroare la arhivarea manuală!'
        },
        ATTACHMENTS: {
            PLACEHOLDER: 'fișiere selectate',
            EMPTY: 'Nu există documente atașate.',
            EMPTY_WARNING: 'Pentru a salva, arhiva trebuie sa conțină fișiere atașate.',
            LABEL: 'Fișier',
            ADD_BUTTON: 'Adaugă atașament'
        },
        REPORTS: {
            WORKSPACE_LABEL: 'Workspace',
            WORKSPACE_DIMENSION: {
                TITLE: 'Total spațiu arhivat',
                DESCRIPTION: 'Raport workspace'
            },
            WORKSPACE_DOCUMENTS: {
                TITLE: 'Total număr documente arhivate',
                DESCRIPTION: 'Raport workspace'
            },
            WORKSPACE_FOLDERS_DIMENSION: {
                TITLE: 'Total dimensiune documente arhivate per workspace',
                CHART_LABELS_TITLE: 'Listă foldere',
                DESCRIPTION: 'Raport foldere din workspace'
            },
            WORKSPACE_FOLDERS_DOCUMENTS: {
                TITLE: 'Total număr documente arhivate per workspace',
                CHART_LABELS_TITLE: 'Listă foldere',
                DESCRIPTION: 'Raport foldere din workspace'
            },
            WORKSPACE_PROGRESS_ANNUAL: {
                TITLE_DOC: 'Progres de arhivare anual - documente',
                TITLE_DIMENSION: 'Progres de arhivare anual - dimensiune',
                TITLE_DESCRIPTION_DOC: 'Fișiere arhivate',
                TITLE_DESCRIPTION_DIMENSION: 'Dimensiune fișiere arhivate',
                TOTAL_DESCRIPTION_DOC: 'Documente in perioda selectată',
                TOTAL_DESCRIPTION_DIMENSION: 'Dimensiune in perioda selectată',
                CHART_TITLE: 'Analiză',
                Y_AXIS_DOC: 'Documente',
                Y_AXIS_DIMENSION: 'Dimensiune',
            },
        },
        PERIOD: {
            ANNUALY: 'anual',
            MONTHLY: 'lunar',
            WEEKLY: 'săptămânal'
        }
    },
    FORMS: {
        LIST: {
            TITLE: 'Interfețe formulare',
            ADD_BUTTON: 'Adaugă formular',
            TABLE_HEADER: 'Modele interfețe formulare',
        },
        FIELDS: {
            NAME: 'Denumire',
            STATUS: 'Stare',
            DESCRIPTION: 'Descriere',
        },
        PLACEHOLDERS: {
            NAME: 'Nume',
        },
        ENUMS: {
            STATUS: {
                DRAFT: 'Draft',
                PUBLISHED: 'Public',
            },
        },
        CREATE_MODAL: {
            TITLE: 'Salvare interfață formular'
        },
        COMPONENTS: {
            FIELDS: {
                input: 'Input',
                textarea: 'Textarea',
                number: 'Number',
                radio: 'Radio',
                checkbox: 'Checkbox',
                time: 'Time',
                date: 'Date',
                rate: 'Rate',
                color: 'Color',
                select: 'Select',
                switch: 'Switch',
                slider: 'Slider',
                text: 'Text',
                blank: 'Custom',
                fileupload: 'File',
                imgupload: 'Image',
                editor: 'Editor',
                cascader: 'Cascader',
                table: 'Sub-table',
                grid: 'Grid',
                tabs: 'Tabs',
                divider: 'Divider',
                file: 'File',
                'beneficiary-list': 'Beneficiar',
                'applicant-form': 'Solicitant',
                'application-detail-form': 'Detalii cerere',
                'real-estate-form': 'Imobil',
            },
            BASIC: {
                TITLE: 'Basic Component',
            },
            ADVANCE: {
                TITLE: 'Advance Component',
            },
            LAYOUT: {
                TITLE: 'Layout',
            },
            CUSTOM: {
                TITLE: 'Custom Component',
            },
        },
        DESCRIPTION: {
            CONTAINER_EMPTY: 'You can drag and drop the item from the left to add components',
            CONFIG_EMPTY: 'Please add a component',
            TABLE_EMPTY: 'You can drag and drop the item from the left to add components',
            UPLOAD_JSON_INFO: 'There is the format of JSON below，you can overwrite it with you own JSON code'
        },
        MESSAGE: {
            COPY_SUCCESS: 'Copy Successed',
            VALID_ERROR: 'Please fill in all the required fields.'
        },
        ACTIONS: {
            IMPORT: 'Import JSON',
            CLEAR: 'Clear',
            PREVIEW: 'Preview',
            JSON: 'Generate JSON',
            CODE: 'Generate Code',
            GET_DATA: 'Get Data',
            RESET: 'Reset',
            COPY_DATA: 'Copy Data',
            CANCEL: 'Cancel',
            CONFIRM: 'Confirm',
            ADD_OPTION: 'Add Option',
            ADD_COLUMN: 'Add Column',
            ADD_TAB: 'Add Tab',
            UPLOAD: 'Upload',
            ADD: 'Add'
        },
        CONFIG: {
            FORM: {
                TITLE: 'Form Attribute',
            },
            WIDGET: {
                TITLE: 'Component Attribute',
                MODEL: 'ID',
                NAME: 'Name',
                META: 'Meta',
                WIDTH: 'Width',
                HEIGHT: 'Height',
                SIZE: 'Size',
                LABEL_WIDTH: 'Label Width',
                CUSTOM: 'Custom',
                PLACEHOLDER: 'Placeholder',
                LAYOUT: 'Layout',
                BLOCK: 'Block',
                INLINE: 'Inline',
                CONTENT_POSITION: 'Content Position',
                LEFT: 'Left',
                RIGHT: 'Right',
                CENTER: 'Center',
                SHOW_INPUT: 'Display Input Box',
                MIN: 'Minimum',
                MAX: 'Maximum',
                STEP: 'Step',
                MULTIPLE: 'Multiple',
                FILTERABLE: 'Searchable',
                ALLOW_HALF: 'Allow Half',
                SHOW_ALPHA: 'Support transparency options',
                SHOW_LABEL: 'Show lable',
                OPTION: 'Option',
                STATIC_DATA: 'Static Data',
                REMOTE_DATA: 'Remote Date',
                REMOTE_FUNC: 'Remote Function',
                VALUE: 'Value',
                LABEL: 'Label',
                CHILDREN_OPTION: 'Sub-Option',
                DEFAULT_VALUE: 'Default Value',
                LIMIT: 'Maximum Upload Count',
                IMAGE_ACTION: 'Picture upload address',
                TIP: 'Text Prompt',
                ACTION: 'Upload Address',
                DEFAULT_TYPE: 'Data Type',
                STRING: 'String',
                OBJECT: 'Object',
                ARRAY: 'Array',
                NUMBER: 'Number',
                BOOLEAN: 'Boolean',
                INTEGER: 'Integer',
                FLOAT: 'Float',
                URL: 'URL',
                EMAIL: 'E-mail',
                HEX: 'Hexadecimal',
                GUTTER: 'Grid Spacing',
                COLUMN_OPTION: 'Column Configuration',
                SPAN: 'Grid spans',
                JUSTIFY: 'Horizontal Arrangement',
                JUSTIFY_START: 'Start',
                JUSTIFY_END: 'End',
                JUSTIFY_CENTER: 'Center',
                JUSTIFY_SPACE_AROUND: 'Space Around',
                JUSTIFY_SPACE_BETWEEN: 'Space Between',
                ALIGN: 'Vertical Arrangement',
                ALIGN_TOP: 'Top',
                ALIGN_MIDDLE: 'Middle',
                ALIGN_BOTTOM: 'Bottom',
                TYPE: 'Type',
                DEFAULT: 'Default',
                CARD: 'Tabs',
                BORDER_CARD: 'Border-Card',
                TAB_POSITION: 'Tab Position',
                TOP: 'Top',
                BOTTOM: 'Bottom',
                TAB_OPTION: 'Label Configuration',
                TAB_NAME: 'Tab Name',
                CUSTOM_CLASS: 'Custom Class',
                ATTRIBUTE: 'Attribute Action',
                DATA_BIND: 'Data Binding',
                HIDDEN: 'Hidden',
                READONLY: 'Read Only',
                DISABLE: 'Disable',
                EDITABLE: 'Text box is editable',
                CLEARABLE: 'Display Clear Button',
                ALLOW_CONTROL: 'Use the arrow for time selection',
                IS_DELETE: 'Deletable',
                IS_EDIT: 'Editable',
                SHOW_PASSWORD: 'Display Password',
                VALIDATE: 'Validation',
                REQUIRED: 'Required',
                PATTERN_PLACEHOLDER: 'Fill in the regular expressions',
                NEW_OPTION: 'New Option',
                TAB: 'Tab',
                VALIDATOR_REQUIRED: 'Required',
                VALIDATOR_TYPE: 'Invaild format',
                VALIDATOR_PATTERN: 'Unmatched pattern',
                SHOW_TOOLTIP: 'Show Tooltip',
                TOOLTIP_TEXT: 'Text Tooltip'
            }
        },
        UPLOAD: {
            PREVIEW: 'preview',
            EDIT: 'replace',
            DELETE: 'delete'
        }
    },
    APPLICATION_TYPE: {
        LIST: {
            TITLE: 'Tipologii de cereri',
        },
        ADD_BUTTON: 'Tipologie nouă',
        FIELDS: {
            NAME: 'Denumire',
            STATUS: 'Status',
            DESCRIPTION: 'Descriere',
            FORM_CITIZEN: 'Interfață formular cetățean',
            FORM_STAFF: 'Interfață formular funcționar',
            REJECT_FORM_STAFF: 'Interfață formular respingere funcționar',
            DOCUMENT_TEMPLATE: 'Formular tipizat funcționar',
            REJECT_DOCUMENT_TEMPLATE: 'Formular tipizat respingere funcționar',
            TAX_CATEGORY: 'Categorie de taxare',
            APPLICATION_TYPE_REF: 'Documente referință',
            ARCHIVE_DOCUMENT_TYPE_CODE: 'Tip document arhivă',
            WORKFLOW: 'Proces',
            TENANT_ID: 'Organizație',
            IS_AUTO: 'Tipologie automată',
        },
        FORM: {
            EDIT_TITLE: 'Editează tip cerere',
            ADD_TITLE: 'Tip cerere nouă',
        },
        ENUMS: {
            STATUS: {
                ACTIVE: 'Activ',
                INACTIVE: 'Inactiv'
            }
        },
        PLACEHOLDERS: {
            NAME: 'Caută după denumire',
            STATUS: 'Status',
            NO_REF_DOC: 'Nici un document de referință',
        },
        MESSAGES: {
            IS_AUTO: 'O tipologie automata nu are documente de referinta'
        }
    },
    DOCUMENT_TEMPLATE: {
        LIST: {
            TITLE: 'Formulare tipizate',
            ADD_BUTTON: 'Formular nou',
            TABLE_HEADER: 'Modele formulare',
        },
        FIELDS: {
            NAME: 'Denumire',
            DESCRIPTION: 'Descriere',
            STATUS: 'Stare',
            CREATED_DATE: 'Data inserării',
        },
        PLACEHOLDERS: {
            STATUS: 'Stare',
            NAME: 'Nume',
        },
        ENUMS: {
            STATUS: {
                ACTIVE: 'Activ',
                INACTIVE: 'Inactiv',
            },
        },
        CREATE_MODAL: {
            TITLE: 'Salvare formular tipizat'
        },
        EDIT_MODAL: {
            TITLE: 'Editare formular tipizat'
        },
        FORM: {
            TITLE: 'Template formular',
            DOWNLOAD_BUTTON: 'Descarcă',
            FIELDS_LIST_TITLE: 'Inserează câmpuri',
            APPLICATION_TYPES_LABEL: 'Selectează tipul de cerere',
            RELATED_APPLICATION_TYPES_LABEL: 'Selectează tipul de cerere asociat'
        }
    },
    PROCESS_EDITOR:{
        HEADER: 'Management procese',
        NEW_PROCESS_BTN: 'Proces nou',
        GENERAL: {
            HEADER: 'Process editor',
            HEADER_LIST: 'Processes list',
            TITLE: 'New Process',
            SAVE_BTN: 'Save',
            EDIT_BTN: 'Update',
            FIELDS: {
                NAME: 'Denumire',
                DESCRIPTION: 'Descriere',

            },
            PLACEHOLDERS: {
                NAME: 'Caută dupa denumire',
                DESCRIPTION: 'Descriere'
            },
        },
        TABS: {
            GENERAL: 'General',
            STEPS: 'Steps'
        },
        STEPS: {
            HEADER: 'Editor de proces',
            HEADER_LIST: 'Etape Create',
            EDIT_MODAL_HEADER: 'Modifică etapă',
            ADD_MODAL_HEADER: 'Adaugă etapă',
            ADD_BTN: 'Adaugă etapă',
            SAVE_LIST_BTN: 'Salvează etape',
            PUBLISH_BTN: 'Publish',
            PUBLISH_MSG: 'Start step is not first in list. The list cannot be published',
            PUBLISH_CONFIRM_MSG: 'You are sure you want to publish this process? The process will be ready to use.',
            FIELDS: {
                NAME: 'Nume Etapă',
                ACTION_NAME: 'Actiune',
                ACTION_TYPE: 'Tip Actiune',
                NEXT_STEP: 'Etapa urmatoare',
                CONDITIONAL_ROUTING: 'Rutare conditionata',
                FUNCTION_TYPE: 'Funcție',
                SIGN_BY_ASSIGNED_USER: 'Semnare de către userul asignat',
                APPLICATION_STATUS: 'Status cerere',
                RETURN_ACTION: 'Revenire la',
            },
            PLACEHOLDERS: {
                NAME: 'Nume',
                ACTION_NAME: 'Actiune',
                ACTION_TYPE: 'Tip Actiune',
                NEXT_STEP: 'Etapa urmatoare',
                CONDITIONAL_ROUTING: 'Rutare conditionata',
                FILE_STATUS: 'File status',
                FILE_TYPE: 'File type'
            },
            ACTIONS: {
                START: 'Start',
                END: 'End'
            }
        },
        ENUMS: {
            STATUS: {
                YES: 'DA',
                NO: 'NU',
                DRAFT: 'Draft',
                ERROR: 'Nepublicat',
                PUBLISHED: 'Publicat',
                PENDING: 'Procesare',
            },
        },
        TABLE_HEADER: {
            TRANSITION: 'Tranzitie',
            ACTIONS: 'Actiuni'
        },
        MESSAGES: {
            ALREADY_EXISTS: 'Un proces cu această denumire există deja'
        }
    },
    PERMISSIONS: {
        HEADER: 'Permissions',
        FILTER: {
            TITLE: 'Permissions list',
        },
        BUTTONS: {
            SET_PERMISSIONS: 'Set Permission',
        },
        FIELDS: {
            NAME: 'Name',
            PERMISSION_NAME: 'Name',
            DESCRIPTION: 'Description',
            CATEGORY: 'Category',
            SCOPE: 'Scope',
            ORGANISATIONS: 'Organisation',
            SYSTEM_DEFAULT: 'Default Assigned',
            DEFAULT_ASSIGNED_TO: 'User Default',
            APPLY_DEFAULT_TO_ORGANISATION: 'Organisation Default',
        },
        PLACEHOLDERS: {
            NAME: 'Search by name',
            PERMISSION_NAME: 'Search by name',
        },
        FORM: {
            TITLE: 'Set Permissions',
            PERMISSIONS: 'Permissions'
        },
    },
    REAL_ESTATE: {
        FORM: {
            HEADER: 'Adaugă amplasament',
            ADD_BTN: 'Adaugă amplasament nou',
            ADD_FILE_LABEL: 'Adaugă plan cadastral (pdf/zip)',
            ADD_FILE_BTN: 'Alege fișiere',
            DUPLICATE_CADASTRAL_NUMBER: 'Acest număr cadastral este deja adăugat'
        },
        VIEW: {
            ADDRESS: 'Adresă',
            CONTACT: 'Contact',
            CADASTRAL_PLAN: 'Plan cadastral',
            NO_FILES: 'Nu există fișiere',
            FILES_LABEL: 'Fișiere de identificare',
        },
        SINGLE: 'Total {total} amplasament',
        MULTIPLE: 'Total {total} amplasamente',
        LIST_HEADER: 'Lista amplasamentelor',
        MODAL: {
            ADD_HEADER: 'Adaugă un amplasament'
        }
    },
    METADATA_MAPPING: {
        FIELDS: {
            METADATA: 'Metadata GU',
            ARCHIVE_METADATA_NAME: 'Metadata Arhivă Nume',
            ARCHIVE_METADATA_LABEL: 'Metadata Arhivă Etichetă',
        },
        PAGE_TITLE: 'Mapare Metadate Arhiva cu Metadate GU',
        TABLE_TITLE: 'Lista mapărilor existente',
        FORM: {
            ARCHIVE: {
                DOC_TYPE:'Selectează Document',
                METADATA: 'Selectează Metadata - Arhivă'
            },
            GU: {
                APP_TYPE: 'Selectează tip cerere',
                METADATA: 'Selectează Metadata - GU'
            }
        },
        MANDATORY_NOTE: 'Opțiunile cu prefixul (M) sunt mandatory (obligatorii).',
        CONFIRMATION_MESSAGE : 'Metadata arhiva <strong>{label}</strong> este asociat la metadata GU <strong>{metadata}</strong>. <hr> Doresti suprascrierea cu <strong>{text}</strong>?'
    },
    CONTROL: {
        PAGE_HEADER: 'Registru control',
        LIST: {
            TITLE: 'Controale',
        },
        BUTTON: {
            ADD_CONTROL: 'Control nou',
            ADD_COMPLAIN: 'Adaugă sesizare',
            ADD_LOCATION: 'Adaugă locație',
            NEXT_STEP: 'Pasul următor',
            DELETE_LOCATION: 'Șterge locație'
        },
        FIELDS: {
            CREATED_DATE: 'Data',
            CONTROL_ID: 'Cod Intrare',
            COMPLAIN_REF: 'Ref Sesizare',
            CREATED_BY: 'Creat de',
            STATUS: 'Stare',
            COMPLAIN_NUMBER: 'Nr. sesizare',
            TERRITORIAL_ADMINISTRATIVE_UNIT_ID: 'UAT',
            ADDRESS: 'Adresă'
        },
        PLACEHOLDERS: {
            CONTROL_ID: 'Cod Intrare',
            COMPLAIN_REF: 'Ref Sesizare',
            CREATED_BY: 'Creat de',
            STATUS: 'Stare',
        },
        ENUMS: {
            STATUS: {
                NEW: 'Inregistrată',
                PENDING: 'In procesare',
                RESOLVED: 'Soluţionată',
                DISMISSED: 'Clasată',
            },
        },
        LABEL: {
            CONTROL_ID: 'Numar registru',
        },
        FORM: {
            ADD_TITLE: 'Adaugă control',
            EDIT_TITLE: 'Modifică control',
            NEW_CONTROL: 'Control nou',
        },
        PREPARATION:{
            HEADER: 'Detalii sesizare',
            COMPLAIN_LIST_TAGS: 'Listă cod intrare sesizări',
            WARNING: 'Pentru a adăuga un control adaugă o locaţie sau selectează din lista de sesizări !',
            LIST: 'Listă locaţii',
            PREVIEW_MAP: 'Previzualizare din atlas teritorial',
            FORM: {
                LOCATION: 'Locaţie',
                COMPLAIN_DESCRIPTION: 'Conform sesizare',
                MODAL_HEADER: 'Date locaţie sesizare'
            }
        }

    },
    DASHBOARD: {
        APPLICATIONS_STATUS: {
            HEADER: 'Status Cereri',
            PERIOD: 'Ultimele 30 zile'
        },
        TOP_USERS: {
            HEADER: 'Top utilizatori'
        },
        REGISTRED_APPLICATIONS: {
            HEADER: 'Cereri înregistrate',
            DESCRIPTION: 'Raport cereri depuse vs soluționate',
            PERIOD: 'Ultimele 12 luni'
        },
        SOLVED_APPLICATIONS: {
            HEADER: 'Cereri soluționate',
            DESCRIPTION: 'Procentaj soluționări din totalul depunerilor',
            APPLICATIONS_TYPE_HEADER_LEGAL: 'Top tipuri de cereri',
            APPLICATIONS_TYPE_HEADER_STAFF: 'Cereri pe UAT',
        },
        RECENT_APPLICATIONS: {
            HEADER: 'Cereri recente',
            BUTTON: {
                VIEW_ALL_APPLICATIONS: 'Vezi toate cererile',
            },
        },
        RECENT_ISSUED_DOC: {
            HEADER: 'Acte emise recent',
            BUTTON: {
                VIEW_ALL_ISSUED_DOC: 'Vezi toate emiterile',
            },
        },
        TOTALS: {
            NEW: 'Total noi',
            SOLVED: 'Total soluţionate',
            IN_OPERATION: 'Total in operare',
            PERCENT: 'Procent total',
        }
    }
};


module.exports = { locale };
