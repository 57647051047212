<template>
    <div>
        <div
            v-can:create="'application'"
            class="row justify-content-center mx-5 py-5">
            <st-button
                variant="secondary"
                :block="true"
                :callback="createRequest"
                id="btn_create_request"
                customClass="text-dark application-add-button"
            >
                <i class="fas fa-plus text-dark application-btn-icon"/>
                <span class="application-create-btn-text">{{ $t('MENU.CREATE_REQUEST_BUTTON') }}</span>
            </st-button>
        </div>
        <ul class="menu-nav custom-menu-nav">
            <!-- Root -->
            <!-- Dashboard menu item available after BE is done -->
            <router-link
                v-if="hasPermissionsToViewDashboard"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/dashboard">
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fas fa-th-large"/>
                        <span class="menu-text">{{ $t('MENU.DASHBOARD') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-can:read="'applications'"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/applications">
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon far fa-file"/>
                        <span class="menu-text">{{ $t('MENU.MY_APPLICATIONS') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-if="hasPermissionToViewApplications"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/applications">
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon far fa-file"/>
                        <span class="menu-text">{{ $t('MENU.STAFF_APPLICATIONS') }}</span>
                    </a>
                    <ul>
                        <li class="menu-item menu-item-submenu menu-item-open">
                            <div class="menu-submenu menu-submenu-classic menu-submenu-right ">
                                <ul class="menu-subnav">
                                    <router-link
                                        v-can:read="'applicationsStaffOwn'"
                                        to="/applications-assigned"
                                        v-slot="{ href, navigate, isExactActive }"
                                    >
                                        <li
                                            aria-haspopup="true"
                                            data-menu-toggle="hover"
                                            class="menu-item"
                                            :class="[
                                                isExactActive && 'menu-item-active'
                                            ]"
                                            >
                                             <a :href="href" class="menu-link" @click="navigate">
                                                <i class="menu-icon fas fa-arrow-right"><span></span></i>
                                                <span class="menu-text"> {{ $t('MENU.STAFF_OWN_APPLICATIONS') }} ({{ countApplicationsAssigned }})</span>
                                            </a>
                                        </li>
                                    </router-link>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </li>
            </router-link>
            <router-link
                v-can:read="'issuedDocument'"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/issued-documents"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon far fa-folder"/>
                        <span class="menu-text">{{ $t('MENU.ISSUED_DOCUMENT') }}</span>
                    </a>
                </li>
            </router-link>
            <!-- Remove condition once complains are ready to use -->
            <router-link
                v-if="false"
                v-can:read="'complain'"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/complain">
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fas fa-exclamation-circle"/>
                        <span class="menu-text">{{ $t('MENU.MY_COMPLAINS') }}</span>
                    </a>
                </li>
            </router-link>

            <router-link
                v-can:read="'complainStaff'"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/complain">
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fas fa-exclamation-circle"/>
                        <span class="menu-text">{{ $t('MENU.STAFF_COMPLAINS') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-can:read="'control'"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/controls">
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fas fa-file-signature"/>
                        <span class="menu-text">{{ $t('MENU.CONTROLS') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-can:read="'paymentRegistry'"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/payments"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon far fa-credit-card"/>
                        <span class="menu-text">{{ $t('MENU.PAYMENT_REGISTRY') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="document-series-allocation"
                v-can:read="'documentSeriesAllocation'"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fas fa-file"></i>
                        <span class="menu-text">{{ $t('MENU.DOCUMENT_SERIES_ALLOCATION') }}</span>
                    </a>
                </li>
            </router-link>
            <!-- END Root -->
            <!-- Organisation config -->
            <li v-can:view="'organisations'">
                <hr/>
            </li>
            <li class="menu-section" v-can:view="'organisations'">
                <h4 class="menu-text">{{ $t('MENU.ORGANISATION_CONFIG') }}</h4>
                <i class="menu-icon flaticon-more-v2"/>
            </li>
            <router-link
                v-can:read="'organisations'"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/institutions"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fas fas fa-sitemap"></i>
                        <span class="menu-text">{{ $t('MENU.ORGANIZATION')}}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-can:viewOwn="'organisations'"
                v-slot="{ href, navigate, isActive, isExactActive }"
                :to="myInstitutionUrl"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fas fas fa-sitemap"></i>
                        <span class="menu-text">{{ $t('MENU.MY_ORGANIZATION')}}</span>
                    </a>
                </li>
            </router-link>
            <li v-can:view="'organisations'">
                <hr/>
            </li>
            <!-- END Organisation config -->
            <!-- System config -->
            <li class="menu-section" v-can:view="'organisations'">
                <h4 class="menu-text">{{ $t('MENU.SYSTEM_CONFIG') }}</h4>
                <i class="menu-icon flaticon-more-v2"/>
            </li>
            <router-link
                v-can:read="'processEditor'"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/process-editor"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fas fa-cogs"></i>
                        <span class="menu-text">{{ $t('MENU.PROCESS_MANAGEMENT')}}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-can:read="'accounts'"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/accounts"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fas fas fa-users"></i>
                        <span class="menu-text">{{ $t('MENU.ACCOUNTS') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-can:read="'taxCategory'"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/tax-category"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fas fas fa-hand-holding-usd"></i>
                        <span class="menu-text">{{ $t('MENU.TAX_CATEGORY') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-can:read="'complainType'"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/complain-type"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fas fas fa-exclamation-circle"></i>
                        <span class="menu-text">{{ $t('MENU.COMPLAIN_TYPE') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/application-type"
                v-can:read="'applicationType'"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fas fas fa-exclamation-circle"></i>
                        <span class="menu-text">{{ $t('MENU.APPLICATION_TYPE') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="document-series-type"
                v-can:read="'documentSeriesType'"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fas fas fa-exclamation-circle"></i>
                        <span class="menu-text">{{ $t('MENU.DOCUMENT_SERIES_TYPE') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-can:read="'forms'"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/forms">
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fab fa-wpforms"/>
                        <span class="menu-text">{{ $t('MENU.FORMS') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-can:read="'documentTemplate'"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/document-template">
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon far fa-copy"/>
                        <span class="menu-text">{{ $t('MENU.DOCUMENT_TEMPLATE') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-can:read="'responseTemplates'"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/response-templates"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fas fas fa-exclamation-circle"></i>
                        <span class="menu-text">{{ $t('MENU.RESPONSE_TEMPLATES') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-can:read="'notificationsTemplate'"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/notifications-templates"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon far fa-bell"/>
                        <span class="menu-text">{{ $t('MENU.NOTIFICATIONS') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/responsibilities"
                v-can:read="'responsibility'"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon  fas fa-people-carry"></i>
                        <span class="menu-text">{{ $t('MENU.RESPONSIBILITIES') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-can:read="'permissions'"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/permissions">
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fas fa-user-lock"/>
                        <span class="menu-text">{{ $t('MENU.PERMISSIONS') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-can:read="'metadataMapping'"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/metadata-mapping">
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fas fa-map-signs"/>
                        <span class="menu-text">{{ $t('MENU.METADATA_MAPPING') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-if="false"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/payments">
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon far fa-credit-card"/>
                        <span class="menu-text">{{ $t('MENU.PAYMENTS') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-if="false"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/documents">
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon far fa-folder"/>
                        <span class="menu-text">{{ $t('MENU.DOCUMENTS') }}</span>
                    </a>
                </li>
            </router-link>
            <li>
                <hr/>
            </li>
            <!-- END System config -->
            <li class="menu-section" v-if="isStaff">
                <h4 class="menu-text">{{ $t('MENU.EXTERNAL_APPS') }}</h4>
                <i class="menu-icon flaticon-more-v2"/>
            </li>
            <router-link
                v-if="isStaff"
                v-slot="{ href, isActive, isExactActive }"
                to="/atlas"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click.prevent="navigateTo('atlas')">
                        <i class="menu-icon far fa-map"/>
                        <span class="menu-text">{{ $t('MENU.ATLAS') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-if="isStaff"
                v-can:read="'archive'"
                v-slot="{ href, isActive, isExactActive }"
                to="/archive-dashboard"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click.prevent="navigateTo('archive')">
                        <i class="menu-icon fas fa-archive"/>
                        <span class="menu-text">{{ $t('MENU.ARCHIVE') }}</span>
                    </a>
                </li>
            </router-link>
            <li v-if="false">
                <hr/>
            </li>
             <!-- Help Section -->
            <li class="menu-section" v-if="false">
                <h4 class="menu-text">{{ $t('MENU.HELP') }}</h4>
                <i class="menu-icon flaticon-more-v2"/>
            </li>
            <router-link
                v-if="false"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/manual">
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon flaticon2-open-text-book"/>
                        <span class="menu-text">{{ $t('MENU.MANUAL') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-if="false"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/faq">
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon far fa-question-circle"/>
                        <span class="menu-text">{{ $t('MENU.FAQ') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-if="false"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/gdpr">
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fas fa-book"/>

                        <span class="menu-text">GDPR</span>
                    </a>
                </li>
            </router-link>
        </ul>
    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex';
    import { ApplicationTypePermissions } from "@/modules/application-type/application-type-permissions";
    import { InstitutionsPermissions } from "@/modules/administrator/institutions-permissions";
    import { ApplicationsPermissions } from '@/modules/applications/applications-permissions';
    import { DashboardPermissions } from '@/modules/dashboard/dashboard-permissions';

    export default {
        name: 'MenuGU',
        computed: {
            ...mapGetters({
                currentUser: 'auth/currentUser',
                accountDetails: 'auth/accountDetails',
                countApplicationsAssigned: 'applications/list/countApplicationsAssigned',
                appConfig: "shared/appConfig",
                isStaff: 'auth/isStaff',
            }),
            hasPermissionToApplicationType() {
                return new ApplicationTypePermissions(this.currentUser).read;
            },
            hasPermissionToOwnOrganisation() {
                return new InstitutionsPermissions(this.currentUser).viewOwnOrganisation;
            },
            myInstitutionUrl() {
                return `/institution?id=${this.accountDetails?.organisation_id}&tab=institution-details`;
            },
            hasPermissionToOwnStaffApplications() {
                return new ApplicationsPermissions(this.currentUser).readOwnStaff;
            },
            hasPermissionToViewOrgApplications() {
                return new ApplicationsPermissions(this.currentUser).readStaff;
            },
            hasPermissionToViewApplications() {
                return this.hasPermissionToViewOrgApplications || this.hasPermissionToOwnStaffApplications
            },
            hasPermissionsToViewDashboard() {
                return new DashboardPermissions(this.currentUser).view;
            },
        },
        methods: {
            ...mapActions({
                getAssignedApplications: 'applications/list/doFetch',
            }),
            hasActiveChildren(match) {
                return this.$route.path.indexOf(match) !== -1;
            },
            createRequest() {
                this.$router.push({ name: 'applicationNew'})
            },
            navigateTo(menu) {
                this.$emit('navigate', menu);
            }
        },
        async created() {
            if (this.hasPermissionToOwnStaffApplications) {
                //get assigned applications or the ones which need to be signed
                const payload = {
                    loadStaff: true,
                    params: {
                        skip: 0,
                        limit: this.appConfig.RECORDS_PER_PAGE,
                        pagination: true,
                        assigned: true,
                    },
                    countApplicationsAssigned: true
                };
                await this.getAssignedApplications(payload);
            }
        }
    };
</script>
