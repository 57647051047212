import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';

const ControlList = () => import('@/modules/control/pages/ControlList.vue');
const ControlForm = () => import('@/modules/control/pages/ControlForm.vue');

export default [
    {
        path: '',
        component: Layout,
        meta: { auth: true },
        children: [
            {
                name: 'controls',
                path: '/controls',
                component: ControlList,
                meta: {
                    auth: true,
                    permission: Permissions.values.controlRead,
                }
            },
            {
                name: 'controlNew',
                path: '/control/new',
                component: ControlForm,
                meta: {
                    auth: true,
                     // TODO: Update Permissions
                    permission: Permissions.values.controlRead,
                }
            },
        ],
    },
];
