<template>
    <div class="pb-lg-0 mb-5">
        <st-button
            id="st_login_facebook"
            :block="true"
            :callback="getLoginStatus"
            customClass="font-size-h4"
        >
            <b-icon icon="facebook" class="p-1"></b-icon>
            {{ buttonText }}
        </st-button>
    </div>
</template>
<script>
    import { initFbsdk } from '@/config/facebook_oAuth.js';
    import { mapActions } from 'vuex';
    import { AUTH_FACEBOOK, GET_PROFILE } from '../auth-store';

    export default {
        name: 'FacebookLoginButton',
        props: {
            formType: {
                type: String,
                required: true
            },
            isPopUpEnabled: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
        data() {
            return {
                emailNotReceivedMessage: this.$t('ERRORS.FACEBOOK_EMAIL_NOT_RECEIVED'),
                invalidAuthMessage: this.$t('ERRORS.AUTH_INVALID'),
            };
        },
        computed: {
            buttonText() {
                return this.formType === 'register'
                    ? this.$t('AUTH.REGISTER.FACEBOOK_BUTTON')
                    : this.$t('AUTH.LOGIN.FACEBOOK_BUTTON');
            }
        },
        methods: {
            ...mapActions({
                authFacebook: `auth/${AUTH_FACEBOOK}`,
                getProfile: `auth/${GET_PROFILE}`
            }),
            getLoginStatus() {
                window.FB.getLoginStatus((response) => {
                    this.statusChangeCallback(response);
                });
            },
            statusChangeCallback(response) {
                const { status } = response;
                if (status === 'connected') {
                    const { accessToken } = response.authResponse;
                    this.authWithFacebook(accessToken);
                } else if (status === 'not_authorized' || status === 'unknown') {
                    this.loginWithFacebook(true);
                }
            },
            loginWithFacebook(needRerequest) {
                window.FB.login((response) => {
                    if (response.status === 'connected') {
                        const { authResponse } = response;
                        const { accessToken, grantedScopes } = authResponse;
                        const hasEmailInScope = grantedScopes?.split(',')?.includes('email')
                        if (!hasEmailInScope) {
                            window.FB.logout();
                            this.showErrorNotification(this.emailNotReceivedMessage, false);
                        } else {
                            this.authWithFacebook(accessToken);
                        }
                    } else if (status === 'not_authorized' || status === 'unknown') {
                        this.loginWithFacebook(true);
                    }
                }, {
                    return_scopes: true,
                    scope: 'email',
                    auth_type: needRerequest ? 'rerequest': '',
                });
            },
            authWithFacebook(accessToken) {
                this.authFacebook(accessToken).then((data) => {
                    const { isPhoneConfirmed } = data;
                    
                    if (!isPhoneConfirmed) {
                        if (this.isPopUpEnabled) {
                            this.$emit('hideModal', { route: "verifySMS" });
                        } else {
                            this.$router.push({ name: 'verifySMS' });
                        }
                    } else {
                        this.getProfile().then(() => {
                            if (this.isPopUpEnabled) {
                                this.$emit('hideModal', { route: "home" });
                            } else {
                                this.$router.push({ name: 'home' });
                            }
                        });
                    }
                }).catch((message) => {
                    if (message === 'FACEBOOK_EMAIL_NOT_RECEIVED') {
                        this.$alert({
                            type: 'error',
                            text: this.emailNotReceivedMessage,
                            callback: () => {
                                this.loginWithFacebook(true);
                            }
                        });
                    } else {
                        this.$notify({
                            type: 'error',
                            message: this.invalidAuthMessage,
                        });
                    }
                });
            },
        },
        mounted() {
            initFbsdk();
        }
    };
</script>
