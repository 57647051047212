import ApiService from '@/shared/services/api.service';
import Errors from '@/shared/error/errors';

export const ADD_INSTITUTION = 'addInstitution';
export const UPDATE_INSTITUTION = 'updateInstitution';
export const DELETE_INSTITUTION = 'deleteInstitution';

const state = {};
const getters = {};

const actions = {
    [ADD_INSTITUTION](context, institution) {
        context.commit('shared/activateLoading', 'admin/addInstitution', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.post('organisations', institution)
            .then(({ data }) => {
                resolve(data);
            })
            .catch((error) => {
                Errors.handle(error);
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'admin/addInstitution', { root: true });
            })
        });
    },
    [UPDATE_INSTITUTION](context, {id, payload}) {
        context.commit('shared/activateLoading', 'admin/updateInstitution', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.patch(`organisations/${id}`, payload)
            .then(({ data }) => {
                resolve(data);
            })
            .catch((error) => {
                Errors.handle(error);
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'admin/updateInstitution', { root: true });
            })
        });
    },
    [DELETE_INSTITUTION](context, id) {
        context.commit('shared/activateLoading', 'admin/deleteInstitution', { root: true });
        return new Promise((resolve) => {
            ApiService.delete(`organisations/${id}`)
            .then(() => {
                context.commit('administrator/orgList/removeOrganisation', id, { root: true });
                resolve();
            })
            .catch((error) => {
                Errors.handle(error);
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'admin/deleteInstitution', { root: true });
            })
        });
    }
}

const mutations = {};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
