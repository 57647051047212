// action types
export const DO_INIT_STATE = 'doInit';
export const SAVE_INTERSECTED_LAYERS = 'saveIntersectedLayers';
export const SAVE_POINT_OF_INTERSECTION = 'savePointOfIntersection';

// mutation types
export const INIT_STATE = 'init';
export const SET_LAYERS = 'setLayers';
export const SET_POINT_OF_INTERSECTION = 'setPointOfIntersection';

const getDefaultState = () => ({
    layers: [],
    pointOfIntersection: null,
});

const state = getDefaultState();

const getters = {
    layers: (state) => state.layers,
    pointOfIntersection: (state) => state.pointOfIntersection,
};

const actions = {
    [DO_INIT_STATE](context) {
        context.commit(INIT_STATE);
    },
    [SAVE_INTERSECTED_LAYERS](context, layers) {
        context.commit(SET_LAYERS, layers);
    },
    [SAVE_POINT_OF_INTERSECTION](context, point) {
        context.commit(SET_POINT_OF_INTERSECTION, point);
    }
};

const mutations = {
    [INIT_STATE](state) {
        Object.assign(state, getDefaultState());
    },
    [SET_LAYERS](state, layers) {
        state.layers = layers;
    },
    [SET_POINT_OF_INTERSECTION](state, point) {
        state.pointOfIntersection = point;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};