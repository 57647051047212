<template>
    <form class="form"
        novalidate="novalidate"
        :id="`st_citizen_form_${id}`"

    >
        <div class="row">
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label>
                        {{ $t('SET_PROFILE_DATA.PERSON.PNC') }} *
                    </label>
                    <st-input-text
                        v-model="personFields.personal_numeric_code"
                        ref="personal_numeric_code"
                        name="personal_numeric_code"
                    />
                </div>
                <div class="form-group">
                    <label>
                        {{ $t('SET_PROFILE_DATA.PERSON.LAST_NAME') }} *
                    </label>
                    <div label="">
                        <st-input-text v-model="personFields.last_name" ref="last_name" name="last_name"/>
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ $t('SET_PROFILE_DATA.PERSON.FIRST_NAME') }} *
                    </label>
                    <div label="">
                        <st-input-text v-model="personFields.first_name" ref="first_name" name="first_name"/>
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        Judet *
                    </label>
                    <div label="">
                        <b-form-select
                            v-model="personFields.county"
                            :options="counties"
                            ref="county"
                            name="county"
                            value-field="county"
                            text-field="county"
                            >
                        </b-form-select>
                    </div>
                </div>

            </div>
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label>
                        {{ $t('SET_PROFILE_DATA.PERSON.LOCALITY') }} *
                    </label>
                    <div label="">
                        <b-form-select
                            v-model="personFields.locality"
                            :options="cityList"
                            value-field="name"
                            text-field="name"
                            ref="locality"
                            name="locality"
                            :disabled= "!cityList.length > 0"
                        ></b-form-select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm">
                        <label>
                            {{ $t('SET_PROFILE_DATA.PERSON.VILLAGE') }}
                        </label>
                        <div label="">
                            <b-form-select
                                v-model="personFields.village"
                                :options="villageList"
                                value-field="name"
                                text-field="name"
                                ref="villages"
                                name="villages"
                                :disabled= "!villageList.length > 0"
                            ></b-form-select>
                        </div>
                    </div>
                    <div class="col-sm">
                        <div class="form-group">
                            <label>
                                {{ $t('SET_PROFILE_DATA.PERSON.SECTOR') }}
                            </label>
                            <div label="">
                                <st-input-text v-model="personFields.sector" ref="sector" name="sector"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ $t('SET_PROFILE_DATA.PERSON.STREET') }} *
                    </label>
                    <div label="">
                        <st-input-text v-model="personFields.street" ref="street" name="street"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm">
                        <div class="form-group">
                            <label>
                                {{ $t('SET_PROFILE_DATA.PERSON.POSTAL_CODE') }} *
                            </label>
                            <div label="">
                                <st-input-text v-model="personFields.postal_code" ref="postal_code" name="postal_code"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm">
                        <div class="form-group">
                            <label>
                                {{ $t('SET_PROFILE_DATA.PERSON.NUMBER') }} *
                            </label>
                            <div label="">
                                <st-input-text v-model="personFields.street_number" ref="number" name="number"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <div class="row">
                        <div class="col-sm">
                            <label>
                                {{ $t('SET_PROFILE_DATA.PERSON.STAIRCASE') }}
                            </label>
                            <div label="">
                                <st-input-text v-model="personFields.staircase" ref="staircase" name="staircase"/>
                            </div>
                        </div>
                        <div class="col-sm">
                            <label>
                                {{ $t('SET_PROFILE_DATA.PERSON.BLOCK') }}
                            </label>
                            <div label="">
                                <st-input-text v-model="personFields.block" ref="block" name="block"/>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-sm">
                            <label>
                                {{ $t('SET_PROFILE_DATA.PERSON.FLOOR') }}
                            </label>
                            <div label="">
                                <st-input-text v-model="personFields.floor" ref="floor" name="floor"/>
                            </div>
                        </div>
                        <div class="col-sm">
                            <label>
                                {{ $t('SET_PROFILE_DATA.PERSON.APARTMENT') }}
                            </label>
                            <div label="">
                                <st-input-text v-model="personFields.apartment" ref="apartment" name="apartment"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ $t('SET_PROFILE_DATA.PERSON.EMAIL') }} *
                    </label>
                    <div label="">
                        <st-input-text
                            v-model="personFields.email"
                            ref="email"
                            name="email"/>
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ $t('SET_PROFILE_DATA.PERSON.PHONE') }} *
                    </label>
                    <div label="">
                        <st-input-text
                            v-model="personFields.phone_number"
                            ref="phone_number"
                            name="phone_number"
                            set-validation
                            />
                    </div>
                </div>

            </div>
        </div>
    </form>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import localitiesAndVillagesMixin from '@/modules/beneficiary/mixins/citizen-person-mixin';

// FormValidation plugins
import formValidation from '@/assets/plugins/formvalidation/dist/es6/core/Core';
import Trigger from '@/assets/plugins/formvalidation/dist/es6/plugins/Trigger';
import Bootstrap from '@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap';
import SubmitButton from '@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton';

export default {
    name: 'CitizenForm',
    props: {
        id: {
            type: String,
            required: true
        },
        isContact: {
            type: Boolean,
            required: true
        }
    },
    mixins: [localitiesAndVillagesMixin],
    data() {
        return {
            personFields: {
                user_type_name: 'citizen',
                personal_numeric_code: '',
                last_name: '',
                first_name: '',
                county: '',
                locality: '',
                village: '',
                sector: '',
                street: '',
                postal_code: '',
                street_number: '',
                staircase: '',
                block: '',
                floor: '',
                apartment: '',
                email: '',
                phone_number: '',
                isContactPerson: false
            },
            cityList: [],
            villageList: []
        }
    },
    computed: {
        ...mapGetters({
            localities: 'profile/settlements',
            villages: 'profile/villages',
        }),
        ...mapState({
            counties: state => state.shared.counties,
        }),
    },
    mounted() {
        const citizen_form = KTUtil.getById(`st_citizen_form_${this.id}`);
        const PHONE_REGEX = '^[\\+\\d]?(?:[\\d]*)$';
        this.fv = formValidation(citizen_form, {
            fields: {
                personal_numeric_code: {
                    validators: {
                        notEmpty: {
                            message: this.$t('AUTH.VALIDATION.REQUIRED_FIELD'),
                        },
                        pnc: {
                            message: this.$t('AUTH.VALIDATION.INVALID_PNC'),
                        },
                    },
                },
                last_name: {
                    validators: {
                        notEmpty: {
                            message: this.$t('AUTH.VALIDATION.REQUIRED_FIELD'),
                        },
                    },
                },
                first_name: {
                    validators: {
                        notEmpty: {
                            message: this.$t('AUTH.VALIDATION.REQUIRED_FIELD'),
                        },
                    },
                },
                county: {
                    validators: {
                        notEmpty: {
                            message: this.$t('AUTH.VALIDATION.REQUIRED_FIELD'),
                        },
                    },
                },
                locality: {
                    validators: {
                        notEmpty: {
                            message: this.$t('AUTH.VALIDATION.REQUIRED_FIELD'),
                        },
                    },
                },
                street: {
                    validators: {
                        notEmpty: {
                            message: this.$t('AUTH.VALIDATION.REQUIRED_FIELD'),
                        },
                    },
                },
                number: {
                    validators: {
                        notEmpty: {
                            message: this.$t('AUTH.VALIDATION.REQUIRED_FIELD'),
                        },
                    },
                },
                postal_code: {
                    validators: {
                        notEmpty: {
                            message: this.$t('AUTH.VALIDATION.REQUIRED_FIELD'),
                        },
                        numeric: {
                            message: this.$t('AUTH.VALIDATION.NUMERIC_FIELD'),
                        },
                        stringLength: {
                            max: 6,
                            message: this.$t('AUTH.VALIDATION.POSTAL_CODE_MAX_LENGTH_FIELD'),
                        },
                    },
                },
                email: {
                    validators: {
                        notEmpty: {
                            message: this.$t('AUTH.VALIDATION.REQUIRED_FIELD'),
                        },
                        emailAddress: {
                            message: this.$t('AUTH.VALIDATION.INVALID_EMAIL'),
                        },
                    },
                },
                phone_number: {
                    validators: {
                        notEmpty: {
                            message: this.$t('AUTH.VALIDATION.REQUIRED_FIELD'),
                        },
                        regexp: {
                            regexp: PHONE_REGEX,
                            message: this.$t('AUTH.VALIDATION.INVALID_PHONE'),
                        }
                    },
                }

            },
            plugins: {
                trigger: new Trigger(),
                submitButton: new SubmitButton(),
                bootstrap: new Bootstrap(),
            },
        });
    },
    methods: {
        onSubmit() {
            console.log('citizen on submit')

            // status can be one of the following value
            // 'NotValidated': The form is not yet validated
            // 'Valid': The form is valid
            // 'Invalid': The form is invalid

            return this.fv.validate()
            .then((status)=>{
                this.personFields.isContactPerson = this.isContact;
                if (status === 'Valid') {
                    return {
                        items: this.personFields
                    }
                } else return false
            });
        }
    }
}
</script>
