import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';

const DocumentSeriesAllocationList = () => import('@/modules/document-series-allocation/pages/DocumentSeriesAllocationList.vue');

export default [
    {
        path: '',
        component: Layout,
        meta: { auth: true },
        children: [
            {
                name: 'document-series-allocation',
                path: '/document-series-allocation',
                component: DocumentSeriesAllocationList,
                meta: {
                    auth: true,
                    permission: Permissions.values.documentSeriesAllocationRead,
                }
            },
        ],
    },
];

