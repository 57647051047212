import ApiService from '@/shared/services/api.service';

export class PaymentRegistryService {
    static list(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/staff-applications', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }
}
