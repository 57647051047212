import PermissionChecker from '@/security/permission-checker';
import Permissions from '@/security/permissions';

export class ApplicationsPermissions {
    constructor(currentUser) {
        const permissionChecker = new PermissionChecker(
            currentUser,
        );

        this.read = permissionChecker.match(
            Permissions.values.applicationsRead,
        );
        this.create = permissionChecker.match(
            Permissions.values.applicationCreate,
        );
        this.view = permissionChecker.match(
            Permissions.values.applicationsView,
        );
        this.readStaff = permissionChecker.match(
            Permissions.values.applicationsStaffRead,
        );
        this.createDoc = permissionChecker.match(
            Permissions.values.createDoc
        );
        this.edit = permissionChecker.match(
            Permissions.values.applicationEdit,
        );
        this.readOwnStaff = permissionChecker.match(
            Permissions.values.applicationsStaffOwnRead,
        );
        this.reassignApplication = permissionChecker.match(
            Permissions.values.applicationsStaffAssignEdit
        );

    }
}
