import Vue from 'vue';
import Counties from '@/shared/utils/counties';
import appConfig from '@/config/app.config.json';
import ApiService from '@/shared/services/api.service';
import i18n from '@/shared/plugins/vue-i18n';
import { PERMISSIONS_LIST } from '@/security/permissionsMapping';
import Errors from '@/shared/error/errors';

// action types
export const FETCH_SETTINGS = 'fetchSettings';
export const DOWNLOAD_FILE = 'downloadFile';
export const FETCH_SORTING_PARAMS = 'fetchSortingParams';
export const SET_ACTIVE_LOADING = 'setActiveLoading';
export const SET_INACTIVE_LOADING = 'setInactiveLoading';

// mutation types
export const SET_ORGANISATIONS = 'setOrganisations';
export const SET_USER_TYPES = 'setUserTypes';
export const SET_SETTINGS = 'setSettings';
export const SET_SORTING_PARAMS = 'setSortingParams';

const state = {
    loading: {},
    counties: Counties,
    settings: {},
    defaultAvatar: 'media/users/default.jpg',
    statuses: [
        {
            text: i18n.t('GENERAL.ACTIVE'),
            value: 'active',
        },
        {
            text: i18n.t('GENERAL.INACTIVE'),
            value: 'inactive',
        },
    ],
    organisations: [],
    permissions: PERMISSIONS_LIST,
};


const getters = {
    appConfig: (state) => state.settings,
    loading: (state) => state.loading,
    statuses: (state) => state.statuses,
    defaultCountyId: (state) => {
      const defaultCounty = state.settings.DEFAULT_COUNTY;
      return state.counties.find((el) => el.county === defaultCounty)?.id;
    },
    counties: (state) => state.counties,
    permissions: (state) => state.permissions,
    organisations: (state) => state.organisations,
};

const actions = {
    getOrganisations(context) {
        context.commit('shared/activateLoading', 'shared/getOrganisations', { root: true });
        return new Promise(resolve => {
            ApiService.get('organisations').then(({data}) => {
                resolve(data);
                context.commit(SET_ORGANISATIONS, data.data);
            }) .catch(({ error }) => {
                reject(error);
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'shared/getOrganisations', { root: true });
            });
        });
    },
    getUserTypes(context) {
        context.commit('shared/activateLoading', 'shared/getUserTypes', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.get('user-types').then(({data}) => {
                resolve(data);
                context.commit(SET_USER_TYPES, data.data);
            }).catch(({ error }) => {
                reject(error);
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'shared/getUserTypes', { root: true });
            });
        });
    },
    async [FETCH_SETTINGS](context) {
        try {
            const { data } = await ApiService.get('settings-registry')
            context.commit(SET_SETTINGS, data.data);
        } catch (e) {
            context.commit(SET_SETTINGS, appConfig);
        }
    },
    [DOWNLOAD_FILE](context, url) {
        context.commit('shared/activateLoading', 'shared/downloadFile', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.download(url)
            .then((response) => {
                const fileURL = window.URL.createObjectURL(new Blob([response.data]));
                const fileLink = document.createElement('a');
                fileLink.href = fileURL;
                // TODO: Find a better file name for download
                fileLink.setAttribute('download', 'file.pdf');
                document.body.appendChild(fileLink);

                fileLink.click();
            })
            .catch((error) => {
                Errors.handle(error);
                reject();
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'shared/downloadFile', { root: true });
            });
        });
    },
    [FETCH_SORTING_PARAMS](context, {stateModule, sortingParams}) {
        context.commit(`${stateModule}/setSort`, sortingParams, { root: true }) ;
    },
    [SET_ACTIVE_LOADING](context, key) {
        context.commit('activateLoading', key);
    },
    [SET_INACTIVE_LOADING](context, key) {
        context.commit('deactivateLoading', key);
    },
};

const mutations = {
    activateLoading(state, key) {
        Vue.set(state.loading, key, true);
    },
    deactivateLoading(state, key) {
        Vue.set(state.loading, key, false);
    },
    [SET_ORGANISATIONS](state, organisations) {
        state.organisations = organisations;
    },
    [SET_USER_TYPES](state, userTypes) {
        state.userTypes = userTypes;
    },
    [SET_SETTINGS](state, settings) {
        const settingsObject = settings.reduce(
            (obj, item) => Object.assign(obj, { [item.key]: item.default_value }), {});
        state.settings = settingsObject;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
