import routes from '@/shared/shared-routes';

import Loader from '@/shared/components/StLoader';
import StButton from '@/shared/components/StButton';
import StInputText from '@/shared/components/StInputText';
import StAutocomplete from '@/shared/components/StAutocomplete';
import StModal from '@/shared/components/StModal';
import StRichtextEditor from '@/shared/components/StRichtextEditor';
import StInfoAlert from '@/shared/components/StInfoAlert';
import StDataTable from '@/shared/components/StDataTable';
import StSelect from '@/shared/components/StSelect';
import StDateRange from '@/shared/components/StDateRange';
import StDatepicker from '@/shared/components/StDatepicker';
import StFiltersPagination from '@/shared/components/filters-pagination/StFiltersPagination';
import StSection from '@/shared/components/StSection';
import StPage from '@/shared/components/StPage';

import ariaCommon from '@/shared/mixins/aria-common';
import positiveIntegerOnly from '@/shared/directives/positive-integer-only';
import positiveDecimalOnly from '@/shared/directives/positive-decimal-only';
import can from '@/shared/directives/can';

import store from '@/shared/services/store/shared-store';

export default {
    components: [
        Loader,
        StButton,
        StInputText,
        StRichtextEditor,
        StInfoAlert,
        StDataTable,
        StAutocomplete,
        StModal,
        StSelect,
        StDateRange,
        StDatepicker,
        StFiltersPagination,
        StPage,
        StSection,
    ],
    filters: [],
    mixins: [ ariaCommon ],
    directives: [ 
        positiveIntegerOnly,
        positiveDecimalOnly,
        can,
    ],
    routes,
    store
};
