<template>
  <div>
        <input
            v-bind="$attrs"
            v-model="input"
            :disabled="disabled"
            :readonly="readonly"
            :type="type"
            class="form-control"
            @keydown="enableKeydown ? keydownHandler($event) : null"
            @blur="enableBlur ? $emit('blur', $event.target.value) : null"
        />
  </div>
</template>

<script>
  export default {
    name: 'StInputText',
    inheritAttrs: false,
    props: {
        value: {
            type: [String, Number],
        },
        setValidation: {
           type: Boolean,
           default: false
        },
        phoneValidation: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        allowOnlyLetters: {
            type: Boolean,
            default: false,
        },
        allowOnlyAlphanumeric: {
            type: Boolean,
            default: false,
        },
        maxLength: {
            type: Number,
            default: 255,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: 'text',
        },
        enableBlur: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            debouncedInput: this.value,
            timeout: null,
            enableKeydown: this.phoneValidation || this.setValidation || this.allowOnlyLetters || this.allowOnlyAlphanumeric,
        }
    },
    computed: {
        input: {
            get() {
                return this.value;
            },
            set(val) {
                if (this.timeout) clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                    this.debouncedInput = val;
                    this.$emit('input', val);
                }, 300);
            }
        }
    },
    methods: {
        keydownHandler(event) {
            if (this.phoneValidation) {
                return this.validatePhoneNumber(event);
            } else if (this.setValidation) {
                return this.isNumber(event);
            } else if (this.allowOnlyLetters) {
                return this.isLetter(event);
            } else if (this.allowOnlyAlphanumeric) {
                return this.isLetterOrNumber(event);
            }
        },
        validatePhoneNumber(evt) {
            evt = (evt) ? evt : window.event;
            const charCode = (evt.which) ? evt.which : evt.keyCode;
            if (evt.key === 'Shift' || charCode === 8 || charCode === 37 || charCode === 39 || evt.keyCode === 9) return true;
            if (this.value?.length === 10) {
                evt.preventDefault();
            }
            // For numpad numbers
            if(evt.keyCode >= 96 && evt.keyCode <= 105 ) {
                return true;
            }
            if (charCode !== 43 && charCode > 31 && (charCode < 48 || charCode > 57)) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        isNumber(event) {
            const evt = (event) ? event : window.event;
            if(evt.keyCode >= 37 && evt.keyCode <= 40 ) {
                return;
            }
            if(evt.keyCode === 8 || evt.keyCode === 46 || evt.keyCode === 9) {
                return;
            }
            if (this.maxLength > 0 && this.value?.length >= this.maxLength) evt.preventDefault();
            // For numpad numbers
            if (evt.keyCode >= 96 && evt.keyCode <= 105 ) {
                return true;
            }
            let char = String.fromCharCode(evt.keyCode);
            if(/^[0-9]+$/.test(char)) return true;
            evt.preventDefault();
        },
        isLetter(event) {
            const evt = (event) ? event : window.event;

            if(evt.keyCode >= 37 && evt.keyCode <= 40 ) {
                return;
            }
            if(evt.keyCode === 8 || evt.keyCode === 46 || evt.keyCode === 9) {
                return;
            }
            if (this.maxLength > 0 && this.value?.length >= this.maxLength) evt.preventDefault();
            let char = String.fromCharCode(evt.keyCode);
            if(/^([-A-Za-z\s]{1,13})+$/.test(char)) return true;
            evt.preventDefault();
        },
        isLetterOrNumber(event) {
            const evt = (event) ? event : window.event;
            if(evt.keyCode >= 37 && evt.keyCode <= 40 ) {
                return;
            }
            if(evt.keyCode === 8 || evt.keyCode === 46 || evt.keyCode === 9 || evt.keyCode === 189) {
                return;
            }
            if (this.maxLength > 0 && this.value?.length >= this.maxLength) evt.preventDefault();
            let char = String.fromCharCode(evt.keyCode);
            if(/^[0-9A-Za-z\s]+$/.test(char)) return true;
            evt.preventDefault();
        }
    }
  }
</script>
