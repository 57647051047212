import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';

const DocumentTemplateList = () => import('@/modules/document-template/pages/DocumentTemplateList.vue');
const DocumentTemplateForm = () => import('@/modules/document-template/pages/DocumentTemplateForm.vue');

export default [
    {
        path: '',
        component: Layout,
        meta: { auth: true },
        children: [
            {
                name: 'documentTemplate',
                path: '/document-template',
                component: DocumentTemplateList,
                meta: {
                    auth: true,
                    permission: Permissions.values.documentTemplateRead,
                }
            },
            {
                name: 'documentTemplateNew',
                path: '/document-template/new',
                component: DocumentTemplateForm,
                meta: {
                    auth: true,
                    permission: Permissions.values.documentTemplateCreate,
                }
            },
            {
                name: 'documentTemplateEdit',
                path: '/document-template/:id',
                component: DocumentTemplateForm,
                meta: {
                    auth: true,
                    permission: Permissions.values.documentTemplateEdit,
                },
                props: true,
            },
        ],
    },
];
