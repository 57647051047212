import { render, staticRenderFns } from "./DropdownNotification.vue?vue&type=template&id=7050e636&scoped=true&"
import script from "./DropdownNotification.vue?vue&type=script&lang=js&"
export * from "./DropdownNotification.vue?vue&type=script&lang=js&"
import style0 from "@/assets/sass/pages/notifications/in-app-notifications.scss?vue&type=style&index=0&id=7050e636&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7050e636",
  null
  
)

export default component.exports