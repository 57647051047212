import { mapActions } from 'vuex';
// TODO: make the mixin globally
export default {
    watch: {
        'personFields.county': {
            deep: true,
            immediate: true,
            handler(countyValue, oldCountyValue) {
                if (countyValue === oldCountyValue) return false;
                this.personFields.locality = '';

                if (countyValue) {
                    // get the selected county to use the ID on the request
                    const countyParams = this.counties.find( county => county.county === countyValue);

                    this.getLocalities({parent_id: countyParams.id})
                        .then(()=> {
                            this.cityList = this.localities;

                            // reset field after another county is selected, after the default selection is made
                            this.fv.resetField('locality', true);
                        })
                        .catch((error)=> {
                            console.log('error', error);
                            this.$notify({
                                type: 'error',
                                message: this.$t('SET_PROFILE_DATA.ERRORS.LOCALITIES'),
                            });
                        });
                } else {
                    // reset cityList on reload or when the profile type is changed and then came back
                    this.cityList = [];
                }
            },
        },
        'personFields.locality': {
            deep: true,
            immediate: true,
            handler(newValue, oldValue) {
                if (newValue === oldValue) return false;
                this.personFields.village = '';
                if (newValue) {
                    const localityParams = this.localities.find( locality => locality.name === newValue);
                    if (localityParams && localityParams.search_villages)  {
                        this.getVillages({parent_id: localityParams.search_villages})
                            .then(()=> {
                                this.villageList = this.villages;
                            })
                            .catch(() => {
                                this.$notify({
                                    type: 'error',
                                    message: this.$t('SET_PROFILE_DATA.ERRORS.VILLAGES'),
                                });
                            })
                    } else {
                        this.villageList = [];
                    }
                } else {
                    // reset cityList on reload or when the profile type is changed and then came back
                    this.villageList = [];
                }
            },
        },
    },
    methods: {
        ...mapActions({
            getLocalities: 'profile/getLocalities',
            getVillages: 'profile/getVillages',
        }),
    }

};

