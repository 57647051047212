import Errors from '@/shared/error/errors';
import Message from '@/shared/message/message';
import i18n from '@/shared/plugins/vue-i18n';

// action types

// mutation types
export const FIND_SUCCESS = 'findSuccess';
export const RESET = 'reset';

const state = {
    record: {},

};

const getters = {
    record: (state) => state.record,
};
const mutations = {
    [FIND_SUCCESS](state, payload) {
        state.record = data;
    },
    [RESET](state) {
        state.record = null;
    }
};
const actions = {}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
